import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

/**
 * Campaign suggested creators store
 */
export interface CampaignState {
  creators: {[key: string]: any};
}

export type CampaignSuggestedCreatorsStoreState = {
  selectedCampaignId: string;
  selectedCampaignNetwork: string;
  selectedCreatorId: string;
  campaigns: {[key: string]: CampaignState};
};

const useCampaignSuggestedCreatorsStore =
  create<CampaignSuggestedCreatorsStoreState>()(
    immer(() => ({
      selectedCampaignId: '',
      selectedCampaignNetwork: '',
      selectedCreatorId: '',
      campaigns: {},
    }))
  );

const setSelectedCampaignId = (campaignId: string) => {
  useCampaignSuggestedCreatorsStore.setState((draft) => {
    draft.selectedCampaignId = campaignId;
  });
};

const setSelectedCampaignNetwork = (campaignNetwork: string) => {
  useCampaignSuggestedCreatorsStore.setState((draft) => {
    draft.selectedCampaignNetwork = campaignNetwork;
  });
};

const setSelectedCreatorId = (creatorId: string) => {
  useCampaignSuggestedCreatorsStore.setState((draft) => {
    draft.selectedCreatorId = creatorId;
  });
};

const setCampaignCreatorInvited = (
  campaignId: string,
  creatorProfileId: string,
  invited: boolean
) => {
  useCampaignSuggestedCreatorsStore.setState((draft) => {
    const campaign = draft.campaigns[campaignId];
    draft.campaigns[campaignId] = {
      ...campaign,
      creators: {
        ...(campaign?.creators || {}),
        [creatorProfileId]: invited,
      },
    };
  });
};

export const campaignSuggestedCreatorsActions = {
  setSelectedCampaignId,
  setSelectedCampaignNetwork,
  setSelectedCreatorId,
  setCampaignCreatorInvited,
};

export default useCampaignSuggestedCreatorsStore;
