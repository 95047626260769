import React from 'react';
import styles from './StepSectionContainer.module.scss';

type StepSectionContainerProps = {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  containerClassName?: string;
};

function StepSectionContainer(props: StepSectionContainerProps) {
  const {children, title, subtitle, containerClassName} = props;
  return (
    <div className={`${styles.stepSectionContainer} ${containerClassName}`}>
      {title || subtitle ? (
        <div className={styles.titleContainer}>
          {title ? <label className={styles.title}>{title}</label> : null}
          {subtitle ? (
            <label className={styles.subtitle}>{subtitle}</label>
          ) : null}
        </div>
      ) : null}
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
}

export default StepSectionContainer;
