import dayjs from 'dayjs';
import i18n from 'i18next';
import {
  camelCase,
  capitalize,
  difference,
  get,
  orderBy,
  snakeCase,
} from 'lodash';
import {useMemo} from 'react';
import translate from '@/utils/translate';
import {
  CombinedPostAnalysis,
  CombinedPostAnalysisFailure,
  PostAnalysis,
  PostAnalysisFailure,
} from '@/types/creatorSafetyReport';
import {TimeRange} from '@/types/timeRange';
import {
  FAILED_SCAN_FLAG,
  Severity,
  VETTING_CATEGORY,
  VETTING_FLAGS_TO_CATEGORY_MAPPING,
} from '@/components/creator-vetting-report/constants';
import getSeverityFromFlagScore from '@/components/creator-vetting-report/utils/getSeverityFromFlagScore';

interface Flag {
  id: string;
  name: string;
  postsAmount: number;
  percentage: number;
  posts: CombinedPostAnalysis[];
}

const INITIAL_SEVERITY_BREAKDOWN = {
  [Severity.ALL]: 0,
  [Severity.LOW]: 0,
  [Severity.MEDIUM]: 0,
  [Severity.HIGH]: 0,
};

export interface VettingCategory {
  id: string;
  name: string;
  postsAmount: number;
  percentage: number;
  severityBreakDown: Record<Severity, number>;
  flags: Flag[];
  hidden?: boolean;
}

export interface FlagWithCategory extends Flag {
  category: Omit<VettingCategory, 'flags' | 'severityBreakDown'>;
}

export interface FailedFlagWithCategory
  extends Omit<FlagWithCategory, 'posts'> {
  posts: CombinedPostAnalysisFailure[];
}

export interface VettingCategories {
  flagged: VettingCategory[];
  cleared: VettingCategory[];
  failed: FailedFlagWithCategory;
  flatFlaggedWithCategory: FlagWithCategory[];
  totalFlaggedPosts: number;
  filteredFlaggedPosts: number;
}

interface FlagAcc extends Flag {
  topScore: number;
  flagTopScore: number;
}

interface FlagObjectAcc {
  [key: string]: FlagAcc;
}

interface VettingCategoryAcc extends Omit<VettingCategory, 'flags'> {
  topScore: number;
  flags: FlagObjectAcc;
}

interface CategoryAcc {
  [key: string]: VettingCategoryAcc;
}

interface VettingFilters {
  timeRange: TimeRange;
  severity: Severity;
}

function getCategoryFlags(category: string): FlagObjectAcc {
  return Object.entries(VETTING_FLAGS_TO_CATEGORY_MAPPING)
    .filter(([, flagCategory]) => flagCategory === category)
    .reduce((acc: FlagObjectAcc, [flag]) => {
      acc[flag] = {
        id: flag,
        name: flag,
        postsAmount: 0,
        percentage: 0,
        topScore: 0,
        flagTopScore: 0,
        posts: [],
      };
      return acc;
    }, {});
}

function getPrettyName(name: string, camelCaseLookup = false): string {
  let lookupName = name;

  if (camelCaseLookup) {
    lookupName = camelCase(name);
  }

  if (i18n.exists(lookupName)) {
    return translate(lookupName);
  }
  const splitName = name.split('_');
  const [firstWord, ...rest] = splitName;

  return [
    capitalize(firstWord.toLowerCase()),
    ...rest.map((word) => word.toLowerCase()),
  ].join(' ');
}

function getPostIsAtGivenSeverity(
  severity: Severity,
  post: CombinedPostAnalysis,
  flag: string
): boolean {
  if (severity === Severity.ALL) {
    return true;
  }
  const {flags} = post.mediaAnalysisResult;

  const flagScore = get(flags, `${flag}.score`, 0) as number;
  const flagSeverity = getSeverityFromFlagScore(flagScore);
  return flagSeverity === severity;
}

function filteredPosts(
  posts: CombinedPostAnalysis[],
  vettingFilters: VettingFilters,
  flag: string
): CombinedPostAnalysis[] {
  return posts.filter((post: CombinedPostAnalysis) => {
    const {timeRange} = vettingFilters;
    if (!timeRange.start || !timeRange.end) {
      return true;
    }
    const {createdAt} = post.postMetadata;
    const createdAtDate = dayjs(createdAt).toDate();
    const isAtGivenCreatedAtDateRange =
      createdAtDate >= timeRange.start && createdAtDate <= timeRange.end;
    const isAtGivenSeverity = getPostIsAtGivenSeverity(
      vettingFilters.severity,
      post,
      flag
    );
    return isAtGivenCreatedAtDateRange && isAtGivenSeverity;
  });
}

function addPostToCombinedPosts(
  postsMap: Map<string, Map<string, CombinedPostAnalysis>>,
  flag: string,
  post: PostAnalysis,
  flagScore: number
): number {
  if (!postsMap.has(flag)) {
    postsMap.set(flag, new Map());
  }

  const flagPostsMap = postsMap.get(flag)!;
  const existingPost = flagPostsMap?.get(post.postMetadata.id);

  if (!existingPost) {
    const newPost: CombinedPostAnalysis = {
      ...post,
      postMetadata: {
        ...post.postMetadata,
        mediaIndex: [post.postMetadata.mediaIndex].filter(
          (index): index is number => index !== undefined
        ),
        highestScoreMediaIndex: post.postMetadata.mediaIndex ?? 0,
      },
    };
    flagPostsMap.set(post.postMetadata.id, newPost);
    return 1;
  }

  const combinedMediaIndexes = [
    ...existingPost.postMetadata.mediaIndex,
    post.postMetadata.mediaIndex,
  ].filter((index): index is number => index !== undefined);

  const currentScore = existingPost.mediaAnalysisResult.flags[flag]?.score || 0;
  const highestScoreMediaIndex: number =
    flagScore > currentScore
      ? post.postMetadata.mediaIndex ?? 0
      : existingPost.postMetadata.highestScoreMediaIndex;
  const basePost = flagScore > currentScore ? post : existingPost;

  const updatedPost: CombinedPostAnalysis = {
    ...basePost,
    postMetadata: {
      ...basePost.postMetadata,
      mediaIndex: combinedMediaIndexes,
      highestScoreMediaIndex,
    },
  };

  flagPostsMap.set(post.postMetadata.id, updatedPost);
  return 0;
}

function addFailedPostToCombined(
  postsMap: Map<string, CombinedPostAnalysisFailure>,
  post: PostAnalysisFailure
): void {
  const existingPost = postsMap.get(post.postMetadata.id);

  if (!existingPost) {
    const newPost: CombinedPostAnalysisFailure = {
      ...post,
      postMetadata: {
        ...post.postMetadata,
        mediaIndex: [post.postMetadata.mediaIndex].filter(
          (index): index is number => index !== undefined
        ),
        highestScoreMediaIndex: post.postMetadata.mediaIndex ?? 0,
      },
    };
    postsMap.set(post.postMetadata.id, newPost);
    return;
  }

  const combinedMediaIndexes = [
    ...existingPost.postMetadata.mediaIndex,
    post.postMetadata.mediaIndex,
  ].filter((index): index is number => index !== undefined);

  const updatedPost: CombinedPostAnalysisFailure = {
    ...existingPost,
    postMetadata: {
      ...existingPost.postMetadata,
      mediaIndex: combinedMediaIndexes,
    },
  };

  postsMap.set(post.postMetadata.id, updatedPost);
}

function useGetVettingCategories(
  flaggedPosts: PostAnalysis[],
  failedPosts: PostAnalysisFailure[],
  disabledCategories: Record<string, boolean>,
  vettingFilters: VettingFilters
): VettingCategories {
  let totalFlaggedPosts = 0;
  const flaggedCategories = useMemo(() => {
    const combinedPostsMap = new Map<
      string,
      Map<string, CombinedPostAnalysis>
    >(); // flag -> (postId -> CombinedPostAnalysis)

    const categories = flaggedPosts.reduce((acc: CategoryAcc, post) => {
      const {flags} = post.mediaAnalysisResult;
      Object.keys(flags).forEach((flag) => {
        if (!(flag in VETTING_FLAGS_TO_CATEGORY_MAPPING)) {
          return;
        }

        const category = VETTING_FLAGS_TO_CATEGORY_MAPPING[flag];
        if (!acc[category]) {
          acc[category] = {
            id: category,
            name: category,
            postsAmount: 0,
            percentage: 0,
            severityBreakDown: {...INITIAL_SEVERITY_BREAKDOWN},
            topScore: 0,
            flags: getCategoryFlags(category),
          };
        }

        acc[category].postsAmount += 1;
        const flagScore = flags[flag]?.score || 0;
        const flagRisk = getSeverityFromFlagScore(flagScore);
        acc[category].severityBreakDown[flagRisk] += 1;
        acc[category].severityBreakDown[Severity.ALL] += 1;
        if (flagScore > acc[category].topScore) {
          acc[category].topScore = flagScore;
        }
        if (!acc[category].flags[flag]) {
          acc[category].flags[flag] = {
            id: flag,
            name: flag,
            postsAmount: 0,
            percentage: 0,
            topScore: 0,
            flagTopScore: 0,
            posts: [],
          };
        }
        acc[category].flags[flag].postsAmount += 1;
        acc[category].flags[flag].topScore = acc[category].topScore;
        if (flagScore > acc[category].flags[flag].flagTopScore) {
          acc[category].flags[flag].flagTopScore = flagScore;
        }
        totalFlaggedPosts += addPostToCombinedPosts(
          combinedPostsMap,
          flag,
          post,
          flagScore
        );
      });

      return acc;
    }, {});

    Object.keys(categories).forEach((category) => {
      Object.keys(categories[category].flags).forEach((flag) => {
        const flagPostsMap = combinedPostsMap.get(flag);
        if (flagPostsMap) {
          categories[category].flags[flag].posts = Array.from(
            flagPostsMap.values()
          );
        }
      });
    });

    return categories;
  }, [flaggedPosts]);

  const flagged = useMemo(
    () =>
      orderBy(
        Object.values(flaggedCategories).map((category) => ({
          id: category.name,
          name: getPrettyName(category.name),
          postsAmount: category.postsAmount,
          percentage: category.topScore * 100,
          severityBreakDown: category.severityBreakDown,
          flags: orderBy(
            Object.values(category.flags).map((flag) => ({
              ...flag,
              percentage: flag.topScore * 100,
              name: getPrettyName(snakeCase(flag.name), true),
            })),
            ['name'],
            ['asc']
          ),
        })),
        ['postsAmount'],
        ['desc']
      ),
    [flaggedCategories]
  );

  const clearedCategories = useMemo(
    () =>
      difference(
        Object.keys(VETTING_CATEGORY),
        flagged.map((category) => category.id)
      ),
    [flagged]
  );

  const cleared = useMemo(
    () =>
      orderBy(
        clearedCategories.map((category) => ({
          id: category,
          hidden: disabledCategories[category],
          name: getPrettyName(category),
          postsAmount: 0,
          percentage: 0,
          severityBreakDown: {...INITIAL_SEVERITY_BREAKDOWN},
          flags: orderBy(
            Object.values(getCategoryFlags(category)).map((flag) => ({
              ...flag,
              name: getPrettyName(snakeCase(flag.name), true),
            })),
            ['name'],
            ['asc']
          ),
        })),
        ['hidden'],
        ['asc']
      ),
    [clearedCategories]
  );

  const flatFlaggedWithCategory = useMemo(
    () =>
      orderBy(
        flagged.reduce((acc: FlagWithCategory[], category) => {
          const filteredFlaggedCategories = category.flags
            .filter((flag) => flag.postsAmount > 0)
            .map((flag) => {
              const posts = orderBy(
                filteredPosts(flag.posts, vettingFilters, flag.id),
                [`mediaAnalysisResult.flags.${flag.id}.score`],
                ['desc']
              );
              return {
                ...flag,
                posts,
                postsAmount: posts.length,
                category: {
                  id: category.id,
                  name: category.name,
                  postsAmount: posts.length,
                  percentage: category.percentage,
                },
              };
            });

          return acc.concat(
            filteredFlaggedCategories.filter((flag) => flag.postsAmount > 0)
          );
        }, []),
        ['flagTopScore'],
        ['desc']
      ),
    [flagged, vettingFilters]
  );

  const failed = useMemo(() => {
    const failedPostsMap = new Map<string, CombinedPostAnalysisFailure>();
    failedPosts.forEach((post) => {
      addFailedPostToCombined(failedPostsMap, post);
    });
    const posts = orderBy(
      Array.from(failedPostsMap.values()),
      [`postMetadata.createdAt`],
      ['desc']
    );

    return {
      id: FAILED_SCAN_FLAG,
      name: translate('Content Not Scanned'),
      postsAmount: posts.length,
      posts,
      percentage: -1,
      category: {
        id: FAILED_SCAN_FLAG,
        name: translate('Content Not Scanned'),
        postsAmount: posts.length,
        percentage: -1,
      },
    };
  }, [failedPosts]);

  // const totalFlaggedPosts = flaggedPosts.reduce((acc, post) => {
  //   const {flags} = post.mediaAnalysisResult;
  //   return acc + Object.keys(flags).length;
  // }, 0);
  const filteredFlaggedPosts = flatFlaggedWithCategory.reduce((acc, flag) => {
    return acc + flag.postsAmount;
  }, 0);

  return {
    flagged,
    cleared,
    failed,
    flatFlaggedWithCategory,
    totalFlaggedPosts,
    filteredFlaggedPosts,
  };
}

export default useGetVettingCategories;
