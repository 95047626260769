import React from 'react';
import {Radio} from '@mui/material';
import {Body} from '@lightricks/react-design-system';
import DropdownButton from '@/components/dropdown-button';
import {Option} from '@/components/dropdown-button/DropdownButton';
import SocialNetworkIcon from '@/components/social-network-icon';
import useVettingHubStore, {
  vettingHubActions,
  type VettingHubState,
} from '@/stores/vettingHubStore';
import styles from './NetworkDropdown.module.scss';

const SOCIAL_NETWORK_ICON_SIZE = 28;
const SOCIAL_NETWORK_ICON_SIZE_LARGE = 32;

const NETWORK_OPTIONS: Option<NetworkOption>[] = [
  {id: 'instagram', label: 'Instagram'},
  {id: 'tiktok', label: 'TikTok'},
];

type NetworkOption = VettingHubState['searchNetwork'];

type NetworkDropdownProps = {
  options?: Option<NetworkOption>[];
  onClick?: () => void;
  onClose?: () => void;
};

function NetworkDropdown(props: NetworkDropdownProps) {
  const {options = NETWORK_OPTIONS, onClick, onClose} = props;
  const {setSearchNetwork} = vettingHubActions;
  const searchNetwork = useVettingHubStore((state) => state.searchNetwork);

  const onChange = (val: Option<NetworkOption>) => {
    setSearchNetwork(val.id);
    onClose?.();
  };

  return (
    <DropdownButton<NetworkOption>
      label={
        <SocialNetworkIcon
          width={SOCIAL_NETWORK_ICON_SIZE}
          height={SOCIAL_NETWORK_ICON_SIZE}
          name={searchNetwork}
        />
      }
      menuWidth={300}
      menuProps={{classes: {paper: styles.menu, list: styles.list}}}
      menuItemProps={{classes: {root: styles.option}}}
      options={options}
      onChange={onChange}
      onClick={onClick}
      onClose={onClose}
      renderMenuItem={(option) => (
        <NetworkDropdownOption
          option={option}
          isSelected={searchNetwork === option.id}
        />
      )}
      buttonClassName={styles.button}
    />
  );
}

type NetworkDropdownOptionProps = {
  option: Option<NetworkOption>;
  isSelected: boolean;
};

function NetworkDropdownOption(props: NetworkDropdownOptionProps) {
  const {option, isSelected} = props;

  return (
    <>
      <SocialNetworkIcon
        name={option.id}
        width={SOCIAL_NETWORK_ICON_SIZE_LARGE}
        height={SOCIAL_NETWORK_ICON_SIZE_LARGE}
      />
      <Body size="lg" className={styles.optionLabel}>
        {option.label}
      </Body>
      <Radio checked={isSelected} sx={{p: 0}} />
    </>
  );
}

export default NetworkDropdown;
