import {designSystemToken} from '@lightricks/react-design-system';

/**
 * Generates CSS box-shadow value for a given elevation level
 * @param level - The elevation level to generate shadow for
 * @returns CSS box-shadow value string
 */
function elevationShadow(level: number): string {
  const shadows = Array.from({length: 2}, (_, layer) => {
    const x = designSystemToken(`shadow.elevation-${level}.${layer}.x`);
    const y = designSystemToken(`shadow.elevation-${level}.${layer}.y`);
    const blur = designSystemToken(`shadow.elevation-${level}.${layer}.blur`);
    const spread = designSystemToken(
      `shadow.elevation-${level}.${layer}.spread`
    );
    const color = designSystemToken(`shadow.elevation-${level}.${layer}.color`);

    return `${x} ${y} ${blur} ${spread} ${color}`;
  });

  return shadows.join(', ');
}

export default elevationShadow;
