import React from 'react';
import {
  Slider as DiscreteSlider,
  SliderProps as DiscreteSliderProps,
} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';

interface SliderProps extends DiscreteSliderProps {
  testID?: string;
  sliderRef?: React.RefObject<HTMLDivElement> | null;
}

function Slider(props: SliderProps) {
  const {
    testID = 'slider',
    sliderRef,
    sx,
    valueLabelDisplay = 'off',
    ...rest
  } = props;

  return (
    <DiscreteSlider
      data-testid={testID}
      ref={sliderRef}
      marks
      valueLabelDisplay={valueLabelDisplay}
      sx={{
        'marginTop': '4px',
        'marginBottom': '16px',
        '& .MuiSlider-rail': {
          backgroundColor: designSystemToken('semantic.bg.neutral-inverse'),
          height: '2px',
        },
        '& .MuiSlider-valueLabel': {
          padding: '10px 12px',
          gap: '8px',
          borderRadius: '8px',
          background: designSystemToken('semantic.bg.secondary'),
          boxShadow:
            '0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 2px 12px -2px rgba(24, 39, 75, 0.12)',
          color: designSystemToken('semantic.fg.primary'),
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '18px',
          letterSpacing: '-0.2px',
        },
        '& .MuiSlider-track': {
          backgroundColor: designSystemToken('semantic.fg.primary'),
          height: '2px',
          border: 0,
        },
        '& .MuiSlider-thumb': {
          'width': '18px',
          'height': '18px',
          'color': '#ffffff',
          'outline': '2px solid #ffffff',
          'border': '2px solid #000000',
          'padding': '2px',
          '&::before': {
            boxShadow: 'none',
          },
        },
        '& .MuiSlider-mark': {
          'backgroundColor': designSystemToken('semantic.bg.neutral-inverse'),
          'height': '8px',
          'width': '2px',
          'marginTop': 0,
          '&.MuiSlider-markActive': {
            backgroundColor: designSystemToken('semantic.fg.primary'),
          },
        },
        '&.Mui-disabled': {
          'color': designSystemToken('semantic.bg.neutral-inverse'),
          '& .MuiSlider-track': {
            backgroundColor: designSystemToken('semantic.bg.neutral-inverse'),
            height: '2px',
          },
          '& .MuiSlider-mark': {
            'backgroundColor': designSystemToken('semantic.bg.neutral-inverse'),
            'height': '8px',
            'width': '2px',
            'marginTop': 0,
            '&.MuiSlider-markActive': {
              backgroundColor: designSystemToken('semantic.bg.neutral-inverse'),
            },
          },
          '& .MuiSlider-thumb': {
            width: '18px',
            height: '18px',
            color: '#ffffff',
            outline: '2px solid #ffffff',
            border: `2px solid ${designSystemToken(
              'semantic.bg.neutral-inverse'
            )}`,
            padding: '2px',
          },
        },
        ...sx,
      }}
      {...rest}
    />
  );
}

export default Slider;
