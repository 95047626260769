import {get, isEmpty} from 'lodash';
import React, {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
  Label,
  Tooltip,
} from '@lightricks/react-design-system';
import getCountryFullName from '@/utils/getCountryFullName';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {TiktokAccount, TiktokAudience} from '@/types/TiktokAccount';
import {Identity} from '@/types/identity';
import Badge from '@/components/badge';
import BarChart from '@/components/charts/bar-chart';
import DonutChart from '@/components/charts/donut-chart';
import Conditional from '@/components/conditional';
import {TIKTOK_IDENTITY_IDS} from '@/components/creator-profile/constants';
import Divider from '@/components/divider';
import Link from '@/components/link';
import SocialNetworkIcon from '@/components/social-network-icon';
import useIdentityHealthEvaluationQuery from '@/hooks/queries/use-identity-health-evaluation-query';
import useTiktokAccountQuery from '@/hooks/queries/use-tiktok-account-query';
import styles from './SocialNetworkIdentitySummary.module.scss';

const TRANSLATION_PREFIX = 'components.social-network-identity-summary';
const SOCIAL_NETWORK_ICON_SIZE = 29;

const MALE_COLOR = LEGACY_COLORS.BLUE_DARK;
const FEMALE_COLOR = LEGACY_COLORS.PURPLE;

const BAR_CHART_ADDITIONAL_OPTIONS = {
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

function getCountryFlagUrl(countryFlag: string) {
  return `url(/assets/svg/country-flags/${countryFlag.toLowerCase()}.svg)`;
}

export type SocialNetworkIdentitySummaryProps = {
  testID?: string;
  identity: Identity;
  initialIsOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  onOpenUrl?: (identity: Identity) => void;
};

const LEVEL_COLOR_MAP = {
  high: LEGACY_COLORS.GREEN,
  medium: LEGACY_COLORS.YELLOW,
  low: LEGACY_COLORS.RED,
  unknown: LEGACY_COLORS.YELLOW,
} as {[key: string]: string};

const COMPLIANCE_COLOR_MAP = {
  compliant: LEGACY_COLORS.GREEN,
  probably_compliant: LEGACY_COLORS.YELLOW,
} as {[key: string]: string};

const COMPLIANCE_TOOLTIP_LOCALE_KEY_MAP = {
  compliant: `${TRANSLATION_PREFIX}.lda-compliant.compliant`,
  probably_compliant: `${TRANSLATION_PREFIX}.lda-compliant.probably_compliant`,
} as {[key: string]: string};

function EvaluationRatingBadge({evaluationRating}: {evaluationRating: string}) {
  function getLevel() {
    if (evaluationRating === 'unhealthy') {
      return 'low';
    }
    if (evaluationRating === 'investigate') {
      return 'medium';
    }
    if (evaluationRating === 'healthy') {
      return 'high';
    }

    return 'unknown';
  }

  const level = getLevel();
  const label = translate(`${TRANSLATION_PREFIX}.health-scores.badge.${level}`);

  return (
    <Badge
      text={label}
      size="xs"
      textColor={designSystemToken('semantic.fg.inverse')}
      backgroundColor={LEVEL_COLOR_MAP[level]}
    />
  );
}

function LdaComplianceBadge({
  ldaComplianceStanding,
}: {
  ldaComplianceStanding: string;
}) {
  return (
    <Tooltip
      type="dark"
      sx={{maxWidth: '200px'}}
      title={
        <Label size="md" color="white" sx={{textAlign: 'center'}}>
          {translate(COMPLIANCE_TOOLTIP_LOCALE_KEY_MAP[ldaComplianceStanding])}
        </Label>
      }
      placement="top"
      arrow
    >
      <div>
        <Badge
          text={translate(`${TRANSLATION_PREFIX}.lda-compliant.lda`)}
          size="xs"
          textColor={designSystemToken('semantic.fg.inverse')}
          backgroundColor={COMPLIANCE_COLOR_MAP[ldaComplianceStanding]}
        />
      </div>
    </Tooltip>
  );
}

const TIKTOK_SUMMARY_STATS_MAP = [
  {
    id: 'followers',
    valueKey: 'formattedFollowersCount',
    condition: (tiktokAudience: TiktokAudience | null) =>
      !!tiktokAudience?.followersCount,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.followers`,
  },
  {
    id: 'likes',
    valueKey: 'formattedAverageLikes',
    condition: (tiktokAudience: TiktokAudience | null) =>
      !!tiktokAudience?.averageLikes || 0,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.likes`,
  },
  {
    id: 'engagement-rate',
    valueKey: 'formattedEngagementRate',
    condition: (tiktokAudience: TiktokAudience | null) =>
      !!tiktokAudience?.engagementRate,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.engagement-rate`,
  },
  {
    id: 'views',
    valueKey: 'formattedAverageViews',
    condition: (tiktokAudience: TiktokAudience | null) =>
      !!tiktokAudience?.averageViews,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.views`,
  },
];

const BASE_SUMMARY_STATS_MAP = [
  {
    id: 'likes',
    valueKey: 'formattedEngagementValue',
    condition: (identity: Identity) =>
      !!identity.engagementValue && identity.engagementLabel === 'likes',
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.likes`,
  },
  {
    id: 'stories',
    valueKey: 'formattedAverageStoryImpressions',
    condition: (identity: Identity) => !!identity.averageStoryImpressions,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.stories`,
  },
  {
    id: 'reels',
    valueKey: 'formattedAverageReelsPlays',
    condition: (identity: Identity) => !!identity.averageReelsPlays,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.reels`,
  },
  {
    id: 'engagement-rate',
    valueKey: 'formattedAverageEngagementRate',
    condition: (identity: Identity) => !!identity.averageEngagementRate,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.engagement-rate`,
  },
  {
    id: 'impressions',
    valueKey: 'formattedAverageImpressionsCount',
    condition: (identity: Identity) => !!identity.averageImpressionsCount,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.impressions`,
  },
  {
    id: 'views',
    valueKey: 'formattedAverageViewCount',
    condition: (identity: Identity) => !!identity.averageViewCount,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.views`,
  },
];

const SUMMARY_STATS_MAP = [
  {
    id: 'followers',
    valueKey: 'formattedCachedFollowerCount',
    condition: (identity: Identity) => !!identity.cachedFollowerCount,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.followers`,
  },
  ...BASE_SUMMARY_STATS_MAP,
];

const YOUTUBE_SUMMARY_STATS_MAP = [
  {
    id: 'followers',
    valueKey: 'formattedCachedFollowerCount',
    condition: (identity: Identity) => !!identity.cachedFollowerCount,
    localeLabelKey: `${TRANSLATION_PREFIX}.profile-identity-summary.stats.subscribers`,
  },
  ...BASE_SUMMARY_STATS_MAP,
];

function SummaryStat({value, label}: {value: string; label: string}) {
  return (
    <div className={styles.summaryStatContainer}>
      <Headline size="md" color={designSystemToken('semantic.fg.neutral')}>
        {value}
      </Headline>
      <Headline size="xxs" color={designSystemToken('semantic.fg.secondary')}>
        {label}
      </Headline>
    </div>
  );
}

function DemographicItem({
  main,
  title,
  subtitle,
}: {
  main: React.ReactNode;
  title: string;
  subtitle: string;
}) {
  return (
    <div className={styles.demographic}>
      {main}
      <div>
        <Headline size="sm" color={designSystemToken('semantic.fg.neutral')}>
          {title}
        </Headline>
        <Headline size="xxs" color={designSystemToken('semantic.fg.secondary')}>
          {subtitle}
        </Headline>
      </div>
    </div>
  );
}

function TiktokAudienceStats({tiktokAccount}: {tiktokAccount: TiktokAccount}) {
  const {currentAudience: audience} = tiktokAccount;
  function renderTopCountry() {
    if (tiktokAccount?.topCountry) {
      return (
        <DemographicItem
          main={
            <div
              className={styles.countryFlag}
              style={{
                backgroundImage: getCountryFlagUrl(
                  tiktokAccount.topCountry.country.toLowerCase()
                ),
              }}
            />
          }
          title={getCountryFullName(tiktokAccount.topCountry.country)}
          subtitle={translate(
            `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.first`,
            {
              percentage: tiktokAccount.topCountry.percentage,
            }
          )}
        />
      );
    }
    return null;
  }

  function renderTopAgeGroup() {
    if (!audience || !audience.ages.length || !audience?.topAgeGroup) {
      return null;
    }

    const ageGroupGraphicValues =
      audience.ages.map((ageGroup) => {
        return ageGroup.percentage;
      }) || [];

    const indexOfMaxValue = ageGroupGraphicValues.reduce(
      (iMax, x, i, arr) => (x > arr[iMax] ? i : iMax),
      0
    );
    const ageGroupGraphicBarColors = ageGroupGraphicValues.map((_, i) => {
      if (i === indexOfMaxValue) {
        return LEGACY_COLORS.GREEN;
      }

      return LEGACY_COLORS.GRAY_LIGHT;
    });
    return (
      <DemographicItem
        main={
          <div className={styles.barChart}>
            <BarChart
              datasets={[
                {
                  data: ageGroupGraphicValues,
                  backgroundColor: ageGroupGraphicBarColors,
                  borderWidth: 0,
                },
              ]}
              labels={ageGroupGraphicValues.map(() => '')}
              displayLegend={false}
              displayTooltip={false}
              additionalOptions={BAR_CHART_ADDITIONAL_OPTIONS}
            />
          </div>
        }
        title={String(audience?.topAgeGroup.age)}
        subtitle={translate(
          `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.second`,
          {
            percentage: audience?.topAgeGroup.percentage,
          }
        )}
      />
    );
  }

  function renderTopGender() {
    if (
      !audience ||
      (!audience.maleGenderPercentage && !audience.femaleGenderPercentage)
    ) {
      return null;
    }

    let title = translate(
      `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.gender.both`
    );
    if (audience?.isMostlyMale) {
      title = translate(
        `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.gender.men`
      );
    } else if (audience?.isMostlyFemale) {
      title = translate(
        `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.gender.women`
      );
    }

    const genderPercentageGraphicValues = [
      audience?.maleGenderPercentage || 0,
      audience?.femaleGenderPercentage || 0,
    ];
    return (
      <DemographicItem
        main={
          <div className={styles.donutChart}>
            <DonutChart
              datasets={[
                {
                  data: genderPercentageGraphicValues,
                  backgroundColor: [MALE_COLOR, FEMALE_COLOR],
                  borderWidth: 0,
                },
              ]}
              labels={[
                translate(`${TRANSLATION_PREFIX}.genders.men`),
                translate(`${TRANSLATION_PREFIX}.genders.women`),
              ]}
            />
          </div>
        }
        title={title}
        subtitle={translate(
          `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.gender.percentages`,
          {
            malePercentage: audience?.maleGenderPercentage,
            femalePercentage: audience?.femaleGenderPercentage,
          }
        )}
      />
    );
  }

  return (
    <div className={styles.identitySummaryRow}>
      {renderTopCountry()}
      {renderTopAgeGroup()}
      {renderTopGender()}
    </div>
  );
}

function AudienceStats({identity}: {identity: Identity}) {
  const {audience} = identity;
  function renderTopCountry() {
    if (audience?.topCountry) {
      return (
        <DemographicItem
          main={
            <div
              className={styles.countryFlag}
              style={{
                backgroundImage: getCountryFlagUrl(
                  audience.topCountry.country.toLowerCase()
                ),
              }}
            />
          }
          title={getCountryFullName(audience.topCountry.country)}
          subtitle={translate(
            `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.first`,
            {
              percentage: audience.topCountry.percentage,
            }
          )}
        />
      );
    }
    return null;
  }

  function renderTopAgeGroup() {
    const ageGroupGraphicValues =
      audience?.genderAgePercentages.all.map(
        (ageGroup) => ageGroup.percentage
      ) || [];
    const indexOfMaxValue = ageGroupGraphicValues.reduce(
      (iMax, x, i, arr) => (x > arr[iMax] ? i : iMax),
      0
    );
    const ageGroupGraphicBarColors = ageGroupGraphicValues.map((value, i) => {
      if (i === indexOfMaxValue) {
        return LEGACY_COLORS.GREEN;
      }

      return LEGACY_COLORS.GRAY_LIGHT;
    });
    return (
      <DemographicItem
        main={
          <div className={styles.barChart}>
            <BarChart
              datasets={[
                {
                  data: ageGroupGraphicValues,
                  backgroundColor: ageGroupGraphicBarColors,
                  borderWidth: 0,
                },
              ]}
              labels={ageGroupGraphicValues.map(() => '')}
              displayLegend={false}
              displayTooltip={false}
              additionalOptions={BAR_CHART_ADDITIONAL_OPTIONS}
            />
          </div>
        }
        title={String(audience?.topAgeGroup.ageRange)}
        subtitle={translate(
          `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.second`,
          {
            percentage: audience?.topAgeGroup.percentage,
          }
        )}
      />
    );
  }

  function renderTopGender() {
    let title = translate(
      `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.gender.both`
    );
    if (audience?.isMostlyMale) {
      title = translate(
        `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.gender.men`
      );
    } else if (audience?.isMostlyFemale) {
      title = translate(
        `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.gender.women`
      );
    }

    const genderPercentageGraphicValues = [
      audience?.normalizedMaleGenderPercentage || 0,
      audience?.normalizedFemaleGenderPercentage || 0,
    ];
    return (
      <DemographicItem
        main={
          <div className={styles.donutChart}>
            <DonutChart
              datasets={[
                {
                  data: genderPercentageGraphicValues,
                  backgroundColor: [MALE_COLOR, FEMALE_COLOR],
                  borderWidth: 0,
                },
              ]}
              labels={[
                translate(`${TRANSLATION_PREFIX}.genders.men`),
                translate(`${TRANSLATION_PREFIX}.genders.women`),
              ]}
            />
          </div>
        }
        title={title}
        subtitle={translate(
          `${TRANSLATION_PREFIX}.profile-identity-summary.demographics.gender.percentages`,
          {
            malePercentage: audience?.normalizedMaleGenderPercentage,
            femalePercentage: audience?.normalizedFemaleGenderPercentage,
          }
        )}
      />
    );
  }

  return (
    <div className={styles.identitySummaryRow}>
      {renderTopCountry()}
      {renderTopAgeGroup()}
      {renderTopGender()}
    </div>
  );
}

function TiktokIdentitySummary({
  tiktokAccount,
}: {
  tiktokAccount: TiktokAccount;
}) {
  if (tiktokAccount.needsRelink) {
    return (
      <div className={styles.identitySummaryContainer}>
        <Label size="lg">
          {translate(
            `${TRANSLATION_PREFIX}.profile-identity-summary.needs-relink`
          )}
        </Label>
      </div>
    );
  }

  const {currentAudience} = tiktokAccount;

  const shouldShowAudienceStats = Boolean(
    currentAudience &&
      (tiktokAccount.topCountry ||
        currentAudience.ages.length ||
        !isEmpty(currentAudience.genders))
  );

  return (
    <div className={styles.identitySummaryContainer}>
      <div className={styles.identitySummaryRow}>
        {TIKTOK_SUMMARY_STATS_MAP.filter((stat) =>
          stat.condition(currentAudience)
        ).map((stat) => (
          <SummaryStat
            key={stat.id}
            value={get(currentAudience, stat.valueKey)}
            label={translate(stat.localeLabelKey)}
          />
        ))}
      </div>
      <Conditional condition={shouldShowAudienceStats}>
        <>
          <Divider margin={16} />
          <TiktokAudienceStats tiktokAccount={tiktokAccount} />
        </>
      </Conditional>
    </div>
  );
}

function IdentitySummary({identity}: {identity: Identity}) {
  const {audience} = identity;

  const shouldShowAudienceStats = Boolean(
    audience && (audience.topCountry || audience.topAgeGroup)
  );

  const summaryStatsMap =
    identity.provider === 'youtube'
      ? YOUTUBE_SUMMARY_STATS_MAP
      : SUMMARY_STATS_MAP;

  return (
    <div className={styles.identitySummaryContainer}>
      <div className={styles.identitySummaryRow}>
        {summaryStatsMap
          .filter((stat) => stat.condition(identity))
          .map((stat) => (
            <SummaryStat
              key={stat.id}
              value={get(identity, stat.valueKey)}
              label={translate(stat.localeLabelKey)}
            />
          ))}
      </div>
      <Conditional condition={shouldShowAudienceStats}>
        <>
          <Divider margin={16} />
          <AudienceStats identity={identity} />
        </>
      </Conditional>
    </div>
  );
}

function SocialNetworkIdentitySummary(
  props: SocialNetworkIdentitySummaryProps
) {
  const {
    testID = 'social-network-identity-summary',
    identity,
    initialIsOpen = false,
    onOpenChange,
    onOpenUrl,
  } = props;
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const {healthEvaluation} = useIdentityHealthEvaluationQuery({
    identityId: identity.id,
    enabled: true,
  });

  const isTiktokIdentity = TIKTOK_IDENTITY_IDS.includes(identity.provider);

  const {tiktokAccount} = useTiktokAccountQuery({
    accountId: identity.uid,
    enabled: isTiktokIdentity && !!identity.uid,
  });

  const shouldRenderCompliance = !!(
    identity.ldaComplianceStanding ||
    (isTiktokIdentity && tiktokAccount?.audienceUsageRating) ||
    healthEvaluation.rating
  );

  function renderHandle() {
    if (isTiktokIdentity && tiktokAccount) {
      return tiktokAccount.handle;
    }
    return identity.cachedHandle;
  }

  function renderIdentitySummary() {
    if (isTiktokIdentity && tiktokAccount) {
      return <TiktokIdentitySummary tiktokAccount={tiktokAccount} />;
    }
    return <IdentitySummary identity={identity} />;
  }

  function renderLdaComplianceBadge() {
    return identity.ldaComplianceStanding ? (
      <LdaComplianceBadge
        ldaComplianceStanding={identity.ldaComplianceStanding}
      />
    ) : null;
  }

  function renderEvaluationRatingBadge() {
    if (isTiktokIdentity && tiktokAccount?.audienceUsageRating) {
      return (
        <EvaluationRatingBadge
          evaluationRating={tiktokAccount.audienceUsageRating}
        />
      );
    }
    if (healthEvaluation.rating) {
      return (
        <EvaluationRatingBadge evaluationRating={healthEvaluation.rating} />
      );
    }
    return null;
  }

  if (isTiktokIdentity && !identity.uid) {
    // do not render if identity is tiktok and uid is not present (public profile endpoint does not return uid)
    return null;
  }

  return (
    <div
      className={styles.socialNetworkIdentitySummaryContainer}
      data-testid={testID}
      data-identity-provider={identity.provider}
    >
      <div
        className={styles.header}
        onClick={() => {
          setIsOpen(!isOpen);
          onOpenChange?.(!isOpen);
        }}
      >
        <div className={styles.socialNetworkAndCompliance}>
          <div className={styles.socialNetworkContainer}>
            <SocialNetworkIcon
              width={SOCIAL_NETWORK_ICON_SIZE}
              height={SOCIAL_NETWORK_ICON_SIZE}
              name={identity.provider}
            />
            <Link
              to={identity.profilePageUrl}
              onClick={(event) => {
                event.stopPropagation();
                onOpenUrl?.(identity);
              }}
              target="_blank"
              keepBrandIdIfPresent={false}
            >
              <Body size="xl" className={styles.handle}>
                {renderHandle()}
              </Body>
            </Link>
          </div>
          <Conditional condition={shouldRenderCompliance}>
            <div className={styles.complianceContainer}>
              {renderLdaComplianceBadge()}
              {renderEvaluationRatingBadge()}
            </div>
          </Conditional>
        </div>
        <div
          className={`${styles.caretContainer} ${
            isOpen ? styles.open : styles.closed
          }`}
        >
          <Icon
            size="large"
            appearance="neutral"
            name="Actions-Arrow-Down-Small"
          />
        </div>
      </div>
      <AnimateHeight
        duration={500}
        height={isOpen ? 'auto' : 0}
        className={styles.body}
      >
        {renderIdentitySummary()}
      </AnimateHeight>
    </div>
  );
}

export default SocialNetworkIdentitySummary;
