import {camelCase} from 'lodash';
import {useMemo} from 'react';
import translate from '@/utils/translate';
import {
  BrandSafetyToleranceSettings,
  BrandSafetyToleranceSettingsDTO,
  CategoryDTO,
  FlagDTO,
} from '@/types/brandSafetyToleranceSettings';
import CategoryStatus from '@/types/categoryStatusEnum';
import brandSafetyToleranceSettingsFetchers from '@/api/fetchers/brandSafetyToleranceSettings';
import useDataQuery from '@/hooks/use-data-query';

/**
 * Transforms a tolerance settings DTO to a tolerance settings object
 * @param data
 */
const transformToleranceSettingsDTOtoToleranceSettings = (
  data: BrandSafetyToleranceSettingsDTO | undefined
): BrandSafetyToleranceSettings => {
  if (!data) {
    return {
      categories: [],
    };
  }
  return {
    categories: data.categories.map(transformCategoryDTOtoCategory),
  };
};

/**
 * Transforms a category DTO to a category object
 * @param category
 */
const transformCategoryDTOtoCategory = (category: CategoryDTO) => {
  const categoryName = category.name.toUpperCase();
  return {
    ...category,
    name: categoryName,
    displayName: translate(categoryName),
    flags: category.flags.map(transformFlagDTOtoFlag),
  };
};

/**
 * Transforms a flag DTO to a flag object
 * @param flag
 */
const transformFlagDTOtoFlag = (flag: FlagDTO) => {
  const flagName = camelCase(flag.name);
  return {
    ...flag,
    id: flagName,
    name: flagName,
    displayName: translate(flagName),
  };
};

/**
 * Returns an object with flag names as keys and their values as values
 * if a category is disabled, it's flags are not included in the object so they can be later filtered out completely from the report
 * example object:
 * {
 *     'flagName1': 0,
 *     'flagName2': 0.33,
 *     'flagName3': 0.66,
 *     'flagName4': 0.33,
 *     ...
 * }
 * @param toleranceSettings
 */
function getFlagValuesObject(
  toleranceSettings: BrandSafetyToleranceSettingsDTO | undefined
) {
  if (!toleranceSettings) {
    return {};
  }
  return toleranceSettings.categories.reduce(
    (acc: Record<string, number>, category) => {
      // If the category is disabled, we do not include its flags in the object
      if (category.status === CategoryStatus.DISABLED) {
        return acc;
      }
      return category.flags.reduce((_, flag) => {
        acc[camelCase(flag.name)] = flag.value;
        return acc;
      }, acc);
    },
    {}
  );
}

/**
 * Returns an object with category names as keys and boolean values indicating if the category is disabled
 * example object:
 * {
 *     'CATEGORY_NAME_1': true,
 *     'CATEGORY_NAME_2': false,
 *     'CATEGORY_NAME_3': true,
 *     ...
 * }
 * @param toleranceSettings
 */
function getDisabledCategoriesObject(
  toleranceSettings: BrandSafetyToleranceSettingsDTO | undefined
) {
  if (!toleranceSettings) {
    return {};
  }
  return toleranceSettings?.categories.reduce(
    (acc: Record<string, boolean>, category) => {
      acc[category.name.toUpperCase()] =
        category.status === CategoryStatus.DISABLED;
      return acc;
    },
    {}
  );
}

function getIsDefaultTolerance(
  toleranceSettings: BrandSafetyToleranceSettingsDTO | undefined
) {
  if (!toleranceSettings) {
    return true;
  }
  return toleranceSettings.categories.every(
    (category) =>
      category.status === CategoryStatus.ENABLED &&
      category.flags.every((flag) => flag.value === 0)
  );
}

export function getQueryKey(brandId: string) {
  return ['brandSafetyToleranceSettings', brandId];
}

function useBrandSafetyToleranceSettingsQuery({brandId}: {brandId: string}) {
  const response = useDataQuery<BrandSafetyToleranceSettingsDTO>({
    queryKey: getQueryKey(brandId),
    queryFn: brandSafetyToleranceSettingsFetchers.customTolerance,
    meta: {brandId},
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const flagValues = useMemo(
    () => getFlagValuesObject(response.data),
    [response.data]
  );
  const disabledCategories = useMemo(
    () => getDisabledCategoriesObject(response.data),
    [response.data]
  );
  const isDefaultSettings = useMemo(
    () => getIsDefaultTolerance(response.data),
    [response.data]
  );

  return {
    ...response,
    toleranceSettings: transformToleranceSettingsDTOtoToleranceSettings(
      response.data
    ),
    flagValues,
    disabledCategories,
    isDefaultSettings,
  };
}

export default useBrandSafetyToleranceSettingsQuery;
