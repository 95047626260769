import React from 'react';
import {Category} from '@/types/brandSafetyToleranceSettings';
import CategoryStatus from '@/types/categoryStatusEnum';
import CategoryFlag from '../category-flag/CategoryFlag';
import styles from './CategoryFlagsList.module.scss';

interface CategoryFlagsListProps {
  category: Category;
  onFlagChange: (flagId: string, value: number) => void;
}

function CategoryFlagsList(props: CategoryFlagsListProps) {
  const {category, onFlagChange} = props;
  return (
    <div className={styles.categoryFlagsContainer}>
      {category.flags.map((flag) => (
        <CategoryFlag
          key={flag.id}
          flag={flag}
          disabled={category.status === CategoryStatus.DISABLED}
          onChange={(flagId, value) => onFlagChange(flagId, value)}
        />
      ))}
    </div>
  );
}

export default CategoryFlagsList;
