import React, {useEffect, useState} from 'react';
import styles from './SegmentedProgressBar.module.scss';

interface ProgressItem {
  id: string;
  amount: number;
  color: string;
}

export interface SegmentedProgressBarProps {
  testID?: string;
  percentage: number;
  initialPercentage?: number;
  items: ProgressItem[];
}

function SegmentedProgressBar(props: SegmentedProgressBarProps) {
  const {
    testID = 'segmented-progress-bar',
    percentage,
    initialPercentage = 0,
    items,
  } = props;

  const [percentageProxy, setPercentageProxy] = useState(initialPercentage);

  useEffect(() => {
    // in order to initially animate the progress bar, we need to set the value in the next tick
    const timeout = setTimeout(() =>
      setPercentageProxy(Math.min(100, percentage))
    );
    return () => clearTimeout(timeout);
  }, [percentage]);

  const totalValue = items.reduce((sum, item) => sum + item.amount, 0);

  return (
    <div data-testid={testID} className={styles.segmentedProgressBarContainer}>
      <div className={styles.progress} style={{width: `${percentageProxy}%`}}>
        {items.map((item) => {
          const partialPercentage = (item.amount / totalValue) * 100;
          if (!partialPercentage) {
            return null;
          }
          return (
            <div
              key={item.id}
              className={styles.filler}
              style={{
                width: `${partialPercentage}%`,
                backgroundColor: item.color,
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SegmentedProgressBar;
