import {UseQueryOptions} from '@tanstack/react-query';
import {useMemo} from 'react';
import brandFetchers from '@/api/fetchers/brand';
import useDataQuery from '@/hooks/use-data-query';

interface UseBrandCampaignsQueryParams<TData, TError = unknown>
  extends UseQueryOptions<TData, TError> {
  brandId?: string;
  query?: string;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  states?: string[];
  orderByLatest?: boolean;
}

interface SubmittedBy {
  email: string;
  displayName: string | null;
}

interface UnderReviewSubmissionInfo {
  submittedBy: SubmittedBy;
  submittedOn: string | null;
}

export type CampaignListItem = {
  contentAgreementTimePeriodInMonths: number;
  generatedByBriefAi: boolean;
  id: string;
  imageUrl: string;
  name: string;
  network: string;
  state: string;
  underReviewSubmissionInfo?: UnderReviewSubmissionInfo;
  launchedOn: Date;
};

export const ACTIVE_STATES = [
  'accepting_proposals',
  'closed_to_new_proposals',
  'approved',
];

function useBrandCampaignsQuery<TData, TError = unknown>({
  brandId,
  refetchOnWindowFocus = true,
  states,
  orderByLatest = false,
  ...rest
}: UseBrandCampaignsQueryParams<TData, TError>) {
  const response = useDataQuery({
    queryKey: ['brandCampaigns', brandId, states],
    queryFn: brandFetchers.brandCampaigns,
    enabled: !!brandId,
    meta: {
      brandId,
      state: states ? states?.join(',') : undefined,
    },
    refetchOnWindowFocus,
    ...rest,
  });

  const brandCampaigns = useMemo(() => {
    const campaigns = (response.data || []) as CampaignListItem[];
    if (orderByLatest) {
      campaigns.sort((a, b) => {
        return (
          new Date(b.launchedOn).getTime() - new Date(a.launchedOn).getTime()
        );
      });
    }

    return campaigns;
  }, [response.data, orderByLatest]);

  return {
    ...response,
    brandCampaigns,
  };
}

export default useBrandCampaignsQuery;
