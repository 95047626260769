import QueryContext from '../QueryContext';
import api from '../index';

const brandSafetyToleranceSettingsFetchers = {
  customTolerance: (context: QueryContext) => {
    return api.get(
      `/brands_safety_settings/${context.meta?.brandId}/categories`,
      {}
    );
  },
};

export default brandSafetyToleranceSettingsFetchers;
