import {orderBy} from 'lodash';
import React, {useMemo} from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
  Label,
  Tooltip,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import {
  Severity,
  SEVERITY_COLORS,
  ToleranceFilter,
} from '@/components/creator-vetting-report/constants';
import Divider from '@/components/divider';
import InfoTooltip from '@/components/info-tooltip';
import SegmentedProgressBar from '@/components/segmented-progress-bar';
import {
  VettingCategories,
  VettingCategory,
} from '../../hooks/use-get-vetting-categories/useGetVettingCategories';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import ToleranceSettingsDropdown from '../tolerance-settings-dropdown';
import styles from './ProfileVettingItemsFlagged.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-items-flagged';

const COLUMN_WIDTHS = {
  CATEGORY_NAME: 136,
  FLAG_NAME: '100%',
  RISK_LEVEL: '100%',
  POSTS: 100,
};

interface ProfileVettingItemsFlaggedProps {
  totalItemsScanned: number;
  totalItemsFlagged: number;
  vettingCategories: VettingCategories;
  selectedToleranceFilterId: string;
  onToleranceFilterChange: (selectedOptionId: string) => void;
  isDefaultToleranceSettings: boolean;
}

interface ColumnProps {
  id: string;
  width: number | string;
  content: React.ReactNode;
}

interface RowProps {
  columns: ColumnProps[];
  className?: string;
}

const OPTIONS = [
  {
    id: ToleranceFilter.DEFAULT as string,
    localeLabelKey: 'Default risk tolerance',
  },
  {
    id: ToleranceFilter.CUSTOM as string,
    localeLabelKey: 'Custom risk tolerance',
  },
  {
    id: ToleranceFilter.EDIT as string,
    localeLabelKey: 'Edit risk tolerance',
    icon: 'Features-Edit-Basic' as IconName,
    className: styles.editToleranceButton,
  },
];

function PostsAmountLabel({amount, hidden}: {amount: number; hidden: boolean}) {
  return amount === 0 ? (
    <Label
      size="sm"
      className={styles.postsAmount}
      color={
        hidden
          ? designSystemToken('semantic.fg.disabled')
          : designSystemToken('semantic.fg.primary')
      }
    >
      <Icon
        size="small"
        appearance="brand"
        name={
          hidden
            ? 'Navigation-Shopbag-Shield-Stroke'
            : 'Navigation-Shopbag-Shield-Filled'
        }
        color={hidden ? designSystemToken('semantic.fg.disabled') : undefined}
      />
      {translate(`${TRANSLATION_PREFIX}.${hidden ? 'hide' : 'clear'}`)}
    </Label>
  ) : (
    <Body
      size="sm"
      className={styles.postsAmount}
      color={designSystemToken('semantic.fg.primary')}
    >
      {amount}
    </Body>
  );
}

function Row(props: RowProps) {
  const {columns, className = ''} = props;
  return (
    <div className={`${styles.row} ${className}`}>
      {columns.map((column) => {
        return (
          <div
            key={column.id}
            className={styles.column}
            style={{width: column.width}}
          >
            {column.content}
          </div>
        );
      })}
    </div>
  );
}

interface RiskLevelPercentageProps {
  totalAmount: number;
  item: VettingCategory;
}

function RiskLevel({totalAmount, item}: RiskLevelPercentageProps) {
  const {severityBreakDown} = item;
  const items = [
    {
      id: Severity.LOW as string,
      amount: severityBreakDown[Severity.LOW],
      color: SEVERITY_COLORS[Severity.LOW],
    },
    {
      id: Severity.MEDIUM as string,
      amount: severityBreakDown[Severity.MEDIUM],
      color: SEVERITY_COLORS[Severity.MEDIUM],
    },
    {
      id: Severity.HIGH as string,
      amount: severityBreakDown[Severity.HIGH],
      color: SEVERITY_COLORS[Severity.HIGH],
    },
  ];
  const totalValue = items.reduce((sum, {amount}) => sum + amount, 0);
  const percentage = (totalValue / totalAmount) * 100;

  return (
    <div className={styles.riskLevelContainer}>
      <div className={styles.progressBarContainer}>
        <SegmentedProgressBar percentage={percentage} items={items} />
      </div>
    </div>
  );
}

function CategoryTooltip({category}: {category: VettingCategory}) {
  return (
    <div className={styles.categoryTooltip}>
      <Row
        columns={[
          {
            id: 'flagName',
            width: COLUMN_WIDTHS.FLAG_NAME,
            content: (
              <Label size="md" className={styles.categoryHeaderColumn}>
                {category.name}
              </Label>
            ),
          },
          {
            id: 'posts',
            width: COLUMN_WIDTHS.POSTS,
            content: (
              <Label size="md" className={styles.categoryHeaderColumn}>
                {translate(`${TRANSLATION_PREFIX}.posts-amount`)}
              </Label>
            ),
          },
        ]}
      />
      <div className={styles.flagRows}>
        {orderBy(category.flags, ['postsAmount'], ['desc']).map((flag) => (
          <Row
            key={flag.id}
            className={styles.flagRow}
            columns={[
              {
                id: 'flagName',
                width: COLUMN_WIDTHS.FLAG_NAME,
                content: (
                  <Body
                    size="md"
                    color={designSystemToken('semantic.fg.secondary')}
                  >
                    {flag.name}
                  </Body>
                ),
              },
              {
                id: 'flagPosts',
                width: COLUMN_WIDTHS.POSTS,
                content: (
                  <Body
                    className={styles.flagPosts}
                    size="sm"
                    color={designSystemToken('semantic.fg.primary')}
                  >
                    {flag.postsAmount}
                  </Body>
                ),
              },
            ]}
          />
        ))}
      </div>
    </div>
  );
}

function VettingItem({
  totalAmount,
  item,
}: {
  totalAmount: number;
  item: VettingCategory;
}) {
  const {name, postsAmount} = item;

  const categoryName = (
    <Body
      size="md"
      className={styles.categoryName}
      color={designSystemToken('semantic.fg.secondary')}
    >
      {name}
    </Body>
  );

  const riskLevel = <RiskLevel totalAmount={totalAmount} item={item} />;
  const posts = (
    <PostsAmountLabel amount={postsAmount} hidden={!!item.hidden} />
  );

  return (
    <Tooltip
      followCursor
      title={<CategoryTooltip category={item} />}
      className={styles.tooltip}
    >
      <div>
        <Row
          columns={[
            {
              id: 'categoryName',
              width: COLUMN_WIDTHS.CATEGORY_NAME,
              content: categoryName,
            },
            {
              id: 'riskLevel',
              width: COLUMN_WIDTHS.RISK_LEVEL,
              content: riskLevel,
            },
            {id: 'posts', width: COLUMN_WIDTHS.POSTS, content: posts},
          ]}
        />
      </div>
    </Tooltip>
  );
}

function RiskLevelInfo() {
  const items = [
    {label: translate('Low'), color: SEVERITY_COLORS[Severity.LOW]},
    {label: translate('Medium'), color: SEVERITY_COLORS[Severity.MEDIUM]},
    {label: translate('High'), color: SEVERITY_COLORS[Severity.HIGH]},
  ];
  return (
    <div className={styles.riskLevelInfo}>
      <Body size="md">{translate('Indicates risk severity:')}</Body>
      <div className={styles.riskLevelInfoItems}>
        {items.map((item) => (
          <div key={item.label}>
            <div className={styles.riskLevelInfoItem}>
              <Body size="md">{item.label}</Body>
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '4px',
                  background: item.color,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ProfileVettingItemsFlagged(props: ProfileVettingItemsFlaggedProps) {
  const {
    totalItemsScanned,
    totalItemsFlagged,
    vettingCategories,
    selectedToleranceFilterId,
    onToleranceFilterChange,
    isDefaultToleranceSettings,
  } = props;

  const toleranceOptions = useMemo(
    () =>
      OPTIONS.filter((option) =>
        isDefaultToleranceSettings ? option.id !== ToleranceFilter.CUSTOM : true
      ).map((option) => ({
        ...option,
        label: translate(option.localeLabelKey),
      })),
    [isDefaultToleranceSettings]
  );

  const itemsFlaggedPercentage =
    totalItemsScanned === 0
      ? 0
      : Math.ceil((totalItemsFlagged / totalItemsScanned) * 100);
  const totalAmount = totalItemsFlagged;

  const headerColumns = useMemo(
    () => [
      {
        id: 'categoryName',
        width: COLUMN_WIDTHS.CATEGORY_NAME,
        content: (
          <Label size="md" className={styles.categoryName}>
            {translate(`${TRANSLATION_PREFIX}.category`)}
          </Label>
        ),
      },
      {
        id: 'riskLevel',
        width: COLUMN_WIDTHS.RISK_LEVEL,
        content: (
          <div className={styles.riskLevelColumnHeader}>
            <Label size="md">
              {translate(`${TRANSLATION_PREFIX}.risk-level`)}
            </Label>
            <InfoTooltip title={<RiskLevelInfo />} />
          </div>
        ),
      },
      {
        id: 'posts',
        width: COLUMN_WIDTHS.POSTS,
        content: (
          <Label size="md" className={styles.postsAmount}>
            {translate(`${TRANSLATION_PREFIX}.posts-amount`)}
          </Label>
        ),
      },
    ],
    []
  );

  return (
    <div
      className={styles.profileVettingItemsFlaggedContainer}
      data-testid={CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT_SUMMARY}
    >
      <div
        className={styles.header}
        data-testid={
          CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT_SUMMARY__HEADER
        }
      >
        <div className={styles.headerTitle}>
          <Headline size="xl" color={designSystemToken('semantic.fg.primary')}>
            {itemsFlaggedPercentage}%
          </Headline>
          <Label size="xl" color={designSystemToken('semantic.fg.primary')}>
            {translate(`${TRANSLATION_PREFIX}.subtitle`)}
          </Label>
        </div>
        <ToleranceSettingsDropdown
          options={toleranceOptions}
          selectedOptionId={selectedToleranceFilterId}
          onChange={onToleranceFilterChange}
        />
      </div>
      <div
        className={styles.itemsContainer}
        data-testid={
          CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT_SUMMARY__FLAGGED_ITEMS
        }
      >
        <Row columns={headerColumns} className={styles.itemsContainerHeader} />
        <div className={styles.items}>
          {vettingCategories.flagged.map((flaggedItem) => (
            <VettingItem
              key={flaggedItem.id}
              totalAmount={totalAmount}
              item={flaggedItem}
            />
          ))}
        </div>
      </div>
      <Conditional
        condition={
          vettingCategories.flagged.length > 0 &&
          vettingCategories.cleared.length > 0
        }
      >
        <Divider margin={0} />
      </Conditional>
      <Conditional condition={vettingCategories.cleared.length > 0}>
        <div
          className={styles.itemsContainer}
          data-testid={
            CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT_SUMMARY__CLEARED_ITEMS
          }
        >
          <div className={styles.items}>
            {vettingCategories.cleared.map((clearedItem) => (
              <VettingItem
                key={clearedItem.id}
                totalAmount={totalAmount}
                item={clearedItem}
              />
            ))}
          </div>
        </div>
      </Conditional>
    </div>
  );
}

export default ProfileVettingItemsFlagged;
