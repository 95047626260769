import brandFetchers from '@/api/fetchers/brand';
import useInfiniteDataQuery from '@/hooks/use-infinite-data-query';

type UseBrandsQueryParams = {
  brandId?: string;
  query?: string;
  enabled?: boolean;
};

function useBrandsQuery({
  brandId,
  query,
  enabled = true,
}: UseBrandsQueryParams) {
  const response: any = useInfiniteDataQuery({
    queryKey: ['brands', query, brandId],
    queryFn: ({pageParam = 1}) =>
      brandFetchers.brands({pageParam, query, brandId}),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled,
    meta: {
      query,
      brandId,
    },
    getNextPageParam: (lastPage: any) => {
      const {currentPage, totalPages} = lastPage.meta;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
  });

  const {pages = []} = response.data || {};
  const meta = pages[pages.length - 1]?.data?.meta;

  return {
    ...response,
    data: pages.flatMap((page: any) => page.items),
    meta,
  };
}

export default useBrandsQuery;
