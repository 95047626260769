import React from 'react';
import {Outlet} from 'react-router-dom';
import {Stack, Box} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';
import Header from './components/header';
import Menu from './components/menu';

function ContentStudio() {
  return (
    <Stack
      direction="column"
      width="100%"
      alignItems="center"
      minHeight="100vh"
    >
      <Header />
      <Stack direction="row" width="100%" flex={1}>
        <Menu />
        <Box
          width="100%"
          height="100%"
          bgcolor={designSystemToken('semantic.bg.primary')}
        >
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
}

export default ContentStudio;
