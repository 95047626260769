import React, {useMemo} from 'react';
import {designSystemToken} from '@lightricks/react-design-system';
import Conditional from '@/components/conditional';
import Skeleton from '@/components/skeleton';
import styles from './MosaicGallery.module.scss';

interface MosaicImage {
  id: string;
  src: string;
}

interface MosaicGalleryProps {
  images: MosaicImage[];
  layouts: number[];
  numRows?: number;
  numColumns?: number;
  baseWidth?: number | string;
  baseHeight?: number;
  gapSize?: number;
  fillMissing?: boolean;
  showSkeletons?: boolean;
}

interface ColumnItem {
  id: string;
  src: string;
  width: number | string;
  height: number;
  originalIndex: number;
}

interface Column {
  id: string;
  items: ColumnItem[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type AccumulatorType = [Column[], number[]];

function validateLayout(
  layouts: number[],
  numRows: number,
  numColumns: number
): boolean {
  // Simulate filling columns to check if layout is valid
  const columnHeights = Array(numColumns).fill(0);

  // eslint-disable-next-line no-restricted-syntax
  for (const height of layouts) {
    // Try to find a column where this item can fit
    let placed = false;
    for (let i = 0; i < numColumns; i += 1) {
      if (columnHeights[i] + height <= numRows) {
        columnHeights[i] += height;
        placed = true;
        break;
      }
    }

    // If we couldn't place an item, the layout is invalid
    if (!placed) {
      return false;
    }
  }

  return true;
}

function MosaicGallery({
  images,
  layouts,
  numRows = 3,
  numColumns = 3,
  baseWidth = 100,
  baseHeight = 100,
  gapSize = 2,
  fillMissing = false,
  showSkeletons = false,
}: MosaicGalleryProps) {
  const columnsMemoized = useMemo(() => {
    const limit = fillMissing
      ? layouts.length
      : Math.min(images.length, layouts.length);

    if (!validateLayout(layouts.slice(0, limit), numRows, numColumns)) {
      console.error(
        'Invalid layout: Some images cannot be placed within the specified rows and columns'
      );
    }

    return Array.from({length: limit}).reduce<AccumulatorType>(
      (acc, _, i) => {
        const [columns, columnHeights] = acc;
        const height = layouts[i];

        const targetColumn = columnHeights.findIndex(
          (colHeight) => colHeight + height <= numRows
        );

        if (targetColumn !== -1) {
          columns[targetColumn].items.push({
            id: images[i]?.id,
            src: images[i]?.src,
            width: baseWidth,
            height: height * baseHeight + ((layouts[i] || 1) - 1) * gapSize,
            originalIndex: i,
          });
          columnHeights[targetColumn] += height;
        }

        return [columns, columnHeights];
      },
      [
        Array.from({length: numColumns}).map((_, index) => ({
          id: `column-${index}`,
          items: [],
        })),
        Array(numColumns).fill(0),
      ]
    )[0];
  }, [
    images,
    layouts,
    baseWidth,
    baseHeight,
    fillMissing,
    numRows,
    numColumns,
  ]);

  return (
    <div
      className={styles.mosaicGalleryContainer}
      style={{gap: `${gapSize}px`}}
    >
      {columnsMemoized.map((column) => (
        <div
          key={column.id}
          className={styles.mosaicColumn}
          style={{gap: `${gapSize}px`}}
        >
          {column.items.map((item) => (
            <div
              key={item.id}
              className={styles.mosaicItem}
              style={{
                width: item.width,
                height: item.height,
                background: showSkeletons
                  ? 'unset'
                  : designSystemToken('semantic.bg.tertiary'),
              }}
            >
              <Conditional
                condition={!showSkeletons}
                fallback={
                  <Skeleton
                    variant="rectangular"
                    width={item.width}
                    height={item.height}
                  />
                }
              >
                <Conditional condition={!!item.src}>
                  <img
                    src={item.src}
                    alt={`mosaic-item-${item.originalIndex}`}
                    loading="lazy"
                  />
                </Conditional>
              </Conditional>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default MosaicGallery;
