import {Point} from 'chart.js';
import dayjs from 'dayjs';
import {sortBy} from 'lodash';
import React, {useMemo} from 'react';
import {Headline} from '@lightricks/react-design-system';
import getChartLabelAbbreviation from '@/utils/getChartLabelAbbreviation';
import getTickValues from '@/utils/getTickValues';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {MONTH_DAY_YEAR_FORMAT} from '@/config/timeFormats';
import {Identity} from '@/types/identity';
import {Post} from '@/types/post';
import {SocialNetworkProvider} from '@/types/socialNetworkProvider';
import PoppaysLoader from '@/components/PoppaysLoader';
import ChartLayout from '@/components/chart-layout';
import LineChart from '@/components/charts/line-chart';
import Conditional from '@/components/conditional';
import FollowerChangeIndication from '@/components/follower-change-indication';
import {SocialHealthChartProps} from '../../socialHealthChart';
import styles from './FollowersCountChart.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-profile.identity-social-health.charts.followers';

const WEEKS_TO_LOOK_BACK = 4;

function getFilteredPosts(posts: Post[]): Post[] {
  if (!posts) {
    return [];
  }

  const postsWithData = posts.filter((post, index) => {
    const {createdAtDay, cachedAccountFollowerCount} = post;
    const previousPost = posts[index - 1];
    const previousCreatedAtDay = previousPost
      ? previousPost.createdAtDay
      : null;

    return cachedAccountFollowerCount && createdAtDay !== previousCreatedAtDay;
  });

  if (postsWithData.length < 2) {
    return [];
  }

  return sortBy(postsWithData, (post) => dayjs(post.createdAtDay).unix());
}

function getPoints(posts: Post[]): Point[] {
  return posts.map((post, index) => ({
    x: index,
    y: post.cachedAccountFollowerCount,
  }));
}

function getFourWeekFollowerChange(filteredPosts: Post[]): number | null {
  const today = dayjs();
  const fourWeeksAgoDayJs = today.subtract(WEEKS_TO_LOOK_BACK, 'weeks');

  const postsInLastFourWeeks = sortBy(filteredPosts, (post) =>
    dayjs(post.createdAt).unix()
  ).filter((post) => {
    const createdAtDayJs = dayjs(post.createdAt);

    return (
      createdAtDayJs.isBefore(today) &&
      createdAtDayJs.isAfter(fourWeeksAgoDayJs)
    );
  });

  if (postsInLastFourWeeks.length <= 1) {
    return null;
  }

  return (
    postsInLastFourWeeks[postsInLastFourWeeks.length - 1]
      .cachedAccountFollowerCount -
    postsInLastFourWeeks[0].cachedAccountFollowerCount
  );
}

interface FollowersCountChartProps extends SocialHealthChartProps {
  identity: Identity;
}

function FollowersCountChart(props: FollowersCountChartProps) {
  const {posts, isLoadingPosts, identity} = props;

  const filteredPosts = useMemo(() => getFilteredPosts(posts), [posts]);

  const points = useMemo(() => getPoints(filteredPosts), [filteredPosts]);
  const tickValues = useMemo(
    () => getTickValues(points.map((point) => point.y)),
    [points]
  );

  const fourWeekFollowerChange = useMemo(
    () => getFourWeekFollowerChange(filteredPosts),
    [filteredPosts]
  );

  const getFourWeekFollowerChangePercentage = useMemo(() => {
    if (
      fourWeekFollowerChange == null ||
      fourWeekFollowerChange === 0 ||
      filteredPosts == null ||
      filteredPosts.length === 0
    ) {
      return null;
    }

    const latestPostFollowerCount = filteredPosts[0].cachedAccountFollowerCount;

    const denominator = latestPostFollowerCount - fourWeekFollowerChange;
    if (denominator === 0) {
      return null;
    }

    return ((fourWeekFollowerChange / denominator) * 100).toFixed(3);
  }, [fourWeekFollowerChange, filteredPosts]);

  const getLabelByProvider = (provider: SocialNetworkProvider) => {
    if (provider === 'youtube') {
      return translate('Subscribers');
    }

    return translate('Followers');
  };

  const title = (
    <div className={styles.title}>
      <Headline size="xxs">
        {getLabelByProvider(identity.provider as SocialNetworkProvider)}
      </Headline>
      <FollowerChangeIndication
        followerChange={fourWeekFollowerChange}
        followerChangePercentage={getFourWeekFollowerChangePercentage}
        followerChangeLabel={translate(`${TRANSLATION_PREFIX}.last-four-weeks`)}
      />
    </div>
  );

  const chart = (
    <Conditional
      condition={!isLoadingPosts}
      fallback={<PoppaysLoader absolute />}
    >
      <LineChart
        datasets={[
          {
            fill: false,
            data: points,
            backgroundColor: LEGACY_COLORS.BLUE,
            borderColor: LEGACY_COLORS.BLUE,
            borderWidth: 2,
            pointRadius: 0,
            cubicInterpolationMode: 'monotone',
          },
        ]}
        yTickValues={tickValues}
        labels={filteredPosts.map(({createdAtDay}) =>
          dayjs(createdAtDay).format(MONTH_DAY_YEAR_FORMAT)
        )}
        xTitle={translate(`${TRANSLATION_PREFIX}.date`)}
        yTitle={getLabelByProvider(identity.provider as SocialNetworkProvider)}
        getYLabel={(label) => getChartLabelAbbreviation(label)}
      />
    </Conditional>
  );

  return (
    <ChartLayout
      title={title}
      chart={chart}
      showEmptyState={filteredPosts.length === 0 && !isLoadingPosts}
    />
  );
}

export default FollowersCountChart;
