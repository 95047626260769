import {isEmpty} from 'lodash';
import {useMemo} from 'react';
import {BrandSafetyScore, PostAnalysis} from '@/types/creatorSafetyReport';

interface ApplyToleranceSettingsFilter {
  flagValues: Record<string, number>;
  flaggedPostAnalyses: PostAnalysis[];
  applyCustomTolerance: boolean;
}

/**
 * Filters flagged post analyses based on the custom tolerance settings
 * @param flagValues
 * @param flaggedPostAnalyses
 * @param applyCustomTolerance
 */
function useApplyToleranceSettingsFilter({
  flagValues,
  flaggedPostAnalyses,
  applyCustomTolerance,
}: ApplyToleranceSettingsFilter) {
  const filteredToleranceFlaggedPostAnalyses = useMemo(() => {
    // If custom tolerance settings are not applied, return the original flagged post analyses
    if (!applyCustomTolerance) {
      return flaggedPostAnalyses;
    }
    // Map flagged post analyses based on the custom tolerance settings
    // We do not filter right away since any given post can have multiple flags so we need to check the flags object and manipulate it accordingly
    return flaggedPostAnalyses.map((postAnalysis: PostAnalysis) => {
      const {flags} = postAnalysis.mediaAnalysisResult;
      const filteredMediaAnalysisFlags = Object.keys(flags).reduce(
        (acc: Record<string, BrandSafetyScore>, flagKey) => {
          // If the flag key is not in the custom tolerance settings, we filter it out completely
          // This happens when the category the flag belongs to is disabled
          if (!(flagKey in flagValues)) {
            return acc;
          }
          // If the flag score is greater or equal to the custom tolerance value, we keep it
          const {score} = flags[flagKey];
          if (score >= flagValues[flagKey]) {
            acc[flagKey] = flags[flagKey];
          }
          return acc;
        },
        {}
      );

      // Return the post analysis with the processed flags
      return {
        ...postAnalysis,
        mediaAnalysisResult: {
          ...postAnalysis.mediaAnalysisResult,
          flags: filteredMediaAnalysisFlags,
        },
      };
    });
  }, [flagValues, flaggedPostAnalyses, applyCustomTolerance]);

  // Filter out post analyses that have no flags after applying the custom tolerance settings so we can correctly calculate % of flagged content
  return filteredToleranceFlaggedPostAnalyses.filter(
    (postAnalysis) => !isEmpty(postAnalysis.mediaAnalysisResult.flags)
  );
}

export default useApplyToleranceSettingsFilter;
