import {useMutation} from '@tanstack/react-query';
import campaignUpdaters from '@/api/updaters/campaign';

type UseInviteCreatorToCampaignArgs = {
  onSuccess?: (data: unknown) => void;
  onMutate?: (data: unknown) => void;
  onError?: (error: unknown, variables: unknown, context: unknown) => void;
  onSettled?: (data: unknown, error: unknown) => void;
};

function useInviteCreatorToCampaign(args: UseInviteCreatorToCampaignArgs) {
  return useMutation({
    mutationKey: ['inviteCreatorToCampaign'],
    mutationFn: campaignUpdaters.inviteCreatorToCampaign,
    ...args,
  });
}

export default useInviteCreatorToCampaign;
