import {xor} from 'lodash';
import React, {useMemo} from 'react';
import {Stack} from '@mui/material';
import {Body} from '@lightricks/react-design-system';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import mapOptions from '@/views/creators/search/utils/mapOptions';
import AutocompleteInput, {Option} from '@/components/autocomplete-input';
import {FilterProps} from '../FilterProps';

export type MultiSelectionOptionsAutocompleteAsyncProps = FilterProps & {
  options: BaseFilterOption[];
  selectedOptions: BaseFilterOption[];
  placeholder?: string;
  onChange: (option: BaseFilterOption) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  valueDisplayMode?: 'label' | 'chips';
  testID?: string;
};

function MultiSelectionOptionsAutocompleteAsync(
  props: MultiSelectionOptionsAutocompleteAsyncProps
) {
  const {
    options,
    selectedOptions = [],
    placeholder,
    onChange,
    onInputChange,
    loading = false,
    valueDisplayMode,
    testID,
  } = props;

  const mappedOptions = useMemo(() => mapOptions(options), [options]);
  const mappedSelectedOptions = useMemo(
    () => mapOptions(selectedOptions),
    [selectedOptions]
  );

  const handleOnChange = (_: React.SyntheticEvent, value: Option[]) => {
    const currentlySelectedOptionsIds = selectedOptions.map(
      (option) => option.id
    );
    const newSelectedOptionsIds = value.map((option: Option) => option.id);
    const diff = xor(currentlySelectedOptionsIds, newSelectedOptionsIds);
    const selectedOptionId = diff?.[0];

    let selectedOption = options.find(
      (option) => option.id === selectedOptionId
    );

    if (!selectedOption) {
      selectedOption = selectedOptions.find(
        (option: BaseFilterOption) => option.id === selectedOptionId
      );
    }

    if (selectedOption) {
      onChange(selectedOption);
    }
  };

  const renderOptionLabel = (option: Option) => {
    const {country, region, place} = option.value;
    const isCountry = !!country && !region && !place;
    const isRegion = !!region && !place;
    let primary = place;
    if (isRegion) {
      primary = region;
    } else if (isCountry) {
      primary = country;
    }
    let secondary;
    if (isRegion) {
      secondary = country;
    } else if (!isCountry && !isRegion) {
      secondary = [region, country].filter(Boolean).join(', ');
    }
    return (
      <Stack direction="row">
        <Body size="md">
          {primary}
          {secondary ? `, ${secondary}` : null}
        </Body>
      </Stack>
    );
  };

  return (
    <AutocompleteInput
      clearIcon={false}
      multiple
      multipleTextMode={valueDisplayMode}
      loading={loading}
      placeholder={placeholder}
      options={mappedOptions}
      renderOptionLabel={renderOptionLabel}
      selectedOptions={mappedSelectedOptions}
      onChange={handleOnChange}
      onInputChange={onInputChange}
      testID={testID}
    />
  );
}

export default MultiSelectionOptionsAutocompleteAsync;
