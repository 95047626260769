import {isEqual, pickBy} from 'lodash';
import React from 'react';
import {Box} from '@mui/material';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {
  CombinedPostAnalysis,
  MediaAnalysisResult,
} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import getRiskLevelFromPercentage from '@/components/creator-vetting-report/utils/getRiskLevelFromPercentage';
import Divider from '@/components/divider';
import GradientProgressBar from '@/components/gradient-progress-bar';
import InfoTooltip from '@/components/info-tooltip';
import styles from './AnalysisPanel.module.scss';

export interface AnalysisPanelProps {
  testID?: string;
  post: CombinedPostAnalysis;
  category: string;
}

function filterBrandSafetyCategories(mediaAnalysisResult: MediaAnalysisResult) {
  const {flags} = mediaAnalysisResult;
  return pickBy(flags, (value) => !!value.score && value.score > 0);
}

function FlagRow(props: {category: string; decimalScore: number}) {
  const {category, decimalScore} = props;
  const categoryDisplay = translate(category);
  return (
    <Box className={styles.row}>
      <Body size="md" className={styles.flagTitle}>
        {categoryDisplay}
      </Body>
      <GradientProgressBar
        value={getRiskLevelFromPercentage(decimalScore * 100)}
      />
    </Box>
  );
}

function FlaggedContent(props: {post: CombinedPostAnalysis; category: string}) {
  const {post, category} = props;
  const {mediaAnalysisResult} = post;

  const flaggedCategories = filterBrandSafetyCategories(mediaAnalysisResult);
  const sortedOtherCategories = Object.keys(flaggedCategories)
    .filter((cat) => cat !== category)
    .sort((a, b) => {
      return flaggedCategories[b].score - flaggedCategories[a].score;
    });

  return (
    <Box className={`${styles.flaggedContentContainer} ${styles.section}`}>
      <Headline size="md">{translate('flagged-content')}</Headline>
      <Box className={styles.flaggedContentList}>
        <Box className={styles.row}>
          <Label size="lg">{translate('category')}</Label>
          <Box className={styles.categoryTitle}>
            <Label size="lg">{translate(`risk-level`)}</Label>
            <InfoTooltip title={translate('risk-level-tooltip')} />
          </Box>
        </Box>
        <FlagRow
          category={category}
          decimalScore={flaggedCategories[category].score}
        />
        <Conditional condition={sortedOtherCategories.length > 0}>
          <>
            <Box className={`${styles.row} ${styles.dividerContainer}`}>
              <Divider margin={0} />
              <Body size="sm">{translate(`also-found-in`)}</Body>
              <Divider margin={0} />
            </Box>
            {sortedOtherCategories.map((flaggedCategory) => (
              <FlagRow
                key={flaggedCategory}
                category={flaggedCategory}
                decimalScore={flaggedCategories[flaggedCategory].score}
              />
            ))}
          </>
        </Conditional>
      </Box>
    </Box>
  );
}

function CarouselPostNote(props: {
  mediaIndexes: number[];
  highestScoreIndex: number;
}) {
  const {mediaIndexes, highestScoreIndex} = props;
  return (
    <Conditional
      condition={!!mediaIndexes?.length && !isEqual(mediaIndexes, [0])}
    >
      <Box className={styles.carouselNoteContainer}>
        <Box className={`${styles.carouselNoteTitleContainer}`}>
          <Label size="md">{translate('carousel-post-note-title')}</Label>
          <Icon size="small" appearance="neutral" name="Actions-Info-Line" />
        </Box>
        <Body size="sm" className={styles.carouselNoteBody}>
          {translate('carousel-post-note-body', {
            flaggedIndexes: mediaIndexes.map((index) => index + 1).join(', '),
            highestScoreIndex: highestScoreIndex + 1,
          })}
        </Body>
      </Box>
    </Conditional>
  );
}

function AnalysisPanel(props: AnalysisPanelProps) {
  const {testID = 'analysis-panel', post, category} = props;

  return (
    <Box className={styles.mainContainer} data-testid={testID}>
      <FlaggedContent post={post} category={category} />
      <Box className={styles.section}>
        <CarouselPostNote
          mediaIndexes={post.postMetadata.mediaIndex ?? [0]}
          highestScoreIndex={post.postMetadata.highestScoreMediaIndex}
        />
        <Box className={styles.summaryContainer}>
          <Headline size="md">{translate(`summary`)}</Headline>
          <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
            {post.mediaAnalysisResult.summary}
          </Body>
        </Box>
      </Box>
    </Box>
  );
}

export default AnalysisPanel;
