import axios from 'axios';
import {getEnv} from '@/config/environment';

const LOCATIONS_API = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

type Geometry = {
  coordinates: number[];
};

type Context = {
  id: string;
  text_en: string;
};

type Feature = {
  id: string;
  place_type: string[];
  text_en: string;
  place_name_en: string;
  center?: number[];
  geometry: Geometry;
  context?: Context[];
};

type ResponsePayload = {
  type: string;
  query: string[];
  features: Feature[];
  attribution: string;
};

export type FetchedLocationValue = {
  longitude: number;
  latitude: number;
  place?: string;
  region?: string;
  country?: string;
};

export type FetchedLocation = {
  id: string;
  value: FetchedLocationValue;
  localeLabelKey: string;
};

function mapFeatureToLocationValue(feature: Feature): FetchedLocationValue {
  const {context} = feature;
  const placeType = feature.place_type;
  const [longitude, latitude] = feature.center || feature.geometry.coordinates;
  let place;
  let region;
  let country;

  if (context) {
    context.forEach((contextItem) => {
      if (contextItem.id.includes('region')) {
        region = contextItem.text_en;
      } else if (contextItem.id.includes('country')) {
        country = contextItem.text_en;
      }
    });
  }
  if (placeType.includes('place')) {
    place = feature.text_en;
  }
  if (placeType.includes('country')) {
    country = feature.text_en;
  }
  if (placeType.includes('region')) {
    region = feature.text_en;
  }
  return {
    longitude,
    latitude,
    place,
    region,
    country,
  };
}

const locationFetchers = {
  locations: async (
    query: string,
    restrictCountry = false,
    restrictRegion = false
  ) => {
    let types = ['place', 'region', 'country'];

    if (restrictRegion) {
      types = types.filter((type) => type !== 'region');
    }

    if (restrictCountry) {
      types = types.filter((type) => type !== 'country');
    }

    const typesParam = types.join(',');
    const response = await axios.get<ResponsePayload>(
      `${LOCATIONS_API}/${query}.json?types=${typesParam}&limit=10&language=en&access_token=${
        getEnv().VITE_MAPBOX_API_KEY
      }`
    );

    return response.data.features.map((feature: any) => ({
      id: feature.id,
      value: mapFeatureToLocationValue(feature),
      localeLabelKey: feature.text_en,
    }));
  },
};

export default locationFetchers;
