import React, {useMemo} from 'react';
import {Box} from '@mui/material';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import AutocompleteInput, {Option} from '@/components/autocomplete-input';

export interface LocationValue {
  country?: string;
  state?: string;
  city?: string;
  latitude?: number;
  longitude?: number;
}

export type LocationOption = {
  id: string;
  localeLabelKey: string;
  value: LocationValue;
};

export type LocationAutoCompleteProps = {
  options: LocationOption[];
  selectedOptions: LocationOption[];
  placeholder?: string;
  onChange: (options: LocationOption[]) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  valueDisplayMode?: 'label' | 'chips';
  isOptionEqualToValue?: (
    option: LocationOption,
    value: LocationOption
  ) => boolean;
  testID?: string;
  disabled?: boolean;
  borderWidth?: number;
};

type MappedLocationOption = LocationOption & Option;

function LocationAutoComplete(props: LocationAutoCompleteProps) {
  const {
    options,
    selectedOptions = [],
    placeholder,
    onChange,
    onInputChange,
    loading = false,
    valueDisplayMode = 'chips',
    isOptionEqualToValue,
    testID,
    disabled,
    borderWidth = 1,
  } = props;

  function mapOptions(locations: LocationOption[]): MappedLocationOption[] {
    return locations.map((location) => ({
      ...location,
      label: translate(location.localeLabelKey),
    }));
  }

  const mappedOptions = useMemo(() => mapOptions(options), [options]);
  const mappedSelectedOptions = useMemo(
    () => mapOptions(selectedOptions),
    [selectedOptions]
  );

  const renderOptionLabel = (option: Option) => {
    const {country, state, city} = option.value;
    const isCountry = !!country && !state && !city;
    const isState = !!state && !city;
    let primary = city;
    if (isState) {
      primary = state;
    } else if (isCountry) {
      primary = country;
    }
    let secondary;
    if (isState) {
      secondary = country;
    } else if (!isCountry && !isState) {
      secondary = [state, country].filter(Boolean).join(', ');
    }
    return (
      <Box display="flex" flexDirection="row">
        <Body size="md">
          {primary}
          {secondary ? `, ${secondary}` : null}
        </Body>
      </Box>
    );
  };

  const wrappedIsOptionEqualToValue = useMemo(() => {
    if (!isOptionEqualToValue) return undefined;
    return (option: Option, value: Option) =>
      isOptionEqualToValue(
        option as MappedLocationOption,
        value as MappedLocationOption
      );
  }, [isOptionEqualToValue]);

  return (
    <AutocompleteInput
      isOptionEqualToValue={wrappedIsOptionEqualToValue}
      clearIcon={false}
      multiple
      multipleTextMode={valueDisplayMode}
      loading={loading}
      placeholder={placeholder}
      options={mappedOptions}
      renderOptionLabel={renderOptionLabel}
      selectedOptions={mappedSelectedOptions}
      onChange={(_, values) => onChange(values)}
      onInputChange={onInputChange}
      testID={testID}
      forcePopupIcon={false}
      selectedOptionVariant="outlined"
      disabled={disabled}
      minHeight={48}
      borderWidth={borderWidth}
      sx={{
        '& .MuiInputBase-root': {
          'padding': '0 9px 0 9px !important',
          '& .MuiInputBase-input.MuiOutlinedInput-input': {
            fontSize: '14px !important',
            lineHeight: '18px !important',
          },
          '& .MuiInputBase-input::placeholder': {
            fontSize: '14px !important',
            lineHeight: '18px !important',
          },
        },
        '& .MuiChip-label': {
          color: `${designSystemToken('semantic.fg.neutral')} !important`,
        },
      }}
    />
  );
}

export default LocationAutoComplete;
