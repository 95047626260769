import {useMemo} from 'react';
import translate from '@/utils/translate';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import vettingHubFetchers from '@/api/fetchers/vettingHub';
import useDataQuery from '@/hooks/use-data-query';

export const queryKey = 'creatorBrandSafetyVettings';

function mapVettingResult(vettingResult: CreatorBrandSafetyVetting) {
  const isUnavailable =
    !!vettingResult.optedOut || vettingResult.postCount === 0;
  const status = isUnavailable ? 'vetting_unavailable' : vettingResult.status;
  const statusTooltip = isUnavailable
    ? translate(
        'This creator enabled privacy settings that don’t allow vetting.'
      )
    : '';
  return {
    ...vettingResult,
    status,
    statusTooltip,
  };
}

function useCreatorBrandSafetyVettingsQuery({
  brandId,
  enabled = true,
}: {
  brandId: string;
  enabled?: boolean;
}) {
  const response = useDataQuery<CreatorBrandSafetyVetting[]>({
    queryKey: [queryKey, brandId],
    queryFn: vettingHubFetchers.creatorBrandSafetyVettings,
    enabled,
    meta: {brandId},
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 5,
  });

  const vettingResults = useMemo(() => {
    const {data} = response;
    if (!data) {
      return undefined;
    }
    return data.map(mapVettingResult);
  }, [response.data]);

  return {
    ...response,
    data: vettingResults,
  };
}

export default useCreatorBrandSafetyVettingsQuery;
