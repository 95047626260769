import {ProfileProps} from '@/types/creatorProfile';
import QueryContext from '../QueryContext';
import api from '../index';

const DEFAULT_CAMPAIGN_QUERY_INCLUDE = [
  'ad_network_account.dashboard_user',
  'brand.agency',
  'brand.tiktok_business_authorization.tiktok_ad_accounts',
  'brand.brand_instagram_business_account.branded_content_ads_partnerships.identity',
  'bulk_export',
  'campaign_brief.tiktok_campaign_brief',
  'campaign_paid_gig_brief_items',
  'campaign_content_submissions_metrics',
  'conversation',
  'creator_groups',
  'deliverables',
  'preferred_creator_age_detail',
  'preferred_creator_capabilities',
  'preferred_creator_cities',
  'preferred_creator_countries',
  'preferred_creator_genders',
  'preferred_creator_profile',
  'preferred_creator_specialties',
  'preferred_creator_states',
  'questions',
  'social_network',
  'user_group_labels',
  'vertical_tags',
  'campaign_brief_deliverable_items',
  'content_agreement_terms',
  'campaign_brief_caption_guidelines',
];

const DEFAULT_SUGGESTED_CREATORS_QUERY_INCLUDE = [
  'content_preferences',
  'identities',
  'portfolio_media_items',
  'campaign_creator_matches',
  'user',
  'tags',
];

interface PaginatedSuggestedCreatorResponse {
  data: ProfileProps[];
  meta: {
    pagination: {
      currentPage: number;
      totalEntries: number;
      totalPages: number;
    };
  };
}

const campaignFetchers = {
  campaign: (context: QueryContext) =>
    api.get(`/campaigns/${context.meta?.id}`, {
      params: {
        include: context?.meta?.include || DEFAULT_CAMPAIGN_QUERY_INCLUDE,
      },
    }),
  campaignContracts: (context: QueryContext) =>
    api.get(`/campaigns/${context.meta?.id}/contracts`, {
      params: {
        campaignId: context.meta?.id,
        include: [
          'pending_content_submissions.child_content_submissions.versions.content_submission_comments',
          'pending_content_submissions.versions.content_submission_comments',
          'deliverables',
          'profile',
        ],
        page: context.meta?.page,
        per_page: context.meta?.perPage,
        with_pending_content: context.meta?.with_pending_content,
      },
    }),
  campaignContentSubmissions: (context: any) =>
    api.get(`/campaigns/${context.meta?.campaignId}/content_submissions`, {
      params: {
        include: [
          'profile',
          'child_content_submissions.versions',
          'versions.content_submission_comments',
        ],
        page: context?.pageParam || context.meta?.page,
        per_page: context.meta?.perPage,
        state: context.meta?.state,
        contract_id: context.meta?.contractId,
      },
    }),
  campaignContentSubmissionsMeta: (context: any) =>
    api.get(
      `/campaigns/${context.meta?.campaignId}/content_submissions/content_submissions_meta`,
      {
        params: {
          state: context.meta?.state,
          contract_id: context.meta?.contractId,
        },
      }
    ),
  suggestedCreatorsOld: (context: QueryContext) =>
    api.get<PaginatedSuggestedCreatorResponse>(
      `/campaigns/${context.meta?.id}/campaign_suggested`,
      {
        params: {
          page: context.pageParam,
          per_page: context?.meta?.perPage,
          sort: '-match_score',
          filter: {
            campaign_id: context.meta?.id,
            suggested: true,
          },
          compact: context?.meta?.compact,
          include:
            context?.meta?.include || DEFAULT_SUGGESTED_CREATORS_QUERY_INCLUDE,
        },
      }
    ),
  suggestedCreators: (context: QueryContext) =>
    api.get<PaginatedSuggestedCreatorResponse>(
      `/campaigns/${context.meta?.id}/v2_campaign_suggested`,
      {
        params: {
          page: context.pageParam,
          per_page: context?.meta?.perPage,
          sort: '-match_score',
          filter: {
            campaign_id: context.meta?.id,
            suggested: true,
          },
          compact: context?.meta?.compact,
          include:
            context?.meta?.include || DEFAULT_SUGGESTED_CREATORS_QUERY_INCLUDE,
        },
      }
    ),
};
export default campaignFetchers;
