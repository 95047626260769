import React from 'react';
import {Body, designSystemToken, Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import {
  BrandSafetyToleranceSettings,
  Category,
} from '@/types/brandSafetyToleranceSettings';
import CategoryStatus from '@/types/categoryStatusEnum';
import styles from './CategoriesList.module.scss';

interface CategoriesListProps {
  categories: BrandSafetyToleranceSettings['categories'];
  activeCategory: string;
  onCategoryChange: (category: string) => void;
}

interface CategoryButtonProps {
  category: Category;
  iconName: string;
  isActive: boolean;
  isDisabled: boolean;
  onClick: (category: string) => void;
}

const CATEGORY_ICONS = {
  SEXUAL: 'Features-Eyes-Hide',
  ADDICTIVE: 'Actions-Random-Line',
  SENSITIVE: 'Navigation-Attention-Line',
  LEGAL: 'Actions-Info-Line',
  OFFENSIVE_LANGUAGE: 'Actions-Comment2',
  VIOLENCE_AND_DANGER: 'Navigation-Shopbag-Shield-Stroke',
} as Record<string, IconName>;

function CategoryButton(props: CategoryButtonProps) {
  const {category, iconName, isActive, isDisabled, onClick} = props;
  return (
    <div
      className={`${styles.categoryButton} ${isActive ? styles.active : ''} ${
        isDisabled ? styles.disabled : ''
      }`}
      onClick={() => onClick(category.name)}
    >
      <Icon size="medium" appearance="neutral" name={iconName as IconName} />
      <Body size="md" color={designSystemToken('semantic.fg.primary')}>
        {category.displayName}
      </Body>
    </div>
  );
}

function CategoriesList(props: CategoriesListProps) {
  const {categories, activeCategory, onCategoryChange} = props;
  return (
    <div className={styles.categoriesListContainer}>
      {categories.map((category) => (
        <CategoryButton
          key={category.name}
          category={category}
          iconName={CATEGORY_ICONS[category.name]}
          isActive={category.name === activeCategory}
          isDisabled={category.status === CategoryStatus.DISABLED}
          onClick={onCategoryChange}
        />
      ))}
    </div>
  );
}

export default CategoriesList;
