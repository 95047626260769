import {capitalize} from 'lodash';
import {v4 as uuid} from 'uuid';
import extractNumberFromString from '@/utils/extractNumberFromString';
import imageFormatIsValid from '@/utils/imageFormatIsValid';
import translate from '@/utils/translate';
import {
  BriefData,
  CampaignProps,
  ContentAgreementTerm,
  RequiredDeliverable,
  VerticalTag,
  WizardFormData,
} from '@/types/campaign';
import {
  CAMPAIGN_STATE,
  CREATOR_COMPENSATION_TYPES,
  DEFAULT_AGE_RANGE,
  DEFAULT_CREATOR_COMPENSATION_TYPE,
  DEFAULT_DAYS_TO_POST_AFTER_DELIVERABLE_FULFILLED,
} from '@/views/campaigns/brief/constants';
import getBriefDeliverableCounters from '@/views/campaigns/brief/utils/getBriefDeliverableCounters';
import getEmptyDeliverableItemsFromWizardForm from '@/views/campaigns/brief/utils/getEmptyDeliverableItemsFromWizardForm';
import {LocationType} from '@/enums/locationType';
import BriefFieldManager from '../brief-field-manager';

type Thoughts = {
  text: string;
  reasoning: string;
  speak: string;
  plan: string;
  criticism: string;
};

type Args = {
  [key: string]: any;
};

type Command = {
  name: string;
  args: Args;
};

type RawMessage = {
  brief?: any;
  thoughts?: Thoughts;
  command?: Command;
};

function getCreatorCompensationRange(
  campaignDataFromServer: CampaignProps,
  briefData: BriefData,
  wizardFormData: WizardFormData | null
) {
  let {
    suggestedCostForCreationsMin,
    suggestedCostForCreations,
    creatorCompensationType,
  } = {
    suggestedCostForCreationsMin:
      campaignDataFromServer?.suggestedCostForCreationsMin || 0,
    suggestedCostForCreations:
      campaignDataFromServer?.suggestedCostForCreations || 0,
    creatorCompensationType: DEFAULT_CREATOR_COMPENSATION_TYPE,
  };
  const {fixedCostForCreations} = {
    fixedCostForCreations: campaignDataFromServer?.fixedCostForCreations ?? '',
  };

  if (typeof fixedCostForCreations === 'number') {
    creatorCompensationType = CREATOR_COMPENSATION_TYPES.FIXED;
  }

  if (briefData?.creator_cost_range || wizardFormData?.creator_cost_range) {
    const [min, max] = (
      (briefData?.creator_cost_range ||
        wizardFormData?.creator_cost_range) as string
    )
      .replaceAll('$', '')
      .split('-');
    suggestedCostForCreationsMin = Number(min);
    suggestedCostForCreations = Number(max);
  }

  return {
    creatorCompensationType,
    suggestedCostForCreationsMin,
    suggestedCostForCreations,
    fixedCostForCreations,
  };
}

function getCreatorAgeRange(
  campaignDataFromServer: CampaignProps,
  briefData: BriefData,
  wizardFormData: WizardFormData | null
) {
  if (campaignDataFromServer?.preferredCreatorAgeDetail?.maxAge) {
    return campaignDataFromServer?.preferredCreatorAgeDetail;
  }

  if (briefData.creator_age_range) {
    const [minAge, maxAge] = briefData.creator_age_range.split('-');
    return {
      id: campaignDataFromServer?.preferredCreatorAgeDetail?.id || uuid(),
      minAge: Number(minAge),
      maxAge: Number(maxAge) || null,
    };
  }

  if (wizardFormData?.age_range) {
    return {
      id: campaignDataFromServer?.preferredCreatorAgeDetail?.id || uuid(),
      minAge: wizardFormData?.age_range?.from_age,
      maxAge: wizardFormData?.age_range?.to_age,
    };
  }

  return null;
}

function getCreatorGenders(
  campaignDataFromServer: CampaignProps,
  briefData: BriefData,
  wizardFormData: WizardFormData | null
) {
  if (campaignDataFromServer?.preferredCreatorGenders?.length) {
    return campaignDataFromServer?.preferredCreatorGenders;
  }

  if (briefData.creator_gender && briefData.creator_gender.length > 0) {
    return briefData.creator_gender?.map((gender: string) => ({
      id: uuid(),
      name: capitalize(gender),
    }));
  }

  return wizardFormData?.genders?.map((gender: string) => ({
    id: uuid(),
    name: capitalize(gender),
  }));
}

function getProductShipping(
  campaignDataFromServer: CampaignProps,
  briefData: BriefData,
  wizardFormData: WizardFormData | null
) {
  return {
    method:
      campaignDataFromServer?.deliverablesType ||
      briefData?.product_fulfillment?.method ||
      wizardFormData?.product_fulfillment?.method,
    cash_value:
      campaignDataFromServer?.estimatedFulfillmentValue ||
      briefData?.product_fulfillment?.cash_value ||
      wizardFormData?.product_fulfillment?.cash_value,
  };
}

function getCreatorFollowerCount(
  campaignDataFromServer: CampaignProps,
  briefData: BriefData,
  wizardFormData: WizardFormData | null
) {
  let {unlocksAt, locksAbove} = {
    unlocksAt: campaignDataFromServer?.unlocksAt,
    locksAbove: campaignDataFromServer?.locksAbove,
  };
  let creatorFollowerCountValue =
    briefData?.creator_followers_count ||
    wizardFormData?.creator_followers_count;

  if (creatorFollowerCountValue) {
    creatorFollowerCountValue = creatorFollowerCountValue.replace('+', '');
    if (creatorFollowerCountValue.includes('-')) {
      const [min, max] = creatorFollowerCountValue.split('-');
      unlocksAt = Number(min);
      locksAbove = Number(max) || null;
    } else {
      unlocksAt = Number(creatorFollowerCountValue);
      locksAbove = null;
    }
  }

  return {unlocksAt, locksAbove};
}

function agentMessageToCampaignData(
  rawMessage: RawMessage,
  campaignDataFromServer: CampaignProps,
  initialData: object,
  wizardFormData: WizardFormData | null,
  availableVerticalTags: VerticalTag[],
  availableAgreementTerms: ContentAgreementTerm[]
): CampaignProps {
  const briefFieldManager = new BriefFieldManager(campaignDataFromServer);

  const initialCampaignData = {
    ...initialData,
  } as {[key: string]: any};

  if (!initialCampaignData.verticalTags) {
    initialCampaignData.verticalTags = [];
  }

  if (!initialCampaignData.preferredCreatorAgeDetail) {
    initialCampaignData.preferredCreatorAgeDetail = {
      minAge: DEFAULT_AGE_RANGE.min,
      maxAge: null,
    };
  }

  const campaignBriefData: any = {
    ...initialCampaignData,
  };

  const {brief} = rawMessage;
  let briefData: BriefData = brief;
  if (brief) {
    if (typeof brief === 'string') {
      try {
        briefData = JSON.parse(brief);
      } catch {
        console.log('Error parsing brief data', brief);
        briefData = JSON.parse(brief.replace(/'/g, '"'));
      }
    }

    campaignBriefData.isProcessing = briefData.is_processing;
    campaignBriefData.isPlaceholder = briefData.is_placeholder;
    campaignBriefData.loaded = campaignDataFromServer?.loaded;
    campaignBriefData.version = briefData.version;
    campaignBriefData.state =
      campaignDataFromServer?.state || CAMPAIGN_STATE.DRAFT;
    campaignBriefData.campaignPaidGigBriefItems = [];

    // BASIC DETAILS SECTION
    campaignBriefData.imageUrl = briefData.logo_url;
    campaignBriefData.name = briefData.campaign_name;
    campaignBriefData.verticalTags = availableVerticalTags.filter(
      (verticalTag: VerticalTag) =>
        briefData.campaign_verticals
          ?.map((tag) => tag.toLowerCase())
          ?.includes(verticalTag.name)
    );
    campaignBriefData.descriptionHtml = briefData.about_your_brand_and_campaign;

    // CREATOR PAYMENTS SECTION
    campaignBriefData.estimatedBudget =
      campaignDataFromServer?.estimatedBudget || wizardFormData?.budget;

    const {
      creatorCompensationType,
      suggestedCostForCreationsMin,
      suggestedCostForCreations,
      fixedCostForCreations,
    } = getCreatorCompensationRange(
      campaignDataFromServer,
      briefData,
      wizardFormData
    );

    campaignBriefData.suggestedCostForCreationsMin =
      suggestedCostForCreationsMin;
    campaignBriefData.suggestedCostForCreations = suggestedCostForCreations;
    campaignBriefData.fixedCostForCreations = fixedCostForCreations;
    campaignBriefData.creatorCompensationType = creatorCompensationType;

    const {method, cash_value} = getProductShipping(
      campaignDataFromServer,
      briefData,
      wizardFormData
    );

    if (method) {
      campaignBriefData.deliverablesType = method;
    }

    if (cash_value) {
      campaignBriefData.estimatedFulfillmentValue =
        extractNumberFromString(cash_value);
    }

    // DELIVERABLES SECTION
    campaignBriefData.network =
      campaignDataFromServer?.network || wizardFormData?.network?.toLowerCase();

    campaignBriefData.campaignBriefDeliverableItems = [];
    if (briefData.required_deliverables) {
      campaignBriefData.campaignBriefDeliverableItems =
        briefData.required_deliverables.map(
          (deliverable: RequiredDeliverable, index) => ({
            id:
              deliverable.id ||
              campaignDataFromServer?.campaignBriefDeliverableItems?.[index]
                ?.id ||
              uuid(),
            itemType: deliverable.type,
            description: deliverable.description,
            weight: index + 1,
          })
        );
    }

    const emptyDeliverableItems = getEmptyDeliverableItemsFromWizardForm(
      campaignBriefData,
      wizardFormData
    );

    campaignBriefData.campaignBriefDeliverableItems.push(
      ...emptyDeliverableItems
    );

    if (briefFieldManager.fields.deliverablesBriefItem) {
      campaignBriefData.campaignPaidGigBriefItems.push(
        briefFieldManager.fields.deliverablesBriefItem.value
      );
    }

    if ('product_to_feature' in briefData) {
      campaignBriefData.campaignPaidGigBriefItems.push({
        id:
          briefFieldManager.fields.productToFeatureBriefItem?.value.id ||
          uuid(),
        title: 'Product to feature',
        contentHtml: briefData.product_to_feature,
        highlighted: '',
        clientBriefTemplateId: '',
      });
    }

    if ('content_mandatories' in briefData) {
      campaignBriefData.campaignPaidGigBriefItems.push({
        id:
          briefFieldManager.fields.contentMandatoriesBriefItem?.value.id ||
          uuid(),
        title: translate(
          'components.campaign-brief.sections.deliverables.content-mandatories.title'
        ),
        contentHtml: briefData?.content_mandatories,
        highlighted: '',
        clientBriefTemplateId: '',
      });
    }
    campaignBriefData.campaignPaidGigBriefItems.push({
      id:
        briefFieldManager.fields.postingInstructionsBriefItem?.value.id ||
        uuid(),
      title: translate(
        'components.campaign-brief.sections.deliverables.posting-instructions.title'
      ),
      contentHtml:
        briefData.posting_instructions ||
        translate(
          'components.campaign-brief.sections.deliverables.posting-instructions.content'
        ),
      highlighted: '',
      clientBriefTemplateId: '',
    });
    campaignBriefData.swapMatchKeywords = [
      (briefData.campaign_hashtag || '')?.replaceAll('#', ''),
    ].filter(Boolean);

    if (briefFieldManager.fields.campaignHashtagBriefItem) {
      campaignBriefData.campaignPaidGigBriefItems.push(
        briefFieldManager.fields.campaignHashtagBriefItem.value
      );
    }

    campaignBriefData.campaignBriefCaptionGuidelines =
      briefData.campaign_brief_caption_guidelines ||
      campaignDataFromServer?.campaignBriefCaptionGuidelines ||
      [];

    if (briefFieldManager.fields.captionGuidelinesBriefItem) {
      campaignBriefData.campaignPaidGigBriefItems.push(
        briefFieldManager.fields.captionGuidelinesBriefItem.value
      );
    }

    // CREATOR DETAILS SECTION
    const {unlocksAt, locksAbove} = getCreatorFollowerCount(
      campaignDataFromServer,
      briefData,
      wizardFormData
    );

    campaignBriefData.unlocksAt = unlocksAt;
    campaignBriefData.locksAbove = locksAbove;

    campaignBriefData.preferredCreatorGenders = getCreatorGenders(
      campaignDataFromServer,
      briefData,
      wizardFormData
    );

    const ageRange = getCreatorAgeRange(
      campaignDataFromServer,
      briefData,
      wizardFormData
    );
    if (ageRange) {
      campaignBriefData.preferredCreatorAgeDetail = ageRange;
    }

    campaignBriefData.preferredCreatorStates =
      campaignDataFromServer?.preferredCreatorStates ||
      wizardFormData?.locations?.filter(
        (location) => location.type === LocationType.STATE
      );

    campaignBriefData.preferredCreatorCities =
      campaignDataFromServer?.preferredCreatorCities ||
      wizardFormData?.locations?.filter(
        (location) => location.type === LocationType.CITY
      );

    campaignBriefData.preferredCreatorCountries =
      campaignDataFromServer?.preferredCreatorCountries ||
      wizardFormData?.locations?.filter(
        (location) => location.type === LocationType.COUNTRY
      );

    campaignBriefData.preferredCreatorSpecialties =
      campaignDataFromServer?.preferredCreatorSpecialties ||
      wizardFormData?.specialties;

    if ('ideal_creator' in briefData) {
      campaignBriefData.campaignPaidGigBriefItems.push({
        id: briefFieldManager.fields.idealCreatorBriefItem?.value.id || uuid(),
        title: 'Ideal creator',
        contentHtml: briefData?.ideal_creator,
        highlighted: '',
        clientBriefTemplateId: '',
      });
    }

    // TIMELINE SECTION
    campaignBriefData.daysToPostAfterDeliverableFulfilled =
      briefData.days_to_post_after_deliverable_fulfilled ||
      DEFAULT_DAYS_TO_POST_AFTER_DELIVERABLE_FULFILLED;

    if (briefFieldManager.fields.timelineBriefItem) {
      campaignBriefData.campaignPaidGigBriefItems.push(
        briefFieldManager.fields.timelineBriefItem.value
      );
    }

    // ADDITIONAL INFORMATION SECTION
    campaignBriefData.questions = campaignDataFromServer?.questions || [];
    campaignBriefData.urlToPromote = campaignDataFromServer?.urlToPromote;

    if (briefFieldManager.fields.brandStyleGuideBriefItem) {
      campaignBriefData.campaignPaidGigBriefItems.push(
        briefFieldManager.fields.brandStyleGuideBriefItem.value
      );
    }

    if (briefFieldManager.fields.eligibilityRequirementsBriefItem) {
      campaignBriefData.campaignPaidGigBriefItems.push(
        briefFieldManager.fields.eligibilityRequirementsBriefItem.value
      );
    }

    // CONTENT AGREEMENT SECTION
    campaignBriefData.contentAgreementTerms =
      briefData.content_agreement_terms || availableAgreementTerms.slice(0, 4);
    campaignBriefData.contentAgreementTimePeriodInMonths =
      briefData.time_period_months || 12;

    if (briefFieldManager.fields.contentAgreementBriefItem) {
      campaignBriefData.campaignPaidGigBriefItems.push(
        briefFieldManager.fields.contentAgreementBriefItem.value
      );
    }

    if (briefFieldManager.fields.termsAndConditionsBriefItem) {
      campaignBriefData.campaignPaidGigBriefItems.push(
        briefFieldManager.fields.termsAndConditionsBriefItem.value
      );
    }

    // MISCELLANEOUS (required for submissions)
    const counters = getBriefDeliverableCounters(
      campaignBriefData.campaignBriefDeliverableItems
    );
    Object.entries(counters).forEach(([counterKey, counterValue]) => {
      campaignBriefData[counterKey] = counterValue;
    });

    if (!imageFormatIsValid(campaignBriefData.imageUrl)) {
      campaignBriefData.imageUrl = undefined;
    }
  }

  campaignBriefData.sectionsToHide = {
    campaignHashtag: ['tiktok_v2'].includes(campaignBriefData.network),
  };

  return campaignBriefData;
}

export default agentMessageToCampaignData;
