import React from 'react';
import {NavLink} from 'react-router-dom';
import {Stack} from '@mui/material';
import {Body, Icon} from '@lightricks/react-design-system';
import {contentStudioActions} from '@/stores/contentStudioStore';
import type {MenuItem as MenuItemType} from '../menuItems';

function MenuItem({item}: {item: MenuItemType}) {
  return (
    <NavLink
      to={`/content-studio/${item.route}`}
      end
      onClick={() => contentStudioActions.setActiveTab(item.route)}
      style={{width: '95%'}}
    >
      {({isActive}) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            gap="32px"
            paddingX="32px"
            paddingY="12px"
            bgcolor={isActive ? 'black' : ''}
            borderRadius="8px"
          >
            <Icon
              name={item.icon}
              size="medium"
              appearance="primary"
              color={isActive ? 'white' : 'black'}
            />
            <Body size="lg" color={isActive ? 'white' : 'black'}>
              {item.label}
            </Body>
          </Stack>
        );
      }}
    </NavLink>
  );
}

export default MenuItem;
