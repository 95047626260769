import React from 'react';
import {Stack} from '@mui/material';
import {designSystemToken, Tooltip} from '@lightricks/react-design-system';
import getEngagementRateString from '@/utils/getEngagementRateString';
import getFollowersCountString from '@/utils/getFollowersCountString';
import translate from '@/utils/translate';
import {SocialNetworkInfo} from '@/types/socials';
import styles from '@/components/creator-card/CreatorSocialsIcons.module.scss';
import SocialStat from '@/components/creator-card/SocialStat';
import Link from '@/components/link';
import SocialNetworkIcon from '@/components/social-network-icon';
import useFilteredTiktokSocialNetworks from '@/hooks/use-filtered-tiktok-social-networks';
import useSortedSocialNetworks from '@/hooks/use-sorted-social-networks';

type CreatorSocialsIconsProps = {
  socialNetworks: SocialNetworkInfo[];
  selectedPlatformProvider?: string;
  onClickSocialNetwork?: (networkProvider: string) => void;
};

function CreatorSocialsIcons({
  socialNetworks,
  selectedPlatformProvider,
  onClickSocialNetwork,
}: CreatorSocialsIconsProps) {
  const SOCIAL_NETWORK_ICON_SIZE = 29;
  const sortedSocialNetworks = useSortedSocialNetworks(
    socialNetworks
  ) as SocialNetworkInfo[];
  const filteredSocialNetworks =
    useFilteredTiktokSocialNetworks(sortedSocialNetworks);
  return (
    <div className={styles.socialNetworksContainer}>
      {filteredSocialNetworks.map((network: SocialNetworkInfo) => {
        return (
          <Tooltip
            key={network.provider}
            type="light"
            title={
              <Stack direction="column">
                <SocialStat
                  title={`${
                    network.provider === 'youtube'
                      ? translate(`Subscribers`)
                      : translate(`Followers`)
                  }:`}
                  value={network.followersCount}
                  titleColor={designSystemToken('semantic.fg.secondary')}
                  valueColor={designSystemToken('semantic.fg.primary')}
                  formatingFunc={getFollowersCountString}
                />
                <SocialStat
                  title={`${
                    network.provider === 'youtube'
                      ? translate('Avg Eng')
                      : translate(`Eng Rate`)
                  }:`}
                  value={network.engagementRate}
                  titleColor={designSystemToken('semantic.fg.secondary')}
                  valueColor={designSystemToken('semantic.fg.primary')}
                  formatingFunc={getEngagementRateString}
                />
              </Stack>
            }
            placement="top-start"
            arrow
          >
            <div key={network.provider}>
              <Link
                to={network.url}
                target="_blank"
                rel="noopener noreferrer"
                keepBrandIdIfPresent={false}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click event
                  onClickSocialNetwork?.(network.provider);
                }}
              >
                <div className={styles.socialIconContainer}>
                  <SocialNetworkIcon
                    name={network.provider}
                    width={SOCIAL_NETWORK_ICON_SIZE}
                    height={SOCIAL_NETWORK_ICON_SIZE}
                    color={
                      !selectedPlatformProvider ||
                      network.provider.includes(selectedPlatformProvider)
                        ? designSystemToken('semantic.fg.neutral')
                        : designSystemToken('semantic.fg.secondary')
                    }
                  />
                </div>
              </Link>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default CreatorSocialsIcons;
