import React, {useMemo} from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import Link from '@/components/link';
import styles from './ActionCard.module.scss';

export interface ActionCardProps {
  testID?: string;
  className?: string;
  backgroundColor: string;
  title: string;
  subtitle: string;
  ctaLink?: string;
  onCtaClick?: () => void;
}

function ActionCard(props: ActionCardProps) {
  const {
    testID = 'action-card',
    className,
    backgroundColor,
    title,
    subtitle,
    ctaLink,
    onCtaClick,
  } = props;

  const card = useMemo(() => {
    return (
      <div
        className={`${styles.actionCardContainer} ${!ctaLink ? className : ''}`}
        style={!ctaLink ? {background: backgroundColor} : {}}
        data-testid={testID}
        onClick={onCtaClick}
      >
        <div className={styles.content}>
          <Headline size="md">{title}</Headline>
          <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
            {subtitle}
          </Body>
        </div>
        <Icon
          className={styles.icon}
          size="medium"
          appearance="neutral"
          name="Actions-Arrow-Forward-Small"
        />
      </div>
    );
  }, [testID, className, backgroundColor, title, subtitle, onCtaClick]);

  if (ctaLink) {
    return (
      <Link
        to={ctaLink}
        className={`${styles.link} ${className}`}
        style={{background: backgroundColor}}
        testID={`${testID}--cta-link`}
      >
        {card}
      </Link>
    );
  }

  return card;
}

export default ActionCard;
