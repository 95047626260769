import React, {useEffect, useMemo, useState} from 'react';
import {Headline} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import getAnalyticsScreenName from '@/utils/getAnalyticsScreenName';
import translate from '@/utils/translate';
import {ArrowDirection} from '@/components/arrow/Arrow';
import {TIKTOK_IDENTITY_IDS} from '@/components/creator-profile/constants';
import DropdownButton from '@/components/dropdown-button';
import {Option} from '@/components/dropdown-button/DropdownButton';
import ItemsBar from '@/components/items-bar';
import CreatorCardWrapper from '@/components/suggested-creators/components/creator-card-wrapper';
import useBrandCampaignsQuery from '@/hooks/queries/use-brand-campaigns-query';
import {ACTIVE_STATES} from '@/hooks/queries/use-brand-campaigns-query/useBrandCampaignsQuery';
import useCampaignSuggestedCreatorsQuery from '@/hooks/queries/use-campaign-suggested-creators-query';
import useBrandId from '@/hooks/use-brand-id';
import useElementDimensions from '@/hooks/use-element-dimensions';
import usePrevious from '@/hooks/use-previous';
import useCampaignSuggestedCreatorsStore, {
  campaignSuggestedCreatorsActions,
} from '@/stores/campaignSuggestedCreatorsStore';
import useEmberIframeStyleStore from '@/stores/emberIframeStyleStore';
import Conditional from '../conditional';
import styles from './SuggestedCreators.module.scss';
import EmptyState from './components/empty-state';

export interface SuggestedCreatorsProps {
  testID?: string;
}

function getScreenName() {
  const screenName = getAnalyticsScreenName(window.location.pathname);
  return `Suggested/${screenName}`;
}

function SuggestedCreators(props: SuggestedCreatorsProps) {
  const [
    recruitmentResultsPresentedCampaigns,
    setRecruitmentResultsPresentedCampaigns,
  ] = useState<Record<string, boolean>>({});
  const {testID = 'suggested-creators'} = props;
  const [screenName] = useState(getScreenName());
  const {selectedCampaignId, selectedCreatorId} =
    useCampaignSuggestedCreatorsStore();
  const brandId = useBrandId();
  const previousBrandId = usePrevious(brandId, brandId);

  const {brandCampaigns} = useBrandCampaignsQuery({
    brandId,
    refetchOnWindowFocus: false,
    states: ACTIVE_STATES,
    cacheTime: Infinity,
    staleTime: Infinity,
    orderByLatest: true,
  });

  const {suggestedCreators, isLoading: isLoadingSuggestedCreators} =
    useCampaignSuggestedCreatorsQuery({
      campaignId: selectedCampaignId,
      perPage: 25,
      refetchOnWindowFocus: false,
      compact: true,
      include: [],
    });

  const itemsBarSuggestedCreators: {
    id: string;
    label: string;
    imageUrl: string;
  }[] = useMemo(
    () =>
      suggestedCreators.map((creator) => ({
        id: creator.id,
        label: creator.firstName || '',
        imageUrl: creator.imageUrl || '',
      })),
    [suggestedCreators]
  );

  const selectedCreator = useMemo(() => {
    return (
      suggestedCreators.find((creator) => creator.id === selectedCreatorId) ||
      null
    );
  }, [selectedCreatorId, suggestedCreators]);

  useEffect(() => {
    if (selectedCampaignId) {
      const recruitmentFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.RECRUITMENT_SEARCH
      );

      AnalyticsService.dispatchEvent(eventNames.RECRUITMENT_SEARCH_DETAIL, {
        flow_id: recruitmentFlow.flow_id,
        screen_presentation_id: recruitmentFlow.screen_presentation_id,
        recruitment_search_id: '',
        recruitment_screen_name: screenName,
        search_detail_id: '',
        search_detail_name: 'select_campaign',
        search_detail_value: selectedCampaignId,
        campaign_id: selectedCampaignId,
      });
    }
  }, [selectedCampaignId]);

  useEffect(() => {
    if (
      isLoadingSuggestedCreators ||
      recruitmentResultsPresentedCampaigns[selectedCampaignId] ||
      !selectedCampaignId
    ) {
      return;
    }
    setRecruitmentResultsPresentedCampaigns((prev) => ({
      ...prev,
      [selectedCampaignId]: true,
    }));
    const recruitmentFlow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.RECRUITMENT_SEARCH
    );

    AnalyticsService.dispatchEvent(eventNames.RECRUITMENT_RESULTS_PRESENTED, {
      flow_id: recruitmentFlow.flow_id,
      screen_presentation_id: recruitmentFlow.screen_presentation_id,
      recruitment_search_id: '',
      recruitment_screen_name: screenName,
      item_position: '',
      item_identifier: '',
      creator_id: JSON.stringify(
        suggestedCreators.map((creator) => creator.id)
      ),
      campaign_id: selectedCampaignId,
      result_source: screenName,
    });
  }, [
    suggestedCreators,
    selectedCampaignId,
    isLoadingSuggestedCreators,
    recruitmentResultsPresentedCampaigns,
  ]);

  const {sidebarWidth} = useEmberIframeStyleStore();

  const {
    elementRef: suggestedCreatorsContainerRef,
    elementWidth: suggestedCreatorsContainerWidth,
  } = useElementDimensions(
    [sidebarWidth, brandCampaigns, suggestedCreators],
    true
  );

  useEffect(() => {
    if (previousBrandId !== brandId) {
      // reset selections on brand change
      campaignSuggestedCreatorsActions.setSelectedCampaignId('');
      campaignSuggestedCreatorsActions.setSelectedCampaignNetwork('');
      campaignSuggestedCreatorsActions.setSelectedCreatorId('');
    }
  }, [brandId, previousBrandId]);

  useEffect(() => {
    if (brandCampaigns.length > 0) {
      if (
        !selectedCampaignId ||
        (selectedCampaignId &&
          !brandCampaigns.find(
            (campaign) => campaign.id === selectedCampaignId
          ))
      ) {
        // Select the first available campaign by default
        campaignSuggestedCreatorsActions.setSelectedCampaignId(
          brandCampaigns[0].id
        );
        campaignSuggestedCreatorsActions.setSelectedCampaignNetwork(
          brandCampaigns[0].network
        );
      }
    }
  }, [brandCampaigns, selectedCampaignId]);

  useEffect(() => {
    if (suggestedCreators.length > 0) {
      if (!selectedCreatorId) {
        // Select the first available creator by default
        campaignSuggestedCreatorsActions.setSelectedCreatorId(
          suggestedCreators[0].id
        );
      } else if (
        selectedCreatorId &&
        !suggestedCreators.find((creator) => creator.id === selectedCreatorId)
      ) {
        campaignSuggestedCreatorsActions.setSelectedCreatorId(
          suggestedCreators[0].id
        );
      }
    }
  }, [suggestedCreators, selectedCreatorId]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        onArrowClick(ArrowDirection.LEFT);
      } else if (e.key === 'ArrowRight') {
        onArrowClick(ArrowDirection.RIGHT);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedCreatorId, suggestedCreators]);

  const selectedCampaignNetwork = useMemo(() => {
    const network = brandCampaigns?.find(
      (campaign) => campaign.id === selectedCampaignId
    )?.network;

    if (TIKTOK_IDENTITY_IDS.includes(network as string)) {
      return 'tiktok';
    }

    return network;
  }, [brandCampaigns, selectedCampaignId]);

  const options = useMemo(() => {
    return brandCampaigns.map((campaign) => ({
      id: campaign.id,
      label: campaign.name,
    }));
  }, [brandCampaigns]);

  const handleCampaignSelected = (option: Option) => {
    if (option.id === selectedCampaignId) {
      return;
    }
    const campaign = brandCampaigns.find(
      (brandCampaign) => brandCampaign.id === option.id
    );

    campaignSuggestedCreatorsActions.setSelectedCampaignId(option.id);
    campaignSuggestedCreatorsActions.setSelectedCampaignNetwork(
      campaign?.network || ''
    );
    campaignSuggestedCreatorsActions.setSelectedCreatorId('');
  };

  const dropdownLabel = useMemo(() => {
    if (!selectedCampaignId) {
      return translate('Select Campaign');
    }
    return (
      brandCampaigns.find((campaign) => campaign.id === selectedCampaignId)
        ?.name || translate('Select Campaign')
    );
  }, [selectedCampaignId]);

  const onArrowClick = (direction: ArrowDirection) => {
    const currentIndex = suggestedCreators.findIndex(
      (creatorProfile) => creatorProfile.id === selectedCreatorId
    );
    if (direction === ArrowDirection.LEFT && currentIndex > 0) {
      campaignSuggestedCreatorsActions.setSelectedCreatorId(
        suggestedCreators[currentIndex - 1].id
      );
    } else if (
      direction === ArrowDirection.RIGHT &&
      currentIndex < suggestedCreators.length - 1
    ) {
      campaignSuggestedCreatorsActions.setSelectedCreatorId(
        suggestedCreators[currentIndex + 1].id
      );
    }
  };

  return (
    <div
      className={styles.suggestedCreatorsContainer}
      data-testid={testID}
      ref={suggestedCreatorsContainerRef}
    >
      <div className={styles.header}>
        <Headline size="md">
          {suggestedCreators?.length || null}{' '}
          {translate('Smart Matches for your campaign')}
        </Headline>
        <DropdownButton
          buttonClassName={styles.dropdownButton}
          label={dropdownLabel}
          options={options}
          selectedOptionIds={[selectedCampaignId]}
          onChange={handleCampaignSelected}
          size="medium"
        />
      </div>
      <Conditional
        condition={isLoadingSuggestedCreators || !!suggestedCreators.length}
        fallback={<EmptyState />}
      >
        <>
          <ItemsBar
            key={selectedCampaignId}
            isLoading={isLoadingSuggestedCreators}
            containerWidth={suggestedCreatorsContainerWidth}
            items={itemsBarSuggestedCreators}
            onItemClick={campaignSuggestedCreatorsActions.setSelectedCreatorId}
            selectedItemId={selectedCreatorId}
          />
          <div className={styles.contentContainer}>
            <CreatorCardWrapper
              brandId={brandId}
              creatorProfileId={selectedCreatorId}
              creatorProfile={selectedCreator}
              campaignId={selectedCampaignId}
              campaignNetwork={selectedCampaignNetwork}
              containerWidth={suggestedCreatorsContainerWidth}
              screenName={screenName}
            />
          </div>
        </>
      </Conditional>
    </div>
  );
}

export default SuggestedCreators;
