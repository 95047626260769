import {create} from 'zustand';
import ContentStudioRoutes from '@/routes/content-studio/contentStudioRoutesList';

type ContentStudioState = {
  activeTab: ContentStudioRoutes;
};

const contentStudioStore = create<ContentStudioState>()(() => ({
  activeTab: ContentStudioRoutes.MAIN,
}));

export const contentStudioActions = {
  setActiveTab: (tab: ContentStudioRoutes) =>
    contentStudioStore.setState({activeTab: tab}),
};

export default contentStudioStore;
