export const SMALL_SCREEN_CONTAINER_WIDTH_THRESHOLD = 1128;
export const CREATOR_CARD_GALLERY_SETTINGS = {
  DEFAULT: {
    gapSize: 2,
    numColumns: 3,
    numRows: 3,
    baseWidth: '100%',
    baseHeight: 174,
  },
  SMALL: {
    gapSize: 2,
    numColumns: 2,
    numRows: 3,
    baseWidth: '100%',
    baseHeight: 174,
  },
};

export const CREATOR_SKILLS_MAX_ITEMS = 5;
