import React from 'react';
import Skeleton from '@/components/skeleton';
import styles from './CreatorInfo.module.scss';

// eslint-disable-next-line import/prefer-default-export
export function CreatorInfoSkeleton() {
  return (
    <div className={styles.creatorInfoContainer}>
      <div className={styles.avatarContainer}>
        <Skeleton variant="circular" width={80} height={80} />
      </div>
      <div className={styles.creatorDetailsContainer}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
          <Skeleton variant="rectangular" width={90} height={24} />
          <Skeleton variant="rectangular" width={150} height={18} />
        </div>
        <div style={{display: 'flex', gap: 8}}>
          <Skeleton variant="rounded" width={55} height={24} />
          <Skeleton variant="rounded" width={80} height={24} />
        </div>
      </div>
      <Skeleton variant="circular" width={40} height={40} />
    </div>
  );
}
