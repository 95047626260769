import {useCallback} from 'react';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import momentSecondsSince from '@/utils/momentSecondsSince';

type ScreenPresentedEventParams = {
  screen_name: string;
  source: string;
  screen_presentation_id?: string;
  trigger_flow_id?: string;
  trigger_flow_name?: string;
  tab?: string;
};

type ScreenDismissedEventParams = {
  screen_name: string;
  destination: string;
  screen_presentation_id?: string;
  tab?: string;
};

function useTrackScreenEvent(flowName?: string) {
  const presented = useCallback(
    ({
      screen_name,
      source,
      screen_presentation_id = '',
      trigger_flow_id = '',
      trigger_flow_name = '',
      tab = '',
    }: ScreenPresentedEventParams) => {
      const flow = flowName
        ? AnalyticsService.getOrCreateFlow(flowName)
        : undefined;

      AnalyticsService.screenPresented(screen_name, source, {
        trigger_flow_id: flow?.flow_id || trigger_flow_id,
        trigger_flow_name: flow?.flow_name || trigger_flow_name,
        screen_presentation_id:
          flow?.screen_presentation_id || screen_presentation_id,
        tab,
      });
    },
    []
  );

  const dismissed = useCallback(
    ({
      screen_name,
      destination,
      screen_presentation_id = '',
      tab = '',
    }: ScreenDismissedEventParams) => {
      const flow = flowName
        ? AnalyticsService.getOrCreateFlow(flowName)
        : undefined;

      const activeScreenPresented = AnalyticsService.getActiveScreenPresented();

      AnalyticsService.screenDismissed(screen_name, destination, {
        flow_id: flow?.flow_id,
        screen_presentation_id:
          flow?.screen_presentation_id || screen_presentation_id,
        session_duration_since_presented:
          activeScreenPresented.presented_at_timestamp
            ? momentSecondsSince(
                activeScreenPresented.presented_at_timestamp
              ).toString()
            : '',
        tab,
      });
    },
    []
  );

  return {presented, dismissed};
}

export default useTrackScreenEvent;
