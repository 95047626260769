import React, {useMemo} from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
  Tooltip,
} from '@lightricks/react-design-system';
import calculateAge from '@/utils/calculateAge';
import translate from '@/utils/translate';
import {YOUTUBE_DS_ICON_INVERSE_OVERRIDES} from '@/config/constants';
import SOCIAL_NETWORK_ICON from '@/config/socialNetworkIconMap';
import {SuggestedCreator} from '@/types/suggestedCreator';
import Conditional from '@/components/conditional';
import Link from '@/components/link';
import ToggleableTags from '@/components/toggleable-tags';
import CreatorAvatar from '../creator-avatar';
import styles from './CreatorInfo.module.scss';

interface CreatorInfoProps {
  creatorProfile: SuggestedCreator;
  network?: string | null;
  onSocialNetworkClick?: (network: string) => void;
  isAddedToList?: boolean;
  onAddToListClick?: () => void;
}

interface CreatorDetailsProps {
  displayName: string;
  age: number | null;
  location: string;
  tags: string[];
}

function CreatorDetails(props: CreatorDetailsProps) {
  const {displayName, age, location, tags} = props;
  return (
    <div className={styles.creatorDetailsContainer}>
      <div>
        <Headline size="sm">{displayName}</Headline>
        <Body size="md" color={designSystemToken('semantic.fg.primary')}>
          {[age, location].filter(Boolean).join(' | ')}
        </Body>
      </div>
      <ToggleableTags
        tags={tags}
        size="md"
        tagClassName={styles.tag}
        tagsLimit={2}
      />
    </div>
  );
}

function CreatorInfo(props: CreatorInfoProps) {
  const {
    creatorProfile,
    network,
    onSocialNetworkClick,
    isAddedToList,
    onAddToListClick,
  } = props;

  const isYouTube = network?.includes('youtube');

  return (
    <div className={styles.creatorInfoContainer}>
      <div className={styles.avatarContainer}>
        <CreatorAvatar creatorProfile={creatorProfile} size="2xl" />
        <Conditional condition={!!network && !!creatorProfile.profilePageUrl}>
          <div className={styles.networkContainer}>
            <Link
              className={`${styles.socialNetworkIcon} ${
                isYouTube ? styles.youtube : ''
              }`}
              to={creatorProfile.profilePageUrl}
              target="_blank"
              keepBrandIdIfPresent={false}
              onClick={() => {
                onSocialNetworkClick?.(network!);
              }}
            >
              <Icon
                size="medium"
                appearance="neutral"
                name={SOCIAL_NETWORK_ICON[network as string]}
                color={designSystemToken('semantic.fg.white')}
                {...(isYouTube ? YOUTUBE_DS_ICON_INVERSE_OVERRIDES : {})}
              />
            </Link>
          </div>
        </Conditional>
      </div>
      <CreatorDetails
        displayName={creatorProfile.displayName}
        age={calculateAge(creatorProfile.birthDate)}
        location={creatorProfile.location}
        tags={creatorProfile.tags.slice(0, 2)}
      />
      <Tooltip arrow placement="top" title={translate('Add to list')}>
        <div className={styles.addToListButton} onClick={onAddToListClick}>
          <Icon
            size="medium"
            appearance="neutral"
            name={
              isAddedToList ? 'Actions-Bookmark-Active' : 'Actions-Bookmark'
            }
          />
        </div>
      </Tooltip>
    </div>
  );
}

export default CreatorInfo;
