import React, {useMemo} from 'react';
import {Avatar} from '@lightricks/react-design-system';
import {avatarSize} from '@lightricks/react-design-system/dist/components/avatar/Avatar';
import {ANONYMOUS_USER_AVATAR_NAME} from '@/config/constants';
import {ProfileProps} from '@/types/creatorProfile';
import useAvatarImageOrInitials from '@/hooks/use-avatar-image-or-initials';
import styles from './CreatorAvatar.module.scss';

function CreatorAvatar(props: {
  creatorProfile: ProfileProps;
  className?: string;
  size?: (typeof avatarSize)[number];
}) {
  const {creatorProfile, className, size = 'lg'} = props;
  const {imageUrl, initials} = useAvatarImageOrInitials(
    creatorProfile.imageUrl,
    creatorProfile.displayName
  );

  const avatarType = useMemo(() => {
    if (!imageUrl) {
      return 'initials';
    }
    if (imageUrl.includes(ANONYMOUS_USER_AVATAR_NAME)) {
      return 'placeholder';
    }

    return 'picture';
  }, [imageUrl]);

  return (
    <div className={styles.creatorAvatarContainer}>
      <Avatar
        size={size}
        type={avatarType}
        initials={initials}
        src={imageUrl}
        className={className}
      />
    </div>
  );
}

export default CreatorAvatar;
