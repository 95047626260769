import {startCase} from 'lodash';
import React from 'react';
import {
  designSystemToken,
  Headline,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ContentPreference} from '@/types/creatorProfile';
import {CREATOR_SKILLS_MAX_ITEMS} from '../../utils/constants';
import styles from './CreatorSkills.module.scss';

interface CreatorSkillsProps {
  skills: ContentPreference[];
}

function CreatorSkills(props: CreatorSkillsProps) {
  const {skills} = props;
  const renderTag = (text: string) => (
    <Label
      size="md"
      key={text}
      className={styles.skill}
      color={designSystemToken('semantic.fg.primary')}
    >
      {startCase(text)}
    </Label>
  );

  return (
    <div className={styles.creatorSkillsContainer}>
      <div className={styles.header}>
        <Icon
          size="medium"
          appearance="neutral"
          name="Navigation-Valentines"
          color={designSystemToken('semantic.fg.secondary')}
        />
        <Headline size="xs">{translate('Skills')}</Headline>
      </div>
      <div className={styles.skillsContainer}>
        {skills
          .slice(0, CREATOR_SKILLS_MAX_ITEMS)
          .map((skill) => renderTag(skill.kind))}
      </div>
    </div>
  );
}
export default CreatorSkills;
