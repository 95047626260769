import React, {useEffect, useMemo, useState} from 'react';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import {
  BriefAiWizardFormProps,
  CreatorTypeOption,
  FormValidatorsMapProps,
  RequiredDeliverable,
} from '@/views/campaigns/brief/components/brief-wizard/BriefAiWizardProps';
import {
  BRAND_AWARENESS_CAMPAIGN_STEPS,
  CONTENT_CREATION_CAMPAIGN_STEPS,
} from '@/views/campaigns/brief/components/brief-wizard/steps';
import calculateCreatorCostAndTotalCreators from '@/views/campaigns/brief/components/brief-wizard/utils/calculateCreatorCostAndTotalCreators';
import formValidatorsMap, {
  stringToNumber,
} from '@/views/campaigns/brief/components/brief-wizard/validators';
import BasicStepper from '@/components/basic-stepper/BasicStepper';
import {
  CAMPAIGN_TYPE,
  CampaignTypes,
} from '@/components/new-campaign-wizard-modal/CampaignTypeOptions';
import {NewCampaignFormData} from '@/contexts/NewCampaignContext';
import styles from './NewCampaignWizard.module.scss';

const DEFAULT_BUDGET = 2000;
const DEFAULT_INSTAGRAM_STORY_POST_NUMBER_OF_FRAMES = 3;
const EMPTY_CREATOR_COST_RANGE = '-';

const INITIAL_FORM_DATA = {
  brandName: '',
  budget: DEFAULT_BUDGET,
  customBudget: null,
  product: '',
  network: 'instagram',
  requiredDeliverables: [],
  creatorType: '',
  creatorCostRange: null,
  sendProductToCreators: false,
  sendProductToCreatorsMethod: null,
  sendProductToCreatorsCashValue: null,
};

const campaignTypeToSteps = {
  [CAMPAIGN_TYPE.BRAND_AWARENESS]: BRAND_AWARENESS_CAMPAIGN_STEPS,
  [CAMPAIGN_TYPE.CONTENT_CREATION]: CONTENT_CREATION_CAMPAIGN_STEPS,
};

export type NewCampaignWizardProps = {
  activeStep: number;
  campaignId: string;
  setActiveStep: (step: number) => void;
  formData: BriefAiWizardFormProps;
  setFormData: (formData: BriefAiWizardFormProps) => void;
  campaignType: CampaignTypes;
  onSubmit: (formData: NewCampaignFormData) => void;
  brandName?: string;
  allowEditCreatorBid?: boolean;
};

function NewCampaignWizard(props: NewCampaignWizardProps) {
  const {
    activeStep,
    setActiveStep,
    formData,
    setFormData,
    campaignType,
    onSubmit,
    brandName,
    campaignId,
    allowEditCreatorBid,
  } = props;
  const [formErrors, setFormErrors] = useState({
    budget: '',
    requiredDeliverables: '',
  });
  const activeCampaignStepsType =
    campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS
      ? BRAND_AWARENESS_CAMPAIGN_STEPS
      : CONTENT_CREATION_CAMPAIGN_STEPS;

  const mapRequiredDeliverables = (
    requiredDeliverables: RequiredDeliverable[]
  ) => {
    const requiredDeliverablesResult = requiredDeliverables.reduce(
      (acc: any, curr: RequiredDeliverable) => {
        acc[curr.value] += 1;
        return acc;
      },
      {
        storyPost: 0,
        feedPostPhoto: 0,
        reelPost: 0,
        tiktokVideo: 0,
        // youtubeShortFormVideo: 0,
        youtubeLongFormVideo: 0,
        // youtubeShort: 0,
        contentStandardPhoto: 0,
        // contentPremiumPhoto: 0,
        contentStandardVideo: 0,
        // contentPremiumVideo: 0,
      }
    );

    if (requiredDeliverablesResult.storyPost > 0) {
      requiredDeliverablesResult.storyPostNumberOfFrames =
        DEFAULT_INSTAGRAM_STORY_POST_NUMBER_OF_FRAMES;
    }
    return requiredDeliverablesResult;
  };

  const getBudget = () => {
    let budget = 0;
    if (formData.budget) {
      budget = stringToNumber(formData.budget);
    } else if (formData.customBudget) {
      budget = stringToNumber(formData.customBudget);
    }
    return budget;
  };

  const creatorTypes = useMemo(
    () =>
      Object.values(
        calculateCreatorCostAndTotalCreators(
          mapRequiredDeliverables(formData.requiredDeliverables),
          getBudget(),
          campaignType === CAMPAIGN_TYPE.CONTENT_CREATION
        )
      ),
    [formData.requiredDeliverables, formData.budget, formData.customBudget]
  ) as CreatorTypeOption[];

  useEffect(() => {
    const selectedCreatorType = creatorTypes.find(
      (creatorType: CreatorTypeOption) =>
        creatorType.value === formData.creatorType
    ) as CreatorTypeOption;
    if (selectedCreatorType?.disabled) {
      updateFormData({creatorType: null});
    }
  }, [creatorTypes]);

  useEffect(() => {
    if (!formData.creatorType) {
      if (
        (creatorTypes?.[0]?.totalCreators || 0) > 0 ||
        campaignType === CAMPAIGN_TYPE.CONTENT_CREATION
      ) {
        updateFormData({creatorType: creatorTypes[0].value});
      }
    }
  }, [formData, creatorTypes]);

  const updateFormData = (formDataPartialUpdate: object) => {
    setFormData({...formData, ...formDataPartialUpdate});
  };

  const formValidators = formValidatorsMap();

  useEffect(() => {
    validateForm();
  }, [formData]);
  const validateForm = () => {
    let isValid = true;

    // reset the error associated with the field/s that were updated
    const errorKeysToReset = Object.keys(formData).reduce((acc: any, key) => {
      acc[key] = '';
      return acc;
    }, {});

    let errors = {};
    Object.keys(formValidators).forEach((key) => {
      const validatorErrors = formValidators[
        key as keyof FormValidatorsMapProps
      ].reduce((acc: any, validator) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (
          !validator.validator(
            formData[key as keyof BriefAiWizardFormProps],
            formData,
            creatorTypes
          )
        ) {
          isValid = false;
          acc[key] = validator.errorMessage;
        }
        return acc;
      }, {});
      errors = {...errors, ...validatorErrors};
    });

    setFormErrors({...formErrors, ...errorKeysToReset, ...errors});
    return isValid;
  };
  const handleSubmit = () => {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    const creatorType = creatorTypes.find(
      (_creatorType: CreatorTypeOption) =>
        _creatorType.value === formData.creatorType
    ) as CreatorTypeOption;

    const requiredDeliverables = mapRequiredDeliverables(
      formData.requiredDeliverables
    );

    let productFulfillment;

    if (formData.sendProductToCreators) {
      productFulfillment = {
        method: formData.sendProductToCreatorsMethod,
        cashValue: formData.sendProductToCreatorsCashValue,
      };
    }

    onSubmit({
      brandName: formData.brandName || brandName,
      network:
        campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS
          ? formData.network
          : null,
      budget: (formData.budget || formData.customBudget) as number,
      ageRange: formData.ageRange,
      genders: formData.genders,
      locations: formData.locations,
      specialties: formData.specialties,
      requiredDeliverables,
      product: formData.product,
      creatorType: creatorType?.value,
      creatorCostRange: allowEditCreatorBid
        ? creatorType?.costRange
        : EMPTY_CREATOR_COST_RANGE,
      creatorFollowersCount: creatorType?.creatorFollowersCount,
      sendProductToCreators: formData.sendProductToCreators ? 'Yes' : 'No',
      sendProductToCreatorsMethod: formData.sendProductToCreators
        ? formData.sendProductToCreatorsMethod
        : undefined,
      sendProductToCreatorsCashValue: formData.sendProductToCreators
        ? formData.sendProductToCreatorsCashValue
        : undefined,
      productFulfillment, // used in the client only
    });
  };

  const handleNextStep = () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);

    const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.CAMPAIGN_CREATION
    );

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_CREATION_TRANSITION, {
      flow_id: campaignCreationFlow.flow_id,
      current_step: activeCampaignStepsType[activeStep].id,
      destination_step: activeCampaignStepsType[nextStep].id,
      step_details: JSON.stringify({
        campaign_type: campaignType,
        ...formData,
      }),
      step_title: activeCampaignStepsType[activeStep].id,
      campaign_id: campaignId,
      step_index: activeStep + 1,
    });
  };

  const handleBasicStepperStepChange = (
    newActiveStep: number,
    previousStep: number
  ) => {
    setActiveStep(newActiveStep);

    const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.CAMPAIGN_CREATION
    );

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_CREATION_TRANSITION, {
      flow_id: campaignCreationFlow.flow_id,
      current_step: activeCampaignStepsType[previousStep].id,
      destination_step: activeCampaignStepsType[newActiveStep].id,
      step_details: JSON.stringify({
        campaign_type: campaignType,
        ...formData,
      }),
      step_title: activeCampaignStepsType[previousStep].id,
      campaign_id: campaignId,
      step_index: activeStep + 1,
    });
  };

  const stepFilterConfig = {
    [CAMPAIGN_TYPE.CONTENT_CREATION]: {
      disallowed: allowEditCreatorBid ? [] : ['budgetAndNetwork'],
    },
  };

  const getSteps = () => {
    if (!campaignType) {
      return [];
    }

    const steps = campaignTypeToSteps[campaignType] || [];

    const filterCriteria = stepFilterConfig[campaignType]?.disallowed || [];

    return steps.filter((step) => !filterCriteria.includes(step.id));
  };

  const STEPS = getSteps();

  const renderStep = STEPS.map((step: any, index) => {
    const {id, Component, testID} = STEPS[index];
    const {canContinueToStep} = STEPS[index] || {};
    if (Component) {
      return (
        <Component
          key={id}
          testID={testID}
          formData={formData}
          formErrors={formErrors}
          updateFormData={updateFormData}
          creatorTypes={creatorTypes}
          onClickNext={handleNextStep}
          canContinue={canContinueToStep?.(formData, formErrors)}
          onSubmit={() => handleSubmit()}
          campaignType={campaignType}
          allowEditCreatorBid={allowEditCreatorBid}
        />
      );
    }
    return null;
  });
  return (
    <div className={styles.wizardContainer}>
      <BasicStepper
        steps={STEPS.length}
        showForwardButton={false}
        showBackButtonLabel={false}
        stepperClassName={styles.customStepperClassName}
        setActiveStep={handleBasicStepperStepChange}
        activeStep={activeStep}
      />
      {renderStep[activeStep]}
    </div>
  );
}

export default NewCampaignWizard;
