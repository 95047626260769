import React from 'react';
import {Box} from '@mui/material';
import translate from '@/utils/translate';
import {Specialty} from '@/types/campaign';
import Chip from '@/components/chip';
import useCreatorsMetadataQuery from '@/hooks/queries/use-creators-metadata-query';
import StepContainer from '../../components/step-container';
import StepSectionContainer from '../../components/step-section-container';
import BRIEF_WIZARD_TEST_IDS from '../../testIds';
import {StepProps} from '../StepProps';
import styles from './Specialties.module.scss';

function Specialties(props: StepProps) {
  const {
    testID,
    formData,
    formErrors,
    updateFormData,
    onClickNext,
    canContinue,
    campaignType,
  } = props;

  const {
    data: metadata,
    isFetched: isMetadataFetched,
    isError: isMetadataError,
  } = useCreatorsMetadataQuery();

  const specialties = React.useMemo(() => {
    if (!metadata?.categories) return [];
    return metadata.categories.map((category) => ({
      id: category.code,
      name: category.name,
    }));
  }, [metadata]);

  const onSpecialtyClick = (specialty: Specialty) => {
    updateFormData({
      specialties: formData.specialties.includes(specialty)
        ? formData.specialties.filter(
            (existingSpecialty) => existingSpecialty.id !== specialty.id
          )
        : [...formData.specialties, specialty],
    });
  };

  return (
    <Box className={styles.container}>
      <StepContainer
        onClickContinue={onClickNext}
        continueButtonDisabled={!canContinue}
        testID={testID}
      >
        <StepSectionContainer
          title={translate(`Which industries should they specialize in?`)}
          subtitle={translate(`Choose the most relevant for your campaign.`)}
        >
          <Box className={styles.specialtiesContainer}>
            {specialties.map((specialty) => (
              <Chip
                key={specialty.id}
                onClick={() => onSpecialtyClick(specialty)}
                variant={
                  formData.specialties.includes(specialty)
                    ? 'filled'
                    : 'outlined'
                }
                testID={`${BRIEF_WIZARD_TEST_IDS.SPECIALTIES__SPECIALTY}--${specialty.id}`}
                className={styles.chip}
                label={<div className={styles.chipLabel}>{specialty.name}</div>}
              />
            ))}
          </Box>
        </StepSectionContainer>
      </StepContainer>
    </Box>
  );
}

export default Specialties;
