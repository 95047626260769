/**
 * Converts a UUID string to a numeric checksum by summing the decimal values of its characters.
 * Numeric digits (0-9) are converted to their decimal values, while letters (a-f) are converted
 * to values 10-15 respectively.
 *
 * @param uuid - The UUID string to convert (can include hyphens, case-insensitive)
 * @returns The sum of all character values in the UUID
 *
 * @example
 * // Returns 52 (sum of 8 + 4 + a(10) + b(11) + c(12) + 7)
 * uuidToChecksum('84abc7')
 */
function uuidToChecksum(uuid: string): number {
  // Remove hyphens from UUID and convert to lowercase
  const cleanUuid = uuid.replace(/-/g, '').toLowerCase();

  // Convert each character to its numeric value and sum
  return cleanUuid.split('').reduce((sum, char) => {
    // For hexadecimal digits (0-9, a-f)
    if (/[0-9]/.test(char)) {
      return sum + parseInt(char, 10);
    }
    // For letters a-f, convert to their decimal value (a=10, b=11, etc.)
    return sum + (char.charCodeAt(0) - 87);
  }, 0);
}

export default uuidToChecksum;
