import {useLocation, useSearchParams} from 'react-router-dom';
import useTrackScreenEvent from '@/hooks/analytics/use-track-screen-event';

const SEARCH_PARAMS_KEY = 'showToleranceSettings';

function useHandleToleranceSettingsModal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const trackScreenEvent = useTrackScreenEvent('brand_safety');
  const location = useLocation();
  const isOpen = searchParams.get(SEARCH_PARAMS_KEY) === 'true';
  const open = () => {
    searchParams.set(SEARCH_PARAMS_KEY, 'true');
    setSearchParams(searchParams);
    trackScreenEvent.presented({
      screen_name: 'brand_safety_tolerance',
      source: location.pathname,
    });
  };

  const close = () => {
    searchParams.delete(SEARCH_PARAMS_KEY);
    setSearchParams(searchParams);
    trackScreenEvent.dismissed({
      screen_name: 'brand_safety_tolerance',
      destination: location.pathname,
    });
  };

  return {isOpen, open, close};
}

export default useHandleToleranceSettingsModal;
