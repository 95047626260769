import React from 'react';
import {Box, Stack} from '@mui/material';
import {
  Headline,
  Body,
  Icon,
  designSystemToken,
} from '@lightricks/react-design-system';
import Link from '@/components/link';
import translate from '../../../utils/translate';

interface TermOfServiceItemProps {
  title: string;
  description: string;
  link: string;
}

function TermsOfServiceItem({
  title,
  description,
  link,
}: TermOfServiceItemProps) {
  return (
    <Box
      gap="8px"
      maxWidth="540px"
      border={`1px solid ${designSystemToken('semantic.bg.neutral-inverse')}`}
      borderRadius="8px"
      sx={{
        backgroundColor: designSystemToken('semantic.bg.secondary'),
      }}
    >
      <Link to={link} target="_blank" keepBrandIdIfPresent={false}>
        <Stack
          direction="row"
          gap="8px"
          padding="12px 16px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack gap="4px">
            <Headline
              size="xs"
              color={designSystemToken('semantic.fg.primary')}
            >
              {title}
            </Headline>
            <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
              {description}
            </Body>
          </Stack>
          <Icon
            size="medium"
            appearance="neutral"
            name="Actions-Arrow-Forward-Small"
          />
        </Stack>
      </Link>
    </Box>
  );
}

const termsOfServiceItems = [
  {
    id: 'popularpays-tos',
    titleLocaleLabelKey: 'Popular Pays Terms',
    descriptionLocaleLabelKey:
      'Understand your rights and responsibilities when using our platform.',
    link: 'https://static.lightricks.com/legal/popular-pays-platform-terms-and-conditions.pdf',
  },
  {
    id: 'popularpays-privacy-policy',
    titleLocaleLabelKey: 'Privacy Policy',
    descriptionLocaleLabelKey: 'Learn about how we use and protect your data.',
    link: 'https://static.lightricks.com/legal/privacy-policy.pdf',
  },
  {
    id: 'youtube-tos',
    titleLocaleLabelKey: 'YouTube Terms of Service',
    descriptionLocaleLabelKey:
      'Understand your rights, responsibilities and limitations when using YouTube for campaigns',
    link: 'https://www.youtube.com/t/terms',
  },
];

function TermsOfService() {
  return (
    <Stack>
      <Stack gap="40px">
        <Stack gap="9px">
          <Headline size="lg">{translate('Terms of Service')}</Headline>
          <Body size="lg">
            {translate('Review our terms and policies listed below')}
          </Body>
        </Stack>
        <Stack gap="8px">
          {termsOfServiceItems.map((termOfService) => (
            <TermsOfServiceItem
              key={termOfService.id}
              title={translate(termOfService.titleLocaleLabelKey)}
              description={translate(termOfService.descriptionLocaleLabelKey)}
              link={termOfService.link}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TermsOfService;
