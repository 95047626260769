import React, {useMemo} from 'react';
import {designSystemToken, Icon, Label} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import Conditional from '@/components/conditional';
import DropdownButton from '@/components/dropdown-button';
import styles from './ToleranceSettingsDropdown.module.scss';

interface ToleranceSettingsDropdownProps {
  options: Option[];
  selectedOptionId: string;
  onChange: (selectedOptionId: string) => void;
}

export interface Option {
  id: string;
  label: string;
  icon?: IconName;
  className?: string;
}

interface ToleranceSettingsDropdownItemProps {
  option: Option;
  isSelected: boolean;
}

function ToleranceSettingsDropdownItem(
  props: ToleranceSettingsDropdownItemProps
) {
  const {option, isSelected} = props;
  return (
    <div className={styles.menuItem}>
      <Conditional condition={!!option.icon}>
        <Icon
          size="medium"
          appearance="neutral"
          color={
            isSelected
              ? designSystemToken('semantic.fg.brand-secondary')
              : designSystemToken('semantic.fg.primary')
          }
          name={option.icon as IconName}
        />
      </Conditional>
      <Label
        size="lg"
        color={
          isSelected
            ? designSystemToken('semantic.fg.brand-secondary')
            : designSystemToken('semantic.fg.primary')
        }
      >
        {option.label}
      </Label>
    </div>
  );
}

function ToleranceSettingsDropdown(props: ToleranceSettingsDropdownProps) {
  const {options, selectedOptionId, onChange} = props;
  const selectedOption = useMemo(
    () =>
      options.find((option) => option.id === selectedOptionId) || options[0],
    [options, selectedOptionId]
  );

  return (
    <DropdownButton
      label={<Label size="lg">{selectedOption.label}</Label>}
      buttonClassName={styles.button}
      options={options}
      selectedOptionIds={[selectedOption.id]}
      onChange={(option) => onChange(option.id)}
      caretIconName="Actions-Arrow-Down-Mini"
      size="medium"
      menuItemProps={{classes: {selected: styles.selectedMenuItem}}}
      renderMenuItem={(option) => (
        <ToleranceSettingsDropdownItem
          key={option.id}
          option={option}
          isSelected={option.id === selectedOption.id}
        />
      )}
      menuProps={{
        sx: {
          '& .MuiList-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          },
          '& .MuiPaper-root': {
            marginTop: '8px',
            borderRadius: '16px',
            paddingInline: '8px',
            width: 'calc(253px - 16px)', // subtract the padding of both sides
          },
        },
      }}
    />
  );
}

export default ToleranceSettingsDropdown;
