import copy from 'copy-to-clipboard';
import React, {useState, useEffect} from 'react';
import {Popover} from '@mui/material';
import {
  Avatar,
  designSystemToken,
  Headline,
  Body,
  Tooltip,
  Label,
  SocialNetworkIcon as SNI,
  Icon,
  Button,
} from '@lightricks/react-design-system';
import calculateAge from '@/utils/calculateAge';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import type Profile from '@/types/profile';
import Link from '@/components/link';
import useBrandId from '@/hooks/use-brand-id';
import SocialNetworkIcon from '../social-network-icon';
import styles from './ProfilePopup.module.scss';
import {formatNumber, formatEngagementRate} from './utils';

const SOCIAL_NETWORK_ICON_SIZE = 29;

type ProfilePopupProps = {
  isVisible: boolean;
  profile: Profile;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  email?: string;
};

const TRANSLATION_PREFIX = 'components.profile-popup';

function ProfilePopup(props: ProfilePopupProps) {
  const {isVisible, profile, anchorEl, onClose, email} = props;
  const brandId = useBrandId();
  const [anchorPosition, setAnchorPosition] = useState<'bottom' | 'top'>(
    'bottom'
  );
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      setAnchorPosition(
        spaceBelow < 300 && spaceAbove > spaceBelow ? 'top' : 'bottom'
      );
    }
  }, [anchorEl]);

  if (!isVisible || !anchorEl) return null;

  const age = calculateAge(profile.birthDate);

  const copyEmail = () => {
    copy(email as string);
  };

  const renderProfileStats = (identity: Profile['identities'][number]) => {
    const stats = [
      {value: identity.cachedFollowerCount, tooltipKey: 'followers'},
      {value: identity.engagementValue, tooltipKey: 'avg-likes'},
      {
        value: identity.averageEngagementRate,
        tooltipKey: 'eng-rate',
        format: formatEngagementRate,
      },
      {value: identity.averageViewCount, tooltipKey: 'avg-views'},
      {value: identity.averageImpressionsCount, tooltipKey: 'avg-impressions'},
    ];

    return stats.map((stat, index) =>
      stat.value !== undefined && stat.value !== null ? (
        <div key={stat.tooltipKey} className={styles.statItem}>
          {index > 0 && <span className={styles.separator}>/</span>}
          <Body size="sm">
            <Tooltip
              title={translate(`${TRANSLATION_PREFIX}.${stat.tooltipKey}`)}
              arrow
              type="dark"
            >
              <span>
                {stat.format
                  ? stat.format(stat.value)
                  : formatNumber(stat.value)}
              </span>
            </Tooltip>
          </Body>
        </div>
      ) : null
    );
  };

  return (
    <Popover
      open={isVisible}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: anchorPosition === 'bottom' ? 'bottom' : 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: anchorPosition === 'bottom' ? 'top' : 'bottom',
        horizontal: 'center',
      }}
      className={styles.profilePopup}
      onMouseLeave={onClose}
      hideBackdrop
    >
      <div className={styles.container} onMouseLeave={onClose}>
        <div className={styles.profileHeader}>
          <Avatar
            size="xl"
            src={profile.imageUrl}
            type={profile.imageUrl ? 'picture' : 'placeholder'}
            variant="circular"
            data-testid="avatar"
            background={designSystemToken('semantic.bg.tertiary')}
          />
          <div>
            <div className={styles.profileInfo}>
              <Headline
                size="md"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Link
                  to={`${ROUTES.CREATOR_PROFILE}/${profile.id}?brandId=${brandId}`}
                  target="_blank"
                  className={styles.profileLink}
                >
                  {profile.displayName}
                </Link>
              </Headline>
              <div>
                {email && (
                  <Button
                    appearance="neutral"
                    size="small"
                    mode="plain"
                    className={styles.copyButton}
                    onClick={copyEmail}
                    icon={
                      <Icon
                        name="Navigation-Copy"
                        size="medium"
                        appearance="primary"
                      />
                    }
                  />
                )}
              </div>
            </div>
            <Body size="sm">
              {age}
              {profile.location && `. ${profile.location}`}
            </Body>
          </div>
        </div>

        <div className={styles.profileDetails}>
          <table className={styles.detailsTable}>
            <tbody>
              {profile.identities.map((identity) => (
                <tr key={identity.id}>
                  <td className={styles.icon}>
                    <Link
                      to={identity.profilePageUrl}
                      target="_blank"
                      className={styles.socialIcon}
                    >
                      <SocialNetworkIcon
                        name={identity.provider}
                        width={SOCIAL_NETWORK_ICON_SIZE}
                        height={SOCIAL_NETWORK_ICON_SIZE}
                      />
                    </Link>
                  </td>
                  <td className={styles.handle}>
                    <Link to={identity.profilePageUrl} target="_blank">
                      <Label size="md">{identity.cachedHandle}</Label>
                    </Link>
                  </td>
                  <td className={styles.stats}>
                    {renderProfileStats(identity)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.paidGigsAndRatings}>
            <Body size="sm" className={styles.rating}>
              <img
                src={
                  !profile.rating
                    ? '/assets/svg/star-fill-gray.svg'
                    : '/assets/svg/star-fill.svg'
                }
                alt="star"
              />
              {profile.rating}
              <span className={styles.completedGigsText}>
                ({profile.ratingsCount})
              </span>
            </Body>
            <Body size="sm">
              <span className={styles.completedGigsCount}>
                {profile.completedPaidGigsCount}
              </span>
              <span className={styles.completedGigsText}>
                {translate(`${TRANSLATION_PREFIX}.completed-gigs`)}
              </span>
            </Body>
          </div>
        </div>
      </div>
    </Popover>
  );
}

export default ProfilePopup;
