import React, {useMemo} from 'react';
import {v4 as uuid} from 'uuid';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import {YOUTUBE_DS_ICON_OVERRIDES} from '@/config/constants';
import {CampaignBriefDeliverableItem} from '@/types/campaign';
import {
  BriefFieldManagerAttributes,
  ErrorMessages,
} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import Deliverable, {
  DELIVERABLE_ICON_NAME,
} from '@/views/campaigns/brief/components/campaign-brief/components/deliverable/Deliverable';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {SaveCampaign} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {DEFAULT_SAVE_CAMPAIGN_DELAY} from '@/views/campaigns/brief/constants';
import {
  DELIVERABLES_OPTIONS_BY_NETWORK,
  DELIVERABLES_OPTIONS_CONTENT_CREATION,
} from '@/views/campaigns/brief/utils/getDeliverablesOptionsByNetwork';
import TagSelector from '@/components/tag-selector';
import styles from './CampaignDeliverables.module.scss';

const TRANSLATION_PREFIX = 'components.campaign-brief.sections.deliverables';

export type CampaignDeliverablesProps = {
  testID?: string;
  briefFieldManager: BriefFieldManagerAttributes;
  editable?: boolean;
  network: string;
  campaignBriefDeliverableItems: CampaignBriefDeliverableItem[];
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number
  ) => void;
  minimumChildContentSubmissionCount?: number;
  saveCampaign?: SaveCampaign;
  errors?: ErrorMessages;
  campaignId: string;
  viewPresentationId?: string;
};

function CampaignDeliverables({
  testID = 'campaign-deliverables',
  briefFieldManager,
  editable,
  network,
  campaignBriefDeliverableItems,
  updateBriefForm,
  minimumChildContentSubmissionCount,
  saveCampaign,
  errors,
  campaignId,
  viewPresentationId,
}: CampaignDeliverablesProps) {
  const deliverableItemOptions = useMemo(() => {
    const DELIVERABLES_OPTIONS = network
      ? [
          ...DELIVERABLES_OPTIONS_BY_NETWORK[network],
          ...DELIVERABLES_OPTIONS_CONTENT_CREATION,
        ]
      : DELIVERABLES_OPTIONS_CONTENT_CREATION;
    return (
      DELIVERABLES_OPTIONS.map(
        (deliverableOption: {
          id: string;
          value: string;
          icon: React.ReactNode;
          dropdownLocaleKeyTitle: string;
        }) => ({
          id: deliverableOption.id,
          value: deliverableOption.id,
          label: (
            <label className={styles.deliverableItemOptionLabel}>
              <Icon
                size="medium"
                appearance="neutral"
                color={designSystemToken('semantic.fg.primary')}
                name={DELIVERABLE_ICON_NAME[deliverableOption.id] as IconName}
                {...(deliverableOption.id.includes('youtube')
                  ? YOUTUBE_DS_ICON_OVERRIDES
                  : {})}
              />
              {translate(deliverableOption.dropdownLocaleKeyTitle)}
            </label>
          ),
        })
      ) || []
    );
  }, [network]);

  const handleOnDeliverableDescriptionBlur = () => {
    saveCampaign?.({
      campaignId,
      fieldName: briefFieldManager.fields.campaignBriefDeliverableItems?.key,
      fieldValue: briefFieldManager.fields.campaignBriefDeliverableItems?.value,
    });
  };

  const handleTagSelection = (selection: string | string[]) => {
    updateBriefForm?.(
      'campaignBriefDeliverableItems',
      [
        ...campaignBriefDeliverableItems,
        {
          id: uuid(),
          itemType: selection,
          description: '',
          weight: campaignBriefDeliverableItems.length + 1,
        },
      ],
      'campaignBriefDeliverableItems'
    );
  };

  const handleRemoveExistingDeliverableItem = (
    deliverableToRemove: CampaignBriefDeliverableItem
  ) => {
    updateBriefForm?.(
      `campaignBriefDeliverableItems`,
      campaignBriefDeliverableItems.filter(
        (deliverable) => deliverable.id !== deliverableToRemove.id
      ),
      'campaignBriefDeliverableItems',
      DEFAULT_SAVE_CAMPAIGN_DELAY
    );
  };

  const renderExistingDeliverableItems = () => {
    return campaignBriefDeliverableItems?.map((deliverable, index) => (
      <ValidatedField
        key={deliverable.id}
        errors={
          !deliverable.description ? errors?.campaignBriefDeliverableItems : []
        }
      >
        <Deliverable
          testID={`${CAMPAIGN_BRIEF_TEST_IDS.DELIVERABLES__DELIVERABLE_ITEM}--${index}`}
          index={index}
          briefFieldManager={briefFieldManager}
          campaignBriefDeliverableItems={campaignBriefDeliverableItems}
          updateBriefForm={updateBriefForm}
          saveCampaign={saveCampaign}
          deliverable={deliverable}
          editable={editable}
          onBlur={() => handleOnDeliverableDescriptionBlur()}
          isInvalid={
            !!(
              errors?.campaignBriefDeliverableItems && !deliverable.description
            )
          }
          onRemove={() => handleRemoveExistingDeliverableItem(deliverable)}
          minimumChildContentSubmissionCount={
            minimumChildContentSubmissionCount
          }
          campaignId={campaignId}
          viewPresentationId={viewPresentationId}
        />
      </ValidatedField>
    ));
  };

  return (
    <ValidatedField
      errors={
        !campaignBriefDeliverableItems.length
          ? errors?.campaignBriefDeliverableItems
          : []
      }
    >
      <div className={styles.container} data-testid={testID}>
        {renderExistingDeliverableItems()}
        <TagSelector
          readOnly={!editable}
          disabled={!editable}
          hasError={
            !campaignBriefDeliverableItems.length &&
            !!errors?.campaignBriefDeliverableItems?.length
          }
          hideSelectedOptions
          testID={CAMPAIGN_BRIEF_TEST_IDS.DELIVERABLES__ADD_DELIVERABLE}
          addChipLabel={translate(`${TRANSLATION_PREFIX}.add-deliverable`)}
          options={deliverableItemOptions}
          selectedValueIds=""
          onChange={handleTagSelection}
        />
      </div>
    </ValidatedField>
  );
}

export default CampaignDeliverables;
