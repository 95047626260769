const CAMPAIGN_BRIEF_TEST_IDS = {
  BRIEF_AGENT_CHAT: 'brief-agent-chat',
  LAUNCH_CAMPAIGN_BUTTON: 'launch-campaign-button',
  AUTO_FILL_BANNER: 'auto-fill-banner',
  GUIDELINES_MODAL: 'guidelines-modal',

  // BASIC DETAILS SECTION
  BASIC_DETAILS__BRAND_NAME: 'basic-details__brand-name',
  BASIC_DETAILS__CAMPAIGN_NAME: 'basic-details__campaign-name',
  BASIC_DETAILS__CAMPAIGN_NAME_INPUT: 'basic-details__campaign-name-input',
  BASIC_DETAILS__CAMPAIGN_VERTICALS: 'basic-details__campaign-verticals',
  BASIC_DETAILS__CAMPAIGN_VERTICALS_INPUT:
    'basic-details__campaign-verticals-input',
  BASIC_DETAILS__ABOUT_YOUR_BRAND_AND_CAMPAIGN:
    'basic-details__about-your-brand-and-campaign',
  BASIC_DETAILS__ABOUT_YOUR_BRAND_AND_CAMPAIGN_INPUT:
    'basic-details__about-your-brand-and-campaign-input',

  // CREATOR PAYMENTS SECTION
  CREATOR_PAYMENTS__COMPENSATION_MIN: 'creator-payments__compensation-min',
  CREATOR_PAYMENTS__COMPENSATION_MAX: 'creator-payments__compensation-max',
  CREATOR_PAYMENTS__COMPENSATION_FIXED: 'creator-payments__compensation-fixed',
  CREATOR_PAYMENTS__SHIPPING_METHOD: 'creator-payments__shipping-method',
  CREATOR_PAYMENTS__SHIPPING_METHOD_INPUT:
    'creator-payments__shipping-method-input',
  CREATOR_PAYMENTS__CASH_VALUE: 'creator-payments__cash-value',
  CREATOR_PAYMENTS__CASH_VALUE_INPUT: 'creator-payments__cash-value-input',

  // CREATOR DETAILS SECTION
  CREATOR_DETAILS__FOLLOWERS_COUNT_INPUT:
    'creator-details__followers-count-input',
  CREATOR_DETAILS__GENDER_INPUT: 'creator-details__gender-input',
  CREATOR_DETAILS__AGE_RANGE: 'creator-details__age-range',
  CREATOR_DETAILS__AGE_RANGE_INPUT_MIN: 'creator-details__age-range-input-min',
  CREATOR_DETAILS__AGE_RANGE_INPUT_MAX: 'creator-details__age-range-input-max',
  CREATOR_DETAILS__PREFERRED_SPECIALTY: 'creator-details__preferred-specialty',
  CREATOR_DETAILS__PREFERRED_SPECIALTY_INPUT:
    'creator-details__preferred-specialty-input',
  CREATOR_DETAILS__IDEAL_CREATOR: 'creator-details__ideal-creator',
  CREATOR_DETAILS__IDEAL_CREATOR_INPUT: 'creator-details__ideal-creator-input',
  CREATOR_DETAILS__LOCATION_INPUT: 'creator-details__location-input',

  // DELIVERABLES SECTION
  DELIVERABLES__ADD_DELIVERABLE: 'deliverables__add-deliverable',
  DELIVERABLES__DELIVERABLE_ITEM: 'deliverables__deliverable-item',
  DELIVERABLES__PRODUCT_TO_FEATURE: 'deliverables__product-to-feature',
  DELIVERABLES__PRODUCT_TO_FEATURE_INPUT:
    'deliverables__product-to-feature-input',
  DELIVERABLES__CONTENT_MANDATORIES: 'deliverables__content-mandatories',
  DELIVERABLES__CONTENT_MANDATORIES_INPUT:
    'deliverables__content-mandatories-input',
  DELIVERABLES__POSTING_INSTRUCTIONS: 'deliverables__posting-instructions',
  DELIVERABLES__POSTING_INSTRUCTIONS_INPUT:
    'deliverables__posting-instructions-input',
  DELIVERABLES__CAPTION_GUIDELINES: 'deliverables__caption-guidelines',
  DELIVERABLES__CAMPAIGN_HASHTAG_INPUT:
    'deliverables__caption-guidelines__campaign-hashtag-input',

  // TIMELINE OVERVIEW SECTION
  TIMELINE__DAYS_TO_POST_AFTER_DELIVERABLE_FULFILLED:
    'timeline__days-to-post-after-deliverable-fulfilled',

  // ADDITIONAL INFORMATION SECTION
  ADDITIONAL_INFORMATION__URL_TO_PROMOTE_INPUT:
    'additional-information__url-to-promote-input',

  // CONTENT AGREEMENT SECTION
  CONTENT_AGREEMENT__CONTENT_AGREEMENT_TIME_PERIOD_IN_MONTHS:
    'content-agreement__content-agreement-time-period-in-months',
  CONTENT_AGREEMENT__TERMS_CHECKBOXES: 'content-agreement__terms-checkboxes',
};

export default CAMPAIGN_BRIEF_TEST_IDS;
