import React, {useEffect, useMemo} from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import getAnalyticsScreenName from '@/utils/getAnalyticsScreenName';
import translate from '@/utils/translate';
import uuidToChecksum from '@/utils/uuidToChecksum';
import {Identity} from '@/types/identity';
import {DEFAULT_CREATOR_PROFILE_INCLUDE} from '@/api/fetchers/creatorProfile';
import Conditional from '@/components/conditional';
import {TIKTOK_IDENTITY_IDS} from '@/components/creator-profile/constants';
import Link from '@/components/link';
import useTrackButtonEvent from '@/hooks/analytics/use-track-button-event';
import useTrackRecruitmentActionFlowEvent from '@/hooks/analytics/use-track-recruitment-action-flow-event';
import useInviteCreatorToCampaign from '@/hooks/mutations/use-invite-creator-to-campaign';
import useCreatorProfileQuery from '@/hooks/queries/use-creator-profile-query';
import useIdentityHealthEvaluationQuery from '@/hooks/queries/use-identity-health-evaluation-query';
import useTiktokAccountQuery from '@/hooks/queries/use-tiktok-account-query';
import useCampaignSuggestedCreatorsStore, {
  campaignSuggestedCreatorsActions,
} from '@/stores/campaignSuggestedCreatorsStore';
import CreatorCard, {CreatorCardSkeleton} from '../creator-card';
import styles from './CreatorCardWrapper.module.scss';

const CREATOR_BADGE_PROPS_OPTIONS = [
  {
    titleLocaleKey: 'Top match!',
    subtitleLocaleKey: 'Successful collaborations with similar brands',
    icon: 'Navigation-Star-Line',
  },
  {
    titleLocaleKey: 'Industry Expert',
    subtitleLocaleKey: 'Pro at creating industry specific content',
    icon: 'Navigation-Badge',
  },
  {
    titleLocaleKey: 'High-Performing Partner',
    subtitleLocaleKey: 'Strong track record of engagement',
    icon: 'Actions-Trending',
  },
  {
    titleLocaleKey: 'Proven Performer',
    subtitleLocaleKey: 'Demonstrated success with brand collabs',
    icon: 'Actions-Patch-Accept-Line',
  },
];

interface CreatorCardWrapperProps {
  brandId: string;
  creatorProfileId: string;
  campaignId: string;
  campaignNetwork?: string | null;
  containerWidth: number;
  screenName?: string;
}

function CreatorCardWrapper({
  brandId,
  creatorProfileId,
  campaignId,
  campaignNetwork,
  containerWidth,
  screenName = 'Suggested',
}: CreatorCardWrapperProps) {
  const trackRecruitmentActionFlowEvent = useTrackRecruitmentActionFlowEvent();
  const trackButtonEvent = useTrackButtonEvent();
  const {campaigns} = useCampaignSuggestedCreatorsStore();
  const isInvitedToCampaign =
    !!campaigns[campaignId]?.creators?.[creatorProfileId];
  const creatorProfileInclude = [...DEFAULT_CREATOR_PROFILE_INCLUDE, 'user'];
  const {profile: creatorProfile} = useCreatorProfileQuery({
    brandId,
    profileId: creatorProfileId,
    enabled: !!brandId && !!creatorProfileId,
    include: creatorProfileInclude,
  });

  const creatorBadgeProps = useMemo(() => {
    const checksum =
      uuidToChecksum(campaignId) + uuidToChecksum(creatorProfileId);
    const badgeProps =
      CREATOR_BADGE_PROPS_OPTIONS[
        checksum % CREATOR_BADGE_PROPS_OPTIONS.length
      ];
    return {
      title: translate(badgeProps.titleLocaleKey),
      subtitle: translate(badgeProps.subtitleLocaleKey),
      icon: badgeProps.icon as IconName,
    };
  }, [campaignId, creatorProfileId]);

  const creatorProfileIdentity = useMemo(() => {
    if (!creatorProfile || !campaignNetwork) {
      return null;
    }
    return (
      creatorProfile.identities.find(
        (identity) =>
          campaignNetwork.includes(identity.provider) ||
          identity.provider.includes(campaignNetwork)
      ) || null
    );
  }, [creatorProfile, campaignNetwork]);

  useEffect(() => {
    const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.RECRUITMENT_SEARCH
    );

    if (creatorProfileId) {
      AnalyticsService.dispatchEvent(
        eventNames.RECRUITMENT_PORTFOLIO_PRESENTED,
        {
          flow_id: recruitmentSearchFlow.flow_id,
          screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
          creator_portfolio_presentation_id: '',
          recruitment_search_id: JSON.stringify({
            creator_badge: creatorBadgeProps.title,
          }),
          creator_id: creatorProfileId,
          recruitment_screen_name: screenName,
        }
      );
    }

    return () => {
      if (creatorProfileId) {
        const targetScreenName = getAnalyticsScreenName(
          window.location.pathname
        );

        AnalyticsService.dispatchEvent(
          eventNames.RECRUITMENT_PORTFOLIO_DISMISSED,
          {
            flow_id: recruitmentSearchFlow.flow_id,
            screen_presentation_id:
              recruitmentSearchFlow.screen_presentation_id,
            creator_portfolio_presentation_id: '',
            recruitment_search_id: '',
            creator_id: creatorProfileId,
            dismissed_reason:
              `Suggested/${targetScreenName}` === screenName
                ? 'changed_creator'
                : `navigated_to_${targetScreenName}`,
            recruitment_screen_name: screenName,
          }
        );
      }
    };
  }, [creatorProfileId]);

  const {healthEvaluation} = useIdentityHealthEvaluationQuery({
    identityId: creatorProfileIdentity?.id as string,
    enabled: !!creatorProfileIdentity,
  });

  const {tiktokAccount} = useTiktokAccountQuery({
    accountId: creatorProfileIdentity?.uid as string,
    enabled:
      TIKTOK_IDENTITY_IDS.includes(
        creatorProfileIdentity?.provider as string
      ) && !!creatorProfileIdentity?.uid,
  });

  const rating = useMemo(() => {
    return (
      healthEvaluation?.rating || tiktokAccount?.audienceUsageRating || null
    );
  }, [healthEvaluation, tiktokAccount]);

  const inviteCreatorToCampaign = useInviteCreatorToCampaign({
    onMutate: () => {
      campaignSuggestedCreatorsActions.setCampaignCreatorInvited(
        campaignId,
        creatorProfileId,
        true
      );
    },
    onError: (_err, _data, context) => {
      campaignSuggestedCreatorsActions.setCampaignCreatorInvited(
        campaignId,
        creatorProfileId,
        false
      );
    },
  });

  const renderActions = () => {
    return (
      <div className={styles.actions}>
        <Button
          appearance="neutral"
          mode="filled"
          size="medium"
          disabled={isInvitedToCampaign}
          startIcon={
            isInvitedToCampaign ? (
              <Icon size="medium" appearance="disabled" name="Actions-Accept" />
            ) : undefined
          }
          onClick={() => {
            if (campaignId && creatorProfile) {
              inviteCreatorToCampaign.mutate({
                campaignId,
                userId: creatorProfile.user!.id,
              });
            }
            const action = 'Invite to Campaign';
            trackRecruitmentActionFlowEvent.ended({
              flow_name: action,
              action_id: action,
              screen_name: screenName,
              recruitment_search_id: '',
              campaign_id: campaignId,
              creator_id: creatorProfileId,
              reason: 'invite_to_campaign',
              source: screenName,
            });
          }}
        >
          {isInvitedToCampaign
            ? translate('Invitation Sent')
            : translate('Invite to Campaign')}
        </Button>
        <Link to={`/creator-profile/${creatorProfileId}`}>
          <Button
            appearance="neutral"
            mode="tinted"
            size="medium"
            onClick={() => {
              const action = 'See Full Profile';
              trackRecruitmentActionFlowEvent.ended({
                flow_name: action,
                action_id: action,
                screen_name: screenName,
                recruitment_search_id: '',
                campaign_id: campaignId,
                creator_id: creatorProfileId,
                reason: 'see_full_profile',
                source: screenName,
              });
            }}
          >
            {translate('See Full Profile')}
          </Button>
        </Link>
      </div>
    );
  };

  const onSocialNetworkClick = (identity: Identity) => {
    trackButtonEvent.pressed({
      button_name: `Suggested => Open ${identity.provider} profile`,
      screen_name: screenName,
      campaign_id: campaignId,
      creator_id: creatorProfileId,
      tab: '',
    });
  };

  return (
    <Conditional
      condition={!!creatorProfile}
      fallback={<CreatorCardSkeleton containerWidth={containerWidth} />}
    >
      <CreatorCard
        brandId={brandId}
        creatorProfile={creatorProfile!}
        network={campaignNetwork}
        identity={creatorProfileIdentity}
        rating={rating}
        actions={renderActions()}
        containerWidth={containerWidth}
        onSocialNetworkClick={onSocialNetworkClick}
        creatorBadgeProps={creatorBadgeProps}
        recruitmentEventStartedParams={{
          screen_name: screenName,
          source: screenName,
        }}
        recruitmentEventEndedParams={{
          screen_name: screenName,
          source: screenName,
          campaign_id: campaignId,
        }}
      />
    </Conditional>
  );
}

export default CreatorCardWrapper;
