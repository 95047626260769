import {iconNames} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import ContentStudioPaths from '@/routes/content-studio/contentStudioRoutesList';
import translate from '@/utils/translate';

export type MenuItem = {
  route: (typeof ContentStudioPaths)[keyof typeof ContentStudioPaths];
  label: string;
  icon: (typeof iconNames)[number];
};

export const menuItems: Record<
  (typeof ContentStudioPaths)[keyof typeof ContentStudioPaths],
  MenuItem
> = {
  [ContentStudioPaths.MAIN]: {
    route: ContentStudioPaths.MAIN,
    label: translate('home'),
    icon: 'Navigation-Home-Stroke',
  },
  [ContentStudioPaths.ASSETS]: {
    route: ContentStudioPaths.ASSETS,
    label: translate('assets'),
    icon: 'Navigation-Projects',
  },
};
