import React from 'react';
import translate from '@/utils/translate';
import ButtonGroup from '@/components/button-group';
import ErrorLabel from '@/components/error-label';
import {CAMPAIGN_TYPE} from '@/components/new-campaign-wizard-modal/CampaignTypeOptions';
import OptionButton from '@/components/option-button';
import SocialNetworkIcon from '@/components/social-network-icon';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useBrandId from '@/hooks/use-brand-id';
import IconInput from '../../components/icon-input';
import StepContainer from '../../components/step-container';
import StepSectionContainer from '../../components/step-section-container';
import BRIEF_WIZARD_TEST_IDS from '../../testIds';
import {StepProps} from '../StepProps';
import styles from './BudgetAndNetwork.module.scss';

const SOCIAL_NETWORK_ICON_SIZE = 29;

const TRANSLATION_PREFIX =
  'views.campaigns.components.wizard.steps.budget-and-network';

const BUDGET_OPTIONS = [
  {value: '1000', label: '$ 1000'},
  {value: '2000', label: '$ 2000'},
  {value: '5000', label: '$ 5000'},
  {value: '15000', label: '$ 15000'},
];

const NETWORK_OPTIONS = [
  {
    value: 'instagram',
    labelLocaleKey: `${TRANSLATION_PREFIX}.network-instagram`,
    iconName: 'instagram',
  },
  {
    value: 'tiktok_v2',
    labelLocaleKey: `${TRANSLATION_PREFIX}.network-tiktok`,
    iconName: 'tiktok',
  },
  {
    value: 'youtube',
    labelLocaleKey: `${TRANSLATION_PREFIX}.network-youtube`,
    iconName: 'youtube',
  },
];

function isNumeric(str: any) {
  if (typeof str !== 'string') return false;
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
}

function numberWithoutCommas(number: string) {
  return number?.toString().replace(/,/g, '');
}

function numberWithCommas(number: number) {
  return Number(number).toLocaleString();
}
function BudgetAndNetwork(props: StepProps) {
  const {
    testID,
    formData,
    formErrors,
    updateFormData,
    onClickNext,
    canContinue,
    campaignType,
  } = props;

  const brandId = useBrandId();
  const {allowEditCreatorBid} = useAgencyQuery({brandId});

  function handleBudgetInputChange(newValue: string) {
    const oldValue = formData.customBudget
      ? numberWithCommas(formData.customBudget)
      : '';
    const newValueParsed = numberWithoutCommas(newValue);
    const validValue =
      isNumeric(newValueParsed) || newValueParsed === ''
        ? newValueParsed
        : numberWithoutCommas(oldValue) || '';

    const value = validValue ? Number(validValue).toLocaleString() : '';

    updateFormData?.({
      budget: null,
      customBudget: numberWithoutCommas(value),
    });
  }

  return (
    <div className={styles.container}>
      <StepContainer
        onClickContinue={onClickNext}
        continueButtonDisabled={!canContinue}
        testID={testID}
      >
        {allowEditCreatorBid && (
          <StepSectionContainer
            title={translate(`${TRANSLATION_PREFIX}.budget-title`)}
            subtitle={translate(`${TRANSLATION_PREFIX}.budget-subtitle`)}
            containerClassName={styles.customBudgetContainerStyle}
          >
            <div className={styles.budgetContainer}>
              <ButtonGroup
                containerClassName={styles.customBudgetButtonGroupStyle}
                buttons={BUDGET_OPTIONS}
                onChange={(selection: any) =>
                  updateFormData?.({
                    customBudget: null,
                    budget: Number(selection.value),
                  })
                }
                selectedButtonValue={String(formData.budget)}
                renderButton={(button: any, isActive, onClick) => (
                  <OptionButton
                    testID={`${BRIEF_WIZARD_TEST_IDS.BUDGET_AND_NETWORK__BUDGET}--${button.value}`}
                    button={button}
                    className={styles.budgetButton}
                    onClick={onClick}
                    isActive={isActive}
                  />
                )}
              />

              <div className={styles.budgetCustomInputContainer}>
                <IconInput
                  testID={
                    BRIEF_WIZARD_TEST_IDS.BUDGET_AND_NETWORK__CUSTOM_BUDGET_INPUT
                  }
                  value={
                    formData.customBudget
                      ? numberWithCommas(formData.customBudget)
                      : ''
                  }
                  placeholder={translate(
                    `${TRANSLATION_PREFIX}.custom-budget-placeholder`
                  )}
                  icon="$"
                  className={styles.budgetCustomInput}
                  onChange={(newValue: string) =>
                    handleBudgetInputChange(newValue)
                  }
                  type="text"
                />
                <ErrorLabel className={styles.stepError}>
                  {formErrors.budget}
                </ErrorLabel>
              </div>
            </div>
          </StepSectionContainer>
        )}
        {campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS && (
          <StepSectionContainer
            title={translate(`${TRANSLATION_PREFIX}.network-label`)}
          >
            <div className={styles.netowrkContainer}>
              <ButtonGroup
                containerClassName={styles.customNetworkButtonGroupStyle}
                buttons={NETWORK_OPTIONS.map((option) => ({
                  ...option,
                  label: translate(option.labelLocaleKey),
                }))}
                onChange={(selection: any) => {
                  if (selection.value === formData.network) {
                    return;
                  }
                  updateFormData?.({
                    requiredDeliverables: [],
                    network: selection.value,
                  });
                }}
                selectedButtonValue={formData.network}
                renderButton={(button: any, isActive, onClick) => (
                  <OptionButton
                    testID={`${BRIEF_WIZARD_TEST_IDS.BUDGET_AND_NETWORK__NETWORK}--${button.value}`}
                    button={button}
                    className={styles.networkButton}
                    onClick={onClick}
                    isActive={isActive}
                  >
                    <>
                      <SocialNetworkIcon
                        name={button.iconName}
                        width={SOCIAL_NETWORK_ICON_SIZE}
                        height={SOCIAL_NETWORK_ICON_SIZE}
                      />
                      <label className={styles.networkLabel}>
                        {button.label}
                      </label>
                    </>
                  </OptionButton>
                )}
              />
            </div>
          </StepSectionContainer>
        )}
      </StepContainer>
    </div>
  );
}

export default BudgetAndNetwork;
