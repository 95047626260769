import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';

const SOCIAL_NETWORK_ICON = {
  instagram: 'Social-Instagram',
  instagram_business: 'Social-Instagram',
  tiktok: 'Social-TikTok',
  tiktok_v2: 'Social-TikTok',
  tiktok_creator_marketplace: 'Social-TikTok',
  facebook: 'Social-Facebook',
  youtube: 'Social-YouTube',
  twitter: 'Social-Twitter',
  pinterest: 'Social-Pinterest',
} as Record<string, IconName>;

export default SOCIAL_NETWORK_ICON;
