import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import getAnalyticsScreenName from '@/utils/getAnalyticsScreenName';
import {ROUTES} from '@/config/routesConstants';
import usePrevious from '@/hooks/use-previous';

const IGNORE_ROUTES_EVENTS = [ROUTES.SIGN_UP_REACT, ROUTES.SIGN_UP_REACT_OLD];

function useDeltaEventScreenChanged() {
  const location = useLocation();
  const previousLocation: any = usePrevious(location);

  useEffect(() => {
    if (IGNORE_ROUTES_EVENTS.includes(location.pathname)) {
      return;
    }
    (async () => {
      if (previousLocation?.pathname) {
        const previousScreenName = getAnalyticsScreenName(
          previousLocation.pathname
        );
        const destinationScreenName = getAnalyticsScreenName(location.pathname);
        await AnalyticsService.screenDismissed(
          previousScreenName,
          destinationScreenName
        );
      }

      const screenName = getAnalyticsScreenName(location.pathname);
      const sourceScreenName = previousLocation
        ? getAnalyticsScreenName(previousLocation.pathname)
        : getAnalyticsScreenName(window.location.pathname);

      await AnalyticsService.screenPresented(screenName, sourceScreenName);
    })();
  }, [location.pathname]);

  return null;
}

export default useDeltaEventScreenChanged;
