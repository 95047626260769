import dayjs from 'dayjs';
import {produce} from 'immer';
import React, {useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import {
  FLOW_NAMES,
  BRANDS_SAFETY_ACTION_NAMES,
} from '@/lib/analytics/analyticsConstants';
import {updateVettingReportInvitationEmailSent} from '@/utils/cache-updaters/vettingReportInvitationEmailSent';
import {
  updateCreatorBrandSafetyVettingsStatus,
  updateVettingReportStatus,
} from '@/utils/cache-updaters/vettingReportStatus';
import formatBytes from '@/utils/formatBytes';
import getPlatformFromIdentityProvider from '@/utils/getIPlatformFromIdentityProvider';
import raiseFlashMessage, {
  raiseFlashMessageError,
} from '@/utils/raiseFlashMessage';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import {getSupportedNetworks} from '@/utils/utils';
import {ROUTES} from '@/config/routesConstants';
import {NUMERIC_DAY_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import type {CreatorBrandSafetyVettingStatus} from '@/types/creatorBrandSafetyVetting';
import {
  CombinedPostAnalysis,
  CreatorSafetyReport,
  CombinedPostAnalysisFailure,
  SocialNetwork,
} from '@/types/creatorSafetyReport';
import useHandleToleranceSettingsModal from '@/views/vetting/components/brand-safety-tolerance-settings-modal/useHandleToleranceSettingsModal';
import ActionInfoCard from '@/components/action-info-card';
import {ActionInfoCardProps} from '@/components/action-info-card/ActionInfoCard';
import Conditional from '@/components/conditional';
import useApplyToleranceSettingsFilter from '@/components/creator-vetting-report/hooks/use-apply-tolerance-settings-filter';
import Divider from '@/components/divider';
import useTrackBrandSafetyCreatorActionEvent from '@/hooks/analytics/use-track-brand-safety-creator-action-event';
import useTrackBrandSafetyCreatorEvent from '@/hooks/analytics/use-track-brand-safety-creator-event';
import useUpdateVettingStatus from '@/hooks/mutations/brand-safety/use-update-vetting-status';
import useInviteExternalProfile from '@/hooks/mutations/use-invite-external-profile';
import useBrandSafetyToleranceSettingsQuery from '@/hooks/queries/use-brand-safety-tolerance-settings-query';
import {queryKey as creatorBrandSafetyVettingsQueryKey} from '@/hooks/queries/use-creator-brand-safety-vettings-query';
import useBrandId from '@/hooks/use-brand-id';
import useParams from '@/hooks/use-params';
import useBrandSafetyToleranceSettingsStore, {
  brandSafetyToleranceSettingsActions,
} from '@/stores/brandSafetyToleranceSettings';
import {creatorVettingReportActions} from '@/stores/creatorVettingReportStore';
import ModalNavigation from '../modal-navigation';
import styles from './CreatorVettingReport.module.scss';
import PostModalContent from './components/post-modal-content';
import PostModalTitle from './components/post-modal-content/post-modal-title';
import ProfileVettingActions from './components/profile-vetting-actions';
import ProfileVettingBackgroundCheck from './components/profile-vetting-background-check';
import ProfileVettingBreadcrumbs from './components/profile-vetting-breadcrumbs';
import ProfileVettingEmptyState from './components/profile-vetting-empty-state';
import ProfileVettingFailedContent from './components/profile-vetting-failed-content';
import ProfileVettingFlaggedContent from './components/profile-vetting-flagged-content';
import ProfileVettingInfo from './components/profile-vetting-info';
import ProfileVettingItemsFlagged from './components/profile-vetting-items-flagged';
import ProfileVettingItemsScanned from './components/profile-vetting-items-scanned';
import ProfileVettingSummary from './components/profile-vetting-summary';
import ProfileVettingTracking from './components/profile-vetting-tracking';
import ScannedPlatforms from './components/scanned-platforms';
import {
  CONTENT_MODAL_ANALYTICS_PREFIX,
  FAILED_SCAN_FLAG,
  Severity,
  ToleranceFilter,
} from './constants';
import useGetVettingCategories from './hooks/use-get-vetting-categories';
import useGetVettingFilters from './hooks/use-get-vetting-filters';
import CREATOR_VETTING_REPORT_TEST_IDS from './testIds';

const TRANSLATION_PREFIX = 'components.creator-vetting-report';

export interface CreatorVettingReportProps {
  testID?: string;
  vettingReport: CreatorSafetyReport;
  showBackButton?: boolean;
  showProfileInfo?: boolean;
}

type PostModalData = {
  posts: (CombinedPostAnalysis | CombinedPostAnalysisFailure)[];
  flag: string;
  name: string;
  selectedPostIndex: number;
};

function SectionTitle(props: {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  actions?: React.ReactNode;
}) {
  const {title, subtitle, actions} = props;
  return (
    <div className={styles.sectionTitleContainer}>
      <div className={styles.sectionTitle}>
        {typeof title === 'string' ? (
          <Headline size="lg" color={designSystemToken('semantic.fg.primary')}>
            {title}
          </Headline>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
            {subtitle}
          </Body>
        ) : (
          subtitle
        )}
      </div>
      <div className={styles.actions}>{actions}</div>
    </div>
  );
}

function CreatorVettingReport(props: CreatorVettingReportProps) {
  const {
    testID = CREATOR_VETTING_REPORT_TEST_IDS.MAIN,
    vettingReport,
    showBackButton = true,
    showProfileInfo = true,
  } = props;
  const {creatorId} = useParams();
  const reportId = vettingReport.id;
  const brandId = useBrandId();
  const [searchParams, setSearchParams] = useSearchParams();
  const updateVettingQueryKey = ['vettingReport', creatorId, reportId];
  const hasBackgroundCheckResults =
    vettingReport.backgroundCheck?.findings?.length > 0;

  const {open: openToleranceSettings} = useHandleToleranceSettingsModal();
  const {toleranceFilter} = useBrandSafetyToleranceSettingsStore();

  const inviteProfile = useInviteExternalProfile({
    onMutate: () => {
      return updateVettingReportInvitationEmailSent({
        creatorId,
        reportId,
      });
    },
    onError: (_err, _data, context) => {
      const error = _err as Error;
      if (error?.message) {
        raiseFlashMessageError({title: 'Error', subtitle: error?.message});
      }
      queryClient.setQueryData(updateVettingQueryKey, context);
    },
  });
  const trackBrandSafetyCreatorEvent = useTrackBrandSafetyCreatorEvent();
  const trackBrandSafetyCreatorActionEvent =
    useTrackBrandSafetyCreatorActionEvent(FLOW_NAMES.BRAND_SAFETY_CREATOR);

  useEffect(() => {
    const creatorPayload = {
      report_id: reportId,
      creator_id: vettingReport.creatorId || '',
      creator_name: vettingReport.creatorName || '',
    };
    trackBrandSafetyCreatorEvent.presented({
      ...creatorPayload,
    });

    return () => {
      trackBrandSafetyCreatorEvent.dismissed({
        ...creatorPayload,
      });
    };
  }, []);

  const trackAction = (actionName: string, actionValue: string) => {
    trackBrandSafetyCreatorActionEvent.action({
      brand_safety_screen_name: 'Vetting Report',
      report_id: vettingReport.id,
      creator_id: vettingReport.creatorId,
      action_name: actionName,
      action_value: actionValue,
    });
  };

  const platforms = useMemo(() => {
    const filteredPlatforms = vettingReport.socialNetworks.reduce(
      (acc: SocialNetwork[], network: SocialNetwork) => {
        const provider = getPlatformFromIdentityProvider(network.provider);
        if (
          !acc.find(
            (platform) =>
              getPlatformFromIdentityProvider(platform.provider) === provider
          )
        ) {
          acc.push(network);
        }
        return acc;
      },
      []
    );

    const socialNetworkOrder = getSupportedNetworks();
    return filteredPlatforms.slice().sort((a, b) => {
      if (a.unlinkedAt) {
        return 1;
      }
      return (
        socialNetworkOrder.indexOf(a.provider) -
        socialNetworkOrder.indexOf(b.provider)
      );
    });
  }, [vettingReport.socialNetworks]);

  const vettingFilters = useGetVettingFilters(
    reportId,
    vettingReport.timeRange
  );

  const {postAnalysesStats} = vettingReport;
  const {flagValues, disabledCategories, isDefaultSettings} =
    useBrandSafetyToleranceSettingsQuery({
      brandId,
    });

  const filteredToleranceFlaggedPostAnalyses = useApplyToleranceSettingsFilter({
    flagValues,
    flaggedPostAnalyses: vettingReport.flaggedPostAnalyses,
    applyCustomTolerance: toleranceFilter === ToleranceFilter.CUSTOM,
  });

  const vettingCategories = useGetVettingCategories(
    filteredToleranceFlaggedPostAnalyses,
    vettingReport.failedPostAnalyses,
    toleranceFilter === ToleranceFilter.CUSTOM ? disabledCategories : {},
    {
      timeRange: {
        start: vettingFilters.dateRange.startDate,
        end: vettingFilters.dateRange.endDate,
      },
      severity: vettingFilters.severity,
    }
  );

  const actionInfoCardProps = useMemo((): ActionInfoCardProps => {
    if (vettingReport.isOnboarded) {
      return {
        testID: CREATOR_VETTING_REPORT_TEST_IDS.GOTO_PROFILE,
        title: translate('Active Creator'),
        subtitle: translate(
          'This creator is active on Popular Pays and ready for you to explore their profile and start working together.'
        ),
        ctaText: translate('Go to Full Profile'),
        ctaLink: `${ROUTES.CREATOR_PROFILE}/${vettingReport.creatorId}`,
        onCtaClick: (): void => {
          trackAction('Go to full profile', '');
        },
      };
    }
    const externalCreatorProps = {
      testID: CREATOR_VETTING_REPORT_TEST_IDS.INVITE_CREATOR,
      title: translate('Invite to Popular Pays'),
      subtitle: translate(
        "This creator isn't on Popular Pays yet. Invite them to access first-party data and direct communication."
      ),
      ctaText: translate('Invite Creator'),
      onCtaClick: (): void => {
        trackAction('Invite Creator', '');
        inviteProfile.mutate({brandId, profileId: vettingReport.creatorId});
      },
    };

    if (vettingReport.invitationEmailSent) {
      return {
        ...externalCreatorProps,
        ctaDisabled: true,
        ctaText: translate('Invitation Sent'),
        ctaStartIcon: (
          <Icon size="small" appearance="disabled" name="Actions-Accept" />
        ),
      };
    }

    return externalCreatorProps;
  }, [
    vettingReport.creatorId,
    vettingReport.isOnboarded,
    vettingReport.invitationEmailSent,
  ]);

  const [liveTrackingProxy, setLiveTrackingProxy] = React.useState(
    !!vettingReport.vettingTracking
  );

  const updateSearchParams = ({
    flag,
    postId,
    replace = false,
  }: {
    flag?: string;
    postId?: string;
    replace?: boolean;
  }) => {
    if (flag && postId) {
      searchParams.set('postModal', `${flag}:${postId}`);
    } else {
      searchParams.delete('postModal');
    }
    setSearchParams(searchParams, {replace});
  };

  const updateVettingStatus = useUpdateVettingStatus({
    onMutate: async ({vettingId, status: newStatus}) => {
      const previousData = updateVettingReportStatus({
        creatorId,
        reportId,
        status: newStatus as CreatorBrandSafetyVettingStatus,
      });

      updateCreatorBrandSafetyVettingsStatus({
        brandId,
        vettingId,
        status: newStatus,
      });
      return previousData;
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData(updateVettingQueryKey, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: updateVettingQueryKey});
      // invalidate the vettings so it can be re-fetched with the updated status
      queryClient.invalidateQueries({
        queryKey: [creatorBrandSafetyVettingsQueryKey, brandId],
      });
    },
  });

  const selectedToleranceFilterId = toleranceFilter;
  const onToleranceFilterChange = (selectedOptionId: string) => {
    trackAction('custom_risk', selectedOptionId);
    if (selectedOptionId === ToleranceFilter.EDIT) {
      openToleranceSettings();
      return;
    }
    brandSafetyToleranceSettingsActions.setToleranceFilter(
      selectedOptionId as ToleranceFilter
    );
  };

  const [showModal, setShowModal] = useState(false);
  const [postModalData, setPostModalData] = useState<PostModalData>({
    posts: [],
    flag: '',
    name: '',
    selectedPostIndex: 0,
  });

  useEffect(() => {
    if (showModal) {
      return;
    }
    const postModal = searchParams.get('postModal');
    if (postModal) {
      const [flag, postId] = postModal.split(':');
      handlePostClick(flag, postId);
    }
  }, [vettingCategories, showModal]);

  useEffect(() => {
    const postModal = searchParams.get('postModal');
    if (!postModal && showModal) {
      setShowModal(false);
    }
  }, [searchParams, showModal, setShowModal]);

  const nextPostClickHandler = () => {
    setPostModalData(
      produce(postModalData, (draft) => {
        draft.selectedPostIndex = Math.min(
          draft.selectedPostIndex + 1,
          draft.posts.length - 1
        );

        const postId = postModalData.posts[draft.selectedPostIndex].id;
        updateSearchParams({flag: postModalData.flag, postId, replace: true});
        trackAction(`${CONTENT_MODAL_ANALYTICS_PREFIX}:Next Clicked`, postId);
      })
    );
  };

  const previousPostClickHandler = () => {
    setPostModalData(
      produce(postModalData, (draft) => {
        draft.selectedPostIndex = Math.max(draft.selectedPostIndex - 1, 0);
        const postId = postModalData.posts[draft.selectedPostIndex].id;
        updateSearchParams({flag: postModalData.flag, postId, replace: true});
        trackAction(
          `${CONTENT_MODAL_ANALYTICS_PREFIX}:Previous Clicked`,
          postId
        );
      })
    );
  };

  const showModalHandler = (data: PostModalData) => {
    setPostModalData(data);
    setShowModal(true);
  };

  const handleOnTrackingChanged = (isTracking: boolean) => {
    setLiveTrackingProxy(isTracking);
    trackAction('Live Tracking Changed', isTracking ? 'On' : 'Off');
  };

  const handleOnVettingStatusChange = (status: string) => {
    updateVettingStatus.mutateAsync({
      vettingId: vettingReport.vettingId,
      status: status as CreatorBrandSafetyVettingStatus,
    });
    trackAction(BRANDS_SAFETY_ACTION_NAMES.VETTING_STATUS_CHANGED, status);
  };

  const handlePostClick = (flag: string, postId: string) => {
    let posts: (CombinedPostAnalysis | CombinedPostAnalysisFailure)[];
    let name = '';
    if (flag === FAILED_SCAN_FLAG) {
      posts = vettingCategories.failed.posts;
      name = vettingCategories.failed.name;
    } else {
      const category = vettingCategories.flatFlaggedWithCategory.find(
        (flagged) => flagged.id === flag
      );
      posts = category?.posts || [];
      name = category?.name || '';
    }
    const selectedPostIndex = posts?.findIndex((post) => post.id === postId);
    if (posts && selectedPostIndex > -1) {
      updateSearchParams({flag, postId});
      showModalHandler({posts, flag, name, selectedPostIndex});
      trackAction(`${CONTENT_MODAL_ANALYTICS_PREFIX}:Open`, postId);
    }
  };

  useEffect(() => {
    creatorVettingReportActions.initializeReportSettings(reportId);
  }, []);

  return (
    <div className={styles.creatorVettingReportContainer} data-testid={testID}>
      <div className={styles.creatorVettingReportContent}>
        <div className={styles.creatorVettingReportWrapper}>
          <div className={styles.sideBarContainer}>
            <Conditional condition={showBackButton}>
              <ProfileVettingBreadcrumbs />
            </Conditional>
            <ProfileVettingInfo
              profileImageUrl={vettingReport.creatorAvatar}
              profileDisplayName={vettingReport.creatorName}
              showProfileInfo={showProfileInfo}
              vettingStatus={vettingReport.vettingStatus}
              onVettingStatusChanged={handleOnVettingStatusChange}
            />
            <span>
              <Divider
                margin={0}
                color={designSystemToken('semantic.bg.neutral-inverse')}
              />
            </span>
            <ProfileVettingTracking
              isTracking={liveTrackingProxy}
              onTrackingChanged={handleOnTrackingChanged}
              timeRange={vettingReport.timeRange}
            />
            <span>
              <Divider
                margin={0}
                color={designSystemToken('semantic.bg.neutral-inverse')}
              />
            </span>
            <ScannedPlatforms
              platforms={platforms}
              trackAction={trackAction}
              hasBackgroundCheckResults={hasBackgroundCheckResults}
            />
            <Conditional condition={showProfileInfo}>
              <div className={styles.creatorActionInfoCardContainer}>
                <ActionInfoCard {...actionInfoCardProps} />
              </div>
            </Conditional>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.vettingSummaryContainer}>
              <ProfileVettingItemsScanned
                totalItemsScanned={postAnalysesStats.totalPosts}
                totalVideoMinutesScanned={
                  postAnalysesStats.totalVideoDurationInSec
                    ? Math.round(postAnalysesStats.totalVideoDurationInSec / 60)
                    : 0
                }
                totalImagesScanned={postAnalysesStats.totalImages}
                totalMediaSize={
                  postAnalysesStats.totalMediaSizeInBytes
                    ? formatBytes(postAnalysesStats.totalMediaSizeInBytes, 0)
                    : '0'
                }
              />
              <ProfileVettingItemsFlagged
                totalItemsScanned={postAnalysesStats.totalPosts}
                totalItemsFlagged={filteredToleranceFlaggedPostAnalyses.length}
                vettingCategories={vettingCategories}
                selectedToleranceFilterId={selectedToleranceFilterId}
                onToleranceFilterChange={onToleranceFilterChange}
                isDefaultToleranceSettings={isDefaultSettings}
              />
              <ProfileVettingSummary summary={vettingReport.summary} />
            </div>
            <Conditional condition={hasBackgroundCheckResults}>
              <div className={styles.onlineControversyContainer}>
                <SectionTitle
                  title={translate(
                    `${TRANSLATION_PREFIX}.background-checks-title`
                  )}
                  subtitle={translate(
                    `${TRANSLATION_PREFIX}.background-checks-subtitle`
                  )}
                />
                <ProfileVettingBackgroundCheck
                  creatorId={vettingReport.creatorId}
                  reportId={reportId}
                  backgroundCheck={vettingReport.backgroundCheck}
                />
              </div>
            </Conditional>
            <Conditional
              condition={vettingReport.flaggedPostAnalyses.length > 0}
              fallback={
                <ProfileVettingEmptyState
                  title={translate('all-clear')}
                  subtitle={translate('no-flagged-content-for-creator')}
                />
              }
            >
              <div className={styles.flaggedContentContainer}>
                <SectionTitle
                  title={translate(
                    `${TRANSLATION_PREFIX}.flagged-content-title`
                  )}
                  actions={
                    <ProfileVettingActions
                      totalPosts={vettingCategories.totalFlaggedPosts}
                      filteredPosts={vettingCategories.filteredFlaggedPosts}
                      vettingFilters={vettingFilters}
                      onDateRangeChange={(newDateRange) => {
                        if (newDateRange.startDate && newDateRange.endDate) {
                          creatorVettingReportActions.setReportTimeRangeFilter(
                            reportId,
                            {
                              start: newDateRange.startDate,
                              end: newDateRange.endDate,
                            }
                          );
                          trackAction(
                            'Date Range Changed',
                            `${dayjs(newDateRange.startDate).format(
                              NUMERIC_DAY_MONTH_YEAR_FORMAT
                            )} => ${dayjs(newDateRange.endDate).format(
                              NUMERIC_DAY_MONTH_YEAR_FORMAT
                            )}`
                          );
                        }
                      }}
                      onSeverityChange={(severity) => {
                        creatorVettingReportActions.setReportSeverityFilter(
                          reportId,
                          severity.id as Severity
                        );
                        trackAction('Severity Changed', severity.id);
                      }}
                      minDate={dayjs(vettingReport.timeRange.start).toDate()}
                      maxDate={dayjs(vettingReport.timeRange.end).toDate()}
                    />
                  }
                />
                <ProfileVettingFlaggedContent
                  reportId={reportId}
                  flaggedContent={vettingCategories.flatFlaggedWithCategory}
                  onPostClick={handlePostClick}
                  trackAction={trackAction}
                />
              </div>
            </Conditional>
            <Conditional condition={vettingCategories.failed.posts.length > 0}>
              <div className={styles.failedContentContainer}>
                <SectionTitle
                  title={
                    <div className={styles.failedContentTitle}>
                      <Headline size="lg">
                        {translate(
                          `${TRANSLATION_PREFIX}.failed-content-title`
                        )}
                      </Headline>
                      <Headline
                        size="lg"
                        color={designSystemToken('semantic.fg.secondary')}
                      >
                        {`(${vettingCategories.failed.posts.length})`}
                      </Headline>
                    </div>
                  }
                />
                <ProfileVettingFailedContent
                  reportId={reportId}
                  failedContent={vettingCategories.failed}
                  onPostClick={handlePostClick}
                  trackAction={trackAction}
                />
              </div>
            </Conditional>
          </div>
        </div>
      </div>
      <ModalNavigation
        testID={CREATOR_VETTING_REPORT_TEST_IDS.MAIN__POST_MODAL}
        open={showModal}
        onClose={() => {
          updateSearchParams({replace: true});
          setShowModal(false);
          trackAction(`${CONTENT_MODAL_ANALYTICS_PREFIX}:Close`, '');
        }}
        onNext={
          postModalData?.selectedPostIndex < postModalData.posts.length - 1
            ? nextPostClickHandler
            : undefined
        }
        onPrevious={
          postModalData.selectedPostIndex > 0
            ? previousPostClickHandler
            : undefined
        }
        title={
          <PostModalTitle
            currentIndex={postModalData.selectedPostIndex}
            totalItems={postModalData.posts.length}
            categoryName={postModalData.name}
          />
        }
      >
        <PostModalContent
          post={postModalData.posts[postModalData.selectedPostIndex]}
          category={postModalData.flag}
          creatorName={vettingReport.creatorName}
          creatorAvatar={vettingReport.creatorAvatar}
          socialNetworks={vettingReport.socialNetworks}
          trackAction={trackAction}
        />
      </ModalNavigation>
    </div>
  );
}

export default CreatorVettingReport;
