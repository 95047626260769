import React from 'react';
import {useShallow} from 'zustand/react/shallow';
import {
  Avatar,
  Body,
  Headline,
  Icon,
  Label,
  designSystemToken,
} from '@lightricks/react-design-system';
import mockData from '@/mocks/brand-safety/mockVettableCreatorsResponse';
import getPlatformDisplayNameFromProvider from '@/utils/getPlatformDisplayNameFromProvider';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import {JsonApiError} from '@/types/errorResponse';
import {
  type VettableCreators,
  type VettableCreator,
} from '@/types/vettableCreatorsResponse';
import getAvatarSrc from '@/views/vetting/vetting-hub/utils/getAvatarSrc';
import useCreateBrandSafetyVetting from '@/hooks/mutations/brand-safety/use-create-brand-safety-vetting';
import {queryKey} from '@/hooks/queries/use-creator-brand-safety-vettings-query';
import useBrandId from '@/hooks/use-brand-id';
import {useBannerStore} from '@/contexts/BannerContext';
import useVettingHubStore from '@/stores/vettingHubStore';
import trackBrandSafetyDetailEvent from '../../../utils/vettingHubAnalytics';
import CreatorSearchResult from './CreatorSearchResult';
import styles from './CreatorSearchResults.module.scss';

const HELP_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScgtRLy-AvrLawbJZhxcdTTk5dTbd4u-WNIT82t8RVAI9HdVA/viewform?usp=sf_link';

const EXTERNAL_MINOR_AGE_GROUP = '13-17';
const MINIMAL_INTERNAL_MINOR_AGE = 18;

type CreatorSearchResultsProps = {
  testID?: string;
  data?: VettableCreators;
  initialAddedCreators?: string[];
  onClose?: () => void;
};

function CreatorSearchResults(props: CreatorSearchResultsProps) {
  const {
    testID = 'creator-search-results',
    data = mockData,
    initialAddedCreators = [],
    onClose,
  } = props;

  const {dispatch} = useBannerStore();
  const brandId = useBrandId();
  const createBrandSafetyVetting = useCreateBrandSafetyVetting({brandId});
  const {searchValue, searchNetwork} = useVettingHubStore(
    useShallow((state) => ({
      searchValue: state.searchValue,
      searchNetwork: state.searchNetwork,
    }))
  );

  const [addedCreators, setAddedCreators] =
    React.useState<VettableCreator['id'][]>(initialAddedCreators);

  const isExternalMinor = (ageGroup: string | null): boolean => {
    return ageGroup === null || ageGroup === EXTERNAL_MINOR_AGE_GROUP;
  };

  const isInternalMinor = (age: number | null): boolean => {
    return age === null || age < MINIMAL_INTERNAL_MINOR_AGE;
  };

  const isOnSearchNetwork = React.useCallback(
    (creator: VettableCreator): boolean => {
      const isOffPlatformProviderMatch = creator.provider === searchNetwork;

      const hasMatchingOnPlatformAccount =
        creator.accounts?.some(
          ({platform, username}) =>
            platform?.includes(searchNetwork) &&
            username?.toLowerCase() === searchValue?.toLowerCase()
        ) ?? false;

      return isOffPlatformProviderMatch || hasMatchingOnPlatformAccount;
    },
    [searchNetwork, searchValue]
  );

  const {onPlatform, offPlatform} = React.useMemo(() => {
    const onPlatformWithIsMinor = data.onPlatform.map((creator) => ({
      ...creator,
      isMinor: isInternalMinor(creator.age ?? null),
    }));

    const offPlatformWithIsMinor = data.offPlatform.map((creator) => ({
      ...creator,
      isMinor: isExternalMinor(creator.ageGroup ?? null),
    }));

    return {
      onPlatform: onPlatformWithIsMinor.filter(isOnSearchNetwork),
      offPlatform: offPlatformWithIsMinor.filter(isOnSearchNetwork),
    };
  }, [data, isOnSearchNetwork]);

  const handleVettingCreation = async (
    creator: VettableCreator,
    type: string
  ) => {
    trackBrandSafetyDetailEvent({
      brand_safety_detail_name: 'Add Creator Clicked',
      brand_safety_detail_value: JSON.stringify(creator),
    });
    try {
      await createBrandSafetyVetting.mutateAsync({
        creator,
        brandId,
        type,
      });
      await queryClient.invalidateQueries([queryKey, brandId]);
      setAddedCreators([...addedCreators, creator.id]);

      const isOnPlatform = creator.accounts !== undefined;

      const username = isOnPlatform
        ? creator.accounts?.[0].username
        : creator.username;

      let title = translate('username-added-to-list', {
        username,
      });
      let message = translate('creator-added-to-list');

      const {inviteOnly} = creator;
      if (inviteOnly) {
        title = translate('invitation-sent', {
          username,
        });
        message = translate('sending-an-external-creator-invite', {
          username,
        });
      }

      const avatarSrc = getAvatarSrc(creator);

      dispatch({
        type: 'SET_BANNER' as const,
        title,
        message,
        icon: (
          <Avatar
            size="md"
            type={avatarSrc ? 'picture' : 'placeholder'}
            src={avatarSrc}
          />
        ),
      });

      onClose?.();
    } catch (e) {
      handleError(e as JsonApiError);
    }
  };

  const handleError = (e: JsonApiError) => {
    const title =
      e.code === '403'
        ? translate("Sorry, you don't have permission to create a vetting")
        : e.message ??
          translate(
            'Vetting creation failed. Please contact support or try again later.'
          );

    dispatch({
      type: 'SET_BANNER' as const,
      title,
      message: '',
      icon: (
        <Icon size="medium" appearance="danger" name="Actions-Alert-Line" />
      ),
    });
  };

  return (
    <div className={styles.container} data-testid={testID}>
      {onPlatform.length > 0 && (
        <Section
          testID="creator-search-results-on-platform"
          title={translate('found-on-popular-pays')}
          results={onPlatform}
          searchNetwork={searchNetwork}
          addedCreators={addedCreators}
          onClickAdd={async (creator) => {
            await handleVettingCreation(creator, 'on_platform');
          }}
        />
      )}
      {offPlatform.length > 0 && (
        <Section
          isOffPlatform
          testID="creator-search-results-off-platform"
          title={translate('found-on-social-media')}
          addedCreators={addedCreators}
          results={offPlatform}
          searchNetwork={searchNetwork}
          onClickAdd={async (creator) => {
            if (!creator.username) {
              handleError({message: translate('creator-handle-not-found')});
              return;
            }

            await handleVettingCreation(
              {
                ...creator,
                id: creator.id,
                inviteOnly: creator.inviteOnly ?? false,
              },
              'off_platform'
            );
          }}
        />
      )}
      {offPlatform.length === 0 && onPlatform.length === 0 && <EmptyState />}
      <Footer helpUrl={HELP_URL} />
    </div>
  );
}

type SectionProps = {
  testID?: string;
  title: string;
  results: VettableCreator[];
  addedCreators: string[];
  onClickAdd: (creator: VettableCreator) => Promise<void>;
  isOffPlatform?: boolean;
  searchNetwork: string;
};

function Section(props: SectionProps) {
  const {
    testID,
    title,
    results,
    addedCreators,
    onClickAdd,
    isOffPlatform = false,
    searchNetwork,
  } = props;

  return (
    <div className={styles.section} data-testid={testID}>
      <Label size="md" color={designSystemToken('semantic.fg.secondary')}>
        {title}
      </Label>
      <div className={styles.sectionChildren}>
        {results.map((creator) => (
          <CreatorSearchResult
            key={creator.id}
            creator={creator}
            avatarSrc={getAvatarSrc(creator)}
            isAdded={addedCreators.includes(creator.id)}
            onClickAdd={onClickAdd}
            isOffPlatform={isOffPlatform}
            searchNetwork={searchNetwork}
          />
        ))}
      </div>
    </div>
  );
}

function Footer({helpUrl}: {helpUrl: string}) {
  return (
    <div className={styles.support} data-testid="creator-search-results-footer">
      <Body size="md">
        {translate('not-expected-creator')}{' '}
        <a href={helpUrl}>
          <Label
            size="md"
            color={designSystemToken('semantic.fg.secondary')}
            component="span"
          >
            {translate('get-help')}
          </Label>
        </a>
      </Body>
    </div>
  );
}

function EmptyState() {
  const searchNetwork = useVettingHubStore((state) => state.searchNetwork);

  return (
    <div className={styles.emptyState}>
      <Headline size="sm">
        {translate('creator-not-found-on', {
          network: getPlatformDisplayNameFromProvider(searchNetwork),
        })}
      </Headline>
      <Body size="lg" color={designSystemToken('semantic.fg.secondary')}>
        {translate('no-search-result-for-handle')}
      </Body>
    </div>
  );
}

export default CreatorSearchResults;
