import uuidToChecksum from '@/utils/uuidToChecksum';

function getGalleryLayout(
  count: number,
  creatorId: string,
  smallScreen = false
) {
  if (smallScreen) {
    return [2, 1, 1, 1, 1];
  }
  const number = uuidToChecksum(creatorId);
  if (count === 6) {
    return number % 2 ? [1, 2, 2, 1, 1, 2] : [2, 1, 1, 2, 2, 1];
  }
  if (count === 5) {
    return number % 2 ? [2, 1, 3, 1, 2] : [1, 2, 3, 2, 1];
  }
  if (count === 4) {
    return number % 2 ? [3, 1, 2, 3] : [3, 2, 1, 3];
  }
  if (count === 3) {
    return [3, 3, 3];
  }
  return [2, 1, 1, 1, 1, 1, 2];
}

export default getGalleryLayout;
