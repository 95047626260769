import React from 'react';
import {Checkbox} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import ButtonGroup from '@/components/button-group';
import OptionButton from '@/components/option-button';
import StepContainer from '../../components/step-container';
import StepSectionContainer from '../../components/step-section-container';
import BRIEF_WIZARD_TEST_IDS from '../../testIds';
import {StepProps} from '../StepProps';
import styles from './InfluencerPackage.module.scss';

const TRANSLATION_PREFIX =
  'views.campaigns.components.wizard.steps.influencer-package';

function InfluencerPackage(props: StepProps) {
  const {
    testID,
    formData,
    formErrors,
    updateFormData,
    creatorTypes,
    onClickNext,
    canContinue,
  } = props;

  const creatorTypesOptions = creatorTypes
    .filter((creatorType: any) => creatorType.value !== 'contentCreation')
    .map((creatorType: any) => ({
      value: creatorType.value,
      label: `${creatorType.totalCreators}`,
      title: `${creatorType.title} influencers`,
      subtitle: creatorType.subtitle,
      tooltip: creatorType.tooltip,
      tooltipPlace: 'bottom-start',
      disabled: creatorType.disabled,
      iconSrc: creatorType.iconSrc,
    }));

  return (
    <div className={styles.container}>
      <StepContainer
        onClickContinue={onClickNext}
        continueButtonDisabled={!canContinue}
        testID={testID}
      >
        <StepSectionContainer
          title={translate(`${TRANSLATION_PREFIX}.influencer-package-title`)}
          subtitle={translate(
            `${TRANSLATION_PREFIX}.influencer-package-subtitle`
          )}
          containerClassName={styles.customInfluencerPackageContainer}
        >
          <ButtonGroup
            containerClassName={styles.customButtonGroupStyle}
            buttons={creatorTypesOptions}
            onChange={(selection: any) =>
              updateFormData?.({creatorType: selection.value})
            }
            selectedButtonValue={formData.creatorType}
            renderButton={(button: any, isActive, onClick) => (
              <OptionButton
                testID={`${BRIEF_WIZARD_TEST_IDS.INFLUENCER_PACKAGE__CREATOR_TYPE}--${button.value}`}
                button={button}
                className={styles.influencerPackageButton}
                onClick={onClick}
                isActive={isActive}
                disabled={button.disabled}
              >
                <>
                  <div className={styles.checkbox}>
                    <Checkbox checked={isActive} />
                  </div>
                  <div
                    className={`${styles.influencerInfo}  ${
                      button.disabled ? styles.disabled : ''
                    }`}
                  >
                    <div
                      className={`${styles.creatorIcon} ${
                        button.disabled ? styles.disabled : ''
                      }`}
                    >
                      <img src={button.iconSrc} alt={button.title} />
                    </div>
                    <label className={styles.title}>{button.title}</label>
                    <label className={styles.subtitle}>{button.subtitle}</label>
                  </div>
                  <div />
                </>
              </OptionButton>
            )}
          />
        </StepSectionContainer>
      </StepContainer>
    </div>
  );
}

export default InfluencerPackage;
