import React from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './Footer.module.scss';

interface FooterProps {
  handleReset: () => void;
  handleSubmit: () => void;
}

function Footer(props: FooterProps) {
  const {handleReset, handleSubmit} = props;
  return (
    <div className={styles.footerContainer}>
      <Button
        className={styles.resetButton}
        startIcon={
          <Icon appearance="neutral" size="medium" name="Actions-Reset" />
        }
        appearance="neutral"
        mode="tinted"
        size="large"
        onClick={handleReset}
      >
        {translate('Reset to default')}
      </Button>
      <Button
        appearance="neutral"
        mode="filled"
        size="large"
        onClick={handleSubmit}
      >
        {translate('Save Changes')}
      </Button>
    </div>
  );
}

export default Footer;
