export const CAMPAIGN_STATE = {
  UNDER_CONSTRUCTION: 'under_construction', // The campaign is still being built
  DRAFT: 'draft', // The draft for the Campaign is being built by the Brand/Agency
  UNDER_REVIEW: 'under_review', // The draft of the Campaign has been submitted for review by a CSM
  APPROVED: 'approved', // This campaign is approved and ready to launch when subscribed
  ACCEPTING_PROPOSALS: 'accepting_proposals', // This campaign is open to applications
  CLOSED_TO_NEW_PROPOSALS: 'closed_to_new_proposals', // This campaign was hidden by an admin
  CLOSED: 'closed', // This campaign has reached its goal and cannot receive or accept more applications
};

export const DEFAULT_AGE_RANGE = {
  min: 18,
  max: 65,
};

export const DEFAULT_DAYS_TO_POST_AFTER_DELIVERABLE_FULFILLED = 14;

export const CREATOR_COMPENSATION_TYPES = {
  RANGE: 'range',
  FIXED: 'fixed',
};

export const DEFAULT_CREATOR_COMPENSATION_TYPE =
  CREATOR_COMPENSATION_TYPES.RANGE;

export const IMMEDIATE_SAVE_CAMPAIGN_IF_CHANGED_DELAY = -1;

export const IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY = 0;

export const DEFAULT_SAVE_CAMPAIGN_DELAY = 2500;

export const SUBSCRIPTION_TYPES_ALLOWED_ZERO_BUDGET = ['campaign', 'platform'];

export const AUTOFILL_BANNER_STATES = {
  INITIAL: 'initial',
  LOADING: 'loading',
  COMPLETED: 'completed',
};

export const AUTOFILL_LOADING_PERCENTAGE = {
  START: 0,
  AWAITING_FULL_COMPLETION: 95,
  FULLY_COMPLETED: 100,
};

export const GENDER_OPTIONS = [
  {value: 'Male', labelLocaleKey: `genders.male`},
  {value: 'Female', labelLocaleKey: `genders.female`},
  {value: 'Other', labelLocaleKey: `genders.other`},
];

export const AGE_MARKS = [
  {value: 18, label: '18'},
  {value: 25, label: '25'},
  {value: 35, label: '35'},
  {value: 45, label: '45'},
  {value: 55, label: '55'},
  {value: 65, label: '65+'},
];
