export const SMALL_SCREEN_CONTAINER_WIDTH_THRESHOLD = 1128;
export const CREATOR_CARD_GALLERY_SETTINGS = {
  DEFAULT: {
    gapSize: 2,
    numColumns: 3,
    numRows: 3,
    baseWidth: '100%',
    baseHeight: 174,
  },
  SMALL: {
    gapSize: 2,
    numColumns: 2,
    numRows: 3,
    baseWidth: '100%',
    baseHeight: 174,
  },
};

export const CREATORS_BAR_MIN_ITEM_WIDTH = 80;
export const CREATORS_BAR_MAX_ITEM_WIDTH = 104;
export const CREATORS_BAR_ITEM_GAP = 16;
export const CREATORS_BAR_ARROW_WIDTH = 24;
export const CREATORS_BAR_MAX_SKELETONS = 10;
export const CREATOR_SKILLS_MAX_ITEMS = 5;
