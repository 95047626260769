import React, {MouseEvent, ReactElement, useMemo} from 'react';
import CloseRounded from '@mui/icons-material/CloseRounded';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import styles from './Modal.module.scss';

export type ModalSize = 'tiny' | 'small' | 'standard' | 'fullscreen';
export type ModalProps = {
  testID?: string;
  children?: ReactElement;
  open?: boolean;
  onClose?: () => void;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  showCloseButton?: boolean;
  size?: ModalSize;
  className?: string;
  contentClassName?: string;
};

function LtxModal(props: ModalProps) {
  const {
    testID = 'modal',
    size = 'standard',
    open = false,
    showCloseButton = false,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    onClose,
    children,
    className = '',
    contentClassName = '',
  } = props;

  const [shake, setShake] = React.useState(false);
  const handleShake = () => {
    setShake(true);
    setTimeout(() => setShake(false), 400);
  };
  const handleClose = (event: MouseEvent<any, any>, reason: string) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      handleShake();
      return false;
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      handleShake();
      return false;
    }

    if (typeof onClose === 'function') {
      onClose();
    }

    return true;
  };
  const containerClasses = useMemo(() => {
    const classes = [styles.container, styles[size]];
    if (contentClassName) {
      classes.push(contentClassName);
    }
    if (shake) {
      classes.push(styles.shaking);
    }
    return classes.join(' ');
  }, [size, contentClassName, shake]);

  return (
    <div>
      <Modal
        data-testid={testID}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
        className={className}
      >
        <Fade in={open}>
          <Box className={containerClasses} data-testid="modal-box">
            {showCloseButton && (
              <Button
                className={styles.closeButton}
                onClick={(e) => handleClose(e, 'd')}
              >
                <CloseRounded className={styles.closeIcon} />
              </Button>
            )}
            {children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default LtxModal;
