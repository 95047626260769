import React from 'react';
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom';
import ContentStudio from '@/views/content-studio';
import Toggle from '@/views/content-studio/components/toggle/Toggle';
import ContentStudioPaths from './contentStudioRoutesList';

function EnsureTrailingSlash() {
  const location = useLocation();

  if (!location.pathname.endsWith('/')) {
    return <Navigate to={`${location.pathname}/`} replace />;
  }

  return <Outlet />;
}

function ContentStudioRoutes() {
  return (
    <Routes>
      <Route element={<EnsureTrailingSlash />}>
        <Route path="/" element={<ContentStudio />}>
          <Route
            key="content-studio"
            path={ContentStudioPaths.MAIN}
            element={<h1>Home</h1>}
          />
          <Route
            key="content-studio-assets"
            path={ContentStudioPaths.ASSETS}
            element={<h1>Assets</h1>}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default ContentStudioRoutes;
