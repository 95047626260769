import DOMPurify from 'dompurify';
import React, {useCallback, useEffect} from 'react';
import {
  Separator,
  Editor,
  HtmlButton,
  EditorProvider,
  Toolbar,
  EditorProps,
  ContentEditableEvent,
} from 'react-simple-wysiwyg';
import styles from './RichTextEditor.module.scss';
import {
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnStrikeThrough,
  BtnNumberedList,
  BtnBulletList,
  BtnClearFormatting,
  BtnLink,
} from './commands';

interface TextEditorProps extends Omit<EditorProps, 'onChange'> {
  testID?: string;
  containerClassName?: string;
  readOnly?: boolean;
  onChange?: (event: ContentEditableEvent, value: string) => void;
}

function getHtmlValue(value: string) {
  const cleanValue = value.trim();
  if (!cleanValue) {
    return '';
  }

  const div = document.createElement('div');
  div.innerHTML = DOMPurify.sanitize(cleanValue);

  // Get text content and remove all whitespace
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hasVisibleContent = div.textContent?.trim().length > 0;

  return hasVisibleContent ? value : '';
}

function RichTextEditor(props: TextEditorProps) {
  const {
    testID = 'rich-text-editor',
    containerClassName = '',
    className = '',
    readOnly = false,
    onChange,
    ...rest
  } = props;
  const editorRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (readOnly) {
      editorRef.current?.setAttribute('contenteditable', 'false');
    } else {
      editorRef.current?.setAttribute('contenteditable', 'true');
    }
  }, [readOnly]);

  const handleOnChange = useCallback(
    (event: ContentEditableEvent) => {
      onChange?.(event, getHtmlValue(event.target.value));
    },
    [onChange]
  );

  return (
    <EditorProvider>
      <Editor
        data-test-id={testID}
        ref={editorRef}
        containerProps={{
          className: `${styles.richTextEditorContainer} ${containerClassName}`,
        }}
        className={`${styles.richTextEditor} ${className} ${
          readOnly ? styles.readOnly : ''
        }`}
        onChange={handleOnChange}
        {...rest}
      >
        <Toolbar>
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnStrikeThrough />
          <Separator />
          <BtnNumberedList />
          <BtnBulletList />
          <Separator />
          <BtnLink />
          <BtnClearFormatting />
          <HtmlButton />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
}

export default RichTextEditor;
