import {useMemo} from 'react';
import {SuggestedCreator} from '@/types/suggestedCreator';
import QueryContext from '@/api/QueryContext';
import campaignFetchers from '@/api/fetchers/campaign';
import useInfiniteDataQuery from '@/hooks/use-infinite-data-query';

interface UseCampaignSuggestedCreatorsQueryParams {
  campaignId?: string;
  perPage?: number;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
  compact?: boolean;
  include?: string[];
}

interface Page {
  data: SuggestedCreator[];
  jsonapi: {version: string};
  meta: {
    pagination: {currentPage: number; totalPages: number; totalEntries: number};
  };
}

function useCampaignSuggestedCreatorsQuery({
  campaignId,
  perPage = 5,
  refetchOnWindowFocus = true,
  enabled = true,
  compact = true,
  include = [],
}: UseCampaignSuggestedCreatorsQueryParams) {
  const response = useInfiniteDataQuery({
    queryKey: ['campaignSuggestedCreators', campaignId, perPage, include],
    queryFn: ({pageParam = 1, ...rest}: QueryContext) =>
      campaignFetchers.suggestedCreators({pageParam, ...rest}),
    enabled: enabled && !!campaignId,
    meta: {id: campaignId, perPage, compact, include},
    retry: (failureCount: number, error: any) =>
      String(error?.code) !== '500' && failureCount < 3,
    refetchOnWindowFocus,
    keepPreviousData: false,
    getNextPageParam: (lastPage: Page) => {
      if (!lastPage.meta) {
        return undefined;
      }
      const {meta} = lastPage;
      return meta.pagination.currentPage < meta.pagination.totalPages
        ? meta.pagination.currentPage + 1
        : undefined;
    },
  });

  const {data = {}} = response;
  const {pages = []}: {pages?: Page[]} = data || {};
  const meta = pages[pages.length - 1]?.meta;

  const suggestedCreators = useMemo(() => {
    if (!response.data) {
      return [];
    }
    return pages.flatMap((page: {data: SuggestedCreator[]}) => page?.data);
  }, [response.data]);

  return {
    ...response,
    suggestedCreators,
    meta,
  };
}

export default useCampaignSuggestedCreatorsQuery;
