import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';

type BrandSafetyToleranceEditEventParams = {
  flow_id?: string;
  screen_presentation_id?: string;
  brand_safety_screen_name?: string;
  tolerance_changes: string;
  source?: string;
};

function useTrackBrandSafetyToleranceEditEvent() {
  const edit = ({
    flow_id = '',
    screen_presentation_id = '',
    brand_safety_screen_name = 'brand_safety_tolerance',
    tolerance_changes = '',
    source = '',
  }: BrandSafetyToleranceEditEventParams) => {
    const flow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.BRAND_SAFETY_CREATOR
    );
    const payload = {
      flow_id: flow_id || flow.flow_id,
      screen_presentation_id:
        screen_presentation_id || flow.screen_presentation_id,
      brand_safety_screen_name,
      tolerance_changes,
      source,
    };

    AnalyticsService.dispatchEvent(
      eventNames.BRAND_SAFETY_TOLERANCE_EDIT,
      payload
    );
  };

  return {edit};
}

export default useTrackBrandSafetyToleranceEditEvent;
