import React, {useMemo} from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import styles from './IconStat.module.scss';

export interface IconStatProps {
  testID?: string;
  title: string;
  subtitle: string;
  icon: IconName;
  backgroundColor?: string;
  iconColor?: string;
}

function IconStat(props: IconStatProps) {
  const {
    testID = 'icon-stat',
    title,
    subtitle,
    icon,
    backgroundColor,
    iconColor,
  } = props;

  const style = useMemo(() => {
    if (backgroundColor) {
      return {
        background: backgroundColor,
      };
    }

    return {};
  }, [backgroundColor]);

  return (
    <div
      className={styles.iconStatContainer}
      style={style}
      data-testid={testID}
    >
      <div className={styles.iconContainer}>
        <Icon
          size="medium"
          appearance="neutral"
          name={icon}
          color={iconColor}
        />
      </div>
      <div className={styles.statContainer}>
        <Headline size="xs" color={designSystemToken('semantic.fg.primary')}>
          {title}
        </Headline>
        <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
          {subtitle}
        </Body>
      </div>
    </div>
  );
}

export default IconStat;
