import {useMutation} from '@tanstack/react-query';
import queryClient from '@/utils/reactQueryClient';
import brandSafetyToleranceSettingsUpdaters from '@/api/updaters/brandSafetyToleranceSettings';
import {getQueryKey} from '@/hooks/queries/use-brand-safety-tolerance-settings-query/useBrandSafetyToleranceSettingsQuery';

function useUpdateBrandSafetyToleranceSettings({brandId}: {brandId: string}) {
  return useMutation({
    mutationKey: ['brandSafetyToleranceSettings', 'update', brandId],
    mutationFn: brandSafetyToleranceSettingsUpdaters.update,
    onMutate: async (payload) => {
      const previousData = await queryClient.getQueryData(getQueryKey(brandId));

      queryClient.setQueryData(getQueryKey(brandId), () => {
        const {categories} = payload;

        return {categories};
      });
      return previousData;
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(getQueryKey(brandId), context);
    },
  });
}

export default useUpdateBrandSafetyToleranceSettings;
