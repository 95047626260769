import {MRT_Row} from 'material-react-table';
import React, {useState} from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Box} from '@mui/material';
import {
  Avatar,
  Button,
  designSystemToken,
  Icon,
  Label,
  Tooltip,
} from '@lightricks/react-design-system';
import getFormattedPastDate from '@/utils/getFormattedPastDate';
import raiseFlashMessage, {
  raiseFlashMessageError,
} from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import Conditional from '@/components/conditional';
import FlashMessage from '@/components/flash-message';
import useRegenerateBrandSafetyReport from '@/hooks/mutations/brand-safety/use-regenerate-brand-safety-report';
import useBrandId from '@/hooks/use-brand-id';
import useVettingHubStore from '@/stores/vettingHubStore';
import styles from './LastUpdatedCell.module.scss';

function LastUpdateText({row}: {row: MRT_Row<CreatorBrandSafetyVetting>}) {
  if (
    row.original.reportStatus === 'vetting' ||
    row.original.reportStatus === 'scraping'
  ) {
    return <Label size="sm">{translate('Scanning...')}</Label>;
  }

  if (row.original.reportUpdatedAt === null) {
    return (
      <Label size="sm">
        <Icon name="Features-Duration" size="medium" appearance="disabled" />
      </Label>
    );
  }

  return (
    <Label size="sm">
      {getFormattedPastDate(new Date(row.original.reportUpdatedAt))}
    </Label>
  );
}

function regenerateIconColor(isReVetting: boolean, isTooltipOpen: boolean) {
  if (isReVetting) return designSystemToken('semantic.fg.disabled');
  return isTooltipOpen
    ? designSystemToken('semantic.fg.secondary')
    : designSystemToken('semantic.bg.neutral');
}

function RegenerateIcon({row}: {row: MRT_Row<CreatorBrandSafetyVetting>}) {
  const isReVetting =
    row.original.reportStatus === 'scraping' ||
    row.original.reportStatus === 'vetting';
  const brandId = useBrandId();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const hoveredRow = useVettingHubStore(
    useShallow((state) => state.hoveredRow)
  );
  const isRowHovered = hoveredRow?.id === row.original.id;

  const regenerateBrandSafetyReport = useRegenerateBrandSafetyReport({brandId});

  const handleRescan = async (e: React.MouseEvent) => {
    e.stopPropagation();

    try {
      const creatorVetting: CreatorBrandSafetyVetting = row.original;
      await regenerateBrandSafetyReport.mutateAsync({
        vettingId: creatorVetting.id,
      });

      const title = translate('creator vetting report is being updated', {
        displayName: creatorVetting.creatorName,
      });
      const message = translate(
        "We'll let you know in about an hour when it's ready for review."
      );
      const avatarSrc = creatorVetting.creatorImageUrl;

      raiseFlashMessage({
        status: 'success',
        message: <FlashMessage.Banner title={title} subtitle={message} />,
        icon: (
          <Avatar
            size="md"
            type={avatarSrc ? 'picture' : 'placeholder'}
            src={avatarSrc}
          />
        ),
        mode: 'light',
      });
    } catch (error) {
      raiseFlashMessageError({
        title: translate(
          'Rescanning failed. Please contact support or try again later.'
        ),
        subtitle: '',
        icon: (
          <Icon size="medium" appearance="danger" name="Actions-Alert-Line" />
        ),
      });
    }
  };

  if (row.original.reportUpdatedAt === null) {
    return null;
  }

  return (
    <Conditional condition={isRowHovered}>
      <Tooltip
        title={<Label size="sm">Rescan</Label>}
        arrow
        placement="top"
        disableHoverListener={isReVetting}
        onOpen={() => setIsTooltipOpen(true)}
        onClose={() => setIsTooltipOpen(false)}
        disableInteractive
      >
        <div>
          <Button
            appearance="neutral"
            mode="plain"
            size="small"
            onClick={handleRescan}
            disabled={isReVetting}
            sx={{
              minWidth: 'unset',
              padding: 0,
            }}
          >
            <Icon
              className={isReVetting ? styles.spin : ''}
              size="small"
              appearance="neutral"
              name="Actions-Reset"
              color={regenerateIconColor(isReVetting, isTooltipOpen)}
            />
          </Button>
        </div>
      </Tooltip>
    </Conditional>
  );
}

function LastUpdatedCell({row}: {row: MRT_Row<CreatorBrandSafetyVetting>}) {
  return (
    <Box className={styles.lastUpdatedCell}>
      <div className={styles.labelContainer}>
        <LastUpdateText row={row} />
      </div>
      <div className={styles.iconContainer}>
        <RegenerateIcon row={row} />
      </div>
    </Box>
  );
}

export default LastUpdatedCell;
