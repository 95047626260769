import React, {useMemo} from 'react';
import {designSystemToken, Tag} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import {ProfileProps} from '@/types/creatorProfile';
import {Identity} from '@/types/identity';
import Conditional from '@/components/conditional';
import IconStat from '@/components/icon-stat';
import MosaicGallery from '@/components/mosaic-gallery';
import {
  RecruitmentActionFlowEndedEventParams,
  RecruitmentActionFlowStartedEventParams,
} from '@/hooks/analytics/use-track-recruitment-action-flow-event/useTrackRecruitmentActionFlowEvent';
import {
  CREATOR_CARD_GALLERY_SETTINGS,
  SMALL_SCREEN_CONTAINER_WIDTH_THRESHOLD,
} from '../../utils/constants';
import getGalleryLayout from '../../utils/getGalleryLayout';
import CreatorInfo from '../creator-info';
import CreatorSkills from '../creator-skills';
import CreatorStats from '../creator-stats';
import styles from './CreatorCard.module.scss';

interface CreatorCardProps {
  brandId: string;
  creatorProfile: ProfileProps;
  network?: string | null;
  identity: Identity | null;
  rating: string | null;
  actions?: React.ReactNode;
  containerWidth: number;
  onSocialNetworkClick?: (identity: Identity) => void;
  creatorBadgeProps?: {
    title: string;
    subtitle: string;
    icon: IconName;
  };
  recruitmentEventStartedParams?: Partial<RecruitmentActionFlowStartedEventParams>;
  recruitmentEventEndedParams?: Partial<RecruitmentActionFlowEndedEventParams>;
}

function CreatorTags(props: {rating: string | null}) {
  const {rating} = props;

  const renderTag = (tag: string) => (
    <span className={styles.tagContainer}>
      <Tag
        text={tag}
        backgroundColor={designSystemToken('semantic.bg.black-secondary')}
        textColor={designSystemToken('semantic.fg.white')}
      />
    </span>
  );

  return (
    <div className={styles.tags}>
      {renderTag(translate('Recently Active'))}
      <Conditional condition={rating === 'healthy'}>
        {renderTag(translate('High Authenticity'))}
      </Conditional>
    </div>
  );
}

function CreatorCard(props: CreatorCardProps) {
  const {
    brandId,
    creatorProfile,
    network,
    identity,
    rating,
    actions,
    containerWidth,
    onSocialNetworkClick,
    creatorBadgeProps,
    recruitmentEventStartedParams,
    recruitmentEventEndedParams,
  } = props;

  const isSmallScreen = containerWidth < SMALL_SCREEN_CONTAINER_WIDTH_THRESHOLD;

  const profileMediaItems = useMemo(
    () =>
      creatorProfile.portfolioMediaItems.map(
        (mediaItem) => ({
          id: mediaItem.id,
          src: mediaItem.thumbnailUrl,
        }),
        [creatorProfile.portfolioMediaItems]
      ),
    [creatorProfile.portfolioMediaItems]
  );

  return (
    <div className={styles.creatorCardContainer}>
      <div className={styles.leftSection}>
        <CreatorTags rating={rating} />
        <MosaicGallery
          images={profileMediaItems}
          layouts={getGalleryLayout(
            isSmallScreen
              ? Math.min(5, profileMediaItems.length)
              : profileMediaItems.length,
            creatorProfile.id,
            isSmallScreen
          )}
          fillMissing={false}
          {...(isSmallScreen
            ? CREATOR_CARD_GALLERY_SETTINGS.SMALL
            : CREATOR_CARD_GALLERY_SETTINGS.DEFAULT)}
        />
      </div>
      <div className={styles.rightSection}>
        <div
          className={`${styles.rightSectionContent} ${
            !identity ? styles.largeGap : ''
          } ${isSmallScreen ? styles.smallScreen : ''}`}
        >
          <CreatorInfo
            brandId={brandId}
            creatorProfile={creatorProfile}
            network={network}
            identity={identity}
            onSocialNetworkClick={onSocialNetworkClick}
            recruitmentEventStartedParams={recruitmentEventStartedParams}
            recruitmentEventEndedParams={recruitmentEventEndedParams}
          />
          <div className={styles.skillsStatsContainer}>
            <Conditional condition={!!creatorBadgeProps}>
              <IconStat
                {...creatorBadgeProps!}
                backgroundColor={designSystemToken(
                  'semantic.bg.warning-secondary'
                )}
                iconColor={designSystemToken('semantic.fg.warning')}
              />
            </Conditional>
            <Conditional
              condition={!!identity && !!network}
              fallback={
                <CreatorSkills skills={creatorProfile.contentPreferences} />
              }
            >
              <CreatorStats
                followers={identity?.formattedCachedFollowerCount}
                engagementRate={identity?.formattedAverageEngagementRate}
                averageImpressions={
                  identity?.formattedAverageImpressionsCount ||
                  identity?.formattedAverageViewCount
                }
                network={network}
              />
            </Conditional>
          </div>
          {actions}
        </div>
      </div>
    </div>
  );
}

export default CreatorCard;
