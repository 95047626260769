import clsx from 'clsx';
import React, {useEffect, useMemo, useState} from 'react';
import {Box, Stack} from '@mui/material';
import {Body, Button, Icon} from '@lightricks/react-design-system';
import {raiseFlashMessageError} from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import creatorsFetchers from '@/api/fetchers/creators';
import AutocompleteInput, {Option} from '@/components/autocomplete-input';
import useRaiseCreatorInvitedToCampaignSuccess from '@/hooks/flash-messages/use-raise-creator-invited-to-campaign-success/useRaiseCreatorInvitedToCampaignSuccess';
import useInviteCreatorsToCampaign from '@/hooks/mutations/creators/use-invite-creators-to-campaign';
import useBrandCampaignsQuery from '@/hooks/queries/use-brand-campaigns-query';
import {CampaignListItem} from '@/hooks/queries/use-brand-campaigns-query/useBrandCampaignsQuery';
import useBrandId from '@/hooks/use-brand-id';
import {ActionProps} from './ActionProps';
import styles from './AddToCampaign.module.scss';
import ContentContainer from './ContentContainer';
import SubmitButtonContainer from './SubmitButtonContainer';

const TRANSLATION_PREFIX =
  'views.creators.components.creator-action.add-to-campaign';

const renderOption = (
  optionProps: React.HTMLAttributes<HTMLLIElement>,
  option: Option
) => {
  if (option.value.isInvited) {
    return (
      <li
        {...optionProps}
        className={clsx(
          optionProps.className,
          styles.invitedLabel,
          option.disabled ? styles.disabled : ''
        )}
      >
        <div>{option.label}</div>
        <div className={clsx(styles.invitedIconContainer, styles.disabled)}>
          <Icon size="small" appearance="disabled" name="Actions-Accept" />
          <Body size="sm">{translate('invited')}</Body>
        </div>
      </li>
    );
  }
  return (
    <li
      {...optionProps}
      className={`${optionProps.className} ${
        option.disabled ? styles.disabled : ''
      }`}
    >
      <span>{option.label}</span>
    </li>
  );
};

function AddToCampaign(
  props: ActionProps & {setHasActiveCampaigns: (hasActive: boolean) => void}
) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNoActiveCampaignsModalOpen, setIsNoActiveCampaignsModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {creators, selectedCampaignId, onSubmitSuccess, setHasActiveCampaigns} =
    props;
  const brandId = useBrandId();
  const inviteCreatorsToCampaign = useInviteCreatorsToCampaign();
  const raiseCreatorInvitedToCampaignSuccess =
    useRaiseCreatorInvitedToCampaignSuccess();

  const {
    brandCampaigns: campaignsAcceptingProposals,
    isLoading: isCampaignsLoading,
  } = useBrandCampaignsQuery({
    brandId,
    states: ['accepting_proposals'],
  });

  const [creatorsInvitedStatus, setCreatorsInvitedStatus] = useState<
    {id: string; invited: boolean; campaignId: string}[]
  >([]);

  useEffect(() => {
    const fetchInvitationStatuses = async () => {
      try {
        setCreatorsInvitedStatus([]);
        const allStatuses = await Promise.all(
          (campaignsAcceptingProposals || []).map(async (campaign) => {
            const campaignStatuses =
              await creatorsFetchers.checkInvitationStatusBatch({
                campaignId: campaign.id,
              });
            return campaignStatuses.map((status) => ({
              ...status,
              campaignId: campaign.id,
            }));
          })
        );

        const flattenedStatuses = allStatuses
          .flat()
          .filter(
            (status) =>
              status.invited &&
              creators.length > 0 &&
              status.id === creators[0].id
          );

        setCreatorsInvitedStatus(flattenedStatuses);
      } catch (error) {
        setCreatorsInvitedStatus([]);
      }
    };

    if (campaignsAcceptingProposals?.length && creators.length) {
      fetchInvitationStatuses();
    } else {
      setCreatorsInvitedStatus([]);
    }
  }, [campaignsAcceptingProposals, creators]);

  useEffect(() => {
    if (!isCampaignsLoading) {
      setIsLoading(false);
    }
  }, [isCampaignsLoading]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const creatorIds = creators.map((creator) => creator.id);
    try {
      const result = await inviteCreatorsToCampaign.invite({
        creatorIds,
        campaignId: selectedOption?.id ?? '',
      });
      if (result) {
        onSubmitSuccess(selectedOption?.id);
        raiseCreatorInvitedToCampaignSuccess(creatorIds.length);
      }
    } catch {
      raiseFlashMessageError();
    }
    setIsSubmitting(false);
  };

  const mappedOptions = useMemo(
    () =>
      (campaignsAcceptingProposals || []).map((campaign: CampaignListItem) => {
        const isInvited = creatorsInvitedStatus.find(
          (status) => status.campaignId === campaign.id
        );
        return {
          id: campaign.id,
          label: campaign.name,
          value: {
            isInvited: !!isInvited,
          },
          disabled: !!isInvited,
        };
      }),
    [campaignsAcceptingProposals, creatorsInvitedStatus]
  );

  const selectedCampaign = useMemo(() => {
    return (
      mappedOptions.find((option) => option.id === selectedCampaignId) || null
    );
  }, [mappedOptions, selectedCampaignId]);

  const [selectedOption, setSelectedOption] = useState<Option | null>(
    selectedCampaign
  );

  useEffect(() => {
    if (isLoading) return;
    // auto select the only option if there is only one
    if (mappedOptions.length === 1) {
      setSelectedOption(mappedOptions[0]);
    }
    if (mappedOptions.length === 0) {
      setIsNoActiveCampaignsModalOpen(true);
      setHasActiveCampaigns(false);
    } else {
      setHasActiveCampaigns(true);
    }
  }, [mappedOptions, setHasActiveCampaigns, isLoading]);

  const handleChange = (_: React.SyntheticEvent, value: Option) => {
    setSelectedOption(value);
  };

  if (isNoActiveCampaignsModalOpen) {
    return null;
  }

  return (
    <Stack>
      <ContentContainer>
        <AutocompleteInput
          placeholder={translate(`${TRANSLATION_PREFIX}.dropdown-placeholder`)}
          options={mappedOptions}
          selectedOptions={selectedOption}
          onChange={handleChange}
          disableCloseOnSelect
          renderOption={renderOption}
        />
        <Box className={styles.warningContainer}>
          <Body
            className={styles.warningText}
            size="lg"
            dangerouslySetInnerHTML={{
              __html: translate(`${TRANSLATION_PREFIX}.invite-action-warning`),
            }}
          />
        </Box>
      </ContentContainer>
      <SubmitButtonContainer>
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          disabled={!selectedOption || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          {translate(`${TRANSLATION_PREFIX}.submit-button`)}
        </Button>
      </SubmitButtonContainer>
    </Stack>
  );
}

export default AddToCampaign;
