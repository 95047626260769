import React, {useRef} from 'react';
import {v4 as uuid} from 'uuid';
import translate from '@/utils/translate';
import {
  PreferredCreatorAgeDetail,
  PreferredCreatorGender,
  PreferredCreatorLocation,
  PreferredCreatorSpecialty,
} from '@/types/campaign';
import locationFetchers from '@/api/fetchers/location';
import {ErrorMessages} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefFieldInput from '@/views/campaigns/brief/components/brief-field-input';
import BriefItem from '@/views/campaigns/brief/components/campaign-brief/components/brief-item';
import Divider from '@/views/campaigns/brief/components/campaign-brief/components/divider';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {
  SaveCampaign,
  SectionProps,
} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {
  DEFAULT_AGE_RANGE,
  DEFAULT_SAVE_CAMPAIGN_DELAY,
  GENDER_OPTIONS,
  IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY,
  IMMEDIATE_SAVE_CAMPAIGN_IF_CHANGED_DELAY,
} from '@/views/campaigns/brief/constants';
import {mapFetchedLocationData} from '@/views/campaigns/brief/utils/mapLocationData';
import LocationAutoComplete, {
  LocationOption,
  LocationValue,
} from '@/components/location-auto-complete/LocationAutoComplete';
import Select from '@/components/select';
import {SELECT_ALL_ID} from '@/components/select/multiple-select/MultipleSelect';
import TagSelector from '@/components/tag-selector';
import useCreatorsMetadataQuery from '@/hooks/queries/use-creators-metadata-query';
import {LocationType} from '@/enums/locationType';
import BriefFieldHelperPopper from '../../../brief-field-helper-popper';
import styles from './CreatorDetails.module.scss';

type GenderOption = {
  id: string;
  name: string;
};

const TRANSLATION_PREFIX = 'components.campaign-brief.sections.creator-details';

const FOLLOWER_COUNT_OPTIONS = [
  {value: '1000-20000', label: '1K - 20K'},
  {value: '20000-150000', label: '20K - 150K'},
  {value: '150000-350000', label: '150K - 350K'},
  {value: '350000', label: '350K+'},
];

const numberFormatter = Intl.NumberFormat('en', {notation: 'compact'});

type EditableCreatorDetailProps = {
  testID?: string;
  title?: string;
  editComponent: React.ReactNode;
  disabled: boolean;
};

function EditableCreatorDetail(props: EditableCreatorDetailProps) {
  const {testID, title, editComponent, disabled} = props;
  const getTitle = () => {
    return (
      <label className={`${styles.title} ${disabled ? styles.disabled : ''}`}>
        {title}
      </label>
    );
  };

  return (
    <div className={styles.editableCreatorDetail}>
      <div className={styles.editableCreatorDetailContent}>
        {getTitle()}
        {editComponent}
      </div>
    </div>
  );
}

type PreferredLocationProps = {
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number,
    triggerShowFormErrors?: boolean
  ) => void;
  preferredCreatorLocations: PreferredCreatorLocation[];
  editable?: boolean;
};

function PreferredLocation(props: PreferredLocationProps) {
  const {updateBriefForm, editable, preferredCreatorLocations} = props;
  const [locationQuery, setLocationQuery] = React.useState('');
  const [locationOptions, setLocationOptions] = React.useState<
    LocationOption[]
  >([]);

  const handleLocationInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length > 0) {
      setLocationQuery(event.target.value);
      locationFetchers.locations(event.target.value).then((options) => {
        setLocationOptions(
          options.map((option) => ({
            ...option,
            value: mapFetchedLocationData(option),
          }))
        );
      });
    }
  };

  const handleLocationChange = (locations: LocationOption[]) => {
    updateBriefForm?.(
      'preferredCreatorLocations',
      locations.map((location) => location.value),
      'preferredCreatorLocations',
      DEFAULT_SAVE_CAMPAIGN_DELAY
    );
  };

  interface ExtendedLocationOption extends LocationOption {
    value: LocationValue & {type: LocationType} & {name?: string};
  }

  const isLocationOptionEqualToSelectedLocation = (
    optionLocation: ExtendedLocationOption,
    preferredLocation: ExtendedLocationOption
  ) => {
    if (!(optionLocation.value.type === preferredLocation.value.type)) {
      return false;
    }
    if (optionLocation.id === preferredLocation.id) {
      return true;
    }

    switch (optionLocation.value.type) {
      case LocationType.CITY:
        return (
          optionLocation.value.city === preferredLocation.value.city &&
          optionLocation.value.state === preferredLocation.value.state &&
          optionLocation.value.country === preferredLocation.value.country
        );
      case LocationType.STATE:
        return (
          optionLocation.value.state ===
            (preferredLocation.value.state || preferredLocation.value.name) &&
          optionLocation.value.country === preferredLocation.value.country
        );
      case LocationType.COUNTRY:
        return (
          optionLocation.value.country ===
            (preferredLocation.value.country || preferredLocation.value.name) &&
          optionLocation.value.state === preferredLocation.value.state &&
          optionLocation.value.city === preferredLocation.value.city
        );
      default:
        return false;
    }
  };

  const selectedOptions = preferredCreatorLocations.map((location) => ({
    id: location.id || '',
    value: location,
    localeLabelKey:
      location.city ||
      location.state ||
      location.name ||
      location.country ||
      '',
  }));
  return (
    <EditableCreatorDetail
      testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__LOCATION_INPUT}
      title={translate(`Preferred location (Optional)`)}
      disabled={!editable}
      editComponent={
        <div className={styles.locationContainer}>
          <LocationAutoComplete
            disabled={!editable}
            isOptionEqualToValue={(option, value) =>
              isLocationOptionEqualToSelectedLocation(
                option as ExtendedLocationOption,
                value as ExtendedLocationOption
              )
            }
            onInputChange={handleLocationInputChange}
            loading={locationQuery.length > 0}
            onChange={handleLocationChange}
            options={locationOptions}
            selectedOptions={selectedOptions}
            placeholder={translate(`Select a country, state and city`)}
          />
        </div>
      }
    />
  );
}

type PreferredSpecialtyProps = {
  editable?: boolean;
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number,
    triggerShowFormErrors?: boolean
  ) => void;
  preferredCreatorSpecialties?: PreferredCreatorSpecialty[] | null;
};

function PreferredSpecialty(props: PreferredSpecialtyProps) {
  const {updateBriefForm, editable, preferredCreatorSpecialties} = props;
  const {
    data: metadata,
    isFetched: isMetadataFetched,
    isError: isMetadataError,
  } = useCreatorsMetadataQuery();

  const specialties = React.useMemo(() => {
    if (!metadata?.categories) return [];
    return metadata.categories.map((category) => ({
      id: category.code,
      label: category.name,
    }));
  }, [metadata]);

  const selectedSpecialties =
    preferredCreatorSpecialties?.map((specialty) => specialty.name || '') || [];
  return (
    <div className={styles.row}>
      <div className={styles.column}>
        <EditableCreatorDetail
          testID={
            CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__PREFERRED_SPECIALTY_INPUT
          }
          title={translate(`Content category (Optional)`)}
          disabled={!editable}
          editComponent={
            <TagSelector
              readOnly={!editable}
              disabled={!editable}
              testID={
                CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__PREFERRED_SPECIALTY_INPUT
              }
              multiple
              hideSelectedOptions
              addChipLabel={translate(`Add Category`)}
              selectedValueIds={selectedSpecialties}
              options={specialties}
              onChange={(selection) =>
                updateBriefForm?.(
                  'preferredCreatorSpecialties',
                  specialties
                    .filter((option: any) => selection.includes(option.id))
                    .map((option: any) => ({
                      ...option,
                      name: option.label,
                    })),
                  'preferredCreatorSpecialties',
                  DEFAULT_SAVE_CAMPAIGN_DELAY
                )
              }
            />
          }
        />
      </div>
    </div>
  );
}

type DemographicsProps = {
  editable?: boolean;
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number,
    triggerShowFormErrors?: boolean
  ) => void;
  unlocksAt: number | null;
  locksAbove: number | null;
  preferredCreatorGenders?: PreferredCreatorGender[] | null;
  preferredCreatorAgeDetail?: PreferredCreatorAgeDetail | null;
  saveCampaign?: SaveCampaign;
  errors?: ErrorMessages;
  sectionsToHide?: Record<string, boolean>;
  campaignId: string;
  preferredCreatorGendersKey: string;
  preferredCreatorGendersValue: GenderOption[];
};

function Demographics(props: DemographicsProps) {
  const {
    editable,
    updateBriefForm,
    unlocksAt,
    locksAbove,
    preferredCreatorGenders,
    preferredCreatorAgeDetail,
    saveCampaign,
    errors,
    sectionsToHide,
    campaignId,
    preferredCreatorGendersKey,
    preferredCreatorGendersValue,
  } = props;
  const getFollowersCountInput = () => {
    const isDisabled = !editable;

    function getSelectedFollowersCount(): string {
      if (unlocksAt && locksAbove) {
        return `${unlocksAt}-${locksAbove}`;
      }
      if (unlocksAt && !locksAbove) {
        return `${unlocksAt}`;
      }
      return '';
    }
    return (
      <Select
        testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__FOLLOWERS_COUNT_INPUT}
        containerClassName={styles.selectContainer}
        immediateOnChange
        disabled={isDisabled}
        options={FOLLOWER_COUNT_OPTIONS.map((option) => ({
          ...option,
          id: option.value,
        }))}
        variant="onboarding"
        selectedValueIds={[getSelectedFollowersCount()]}
        checkmarkRadioButtonClassName={styles.checkmarkRadioButtonClassName}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={(value: string) => {
          let newUnlocksAt;
          let newLocksAbove;
          if (value.includes('-')) {
            const [min, max] = value.split('-');
            newUnlocksAt = min;
            newLocksAbove = max;
          } else {
            newUnlocksAt = value;
            newLocksAbove = null;
          }
          updateBriefForm?.('unlocksAt', newUnlocksAt, 'unlocksAt');
          updateBriefForm?.(
            'locksAbove',
            newLocksAbove,
            'locksAbove',
            IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY
          );
        }}
      />
    );
  };

  const getGenderInput = () => {
    const isDisabled = !editable;

    function getSelectedGendersIds(): string[] {
      return (
        preferredCreatorGenders?.map(
          (preferredCreatorGender) => preferredCreatorGender.name || ''
        ) || []
      );
    }

    return (
      <Select
        testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__GENDER_INPUT}
        multiple
        containerClassName={styles.selectContainer}
        disabled={isDisabled}
        immediateOnChange
        withSelectAllOption
        selectAllLabel={translate('genders.all')}
        options={GENDER_OPTIONS.map((option) => ({
          ...option,
          id: option.value,
          label: translate(option.labelLocaleKey),
        }))}
        variant="onboarding"
        renderSelectedLabel={(options) => {
          if (options.find((option) => option.id === SELECT_ALL_ID)) {
            return translate('genders.all');
          }
          if (options.length === 0) {
            return translate(`${TRANSLATION_PREFIX}.select-genders`);
          }
          return options.map((option) => option.label).join(', ');
        }}
        selectedValueIds={getSelectedGendersIds()}
        onClose={() =>
          saveCampaign?.({
            campaignId,
            fieldName: preferredCreatorGendersKey,
            fieldValue: JSON.stringify(preferredCreatorGendersValue),
          })
        }
        checkmarkRadioButtonClassName={styles.checkmarkRadioButtonClassName}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={(value: string[]) => {
          let newSelections = value;
          if (value.length === 0) {
            newSelections = GENDER_OPTIONS.map((option) => option.value);
          }
          updateBriefForm?.(
            'preferredCreatorGenders',
            newSelections.map((newSelection) => ({
              id: `temp-${uuid()}`,
              name: newSelection,
            })),
            'preferredCreatorGenders'
          );
        }}
      />
    );
  };

  const getAgeRangeInput = () => {
    const isDisabled = !editable;
    function getErrors(key: string, returnErrorsIfBothValuesAreFilled = false) {
      const bothValuesAreFilled =
        preferredCreatorAgeDetail?.minAge &&
        typeof Number(preferredCreatorAgeDetail?.minAge) === 'number' &&
        preferredCreatorAgeDetail?.maxAge &&
        typeof Number(preferredCreatorAgeDetail?.maxAge) === 'number';
      switch (key) {
        case 'minAge':
          if (!preferredCreatorAgeDetail?.minAge) {
            return errors?.preferredCreatorAgeDetail;
          }
          return bothValuesAreFilled ? errors?.preferredCreatorAgeDetail : [];
        case 'maxAge':
          if (preferredCreatorAgeDetail?.maxAge === null) {
            return [];
          }
          return returnErrorsIfBothValuesAreFilled &&
            Number(preferredCreatorAgeDetail?.minAge) >=
              Number(preferredCreatorAgeDetail?.maxAge)
            ? errors?.preferredCreatorAgeDetail
            : [];
        default:
          return errors?.preferredCreatorAgeDetail;
      }
    }

    function updateBriefFormAndSave(triggeredBy: 'minAge' | 'maxAge') {
      let minAge =
        Number(preferredCreatorAgeDetail?.minAge) || DEFAULT_AGE_RANGE.min;
      let maxAge: null | number =
        Number(preferredCreatorAgeDetail?.maxAge) || DEFAULT_AGE_RANGE.max;

      if (triggeredBy === 'minAge') {
        minAge = Math.min(
          Math.max(minAge, DEFAULT_AGE_RANGE.min),
          DEFAULT_AGE_RANGE.max
        );
        maxAge = minAge > maxAge ? minAge : maxAge;
        if (maxAge >= DEFAULT_AGE_RANGE.max) {
          maxAge = null;
        }
      } else if (triggeredBy === 'maxAge') {
        maxAge =
          maxAge >= DEFAULT_AGE_RANGE.max
            ? null
            : Math.max(maxAge, DEFAULT_AGE_RANGE.min);
        minAge =
          typeof maxAge === 'number' && maxAge < minAge ? maxAge : minAge;
      }

      updateBriefForm?.(
        'preferredCreatorAgeDetail',
        {minAge, maxAge},
        'preferredCreatorAgeDetail',
        IMMEDIATE_SAVE_CAMPAIGN_IF_CHANGED_DELAY
      );
    }

    return (
      <div className={styles.row}>
        <div className={styles.column}>
          <ValidatedField errors={getErrors('minAge')}>
            <BriefFieldInput
              testID={
                CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__AGE_RANGE_INPUT_MIN
              }
              disabled={isDisabled}
              type="number"
              label={translate(`${TRANSLATION_PREFIX}.min-age`)}
              inputProps={{
                value: preferredCreatorAgeDetail?.minAge || '',
                min: DEFAULT_AGE_RANGE.min,
                max: DEFAULT_AGE_RANGE.max,
                onBlur: () => updateBriefFormAndSave('minAge'),
              }}
              value={preferredCreatorAgeDetail?.minAge}
              onInputChange={(value: number | null | string) => {
                updateBriefForm?.(
                  'preferredCreatorAgeDetail',
                  {
                    ...preferredCreatorAgeDetail,
                    minAge: value,
                  },
                  'preferredCreatorAgeDetail',
                  undefined,
                  true
                );
              }}
              hasErrors={!!getErrors('minAge')?.length}
            />
          </ValidatedField>
        </div>
        <div className={styles.column}>
          <ValidatedField errors={getErrors('maxAge')}>
            <BriefFieldInput
              testID={
                CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__AGE_RANGE_INPUT_MAX
              }
              type="number"
              disabled={isDisabled}
              label={translate(`${TRANSLATION_PREFIX}.max-age`)}
              endIcon={
                preferredCreatorAgeDetail?.maxAge === null ? (
                  <label
                    className={`${styles.plus} ${
                      isDisabled ? styles.disabled : ''
                    }`}
                  >
                    +
                  </label>
                ) : undefined
              }
              inputProps={{
                value:
                  preferredCreatorAgeDetail?.maxAge === null
                    ? DEFAULT_AGE_RANGE.max
                    : preferredCreatorAgeDetail?.maxAge,
                min: DEFAULT_AGE_RANGE.min,
                max: DEFAULT_AGE_RANGE.max,
                onBlur: () => updateBriefFormAndSave('maxAge'),
              }}
              value={
                preferredCreatorAgeDetail?.maxAge === null
                  ? DEFAULT_AGE_RANGE.max
                  : preferredCreatorAgeDetail?.maxAge
              }
              onInputChange={(value: number | null | string) => {
                updateBriefForm?.(
                  'preferredCreatorAgeDetail',
                  {
                    ...preferredCreatorAgeDetail,
                    maxAge: value,
                  },
                  'preferredCreatorAgeDetail',
                  undefined,
                  true
                );
              }}
              hasErrors={!!getErrors('maxAge', true)?.length}
            />
          </ValidatedField>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.demographicsContainer}>
      <div className={styles.row}>
        {!sectionsToHide?.followersCount ? (
          <EditableCreatorDetail
            testID={
              CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__FOLLOWERS_COUNT_INPUT
            }
            title={translate(`${TRANSLATION_PREFIX}.followers-count`)}
            disabled={!editable}
            editComponent={getFollowersCountInput()}
          />
        ) : null}
        <EditableCreatorDetail
          title={translate(`${TRANSLATION_PREFIX}.gender`)}
          disabled={!editable}
          editComponent={getGenderInput()}
        />
      </div>
      <div className={styles.ageRangeContainer}>
        <EditableCreatorDetail
          testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__AGE_RANGE}
          disabled={!editable}
          editComponent={getAgeRangeInput()}
        />
      </div>
    </div>
  );
}

type IdealCreatorProps = {
  editable?: boolean;
  briefFieldManager: any;
  saveCampaign?: SaveCampaign;
  campaign: any;
  viewPresentationId?: string;
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number,
    triggerShowFormErrors?: boolean
  ) => void;
};

function IdealCreator(props: IdealCreatorProps) {
  const {
    editable,
    briefFieldManager,
    saveCampaign,
    campaign,
    viewPresentationId,
    updateBriefForm,
  } = props;
  const onIdealCreatorChangeRef = useRef<any>(null);
  onIdealCreatorChangeRef.current = (value: string) =>
    updateBriefForm?.(
      `campaignPaidGigBriefItems.${briefFieldManager.fields.idealCreatorBriefItem?.index}.contentHtml`,
      value,
      'idealCreatorBriefItem'
    );

  if (!briefFieldManager.fields.idealCreatorBriefItem) {
    return null;
  }

  return (
    <BriefFieldHelperPopper
      fieldName={briefFieldManager.fields.idealCreatorBriefItem?.agentKey}
      fieldValue={
        briefFieldManager.fields.idealCreatorBriefItem?.value?.contentHtml
      }
      onChange={onIdealCreatorChangeRef.current}
      saveCampaign={saveCampaign}
      disabled={!editable}
      campaignId={campaign.id}
      viewPresentationId={viewPresentationId}
    >
      {(childrenProps) => (
        <BriefItem
          {...childrenProps}
          testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__IDEAL_CREATOR_INPUT}
          optional
          briefItem={briefFieldManager.fields.idealCreatorBriefItem?.value}
          placeholder={translate(
            `${TRANSLATION_PREFIX}.ideal-creator-placeholder`
          )}
          editable={editable}
          onChange={onIdealCreatorChangeRef.current}
          saveCampaign={saveCampaign}
          campaignId={campaign.id}
          fieldName={briefFieldManager.fields.idealCreatorBriefItem?.agentKey}
        />
      )}
    </BriefFieldHelperPopper>
  );
}

function CreatorDetails(props: SectionProps) {
  const {
    updateBriefForm,
    editable,
    saveCampaign,
    briefFieldManager,
    errors,
    campaign,
    viewPresentationId,
  } = props;

  return (
    <div className={styles.container}>
      <Demographics
        editable={editable}
        updateBriefForm={updateBriefForm}
        unlocksAt={briefFieldManager.fields.unlocksAt.value}
        locksAbove={briefFieldManager.fields.locksAbove.value}
        preferredCreatorGenders={
          briefFieldManager.fields.preferredCreatorGenders.value
        }
        preferredCreatorAgeDetail={
          briefFieldManager.fields.preferredCreatorAgeDetail.value
        }
        saveCampaign={saveCampaign}
        errors={errors}
        sectionsToHide={briefFieldManager.fields.sectionsToHide.value}
        campaignId={campaign.id}
        preferredCreatorGendersKey={
          briefFieldManager.fields.preferredCreatorGenders.key
        }
        preferredCreatorGendersValue={
          briefFieldManager.fields.preferredCreatorGenders.value
        }
      />
      <PreferredLocation
        editable={editable}
        updateBriefForm={updateBriefForm}
        preferredCreatorLocations={
          briefFieldManager.fields.preferredCreatorLocations.value
        }
      />
      <Divider />
      <div className={styles.idealCreatorAndSpecialtyContainer}>
        <IdealCreator
          editable={editable}
          briefFieldManager={briefFieldManager}
          saveCampaign={saveCampaign}
          campaign={campaign}
          viewPresentationId={viewPresentationId}
          updateBriefForm={updateBriefForm}
        />
        <PreferredSpecialty
          editable={editable}
          updateBriefForm={updateBriefForm}
          preferredCreatorSpecialties={
            briefFieldManager.fields.preferredCreatorSpecialties.value
          }
        />
      </div>
    </div>
  );
}

export default CreatorDetails;
