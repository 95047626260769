import {useDecision} from '@optimizely/react-sdk';
import {snakeCase} from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {Icon} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import Sentry from '@/services/sentry/Sentry';
import {EVENT_NAMES, FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import mapKeysToCase from '@/utils/mapKeysToCase';
import raiseFlashMessage, {
  raiseFlashMessageError,
} from '@/utils/raiseFlashMessage';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import {HTTP_STATUS} from '@/config/constants';
import {
  BriefData,
  CampaignProps,
  RequiredDeliverable,
  WizardFormData,
} from '@/types/campaign';
import BriefFieldManager from '@/views/campaigns/brief/brief-field-manager';
import BriefHeader from '@/views/campaigns/brief/components/brief-header';
import AutoFillBanner from '@/views/campaigns/brief/components/campaign-brief/components/auto-fill-banner';
import GuidelinesModal from '@/views/campaigns/brief/components/campaign-brief/components/guidelines-modal';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {
  AUTOFILL_LOADING_PERCENTAGE,
  CAMPAIGN_STATE,
} from '@/views/campaigns/brief/constants';
import campaignDataToAgentMessage from '@/views/campaigns/brief/utils/campaignDataToAgentMessage';
import removePseudoEmptyValues from '@/views/campaigns/brief/utils/removePseudoEmptyValues';
import Page from '@/components/page';
import useSubmitCampaignForReview from '@/hooks/mutations/use-submit-campaign-for-review';
import useUpsertCampaign from '@/hooks/mutations/use-upsert-campaign';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useCampaignQuery from '@/hooks/queries/use-campaign-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useLtxAuthToken from '@/hooks/use-ltx-auth-token';
import useNavigation from '@/hooks/use-navigation';
import useParams from '@/hooks/use-params';
import {
  CampaignBriefHelperActions,
  useCampaignBriefHelperStore,
} from '@/contexts/CampaignBriefHelperContext';
import {
  getNewCampaignFormData,
  useNewCampaignFormDataStore,
} from '@/contexts/NewCampaignContext';
import CampaignBrief from '../components/campaign-brief';
import LaunchCampaignModal from '../components/launch-campaign-modal';
import useBriefAiAgentMessages from '../hooks/use-brief-ai-agent-messages';
import useGetCampaignBrief from '../hooks/use-get-campaign-brief';
import styles from './BriefBuilderView.module.scss';

const TRANSLATION_PREFIX = 'views.campaigns.brief.builder';

function BriefBuilderView() {
  const {campaignId} = useParams();
  const {ltxAuthToken} = useLtxAuthToken();
  const brandId = useBrandId();
  const {currentBrand: brand} = useAgencyQuery({brandId});
  const [autoFillProcessId, setAutoFillProcessId] = useState('');

  const navigation = useNavigation();

  const {dispatch: campaignBriefHelperDispatch} = useCampaignBriefHelperStore();

  const {
    state: newCampaignFormDataState,
    dispatch: newCampaignFormDataDispatch,
  } = useNewCampaignFormDataStore();

  const [agentConnectionInitialized, setAgentConnectionInitialized] =
    useState(false);

  const [wizardFormData, setWizardFormData] = useState(
    getNewCampaignFormData(newCampaignFormDataState) //
  );
  const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
    FLOW_NAMES.CAMPAIGN_CREATION
  );
  const [pricingUpdatesDecision] = useDecision('pricing-updates', {
    autoUpdate: true,
  });
  const {subscription} = useSubscriptionQuery({
    brandId,
  });

  useEffect(() => {
    newCampaignFormDataDispatch({
      type: 'SET_NEW_CAMPAIGN_CAMPAIGN_ID',
      campaignId,
    });
    setViewPresentationId(uuid());
    if (getNewCampaignFormData(newCampaignFormDataState)) {
      newCampaignFormDataDispatch({
        type: 'SET_NEW_CAMPAIGN_FORM_DATA',
        formData: null,
      });
    }
    if (!campaignCreationFlow) {
      const newCampaignFlow = AnalyticsService.createFlow(
        FLOW_NAMES.CAMPAIGN_CREATION
      );
      AnalyticsService.dispatchEvent(
        eventNames.CAMPAIGN_CREATION_FLOW_STARTED,
        {
          campaign_creation_location: '',
          campaign_creation_source: '',
          flow_id: newCampaignFlow.flow_id,
          source_text: '',
          campaign_id: campaignId,
        }
      );
    }
    return () => {
      setWizardFormData(null);
      queryClient.removeQueries(['campaign', campaignId]);
    };
  }, []);

  const upsertCampaign = useUpsertCampaign({campaignId});
  const submitCampaignForReview = useSubmitCampaignForReview({campaignId});

  const [campaignBriefDraftSaved, setCampaignBriefDraftSaved] = useState(false);
  const [campaignBriefIsLoading, setCampaignBriefIsLoading] = useState(false);
  const [campaignBriefIsAutofilling, setCampaignBriefIsAutofilling] =
    useState(false);
  const [showGuidelinesModal, setShowGuidelinesModal] = useState(false);
  const [invokeAutofillRequest, setInvokeAutofillRequest] = useState<any>(null);
  const [launchCampaignData, setLaunchCampaignData] =
    useState<CampaignProps | null>(null);
  const [launchCampaignCallback, setLaunchCampaignCallback] =
    useState<any>(null);
  const [campaignBriefSubmitted, setCampaignBriefSubmitted] = useState(false);
  const [interactionEventId, setInteractionEventId] = useState<string>(uuid());
  const [interactionIdForLaunch, setInteractionIdForLaunch] = useState<string>(
    uuid()
  );
  const [viewPresentationId, setViewPresentationId] = useState<string>(uuid());

  const campaignBriefRef = useRef<any>();
  const campaignBriefContainerRef = useRef<any>();
  const draftSavedFlashMessageRef = useRef<any>();

  const [proxiedCampaignName, setProxiedCampaignName] = useState('');

  const {campaign: campaignDataFromServer, status: campaignFetchStatus}: any =
    useCampaignQuery({
      campaignId,
      enabled: !wizardFormData || campaignBriefDraftSaved,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (Number(error?.status) === HTTP_STATUS.NOT_FOUND) {
          navigation.navigate('/not-found');
        }
      },
    });

  useEffect(() => {
    setProxiedCampaignName(campaignDataFromServer?.name || '');
  }, [campaignDataFromServer.loaded]);

  const getAdditionalData = async () => {
    return {
      brand_id: brand.id,
      campaign_id: campaignId,
    };
  };

  const {
    rawMessages,
    rawHelperMessages,
    setRawHelperMessages,
    sendMessage,
    sendJsonMessage,
    initializeAgent,
    initialBriefGenerationCompleted,
    websocketReady,
    websocketClosed,
    autofillError,
    totalFieldsToAutofill,
    autofilledFieldsCount,
  } = useBriefAiAgentMessages(getAdditionalData, true);

  useEffect(() => {
    if (autofillError) {
      AnalyticsService.dispatchEvent(
        EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_ENDED,
        {
          flow_id: campaignCreationFlow.flow_id,
          process_id: autoFillProcessId,
          result: 'failed',
          error: 'ai_agent_error',
          process_details: '',
        }
      );
    }
  }, [autofillError]);

  useEffect(() => {
    if (initialBriefGenerationCompleted) {
      AnalyticsService.dispatchEvent(
        EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_ENDED,
        {
          flow_id: campaignCreationFlow.flow_id,
          process_id: autoFillProcessId,
          result: 'success',
          error: null,
          process_details: '',
        }
      );
    }
  }, [initialBriefGenerationCompleted]);

  useEffect(() => {
    if (websocketClosed && campaignBriefIsAutofilling) {
      setCampaignBriefIsAutofilling(false);
    }
  }, [websocketClosed, campaignBriefIsAutofilling]);

  const onCampaignBriefFormUpdate = (campaignData: CampaignProps) => {
    queryClient.setQueryData(['campaign', campaignId], campaignData);
  };

  const saveDraftCampaign = useCallback(
    async (
      campaignData: CampaignProps,
      showDraftSavedFlashMessage = true,
      processId?: string,
      sendAnalytics = true
    ) => {
      try {
        const {data: draftCampaignData}: any = await upsertCampaign.mutateAsync(
          {
            brandId: brand.id,
            aasmState: CAMPAIGN_STATE.DRAFT,
            salesforceId: 'N/A',
            generatedByBriefAi: true,
            ...campaignData,
          }
        );
        queryClient.setQueryData(['campaign', campaignId], {
          ...draftCampaignData,
          imageUrl: campaignData.imageUrl,
          brand,
        });
        setProxiedCampaignName(draftCampaignData.name);
        if (!campaignBriefDraftSaved) {
          setCampaignBriefDraftSaved(true);
        }
        if (showDraftSavedFlashMessage) {
          draftSavedFlashMessageRef.current?.();
          draftSavedFlashMessageRef.current = raiseFlashMessage({
            status: 'success',
            message: translate(
              `${TRANSLATION_PREFIX}.campaign-saved-to-drafts`
            ),
            icon: (
              <Icon size="large" appearance="brand" name="Actions-LooksOn" />
            ),
          });
        }

        if (sendAnalytics) {
          AnalyticsService.dispatchEvent(
            EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_ENDED,
            {
              flow_id: campaignCreationFlow.flow_id,
              process_id: processId,
              result: 'success',
              error: null,
              process_details: '',
            }
          );
        }

        return draftCampaignData;
      } catch (e: any) {
        raiseFlashMessageError();
        Sentry.captureException(e);
        if (sendAnalytics) {
          AnalyticsService.dispatchEvent(
            EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_ENDED,
            {
              flow_id: campaignCreationFlow.flow_id,
              process_id: processId,
              result: 'failed',
              error: e.message,
              process_details: '',
            }
          );
        }
      }
      return campaignData;
    },
    [campaignDataFromServer]
  );

  const launchCampaign = async (
    campaignData: CampaignProps,
    updatedBrief: CampaignProps,
    subscriptionType: string
  ) => {
    let campaignCreationEndDestination = '';
    if (subscription.isFree && !pricingUpdatesDecision.enabled) {
      campaignCreationEndDestination = `/upgrade?brandId=${brandId}&redirectToCampaignAfterSubscription=${campaignId}`;
    } else if (subscription.isEssentials || pricingUpdatesDecision.enabled) {
      campaignCreationEndDestination = `/campaigns/${campaignId}/brief?brandId=${brandId}&showCampaignIsLiveModal=true`;
    }
    const processId = uuid();

    try {
      AnalyticsService.dispatchEvent(
        EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_STARTED,
        {
          flow_id: campaignCreationFlow.flow_id,
          process_source: 'draft_review',
          process_details: '',
          process_id: processId,
          interaction_id: interactionIdForLaunch,
        }
      );

      const {data: submittedCampaignData}: any =
        await submitCampaignForReview.mutateAsync({
          campaignId: campaignData.id,
          subscriptionType,
        });
      setCampaignBriefSubmitted(true);

      newCampaignFormDataDispatch({
        type: 'SET_NEW_CAMPAIGN_IS_SUBMITTED',
        isSubmitted: true,
      });

      AnalyticsService.dispatchEvent(
        EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_ENDED,
        {
          flow_id: campaignCreationFlow.flow_id,
          process_id: processId,
          result: 'success',
          error: null,
          process_details: '',
        }
      );

      AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_CREATION_FLOW_ENDED, {
        flow_id: campaignCreationFlow.flow_id,
        current_step: 'draft_review',
        campaign_creation_end_destination: campaignCreationEndDestination,
        campaign_id: campaignId,
        reason: 'success',
        campaign_details: '',
        error: '',
      });

      AnalyticsService.endFlow(FLOW_NAMES.CAMPAIGN_CREATION);
    } catch (e: any) {
      raiseFlashMessageError();
      Sentry.captureException(e);

      AnalyticsService.dispatchEvent(
        EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_ENDED,
        {
          flow_id: campaignCreationFlow.flow_id,
          process_id: processId,
          result: 'failed',
          error: e.message,
          process_details: '',
        }
      );

      return;
    }

    const briefForAgent = {
      updated_brief: campaignDataToAgentMessage(updatedBrief),
      request_id: 'launch',
      action: 'launch',
    };
    sendJsonMessage(briefForAgent);
  };

  const triggerLaunchCampaignModal = async (
    campaignData: CampaignProps,
    updatedBrief: CampaignProps
  ) => {
    setLaunchCampaignCallback(
      () => (subscriptionType: string) =>
        launchCampaign(campaignData, updatedBrief, subscriptionType)
    );
    setLaunchCampaignData(campaignData);
  };

  const onRequestAutofill = async (
    guidelines: string,
    requestId: string,
    interactionId: string
  ) => {
    const campaignBriefSource =
      campaignBriefRef?.current?.campaignBriefForm || campaignBrief;
    if (campaignBriefSource) {
      const briefForAgent = {
        updated_brief: removePseudoEmptyValues(
          campaignDataToAgentMessage(campaignBriefSource)
        ),
        action: 'autofill',
        guidelines,
        request_id: requestId,
      };
      sendJsonMessage(briefForAgent);
    }

    const newProcessId = uuid();
    setAutoFillProcessId(newProcessId);
    AnalyticsService.dispatchEvent(
      EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_STARTED,
      {
        flow_id: campaignCreationFlow.flow_id,
        process_source: 'brief_ai_autofill',
        process_details: JSON.stringify({guidelines}),
        process_id: newProcessId,
        interaction_id: interactionId,
      }
    );
  };

  const onRequestFieldChange = async (
    fieldName: keyof BriefData,
    message: string,
    requestId: string,
    interactionId: string,
    processId: string
  ) => {
    const campaignBriefSource =
      campaignBriefRef?.current?.campaignBriefForm || campaignBrief;
    let field_name = fieldName;
    const updatedBrief = removePseudoEmptyValues(
      campaignDataToAgentMessage(campaignBriefSource)
    );
    let field_value;
    if (fieldName.startsWith('required_deliverables')) {
      field_value = updatedBrief?.required_deliverables?.find(
        (deliverable: RequiredDeliverable) =>
          deliverable.id === fieldName.split('.')[1]
      );
      field_name = 'required_deliverables';
    } else {
      field_value = updatedBrief?.[fieldName];
    }

    const briefForAgent = {
      updated_brief: updatedBrief,
      action: 'helper',
      field_name,
      field_value,
      message,
      request_id: requestId,
    };
    sendJsonMessage(briefForAgent);

    AnalyticsService.dispatchEvent(
      EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_STARTED,
      {
        flow_id: campaignCreationFlow.flow_id,
        process_source: 'brief_ai_field_helper',
        process_details: JSON.stringify({
          fieldName: snakeCase(fieldName),
          message,
        }),
        process_id: processId,
        interaction_id: interactionId,
      }
    );
  };

  const {campaignBrief} = useGetCampaignBrief({
    rawMessages,
    campaignDataFromServer,
    initialData: {
      id: campaignId,
      brand,
    },
    wizardFormData: wizardFormData
      ? mapKeysToCase<WizardFormData | any>(wizardFormData, snakeCase)
      : null,
    onNewCampaignBriefCallback: async (
      newCampaignBrief,
      requestId,
      showDraftSavedFlashMessage = true
    ) => {
      const briefFieldManager = new BriefFieldManager(newCampaignBrief);
      if (campaignBriefIsAutofilling && requestId === 'autofill') {
        setCampaignBriefIsAutofilling(false);
      }
      const serializedData = await briefFieldManager.getSerializedData();
      return saveDraftCampaign(
        serializedData,
        showDraftSavedFlashMessage,
        undefined,
        false
      );
    },
  });
  const isEditable =
    campaignBrief?.state === CAMPAIGN_STATE.DRAFT && !campaignBriefSubmitted;

  useEffect(() => {
    campaignBriefHelperDispatch({
      type: CampaignBriefHelperActions.SET_WEBSOCKET_CONTROLS,
      websocketReady,
      websocketClosed,
      websocketControls: {
        rawHelperMessages,
        setRawHelperMessages,
        requestFieldChange: onRequestFieldChange,
      },
    });
  }, [
    sendMessage,
    campaignBrief,
    rawHelperMessages,
    setRawHelperMessages,
    websocketReady,
  ]);

  useEffect(() => {
    // invoke auto-fill request if it was requested before the campaign brief was saved in the PPSuite server
    if (
      campaignBrief &&
      ltxAuthToken &&
      isEditable &&
      !campaignBrief.isProcessing &&
      campaignDataFromServer?.loaded &&
      websocketReady &&
      !websocketClosed &&
      invokeAutofillRequest
    ) {
      invokeAutofillRequest();
      setInvokeAutofillRequest(null);
    }
  }, [
    ltxAuthToken,
    isEditable,
    campaignDataFromServer?.loaded,
    websocketReady,
    websocketClosed,
    invokeAutofillRequest,
  ]);

  const initializeAgentConnection = () => {
    const serializedWizardFormData = mapKeysToCase<WizardFormData | any>(
      wizardFormData,
      snakeCase
    );
    initializeAgent(serializedWizardFormData);
  };

  useEffect(() => {
    if (!campaignDataFromServer?.loaded) {
      return;
    }
    if (
      !agentConnectionInitialized &&
      ltxAuthToken &&
      (wizardFormData || campaignDataFromServer?.state === CAMPAIGN_STATE.DRAFT)
    ) {
      setAgentConnectionInitialized(true);
      initializeAgentConnection();
    }
  }, [agentConnectionInitialized, ltxAuthToken, campaignDataFromServer]);

  if (campaignFetchStatus === 'error') {
    // TODO: handle error
    return <div>ERROR FETCHING CAMPAIGN</div>;
  }

  const onAutoFillButtonClick = () => {
    setShowGuidelinesModal(true);

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_VIEW_INTERACTION, {
      flow_id: campaignCreationFlow.flow_id,
      interaction_type: 'click',
      interaction_name: 'ai_autofill_button',
      campaign_id: campaignId,
      interaction_location: 'auto_fill_banner',
      interaction_details: '',
      interaction_id: interactionEventId,
      view_presentation_id: viewPresentationId,
    });
  };

  const onGuidelinesModalClose = () => {
    setShowGuidelinesModal(false);

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_VIEW_INTERACTION, {
      flow_id: campaignCreationFlow.flow_id,
      interaction_type: 'click',
      interaction_name: 'guidelines_modal_close',
      campaign_id: campaignId,
      interaction_location: 'guidelines_modal',
      interaction_details: '',
      interaction_id: interactionEventId,
      view_presentation_id: viewPresentationId,
    });
  };

  const onAutoFillSubmit = (guidelines: string) => {
    const interactionId = uuid();
    setInteractionEventId(interactionId);

    setShowGuidelinesModal(false);
    setCampaignBriefIsAutofilling(true);
    setInvokeAutofillRequest(() => () => {
      onRequestAutofill(guidelines, 'autofill', interactionId);
    });

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_VIEW_INTERACTION, {
      flow_id: campaignCreationFlow.flow_id,
      interaction_type: 'click',
      interaction_name: 'guidelines_modal_auto_fill_submit',
      campaign_id: campaignId,
      interaction_location: 'guidelines_modal',
      interaction_details: JSON.stringify({guidelines}),
      interaction_id: interactionId,
      view_presentation_id: viewPresentationId,
    });
  };

  /*
    To better represent the progress of the autofill process, we don't want to show 100%
    until the process itself is completed, even if all fields are autofilled
   */
  const getAutofillProgressPercentage = () => {
    if (totalFieldsToAutofill === null) {
      return AUTOFILL_LOADING_PERCENTAGE.START;
    }
    if (initialBriefGenerationCompleted) {
      return AUTOFILL_LOADING_PERCENTAGE.FULLY_COMPLETED;
    }
    if (totalFieldsToAutofill === 0) {
      return AUTOFILL_LOADING_PERCENTAGE.AWAITING_FULL_COMPLETION;
    }
    const progress = (autofilledFieldsCount / totalFieldsToAutofill) * 100;
    return Math.min(
      progress,
      AUTOFILL_LOADING_PERCENTAGE.AWAITING_FULL_COMPLETION
    );
  };

  return (
    <Page
      id="brief-builder-view"
      testID="brief-builder-view"
      className={styles.container}
    >
      <GuidelinesModal
        testID={CAMPAIGN_BRIEF_TEST_IDS.GUIDELINES_MODAL}
        open={showGuidelinesModal}
        onClose={onGuidelinesModalClose}
        onSubmit={onAutoFillSubmit}
      />

      <div className={styles.briefContainer} ref={campaignBriefContainerRef}>
        <div className={styles.breadcrumbs}>
          <BriefHeader name={proxiedCampaignName} />
        </div>
        {campaignBrief ? (
          <div className={styles.bannerAndBrief}>
            <AutoFillBanner
              testID={CAMPAIGN_BRIEF_TEST_IDS.AUTO_FILL_BANNER}
              onAutofillClick={onAutoFillButtonClick}
              autofillProgressPercentage={getAutofillProgressPercentage()}
              disabled={websocketClosed || !isEditable}
              loading={campaignBriefIsAutofilling}
            />
            <CampaignBrief
              campaignBriefRef={campaignBriefRef}
              campaignBriefContainerRef={campaignBriefContainerRef}
              campaign={campaignBrief}
              onCampaignBriefFormUpdate={onCampaignBriefFormUpdate}
              editable={isEditable}
              isLoading={campaignBriefIsLoading || campaignBriefIsAutofilling}
              onSubmitForm={triggerLaunchCampaignModal}
              saveCampaign={saveDraftCampaign}
              originalCampaign={campaignBrief}
              viewPresentationId={viewPresentationId}
            />
          </div>
        ) : null}
      </div>
      <LaunchCampaignModal
        campaignData={launchCampaignData}
        brandId={brand.id}
        onSubmit={launchCampaignCallback}
        onClose={() => {
          setLaunchCampaignCallback(null);
          setLaunchCampaignData(null);
        }}
        interactionId={interactionIdForLaunch}
        viewPresentationId={viewPresentationId}
      />
    </Page>
  );
}

function BriefBuilderViewReMounter() {
  const {campaignId} = useParams();

  return <BriefBuilderView key={campaignId} />;
}

export default BriefBuilderViewReMounter;
