import {Metadata} from '@/types/models/search-creators/searchCreators';
import creatorsFetchers from '@/api/fetchers/creators';
import useDataQuery from '@/hooks/use-data-query';

function useCreatorsMetadataQuery() {
  const response = useDataQuery<Metadata>({
    queryKey: ['searchCreatorsMetadata'],
    queryFn: creatorsFetchers.metadata,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const {data: metadata}: {data: Metadata | undefined} = response;
  return {
    data: metadata,
    isError: response.isError,
    isLoading: response.isLoading,
    isFetched: response.isFetched,
    refetch: response.refetch,
  };
}

export default useCreatorsMetadataQuery;
