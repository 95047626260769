import React from 'react';
import {Stack} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';
import ContentStudioPaths from '@/routes/content-studio/contentStudioRoutesList';
import Toggle from '../toggle/Toggle';
import MenuItem from './menu-item/MenuItem';
import {menuItems} from './menuItems';

function Menu() {
  return (
    <Stack
      direction="column"
      width="256px"
      alignItems="center"
      gap="8px"
      paddingY="16px"
      bgcolor={designSystemToken('semantic.bg.secondary')}
      borderRight={`1px solid ${designSystemToken('semantic.bg.tertiary')}`}
    >
      <MenuItem item={menuItems[ContentStudioPaths.MAIN]} />
      <MenuItem item={menuItems[ContentStudioPaths.ASSETS]} />
    </Stack>
  );
}

export default Menu;
