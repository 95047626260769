import {v4 as uuid} from 'uuid';
import {FetchedLocation, FetchedLocationValue} from '@/api/fetchers/location';
import {
  LocationOption,
  LocationValue,
} from '@/components/location-auto-complete/LocationAutoComplete';
import {LocationType} from '@/enums/locationType';

function getLocationType(location: LocationValue) {
  if (location.city) {
    return LocationType.CITY;
  }
  if (location.state) {
    return LocationType.STATE;
  }
  return LocationType.COUNTRY;
}

function getFetchedLocationType(location: FetchedLocationValue) {
  if (location.place) {
    return LocationType.CITY;
  }
  if (location.region) {
    return LocationType.STATE;
  }
  return LocationType.COUNTRY;
}

export function mapLocationData(location: LocationOption) {
  return {
    id: location.id || uuid(),
    name: location.value.city || location.value.state || location.value.country,
    city: location.value.city,
    state: location.value.state,
    country: location.value.country,
    api_uid: location.id,
    type: getLocationType(location.value),
    latitude: location.value.latitude,
    longitude: location.value.longitude,
  };
}

export function mapFetchedLocationData(location: FetchedLocation) {
  return {
    id: location.id || uuid(),
    name:
      location.value.place || location.value.region || location.value.country,
    city: location.value.place,
    state: location.value.region,
    country: location.value.country,
    api_uid: location.id,
    type: getFetchedLocationType(location.value),
    latitude: location.value.latitude,
    longitude: location.value.longitude,
  };
}
