import {snakeCase, kebabCase} from 'lodash';
import {v4 as uuid} from 'uuid';
import mapKeysToCase from '@/utils/mapKeysToCase';
import {Campaign} from '@/types/campaign';
import api from '../index';

const INCLUDED_KEYS = [
  'campaignPaidGigBriefItems',
  'campaignBriefDeliverableItems',
  'campaignBriefCaptionGuidelines',
  'questions',
  'preferredCreatorAgeDetail',
  'preferredCreatorGenders',
  'preferredCreatorCities',
  'preferredCreatorStates',
  'preferredCreatorCountries',
  'preferredCreatorCapabilities',
  'preferredCreatorSpecialties',
  'creatorGroups',
  'contentAgreementTerms',
  'verticalTags',
];

const RELATIONSHIP_KEYS = INCLUDED_KEYS;

const RELATIONSHIP_TYPE_MAPPER = {
  questions: 'text_questions',
} as {[key: string]: string};

const DEFAULT_UPSERT_CAMPAIGN_INCLUDE = [
  'campaign_paid_gig_brief_items',
  'creator_groups',
  'questions',
  'preferred_creator_age_detail',
  'preferred_creator_capabilities',
  'preferred_creator_cities',
  'preferred_creator_countries',
  'preferred_creator_genders',
  'preferred_creator_profile',
  'preferred_creator_specialties',
  'preferred_creator_states',
  'vertical_tags',
  'campaign_brief_deliverable_items',
  'campaign_brief_caption_guidelines',
  'content_agreement_terms',
];

const caseMapper = {
  preferredCreatorAgeDetail: kebabCase,
} as {[key: string]: (key: string) => string};

const getType = (key: string) =>
  RELATIONSHIP_TYPE_MAPPER[key] || (caseMapper[key] || snakeCase)(key);

const getTypeKey = (key: string) =>
  RELATIONSHIP_TYPE_MAPPER[key] || snakeCase(key);

/**
 * @description assign unique ids to relationships if none already present
 * @param campaignData
 */
function getCampaignDataRelationshipsWithIds(campaignData: any) {
  const relationships = RELATIONSHIP_KEYS.reduce(
    (acc: any, relationshipKey) => {
      if (campaignData[relationshipKey]) {
        if (Array.isArray(campaignData[relationshipKey])) {
          acc[relationshipKey] = campaignData[relationshipKey].map(
            (relationshipItem: any) => ({
              ...relationshipItem,
              id: relationshipItem.id || uuid(),
            })
          );
        } else {
          const relationshipItem = campaignData[relationshipKey];
          acc[relationshipKey] = {
            ...campaignData[relationshipKey],
            id: relationshipItem.id || uuid(),
          };
        }
      }
      return acc;
    },
    {}
  );

  return {
    ...campaignData,
    ...relationships,
  };
}

/**
 * @description get JSONAPI compliant attributes for a campaign item
 * @param item
 */
function getItemAttributes(item: any) {
  const itemAttributes = Object.keys(item).reduce((acc: any, key) => {
    if (key === 'id') {
      // skip
    } else if (typeof item[key] !== 'object' || item[key] === null) {
      acc[key] = item[key];
    }
    return acc;
  }, {});
  return mapKeysToCase(itemAttributes, snakeCase);
}

/**
 * @description get JSONAPI compliant included data for a campaign
 * @param campaignData
 */
function getIncludedData(campaignData: any) {
  return INCLUDED_KEYS.reduce((acc: any, includedKey) => {
    if (campaignData[includedKey]) {
      if (Array.isArray(campaignData[includedKey])) {
        acc.push(
          ...campaignData[includedKey].map((includedItem: any) => ({
            attributes: {
              ...getItemAttributes(includedItem),
            },
            id: includedItem.id,
            type: getType(includedKey),
          }))
        );
      } else {
        const includedItem = campaignData[includedKey];
        acc.push({
          attributes: {...getItemAttributes(includedItem)},
          id: includedItem.id,
          type: getType(includedKey),
        });
      }
    }

    return acc;
  }, []);
}

/**
 * @description get JSONAPI compliant relationship data for a campaign
 * @param key
 * @param item
 */
function getItemRelationshipData(key: string, item: any) {
  return {
    id: item.id,
    type: getType(key),
  };
}

/**
 * @description get JSONAPI compliant relationships data for a campaign
 * @param campaignData
 */
function getRelationshipsData(campaignData: any) {
  return RELATIONSHIP_KEYS.reduce((acc: any, relationshipKey) => {
    if (campaignData[relationshipKey]) {
      if (Array.isArray(campaignData[relationshipKey])) {
        acc[getTypeKey(relationshipKey)] = {
          data: campaignData[relationshipKey].map((relationshipItem: any) =>
            getItemRelationshipData(relationshipKey, relationshipItem)
          ),
        };
      } else {
        const relationshipItem = campaignData[relationshipKey];
        acc[getTypeKey(relationshipKey)] = {
          data: getItemRelationshipData(relationshipKey, relationshipItem),
        };
      }
    }
    return acc;
  }, {});
}

const campaignUpdaters = {
  upsertCampaign: async (campaignData: Campaign) => {
    return api.put(
      `/brands/${campaignData.brandId}/campaigns`,
      {
        id: campaignData.id || uuid(),
        affiliateType: campaignData.affiliateType || null,
        blogsToUpload: campaignData.blogsToUpload || 0,
        budgetRange: campaignData.budgetRange || null,
        estimatedBudget: campaignData.estimatedBudget || null,
        deliverablesType: campaignData.deliverablesType || null,
        descriptionHtml: campaignData.descriptionHtml || null,
        estimatedFulfillmentValue:
          campaignData.estimatedFulfillmentValue || null,
        fixedCostForCreations: campaignData.fixedCostForCreations ?? null,
        image: campaignData.image || null,
        isDraft: campaignData.isDraft ?? true,
        locksAbove: campaignData.locksAbove || null,
        minimumChildContentSubmissionCount:
          campaignData.minimumChildContentSubmissionCount || null,
        mixedMediaToUpload: campaignData.mixedMediaToUpload || 0,
        name: campaignData.name,
        network: campaignData.network || null,
        photosToUpload: campaignData.photosToUpload || 0,
        requiredFeedPostsCount: campaignData.requiredFeedPostsCount || 0,
        requiredReelsPostsCount: campaignData.requiredReelsPostsCount || 0,
        requiredStoryPostsCount: campaignData.requiredStoryPostsCount || 0,
        suggestedCostForCreations:
          campaignData.suggestedCostForCreations || null,
        suggestedCostForCreationsMin:
          campaignData.suggestedCostForCreationsMin || null,
        swapMatchKeywords: campaignData.swapMatchKeywords || [],
        unlocksAt: campaignData.unlocksAt || 0,
        urlToPromote: campaignData.urlToPromote || null,
        videosToUpload: campaignData.videosToUpload || 0,
        aasmState: campaignData.aasmState || null,
        salesforceId: campaignData.salesforceId || null,
        daysToPostAfterDeliverableFulfilled:
          campaignData.daysToPostAfterDeliverableFulfilled || 0,
        generatedByBriefAi: campaignData.generatedByBriefAi,
        contentAgreementTimePeriodInMonths:
          campaignData.contentAgreementTimePeriodInMonths || null,
      },
      {
        params: {
          include: DEFAULT_UPSERT_CAMPAIGN_INCLUDE,
        },
        customSerializer: (data: any) => {
          const serializedData = {
            ...data,
          };

          serializedData.id = serializedData.data.id;
          serializedData.brand_id = serializedData.data.attributes.brand_id;
          serializedData.type = 'campaigns';
          delete serializedData.data.attributes.brand_id;

          const campaignDataRelationshipsWithIds =
            getCampaignDataRelationshipsWithIds(campaignData);
          const included = getIncludedData(campaignDataRelationshipsWithIds);
          if (included.length) {
            serializedData.included = included;
          }
          const relationships = getRelationshipsData(
            campaignDataRelationshipsWithIds
          );
          if (Object.keys(relationships).length) {
            serializedData.data.relationships = relationships;
          }

          return serializedData;
        },
      }
    );
  },
  submitForReview: async ({
    campaignId,
    subscriptionType,
  }: {
    campaignId: string;
    subscriptionType: string;
  }) => {
    const subscriptionTypeToEndpoint: Record<string, string> = {
      platform_free: 'submit_starter_ai_brief',
      essentials: 'submit_essentials_ai_brief',
    };

    const requiredEndpoint =
      subscriptionTypeToEndpoint[subscriptionType] || 'submit_for_review';

    return api.put(`/campaigns/${campaignId}/${requiredEndpoint}`, null);
  },
  inviteCreatorToCampaign: async ({
    campaignId,
    userId,
  }: {
    campaignId: string;
    userId: string;
  }) => {
    return api.put(
      `/campaigns/${campaignId}/campaign_creator_matches/${userId}/invite`,
      null
    );
  },
};

export default campaignUpdaters;
