import {
  CombinedPostAnalysis,
  CombinedPostAnalysisFailure,
} from '@/types/creatorSafetyReport';

export default function getIsPostAnalysis(
  post: CombinedPostAnalysis | CombinedPostAnalysisFailure
): post is CombinedPostAnalysis {
  return 'mediaAnalysisResult' in post;
}
