/**
 * Format a number in a compact format
 * examples:
 * 1000 -> 1K
 * 1000000 -> 1M
 * 1000000000 -> 1B
 * @param value
 */
function compactNumberFormat(value: number): string {
  if (!value) {
    return '';
  }
  const formatter = Intl.NumberFormat('en', {notation: 'compact'});
  return formatter.format(value);
}

export default compactNumberFormat;
