import React, {useMemo} from 'react';
import {designSystemToken, Tag} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import formattedPercentage from '@/utils/formattedPrecentage';
import numberAbbreviation from '@/utils/numberAbbreviation';
import translate from '@/utils/translate';
import {SuggestedCreator} from '@/types/suggestedCreator';
import Conditional from '@/components/conditional';
import IconStat from '@/components/icon-stat';
import MosaicGallery from '@/components/mosaic-gallery';
import {
  CREATOR_CARD_GALLERY_SETTINGS,
  SMALL_SCREEN_CONTAINER_WIDTH_THRESHOLD,
} from '../../utils/constants';
import getGalleryLayout from '../../utils/getGalleryLayout';
import CreatorInfo from '../creator-info';
import CreatorSkills from '../creator-skills';
import CreatorStats from '../creator-stats';
import styles from './CreatorCard.module.scss';

interface CreatorCardProps {
  brandId: string;
  creatorProfile: SuggestedCreator;
  network?: string | null;
  actions?: React.ReactNode;
  containerWidth: number;
  onSocialNetworkClick?: (network: string) => void;
  isAddedToList?: boolean;
  onAddToListClick?: () => void;
  creatorBadgeProps?: {
    title: string;
    subtitle: string;
    icon: IconName;
  };
}

function CreatorTags(props: {healthScore: string | null}) {
  const {healthScore} = props;

  const renderTag = (tag: string) => (
    <span className={styles.tagContainer}>
      <Tag
        text={tag}
        backgroundColor={designSystemToken('semantic.bg.black-secondary')}
        textColor={designSystemToken('semantic.fg.white')}
      />
    </span>
  );

  return (
    <div className={styles.tags}>
      {renderTag(translate('Recently Active'))}
      <Conditional condition={healthScore === 'healthy'}>
        {renderTag(translate('High Authenticity'))}
      </Conditional>
    </div>
  );
}

function CreatorCard(props: CreatorCardProps) {
  const {
    creatorProfile,
    network,
    actions,
    containerWidth,
    onSocialNetworkClick,
    isAddedToList,
    onAddToListClick,
    creatorBadgeProps,
  } = props;

  const isSmallScreen = containerWidth < SMALL_SCREEN_CONTAINER_WIDTH_THRESHOLD;

  const formattedCachedFollowerCount = useMemo(() => {
    return creatorProfile.followerCount
      ? numberAbbreviation(creatorProfile.followerCount)
      : '';
  }, [creatorProfile]);
  const formattedAverageEngagementRate = useMemo(() => {
    return creatorProfile.averageEngagementRate
      ? formattedPercentage(creatorProfile.averageEngagementRate)
      : '';
  }, [creatorProfile]);
  const formattedAverageImpressionsCount = useMemo(() => {
    return creatorProfile.averageImpressionsCount
      ? numberAbbreviation(creatorProfile.averageImpressionsCount)
      : '';
  }, [creatorProfile]);

  const profileMediaItems = useMemo(
    () =>
      creatorProfile.matchedPosts?.map(
        (matchedPost) => ({
          id: matchedPost.postId,
          src: matchedPost.thumbnailUrl,
        }),
        [creatorProfile.matchedPosts]
      ) || [],
    [creatorProfile.matchedPosts]
  );

  return (
    <div className={styles.creatorCardContainer}>
      <div className={styles.leftSection}>
        <CreatorTags healthScore={creatorProfile.healthScore} />
        <MosaicGallery
          images={profileMediaItems}
          layouts={getGalleryLayout(
            isSmallScreen
              ? Math.min(5, profileMediaItems.length)
              : profileMediaItems.length,
            creatorProfile.id,
            isSmallScreen
          )}
          fillMissing={false}
          {...(isSmallScreen
            ? CREATOR_CARD_GALLERY_SETTINGS.SMALL
            : CREATOR_CARD_GALLERY_SETTINGS.DEFAULT)}
        />
      </div>
      <div className={styles.rightSection}>
        <div
          className={`${styles.rightSectionContent} ${
            !network ? styles.largeGap : ''
          } ${isSmallScreen ? styles.smallScreen : ''}`}
        >
          <CreatorInfo
            creatorProfile={creatorProfile}
            network={network}
            onSocialNetworkClick={onSocialNetworkClick}
            isAddedToList={isAddedToList}
            onAddToListClick={onAddToListClick}
          />
          <div className={styles.skillsStatsContainer}>
            <Conditional condition={!!creatorBadgeProps}>
              <IconStat
                {...creatorBadgeProps!}
                backgroundColor={designSystemToken(
                  'semantic.bg.warning-secondary'
                )}
                iconColor={designSystemToken('semantic.fg.warning')}
              />
            </Conditional>
            <Conditional
              condition={!!network}
              fallback={
                <CreatorSkills skills={creatorProfile.contentPreferences} />
              }
            >
              <CreatorStats
                followers={formattedCachedFollowerCount}
                engagementRate={formattedAverageEngagementRate}
                averageImpressions={formattedAverageImpressionsCount}
                network={network}
              />
            </Conditional>
          </div>
          {actions}
        </div>
      </div>
    </div>
  );
}

export default CreatorCard;
