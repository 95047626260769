import React from 'react';
import {createButton} from 'react-simple-wysiwyg';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import {SvgIconOwnProps} from '@mui/material';

const defaultProps = {fontSize: 'small'} as SvgIconOwnProps;

const BtnBold = createButton(
  'Bold',
  React.createElement(FormatBold, defaultProps),
  'bold'
);
const BtnBulletList = createButton(
  'Bullet list',
  React.createElement(FormatListBulletedIcon, defaultProps),
  'insertUnorderedList'
);
const BtnClearFormatting = createButton(
  'Clear formatting',
  React.createElement(FormatClearIcon, defaultProps),
  'removeFormat'
);
const BtnItalic = createButton(
  'Italic',
  React.createElement(FormatItalicIcon, defaultProps),
  'italic'
);
const BtnStrikeThrough = createButton(
  'Strike through',
  React.createElement(FormatStrikethroughIcon, defaultProps),
  'strikeThrough'
);
const BtnLink = createButton(
  'Link',
  React.createElement(InsertLinkIcon, defaultProps),
  ({$selection}) => {
    if ($selection?.nodeName === 'A') {
      document.execCommand('unlink');
    } else {
      const Selection = document.getSelection()?.toString();
      // eslint-disable-next-line no-alert -- ignore
      const Uri = prompt('URL', '');
      document.execCommand(
        'insertHTML',
        false,
        Uri
          ? `<a href="${Uri}" target="_blank">${Selection || Uri}</a>`
          : Selection
      );
    }
  }
);

const BtnNumberedList = createButton(
  'Numbered list',
  React.createElement(FormatListNumberedIcon, defaultProps),
  'insertOrderedList'
);
const BtnRedo = createButton(
  'Redo',
  React.createElement(RedoIcon, defaultProps),
  'redo'
);
const BtnUnderline = createButton(
  'Underline',
  React.createElement(FormatUnderlinedIcon, defaultProps),
  'underline'
);
const BtnUndo = createButton(
  'Undo',
  React.createElement(UndoIcon, defaultProps),
  'undo'
);

export {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnStrikeThrough,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnUnderline,
  BtnUndo,
};
