import React from 'react';
import {Toolbar, AppBar, Box, Badge, Avatar} from '@mui/material';
import {Label, Icon, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

function Header() {
  return (
    <AppBar
      position="static"
      enableColorOnDark
      sx={{
        boxShadow: 'none',
        paddingX: '24px',
        bgcolor: designSystemToken('semantic.bg.secondary'),
        borderBottom: `1px solid ${designSystemToken('semantic.bg.tertiary')}`,
      }}
    >
      <Toolbar>
        <Icon name="Social-Lightricks" appearance="primary" size="large" />
        <Label size="xl" fontSize="20px" marginX="16px" flex={1}>
          {translate('ltx-commerce')}
        </Label>
        <Box height="40px" width="40px" display="flex" alignItems="center">
          <Badge
            badgeContent={1}
            color="primary"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: designSystemToken('semantic.fg.neutral'),
              },
            }}
          >
            <Icon
              name="Navigation-Creations"
              appearance="primary"
              size="medium"
            />
          </Badge>
        </Box>
        <Avatar sx={{width: '40px', height: '40px', marginLeft: '16px'}}>
          OP
        </Avatar>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
