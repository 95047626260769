import React, {useMemo} from 'react';
import {Avatar} from '@lightricks/react-design-system';
import {avatarSize} from '@lightricks/react-design-system/dist/components/avatar/Avatar';
import {ANONYMOUS_USER_AVATAR_NAME} from '@/config/constants';
import useAvatarImageOrInitials from '@/hooks/use-avatar-image-or-initials';
import styles from './ItemAvatar.module.scss';

function ItemAvatar(props: {
  imageUrl: string;
  label: string;
  className?: string;
  size?: (typeof avatarSize)[number];
}) {
  const {imageUrl: itemImageUrl, label, className, size = 'lg'} = props;
  const {imageUrl, initials} = useAvatarImageOrInitials(
    itemImageUrl || '',
    label
  );

  const avatarType = useMemo(() => {
    if (!imageUrl) {
      return 'initials';
    }
    if (imageUrl.includes(ANONYMOUS_USER_AVATAR_NAME)) {
      return 'placeholder';
    }

    return 'picture';
  }, [imageUrl]);

  return (
    <div className={styles.itemAvatarContainer}>
      <Avatar
        size={size}
        type={avatarType}
        initials={initials}
        src={imageUrl}
        className={className}
      />
    </div>
  );
}

export default ItemAvatar;
