import React from 'react';
import {Box} from '@mui/material';
import {
  CombinedPostAnalysis,
  CombinedPostAnalysisFailure,
  SocialNetwork,
} from '@/types/creatorSafetyReport';
import {CONTENT_MODAL_ANALYTICS_PREFIX} from '@/components/creator-vetting-report/constants';
import getIsPostAnalysis from '../../utils/getIsPostAnalysis';
import FailedScanNote from '../failed-scan-note';
import styles from './PostModalContent.module.scss';
import AnalysisPanel from './analysis-panel';
import PostPanel from './post-panel';

export interface PostModalContentProps {
  testID?: string;
  post: CombinedPostAnalysis | CombinedPostAnalysisFailure;
  category: string;
  creatorName: string | null;
  creatorAvatar: string | null;
  socialNetworks: SocialNetwork[];
  trackAction?: (actionName: string, actionValue: string) => void;
}

function PostModalContent(props: PostModalContentProps) {
  const {
    testID = 'post-modal-content',
    post,
    category,
    creatorName,
    creatorAvatar,
    socialNetworks,
    trackAction,
  } = props;

  const {mediaType, url: mediaUrl, thumbnailUrl} = post.mediaMetadata;

  const isPostAnalysis = getIsPostAnalysis(post);

  return (
    <Box className={styles.postModalContentContainer} data-testid={testID}>
      <Box className={styles.postContainer}>
        <Box className={styles.postPanelWrapper}>
          <PostPanel
            mediaType={mediaType}
            mediaUrl={mediaUrl}
            thumbnailUrl={thumbnailUrl}
            mediaMetadata={post.mediaMetadata}
            postMetadata={post.postMetadata}
            creatorName={creatorName}
            creatorAvatar={creatorAvatar}
            socialNetworks={socialNetworks}
            isPostAnalysis={isPostAnalysis}
            trackAction={(actionName, actionValue) =>
              trackAction?.(
                `${CONTENT_MODAL_ANALYTICS_PREFIX}:${actionName}`,
                actionValue
              )
            }
          />
        </Box>
      </Box>
      <Box className={styles.analysisContainer}>
        {isPostAnalysis ? (
          <AnalysisPanel post={post} category={category} />
        ) : (
          <FailedScanNote />
        )}
      </Box>
    </Box>
  );
}

export default PostModalContent;
