import QueryContext from '@/api/QueryContext';
import api from '@/api';

const vettingHubFetchers = {
  vettableCreators: (context: QueryContext) => {
    return api.get('/vettable_creators', {
      params: {handle: context.meta?.handle, case_insensitive: true},
    });
  },
  creatorBrandSafetyVettings: (context: QueryContext) => {
    return api.get('/creator_brand_safety_vettings', {
      params: {brand_id: context.meta?.brandId},
    });
  },
};

export default vettingHubFetchers;
