import axiosWithAuth from '@/utils/axiosWithAuth';
import {HTTP_STATUS} from '@/config/constants';
import {Metadata} from '@/types/models/search-creators/searchCreators';

const CREATORS_PATH = 'creators';
const CREATORS_METADATA_PATH = 'creators/metadata';

const creatorsFetchers = {
  checkInvitationStatusBatch: async (data: {
    campaignId: string;
  }): Promise<{id: string; invited: boolean; campaignId: string}[]> => {
    try {
      const result = await axiosWithAuth.get(
        `${CREATORS_PATH}/invitation_status`,
        {
          params: {campaign_id: data.campaignId},
        }
      );

      if (
        result.status >= HTTP_STATUS.OK &&
        result.status < HTTP_STATUS.BAD_REQUEST
      ) {
        return result.data;
      }

      return [];
    } catch (error) {
      console.error('Error fetching invitation statuses', error);
      return [];
    }
  },
  metadata: async (): Promise<Metadata> => {
    const result = await axiosWithAuth.get<Metadata>(CREATORS_METADATA_PATH);
    return result.data;
  },
};

export default creatorsFetchers;
