import {useEffect, useLayoutEffect, useRef, useState} from 'react';

function useElementDimensions(deps: any[] = [], attachResizeHandler = false) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [elementDimensions, setElementDimensions] = useState({
    elementWidth: 0,
    elementHeight: 0,
  });

  useEffect(() => {
    const onResize = () => {
      setElementDimensions(() => ({
        elementWidth: elementRef.current?.clientWidth || 0,
        elementHeight: elementRef.current?.clientHeight || 0,
      }));
    };

    if (attachResizeHandler) {
      window.addEventListener('resize', onResize);
    }

    return () => {
      if (attachResizeHandler) {
        window.removeEventListener('resize', onResize);
      }
    };
  }, []);

  useLayoutEffect(() => {
    setElementDimensions(() => ({
      elementWidth: elementRef.current?.clientWidth || 0,
      elementHeight: elementRef.current?.clientHeight || 0,
    }));
  }, [elementRef, ...deps]);

  return {elementRef, ...elementDimensions};
}

export default useElementDimensions;
