import React from 'react';
import {Box} from '@mui/material';
import {
  Body,
  designSystemToken,
  Headline,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './PostPanelCarouselMessage.module.scss';

export interface PostPanelCarouselMessageProps {
  flaggedMediaIndex: number[];
}

const handSwipeGif = '/assets/images/hand-swipe.gif';

function PostPanelCarouselMessage(props: PostPanelCarouselMessageProps) {
  const {flaggedMediaIndex} = props;

  return (
    <Box className={styles.carouselMessage}>
      <img
        src={handSwipeGif}
        alt="handSwipeGif"
        className={styles.carouselMessageGif}
      />
      <Box className={styles.carouselMessageTextBox}>
        <Body
          size="md"
          color={designSystemToken('semantic.fg.primary')}
          sx={{textAlign: 'center'}}
        >
          {translate('carousel-post-message-part-1')}
        </Body>
        <Headline size="xxs" color={designSystemToken('semantic.fg.primary')}>
          {translate('carousel-post-message-part-2', {
            flaggedIndexes: flaggedMediaIndex
              .map((index) => index + 1)
              .join(', '),
          })}
        </Headline>
        <Body
          size="md"
          color={designSystemToken('semantic.fg.primary')}
          sx={{textAlign: 'center'}}
        >
          {translate('carousel-post-message-part-3')}
        </Body>
      </Box>
    </Box>
  );
}

export default PostPanelCarouselMessage;
