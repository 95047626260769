import React, {useMemo, useRef, useState} from 'react';
import {Box, ListItemText, MenuItem, Select} from '@mui/material';
import {Icon, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import CheckmarkRadioButton from '@/components/checkmark-radio-button';
import Chip from '@/components/chip';
import styles from './TagSelector.module.scss';

const TRANSLATION_PREFIX = 'components.tag-selector';

export type Option = {
  id: string;
  label: any;
  [key: string]: any;
};

export type TagSelectorProps = {
  testID?: string;
  options: Option[];
  optionLabelKey?: string;
  selectedValueIds: string[] | '';
  multiple?: boolean;
  onChange: (selection: string | string[]) => void;
  hideSelectedOptions?: boolean;
  addChipLabel?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onClose?: () => void;
  hasError?: boolean;
};

function TagSelector(props: TagSelectorProps) {
  const {
    testID = 'tag-selector',
    options,
    optionLabelKey = 'label',
    selectedValueIds,
    multiple,
    onChange,
    hideSelectedOptions = false,
    addChipLabel = translate(`${TRANSLATION_PREFIX}.add`),
    readOnly = false,
    disabled = false,
    onClose,
    hasError = false,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const addChipRef = useRef<HTMLDivElement>(null);

  const memoizedOptions = useMemo(() => {
    if (hideSelectedOptions) {
      return options.filter((option) => !selectedValueIds.includes(option.id));
    }
    return options;
  }, [options, selectedValueIds, hideSelectedOptions]);

  const toggleIsOpen = (nextIsOpen: boolean) => {
    if (nextIsOpen && hideSelectedOptions && !memoizedOptions.length) {
      return;
    }

    setIsOpen(nextIsOpen);
  };

  const menuProps = {
    MenuProps: {
      classes: {
        paper: styles.optionsList,
      },
      anchorEl: addChipRef.current,
      anchorOrigin: {
        vertical: 'bottom' as const,
        horizontal: 'left' as const,
      },
      transformOrigin: {
        vertical: 'top' as const,
        horizontal: 'left' as const,
      },
      PaperProps: {
        sx: {
          marginTop: '8px',
          minWidth: '320px !important',
          maxWidth: '320px !important',
          maxHeight: '62vh',
          borderRadius: '24px',
        },
      },
    },
  };

  const renderValue = (selections: string[]) => {
    const selectedOptions = options.filter((option) =>
      selections.includes(option.id)
    );

    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <>
          {!readOnly ? (
            <Chip
              testID={`${testID}--add-chip`}
              className={`${styles.addChip} ${
                disabled ? styles.disabled : ''
              } ${hasError ? styles.error : ''}`}
              chipRef={addChipRef}
              disabled={disabled}
              key="add-chip"
              label={
                <div
                  className={`${styles.addChipLabel} ${
                    disabled ? styles.disabled : ''
                  }`}
                >
                  <Icon
                    name="Actions-Add-Small1"
                    appearance={
                      // eslint-disable-next-line no-nested-ternary
                      disabled ? 'disabled' : hasError ? 'danger' : 'neutral'
                    }
                    size="medium"
                  />
                  {addChipLabel}
                </div>
              }
              onClick={() => toggleIsOpen(true)}
            />
          ) : null}
          {selectedOptions.map((value) => (
            <Chip
              key={value.id}
              label={value.label}
              variant="outlined"
              sx={{
                '.MuiChip-label': {
                  fontSize: 14,
                  color: disabled
                    ? designSystemToken('semantic.fg.disabled')
                    : undefined,
                },
                '&.MuiChip-root': {
                  backgroundColor: 'transparent',
                  border: `1px solid ${designSystemToken(
                    'semantic.bg.neutral-inverse'
                  )}`,
                },
              }}
              deleteIcon={
                <div>
                  <Icon
                    name="Actions-Close-Normal"
                    appearance="neutral"
                    size="small"
                  />
                </div>
              }
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              handleDelete={
                !readOnly
                  ? () => {
                      onChange(
                        selectedOptions
                          .filter((option) => option.id !== value.id)
                          .map((option) => option.id)
                      );
                    }
                  : undefined
              }
            />
          ))}
        </>
      </Box>
    );
  };

  const selectValue =
    // eslint-disable-next-line no-nested-ternary
    selectedValueIds.length > 0 ? selectedValueIds : multiple ? [] : '';

  return (
    <div
      className={`${styles.container} ${disabled ? styles.disabled : ''}`}
      data-testid={testID}
    >
      <Select
        {...menuProps}
        sx={{
          '*': {
            cursor: readOnly || disabled ? 'default !important' : 'pointer',
          },
          '.MuiSelect-select': {
            padding: '2px 0',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'unset',
          },
        }}
        disabled={disabled}
        open={isOpen}
        onClose={() => {
          toggleIsOpen(false);
          onClose?.();
        }}
        IconComponent={() => null}
        className={styles.selectContainer}
        value={selectValue}
        multiple={multiple}
        onChange={({target: {value}}) => onChange(value)}
        displayEmpty
        renderValue={renderValue}
      >
        {memoizedOptions.map((option) => (
          <MenuItem
            data-testid={`${testID}--option--${option.id}`}
            key={`menu-item-${option.id}`}
            value={option.id}
            focusVisibleClassName={styles.optionFocused}
            className={styles.option}
          >
            <ListItemText
              primary={option[optionLabelKey]}
              sx={{textWrap: 'wrap'}}
            />
            {!hideSelectedOptions ? (
              <CheckmarkRadioButton
                className={`${styles.checkmarkRadioButton}`}
                checked={selectedValueIds.indexOf(option.id) > -1}
              />
            ) : null}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default TagSelector;
