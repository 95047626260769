import React, {useState} from 'react';
import {Stack} from '@mui/material';
import {Input, Button, Headline, Body} from '@lightricks/react-design-system';
import raiseFlashMessage from '@/utils/raiseFlashMessage';
import queryClient from '@/utils/reactQueryClient';
import useUpdateDashboardUser from '@/hooks/mutations/use-update-dashboard-user';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useToken from '@/hooks/use-token';
import translate from '../../../utils/translate';
import styles from './Account.module.scss';

function Account() {
  const {isAuthenticated} = useToken(true);
  const dashboardUserMutation = useUpdateDashboardUser();
  const {dashboardUser} = useDashboardUserQuery({
    enabled: isAuthenticated,
  });

  const [displayName, setDisplayName] = useState(
    dashboardUser?.displayName || ''
  );
  const [email, setEmail] = useState(dashboardUser?.email);
  const TRANSLATION_PREFIX = 'components.preferences.account';

  const updateDisplayName = async () => {
    await dashboardUserMutation.mutateAsync({
      id: dashboardUser.id,
      displayName,
    });

    raiseFlashMessage({
      status: 'success',
      mode: 'light',
      message: translate('Your preferences have been updated successfully.'),
    });

    queryClient.setQueryData(['dashboardUser'], {
      ...dashboardUser,
      displayName,
    });
  };

  const handleDisplayNameChange = (value: string) => {
    setDisplayName(value);
  };

  if (!dashboardUser?.loaded) return null;

  return (
    <Stack className={styles.accountContainer}>
      <Stack gap="40px">
        <Stack gap="9px">
          <Headline size="lg">{translate('Login')}</Headline>
          <Body size="lg">
            {translate('Update your name and login email address here.')}
          </Body>
        </Stack>
        <Stack gap="8px">
          <div className={styles.form}>
            <div className={styles.formItem}>
              <label>{translate(`${TRANSLATION_PREFIX}.display-name`)}</label>
              <Input
                type="text"
                value={displayName}
                onInputChange={handleDisplayNameChange}
                className={styles.displayNameInput}
                testID="display-name-input"
              />
              <p className={styles.helperText}>
                {translate(`${TRANSLATION_PREFIX}.display-name-helper-text`)}
              </p>
            </div>

            <div className={styles.formItem}>
              <label>{translate(`${TRANSLATION_PREFIX}.email`)}</label>
              <Input
                type="email"
                value={email}
                disabled
                className={styles.emailInput}
                clearIcon={false}
              />
            </div>
            <Button
              appearance="neutral"
              mode="filled"
              size="medium"
              onClick={updateDisplayName}
              className={styles.updateButton}
            >
              {translate(`${TRANSLATION_PREFIX}.update`)}
            </Button>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Account;
