import React from 'react';
import {Box} from '@mui/material';
import translate from '@/utils/translate';
import locationFetchers from '@/api/fetchers/location';
import {AGE_MARKS, GENDER_OPTIONS} from '@/views/campaigns/brief/constants';
import {
  mapFetchedLocationData,
  mapLocationData,
} from '@/views/campaigns/brief/utils/mapLocationData';
import ButtonGroup from '@/components/button-group';
import DiscreteSlider from '@/components/discrete-slider/DiscreteSlider';
import LocationAutoComplete, {
  LocationOption,
} from '@/components/location-auto-complete/LocationAutoComplete';
import OptionButton from '@/components/option-button';
import {BriefAiWizardFormProps} from '../../BriefAiWizardProps';
import StepContainer from '../../components/step-container';
import StepSectionContainer from '../../components/step-section-container';
import BRIEF_WIZARD_TEST_IDS from '../../testIds';
import {StepProps} from '../StepProps';
import styles from './Demographics.module.scss';

type AgeRangeSectionProps = {
  formData: BriefAiWizardFormProps;
  updateFormData?: (formDataPartialUpdate: object) => void;
};

function AgeRangeSection({formData, updateFormData}: AgeRangeSectionProps) {
  const handleAgeChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      updateFormData?.({
        ageRange: {
          from_age: newValue[0],
          to_age: newValue[1],
        },
      });
    }
  };

  return (
    <StepSectionContainer
      title={translate(`What creator demographics are you looking for?`)}
      subtitle={translate(
        `Choose the age range and gender of your ideal creator for this campaign.`
      )}
      containerClassName={styles.ageContainer}
    >
      <DiscreteSlider
        testID={BRIEF_WIZARD_TEST_IDS.DEMOGRAPHICS__AGE_RANGE_SLIDER}
        value={
          formData.ageRange
            ? [formData.ageRange.from_age, formData.ageRange.to_age || 65]
            : [AGE_MARKS[0].value, AGE_MARKS[AGE_MARKS.length - 1].value]
        }
        valueLabelDisplay="auto"
        min={AGE_MARKS[0].value}
        max={AGE_MARKS[AGE_MARKS.length - 1].value}
        step={1}
        marks={AGE_MARKS}
        disabled={false}
        onChange={handleAgeChange}
      />
    </StepSectionContainer>
  );
}

type GenderSectionProps = {
  formData: BriefAiWizardFormProps;
  updateFormData?: (formDataPartialUpdate: object) => void;
};

function GenderSection({formData, updateFormData}: GenderSectionProps) {
  return (
    <StepSectionContainer>
      <ButtonGroup
        buttons={GENDER_OPTIONS.map((option) => ({
          ...option,
          label: translate(option.labelLocaleKey),
        }))}
        containerClassName={styles.genderContainer}
        multiple
        onChange={(selection) =>
          updateFormData?.({
            genders: Array.isArray(selection)
              ? selection.map((option) => option.value)
              : [],
          })
        }
        selectedButtonValues={formData.genders || []}
        renderButton={(button, isActive, onClick) => (
          <OptionButton
            testID={`${BRIEF_WIZARD_TEST_IDS.DEMOGRAPHICS__GENDERS}--${button.value}`}
            button={button}
            className={styles.genderButton}
            onClick={onClick}
            isActive={isActive}
          />
        )}
      />
    </StepSectionContainer>
  );
}

type LocationSectionProps = {
  updateFormData?: (formDataPartialUpdate: object) => void;
  formData: BriefAiWizardFormProps;
};

function LocationSection({updateFormData, formData}: LocationSectionProps) {
  const [locationQuery, setLocationQuery] = React.useState('');
  const [locationOptions, setLocationOptions] = React.useState<
    LocationOption[]
  >([]);
  const handleLocationInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length > 0) {
      setLocationQuery(event.target.value);
      locationFetchers.locations(event.target.value).then((options) => {
        setLocationOptions(
          options.map((option) => ({
            ...option,
            value: mapFetchedLocationData(option),
          }))
        );
      });
    }
  };

  const handleLocationChange = (locations: LocationOption[]) => {
    const formLocations = locations.map((location) =>
      mapLocationData(location)
    );
    updateFormData?.({locations: formLocations});
  };

  return (
    <StepSectionContainer
      title={translate(`Where are your ideal creators located?`)}
      containerClassName={styles.locationContainer}
    >
      <LocationAutoComplete
        onInputChange={handleLocationInputChange}
        loading={locationQuery.length > 0}
        onChange={handleLocationChange}
        options={locationOptions}
        selectedOptions={formData.locations.map((location) => ({
          id: location.id,
          localeLabelKey:
            location.city || location.state || location.country || '',
          value: location,
        }))}
        placeholder={translate(`Select a country, state and city`)}
        testID={BRIEF_WIZARD_TEST_IDS.DEMOGRAPHICS__LOCATION_INPUT}
        borderWidth={2}
      />
    </StepSectionContainer>
  );
}

function Demographics(props: StepProps) {
  const {
    testID,
    formData,
    formErrors,
    updateFormData,
    onClickNext,
    canContinue,
    campaignType,
  } = props;

  return (
    <Box className={styles.container}>
      <StepContainer
        onClickContinue={onClickNext}
        continueButtonDisabled={!canContinue}
        testID={testID}
      >
        <AgeRangeSection formData={formData} updateFormData={updateFormData} />
        <GenderSection formData={formData} updateFormData={updateFormData} />
        <LocationSection updateFormData={updateFormData} formData={formData} />
      </StepContainer>
    </Box>
  );
}

export default Demographics;
