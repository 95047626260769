import React, {useMemo, useState} from 'react';
import calculateAge from '@/utils/calculateAge';
import getAnalyticsScreenName from '@/utils/getAnalyticsScreenName';
import queryClient from '@/utils/reactQueryClient';
import {MembershipLabel} from '@/types/creatorProfile';
import CreatorAction from '@/views/creators/components/creator-action';
import {
  CREATOR_ACTION_KEYS,
  CreatorActionKey,
} from '@/views/creators/components/creator-action/CreatorAction';
import {CreatorProps} from '@/views/creators/components/creator-action/action/ActionProps';
import useCreatorGroupsLabelsQuery from '@/hooks//queries/use-creator-groups-labels-query';
import useCreatorProfileQuery from '@/hooks//queries/use-creator-profile-query';
import {getQueryKey} from '@/hooks//queries/use-creator-profile-query/useCreatorProfileQuery';
import useTrackRecruitmentActionFlowEvent from '@/hooks/analytics/use-track-recruitment-action-flow-event';
import {
  RecruitmentActionFlowEndedEventParams,
  RecruitmentActionFlowStartedEventParams,
} from '@/hooks/analytics/use-track-recruitment-action-flow-event/useTrackRecruitmentActionFlowEvent';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useRaiseCreatorAddedToListSuccess from '@/hooks/use-raise-creators-added-to-list-success';

interface UseCreatorActionArgs {
  profileId: string;
  brandId: string;
  recruitmentEventStartedParams?: Partial<RecruitmentActionFlowStartedEventParams>;
  recruitmentEventEndedParams?: Partial<RecruitmentActionFlowEndedEventParams>;
}

interface OverrideParams {
  [key: string]: string;
}

/**
 * preload data required for the creator actions
 * @param brandId
 */
function usePreloadBrandData(brandId: string) {
  // ADD_TO_MY_CREATORS DATA
  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';
  useCreatorGroupsLabelsQuery({creatorGroupId});
}

function useCreatorAction({
  profileId,
  brandId,
  recruitmentEventStartedParams = {},
  recruitmentEventEndedParams = {},
}: UseCreatorActionArgs) {
  const [activeCreatorAction, setActiveCreatorAction] =
    useState<CreatorActionKey | null>(null);
  usePreloadBrandData(brandId);
  const raiseCreatorAddedToListSuccess = useRaiseCreatorAddedToListSuccess();
  const trackRecruitmentActionFlowEvent = useTrackRecruitmentActionFlowEvent();

  const {profile} = useCreatorProfileQuery({
    profileId,
    brandId,
    enabled: !!(profileId && brandId),
  });

  const profileForCreatorAction = useMemo(() => {
    if (!profile) {
      return null;
    }
    return {
      ...profile,
      age: calculateAge(profile.birthDate),
      name: profile.displayName,
      avatar: profile.imageUrl,
    };
  }, [profile]);

  const trackRecruitmentEventStarted = (actionName: string) => {
    const screenName = getAnalyticsScreenName(window.location.pathname);
    trackRecruitmentActionFlowEvent.started({
      flow_name: actionName,
      action_name: actionName,
      screen_name: screenName,
      recruitment_search_id: '',
      creator_id: profile?.id || '',
      source: screenName,
      ...recruitmentEventStartedParams,
    });
  };

  const trackRecruitmentEventEnded = (
    actionName: string,
    reason: string,
    overrideParams = {}
  ) => {
    const screenName = getAnalyticsScreenName(window.location.pathname);
    trackRecruitmentActionFlowEvent.ended({
      flow_name: actionName,
      action_id: actionName || '',
      screen_name: screenName,
      recruitment_search_id: '',
      creator_id: profile?.id || '',
      reason,
      source: screenName,
      ...overrideParams,
      ...recruitmentEventEndedParams,
    });
  };

  const onCloseCreatorAction = (
    reason: 'success' | 'cancelled',
    creators?: CreatorProps[],
    actionName?: string,
    result?: unknown
  ) => {
    const overrideParams: OverrideParams = {};
    if (reason === 'success') {
      if (actionName === CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS) {
        raiseCreatorAddedToListSuccess(
          (result as MembershipLabel[]).filter(
            (label: MembershipLabel & {disabled?: boolean}) => !label.disabled
          )
        );
      }
      if (
        [
          CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
          CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ].includes(actionName)
      ) {
        // invalidate the query and refetch the creator profile =>
        // some actions such as sending a message to a creator would change the collaborate button behavior
        const queryKey = getQueryKey({profileId, brandId});
        queryClient.invalidateQueries(queryKey);
      }
      if (actionName === CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN) {
        overrideParams.campaign_id = result as string;
      }
    }
    trackRecruitmentEventEnded(actionName || '', reason, overrideParams);
    setActiveCreatorAction(null);
  };

  const handlePresentedOrDismissed = (
    presentedOrDismissed: 'presented' | 'dismissed',
    actionName: string,
    creators?: CreatorProps[]
  ) => {
    if (presentedOrDismissed === 'presented') {
      trackRecruitmentEventStarted(actionName);
    } else if (presentedOrDismissed === 'dismissed') {
      // do nothing.... for now
    }
  };

  const currentBrandMembershipLabels = useMemo(() => {
    if (
      profile?.currentBrandMembership &&
      !profile.currentBrandMembership.removedAt
    ) {
      return profile.currentBrandMembership.labels.map((label) => ({
        ...label,
        value: label.id,
        label: label.name,
        disabled: true,
      }));
    }
    return [];
  }, [profile?.currentBrandMembership]);

  const creatorActionComponent = profileForCreatorAction ? (
    <CreatorAction
      creators={[profileForCreatorAction]}
      activeCreatorAction={activeCreatorAction}
      close={onCloseCreatorAction}
      onPresentedOrDismissed={handlePresentedOrDismissed}
      initState={{
        [CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS]: currentBrandMembershipLabels,
      }}
    />
  ) : null;

  return {
    creatorActionComponent,
    setActiveCreatorAction,
    isAddedToList: !!currentBrandMembershipLabels.length,
  };
}

export default useCreatorAction;
