import dayjs from 'dayjs';
import React, {useState} from 'react';
import {Body, designSystemToken, Icon} from '@lightricks/react-design-system';
import {MONTH_DAY_FORMAT} from '@/config/timeFormats';
import Conditional from '@/components/conditional';
import Link from '@/components/link';
import styles from './BackgroundCheckItem.module.scss';

export interface BackgroundCheckItemProps {
  siteIcon: string;
  siteName: string | null;
  date: Date | null;
  title: string;
  thumbnailUrl: string;
  url: string;
  summary: string;
}

function ItemFooter({
  siteIcon,
  siteName,
  date,
}: {
  siteIcon: string;
  siteName: string | null;
  date: Date | null;
}) {
  const [hideSiteIcon, setHideSiteIcon] = useState(false);

  return (
    <div className={styles.footer}>
      <Conditional
        condition={!!siteIcon && !hideSiteIcon}
        fallback={
          <Icon
            name="Navigation-Web"
            size="small"
            appearance="neutral"
            color={designSystemToken('semantic.fg.secondary')}
          />
        }
      >
        <img
          className={styles.siteIcon}
          src={siteIcon}
          onError={() => setHideSiteIcon(true)}
          alt="site icon"
        />
      </Conditional>
      <div className={styles.siteNameAndDate}>
        <Conditional condition={!!siteName}>
          <Body
            size="sm"
            className={`${styles.siteName} ${!date ? styles.noDate : ''}`}
            color={designSystemToken('semantic.fg.secondary')}
          >
            {siteName}
          </Body>
        </Conditional>
        <Conditional condition={!!date}>
          <>
            <Body size="sm" color={designSystemToken('semantic.fg.secondary')}>
              .
            </Body>
            <Body size="sm" color={designSystemToken('semantic.fg.secondary')}>
              {dayjs(date).format(MONTH_DAY_FORMAT)}
            </Body>
          </>
        </Conditional>
      </div>
    </div>
  );
}

function BackgroundCheckItem(props: BackgroundCheckItemProps) {
  const {siteIcon, siteName, date, title, thumbnailUrl, url, summary} = props;
  const [showUnavailableOverlay, setShowUnavailableOverlay] = useState(false);

  return (
    <Link
      to={url}
      className={styles.backgroundCheckItemContainer}
      keepBrandIdIfPresent={false}
      target="_blank"
    >
      <div className={styles.backgroundCheckItemWrapper}>
        <div className={styles.thumbnailContainer}>
          <Conditional condition={!!thumbnailUrl && !showUnavailableOverlay}>
            <img
              className={styles.thumbnail}
              src={thumbnailUrl}
              alt="thumbnail"
              onError={() => setShowUnavailableOverlay(true)}
            />
          </Conditional>
        </div>
        <div className={styles.bodyContainer}>
          <Body
            size="sm"
            className={styles.summary}
            color={designSystemToken('semantic.fg.primary')}
          >
            {title}
          </Body>
          <ItemFooter siteIcon={siteIcon} siteName={siteName} date={date} />
        </div>
      </div>
    </Link>
  );
}

export default BackgroundCheckItem;
