import dayjs from 'dayjs';
import {isEqual} from 'lodash';
import React from 'react';
import {Box} from '@mui/material';
import getPlatformFromIdentityProvider from '@/utils/getIPlatformFromIdentityProvider';
import {MONTH_DAY_YEAR_FORMAT} from '@/config/timeFormats';
import {
  CombinedPostMetadata,
  MediaMetadata,
  SocialNetwork,
} from '@/types/creatorSafetyReport';
import PostPanelEmbeddedMedia from '@/components/creator-vetting-report/components/post-modal-content/post-panel/post-panel-embedded-media';
import styles from './PostPanel.module.scss';
import PostPanelHeader, {postTypeTitles} from './post-panel-header';

export interface PostPanelProps {
  testID?: string;
  mediaType: string | null;
  mediaUrl: string | null;
  thumbnailUrl: string | null;
  mediaMetadata: MediaMetadata;
  postMetadata: CombinedPostMetadata;
  creatorName: string | null;
  creatorAvatar: string | null;
  socialNetworks: SocialNetwork[];
  isPostAnalysis: boolean;
  trackAction?: (actionName: string, actionValue: string) => void;
}

function mapPostTypeToHeaderType(
  socialNetwork: string | null,
  socialStream: string | null,
  mediaIndex: number[],
  postUrl: string | null
) {
  const platformName = getPlatformFromIdentityProvider(socialNetwork);
  if (platformName === 'instagram') {
    switch (socialStream) {
      case 'feed':
        if (mediaIndex.length && !isEqual(mediaIndex, [0])) {
          return postTypeTitles.InstagramCarousel;
        }
        return postTypeTitles.InstagramPost;
      case 'reels':
        return postTypeTitles.InstagramReel;
      case 'story':
        return postTypeTitles.InstagramStory;
      default:
        return postTypeTitles.Post;
    }
  }
  switch (platformName) {
    case 'youtube':
      if (postUrl?.includes?.('shorts')) {
        return postTypeTitles.YouTubeShorts;
      }
      return postTypeTitles.YouTubeVideo;
    case 'facebook':
      return postTypeTitles.FacebookPost;
    case 'twitter':
      return postTypeTitles.TwitterPost;
    case 'tiktok':
      return postTypeTitles.TikTokVideo;
    case 'pinterest':
      return postTypeTitles.PinterestPost;
    default:
      return postTypeTitles.Post;
  }
}

function PostPanel(props: PostPanelProps) {
  const {
    testID = 'post-panel',
    mediaType,
    mediaUrl,
    thumbnailUrl,
    mediaMetadata,
    postMetadata,
    creatorName,
    creatorAvatar,
    socialNetworks,
    isPostAnalysis,
    trackAction,
  } = props;

  const isStory = postMetadata.socialStream === 'story';
  const postDate = dayjs(postMetadata.createdAt).format(MONTH_DAY_YEAR_FORMAT);

  const originalPostUrl = postMetadata.url;
  const socialNetwork = socialNetworks.find(
    (sc) => sc.provider === postMetadata.socialNetwork
  );

  return (
    <Box className={styles.panelContainer} data-testid={testID}>
      <PostPanelHeader
        type={mapPostTypeToHeaderType(
          postMetadata.socialNetwork,
          postMetadata.socialStream,
          postMetadata.mediaIndex ?? [0],
          postMetadata.url
        )}
        mediaIndex={postMetadata.mediaIndex ?? [0]}
        date={postDate}
        url={originalPostUrl}
        shouldShowNavigateButton={!isStory}
        trackAction={trackAction}
      />
      <div className={styles.embeddedContainer}>
        <PostPanelEmbeddedMedia
          key={`${postMetadata.url}-${postMetadata.mediaIndex}`}
          useInternalVideoPlayer={
            !!mediaUrl && isStory && mediaType === 'video'
          }
          useInternalImageViewer={
            !!mediaUrl && isStory && mediaType === 'image'
          }
          isVideo={
            mediaType === 'video' ||
            typeof mediaMetadata.videoDurationSeconds === 'number'
          }
          mediaUrl={mediaUrl}
          thumbnailUrl={thumbnailUrl}
          postMetadata={postMetadata}
          creatorName={creatorName}
          creatorAvatar={creatorAvatar}
          socialNetwork={socialNetwork}
          isPostAnalysis={isPostAnalysis}
          trackAction={trackAction}
        />
      </div>
    </Box>
  );
}

export default PostPanel;
