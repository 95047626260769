import React, {useMemo} from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
  Tooltip,
} from '@lightricks/react-design-system';
import calculateAge from '@/utils/calculateAge';
import translate from '@/utils/translate';
import {
  YOUTUBE_DS_ICON_INVERSE_OVERRIDES,
  YOUTUBE_DS_ICON_OVERRIDES,
} from '@/config/constants';
import SOCIAL_NETWORK_ICON from '@/config/socialNetworkIconMap';
import {ProfileProps} from '@/types/creatorProfile';
import {Identity} from '@/types/identity';
import Conditional from '@/components/conditional';
import Link from '@/components/link';
import ToggleableTags from '@/components/toggleable-tags';
import {
  RecruitmentActionFlowEndedEventParams,
  RecruitmentActionFlowStartedEventParams,
} from '@/hooks/analytics/use-track-recruitment-action-flow-event/useTrackRecruitmentActionFlowEvent';
import useCreatorAction from '@/hooks/use-creator-action';
import CreatorAvatar from '../creator-avatar';
import styles from './CreatorInfo.module.scss';

interface CreatorInfoProps {
  brandId: string;
  creatorProfile: ProfileProps;
  network?: string | null;
  identity: Identity | null;
  onSocialNetworkClick?: (identity: Identity) => void;
  recruitmentEventStartedParams?: Partial<RecruitmentActionFlowStartedEventParams>;
  recruitmentEventEndedParams?: Partial<RecruitmentActionFlowEndedEventParams>;
}

interface CreatorDetailsProps {
  displayName: string;
  age: number | null;
  location: string;
  tags: string[];
}

function CreatorDetails(props: CreatorDetailsProps) {
  const {displayName, age, location, tags} = props;
  return (
    <div className={styles.creatorDetailsContainer}>
      <div>
        <Headline size="sm">{displayName}</Headline>
        <Body size="md" color={designSystemToken('semantic.fg.primary')}>
          {[age, location].filter(Boolean).join(' | ')}
        </Body>
      </div>
      <ToggleableTags
        tags={tags}
        size="md"
        tagClassName={styles.tag}
        tagsLimit={2}
      />
    </div>
  );
}

function CreatorInfo(props: CreatorInfoProps) {
  const {
    brandId,
    creatorProfile,
    network,
    identity,
    onSocialNetworkClick,
    recruitmentEventStartedParams,
    recruitmentEventEndedParams,
  } = props;
  const {creatorActionComponent, setActiveCreatorAction} = useCreatorAction({
    profileId: creatorProfile.id,
    brandId,
    recruitmentEventStartedParams,
    recruitmentEventEndedParams,
  });
  const isAddedToList = useMemo(() => {
    const {currentBrandMembership} = creatorProfile;
    if (!currentBrandMembership) {
      return false;
    }
    return (
      currentBrandMembership.labels.length && !currentBrandMembership.removedAt
    );
  }, [creatorProfile?.currentBrandMembership]);

  const isYouTube = network?.includes('youtube');

  return (
    <>
      {creatorActionComponent}
      <div className={styles.creatorInfoContainer}>
        <div className={styles.avatarContainer}>
          <CreatorAvatar creatorProfile={creatorProfile} size="2xl" />
          <Conditional condition={!!identity}>
            <div className={styles.networkContainer}>
              <Link
                className={`${styles.socialNetworkIcon} ${
                  isYouTube ? styles.youtube : ''
                }`}
                to={identity?.profilePageUrl as string}
                target="_blank"
                keepBrandIdIfPresent={false}
                onClick={() => {
                  onSocialNetworkClick?.(identity as Identity);
                }}
              >
                <Icon
                  size="medium"
                  appearance="neutral"
                  name={SOCIAL_NETWORK_ICON[network as string]}
                  color={designSystemToken('semantic.fg.white')}
                  {...(isYouTube ? YOUTUBE_DS_ICON_INVERSE_OVERRIDES : {})}
                />
              </Link>
            </div>
          </Conditional>
        </div>
        <CreatorDetails
          displayName={creatorProfile.displayName}
          age={calculateAge(creatorProfile.birthDate)}
          location={creatorProfile.location}
          tags={creatorProfile.tags
            .map((tag) => (tag as {id: string; name: string}).name)
            .slice(0, 2)}
        />
        <Tooltip arrow placement="top" title={translate('Add to list')}>
          <div
            className={styles.addToListButton}
            onClick={() => setActiveCreatorAction('add-to-my-creators')}
          >
            <Icon
              size="medium"
              appearance="neutral"
              name={
                isAddedToList ? 'Actions-Bookmark-Active' : 'Actions-Bookmark'
              }
            />
          </div>
        </Tooltip>
      </div>
    </>
  );
}

export default CreatorInfo;
