import {uuidRegex} from '@/config/constants';

export const ROUTES = {
  HOME: '/',
  PUBLIC_CREATOR_PROFILE: '/p',
  CAMPAIGNS: '/campaigns',
  THREADS: '/collaborations',
  CONTENT__LIBRARY: '/content-library',
  STATS: '/stats',
  NOT_FOUND: '/not-found',
  MAINTENANCE: '/maintenance',
  ERROR: '/errors/(deactivated|session-expired|forbidden)',
  PUBLIC_PROFILE: `/p/${uuidRegex}`, // /p/:profileId
  PROFILE_SHARED_LIST: `/shared-list(/${uuidRegex})?`, // /shared-list/:profileId?type=(proposals|contracts|creator_groups|labels)&ref_id=(:campaignId|:userGroupId|:labelId)&filter=(is_pending|is_shortlisted|all)
  VIDEO_PREVIEWS_URL: `/video_previews/${uuidRegex}`, // /video_previews/:contentSubmissionId
  VIDEO_PREVIEWS: `/video_previews`, // /video_previews/:contentSubmissionId
  PHOTO_PREVIEWS_URL: `/photo_previews/${uuidRegex}`, // /photo_previews/:contentSubmissionId
  PHOTO_PREVIEWS: `/photo_previews`, // /photo_previews/:contentSubmissionId
  SIGN_UP_REACT: '/signup',
  SIGN_UP_REACT_OLD: '/sign-up',
  LOGIN: '/login',
  UPGRADE_SUBSCRIPTION: '/upgrade',
  AI_STUDIO: '/ai-studio',
  AI_STUDIO_DASHBOARD: '/ai-studio/generated-content/dashboard',
  MOBILE_NOT_SUPPORTED: '/mobile-not-supported',
  PRICING: '/pricing',
  NEW_CAMPAIGN_BRIEF: `/new-campaigns/${uuidRegex}/brief/edit`, // /new-campaigns/:campaignId/brief/edit
  SEARCH: '/search',
  MY_CREATORS: '/creators',
  CREATOR_PROFILE: '/creator-profile',
  CONTENT_LIBRARY: '/content-library-react',
  SHARED_LIST: '/shared-list',
  PREFERENCES: '/preferences',
  PREFERENCES_TERMS_OF_SERVICE: '/preferences/terms-of-service',
  ORGANIZATION_SETTINGS: '/organization-settings',
  CAMPAIGN_BRIEF: `/campaigns/${uuidRegex}/brief`,
  CAMPAIGN_RECRUIT: `/campaigns/${uuidRegex}/recruit`,
  CAMPAIGN_SUGGESTED: `/campaigns/${uuidRegex}/candidates`,
  CAMPAIGN_INVITED: `/campaigns/${uuidRegex}/candidates/invited`,
  CAMPAIGN_APPLICATIONS: `/campaigns/${uuidRegex}/proposals`,
  CAMPAIGN_PARTICIPANTS: `/campaigns/${uuidRegex}/contracts`,
  CAMPAIGN_ASSETS: `/campaigns/${uuidRegex}/content-downloads`,
  CAMPAIGN_STATS: `/campaigns/${uuidRegex}/stats`,
  CAMPAIGN_SETTINGS: `/campaigns/${uuidRegex}/settings`,
  CAMPAIGN_TIMELINE: `/campaigns/${uuidRegex}/timeline`,
  CONTENT_STUDIO: '/content-studio',
  VETTING: '/vetting',
  VETTING_HUB: '/vetting/hub',
  CREATOR_VETTING_REPORT: `/vetting/creators/${uuidRegex}/report/${uuidRegex}`,
  CREATOR_VETTING_REPORT_DEV: `/vetting/creators/\\d+/report/${uuidRegex}`, // TODO: remove once we remove the mock data of the vetting report
};
export const UNAUTHENTICATED_ROUTES = [
  ROUTES.ERROR,
  ROUTES.PUBLIC_PROFILE,
  ROUTES.PROFILE_SHARED_LIST,
  ROUTES.VIDEO_PREVIEWS_URL,
  ROUTES.PHOTO_PREVIEWS_URL,
  ROUTES.SIGN_UP_REACT,
  ROUTES.SIGN_UP_REACT_OLD,
  ROUTES.LOGIN,
];
export const HIDE_BRAND_HEADER_ROUTES = [
  ...UNAUTHENTICATED_ROUTES,
  ROUTES.UPGRADE_SUBSCRIPTION,
  ROUTES.PRICING,
  ROUTES.CONTENT_STUDIO,
];

export const HIDE_MENU_ROUTES = [
  ...HIDE_BRAND_HEADER_ROUTES,
  ROUTES.AI_STUDIO_DASHBOARD,
  ROUTES.PRICING,
  ROUTES.CREATOR_VETTING_REPORT,
  ROUTES.CREATOR_VETTING_REPORT_DEV, // TODO: remove once we remove the mock data of the vetting report
  ROUTES.CONTENT_STUDIO,
];

export const HIDE_SUBSCRIPTION_BANNER_ROUTES = [
  ...HIDE_BRAND_HEADER_ROUTES,
  ROUTES.AI_STUDIO_DASHBOARD,
  ROUTES.CONTENT_STUDIO,
];
