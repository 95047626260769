import {SortingState} from '@tanstack/table-core';
import {isEmpty} from 'lodash';
import {MRT_RowVirtualizer} from 'material-react-table';
import objectHash from 'object-hash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Stack} from '@mui/material';
import {Button, Headline, Icon} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import getCreatorProfileModalUrl from '@/utils/getCreatorProfileModalUrl';
import {raiseFlashMessageError} from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import {
  BaseFilterOption,
  SingleSelection,
} from '@/types/models/search-creators/filter';
import {PlatformId} from '@/types/models/search-creators/filterId';
import {
  CreatorSearchResult,
  SearchDisplayCreator,
} from '@/types/models/search-creators/searchCreators';
import {ViewType} from '@/types/models/search-creators/searchCreatorsStore';
import {DefaultSortDirection} from '@/types/models/search-creators/sortField';
import useCreatorsTableColumns from '@/views/creators/hooks/use-creators-table-columns';
import useCreatorsVirtualizedGridProps from '@/views/creators/hooks/use-creators-virtualized-grid-props/useCreatorsVirtualizedGridProps';
import getAnalyticsSearchScreenName from '@/views/creators/search/utils/getAnalyticsSearchScreenName';
import getProfileImageUrl from '@/views/creators/search/utils/getProfileImageUrl';
import mapCreatorsForAnalytics from '@/views/creators/search/utils/mapCreatorsForAnalytics';
import mapCreatorsForDisplay from '@/views/creators/search/utils/mapCreatorsForDisplay';
import CreatorCard from '@/components/creator-card';
import {
  CreatorCardCtaProps,
  CreatorCardProps,
} from '@/components/creator-card/CreatorCard';
import CreatorCardSkeleton from '@/components/creator-card/CreatorCardSkeleton';
import {CreatorMenuItemProps} from '@/components/creator-card/CreatorOverflowMenuButton';
import Table from '@/components/table';
import VirtualizedGrid from '@/components/virtualized-grid';
import useTrackSearchButtonEvent from '@/hooks/analytics/use-track-search-button-event';
import useSearchCreatorsQuery from '@/hooks/queries/use-search-creators-query';
import useIsKeyPressed from '@/hooks/use-is-key-pressed';
import useNavigation from '@/hooks/use-navigation';
import usePrevious from '@/hooks/use-previous';
import useEmberIframeStyleStore from '@/stores/emberIframeStyleStore';
import useSearchCreatorsStore, {
  SEARCH_CREATORS_ITEMS_PER_PAGE,
  searchCreatorsActions,
  VIEW_TYPE_GRID,
  VIEW_TYPE_TABLE,
} from '@/stores/search-creators/searchCreatorsStore';
import styles from './SearchCreatorsDisplay.module.scss';

const searchEmptyStateSrc = '/assets/svg/search-empty-state.svg';

const TRANSLATION_PREFIX = 'views.creators';
const TABLE_ROW_HEIGHT = 50;
const TABLE_SCROLL_TOP_KEY = 'tableScrollTop';
const GRID_SCROLL_TOP_KEY = 'gridScrollTop';

type SearchCreatorsDisplayProps = {
  isRestoringFromPersistence: boolean;
  onCreatorsSelection?: (
    creators: CreatorSearchResult[],
    isSelected: boolean
  ) => void;
  creatorCardCtaProps?: CreatorCardCtaProps;
  onSortingSelection?: (sortField: string, direction: string) => void;
  searchCreatorsQueryDisabled?: boolean;
  creatorCardOverflowMenuEnabled?: boolean;
  creatorCardOverflowMenuItemsCallBack?: (
    creator: CreatorCardProps
  ) => Promise<CreatorMenuItemProps[]>;
  viewType: ViewType;
};

function SearchCreatorsDisplay({
  isRestoringFromPersistence,
  onCreatorsSelection,
  creatorCardCtaProps,
  onSortingSelection,
  searchCreatorsQueryDisabled,
  creatorCardOverflowMenuEnabled,
  creatorCardOverflowMenuItemsCallBack,
  viewType,
}: SearchCreatorsDisplayProps) {
  const [
    sessionId,
    paging,
    searchQuery,
    sorting,
    filterGroups,
    selectedCreators,
    selectedCampaignId,
    selectedBrandId,
  ] = useSearchCreatorsStore(
    useShallow((state) => [
      state.content.sessionId,
      state.content.paging,
      state.searchQuery,
      state.content.sorting,
      state.content.filtersState.filtersGroupsSnapshot,
      state.content.selectedCreators,
      state.selectedCampaignId,
      state.selectedBrandId,
    ])
  );
  const previousFilterGroups = usePrevious(filterGroups, filterGroups);
  const {navigate} = useNavigation();
  // Control - Windows Ctrl, Meta - Mac Cmd
  const isCtrlPressed = useIsKeyPressed(['Control', 'Meta']);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerRef = useRef<MRT_RowVirtualizer>(null);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridScrollRef = useRef<HTMLDivElement>(null);
  const {sidebarWidth} = useEmberIframeStyleStore();
  const trackSearchButtonEvent = useTrackSearchButtonEvent();
  let selectedPlatform: PlatformId = 'all';
  if (filterGroups.platforms.filters.platform) {
    selectedPlatform =
      (
        filterGroups.platforms.filters
          .platform as SingleSelection<BaseFilterOption>
      ).selected?.value ?? 'all';
  }
  const isPlatformFiltered = selectedPlatform !== 'all';

  const creatorsVirtualizedGridProps = useCreatorsVirtualizedGridProps(
    gridContainerRef,
    isPlatformFiltered,
    [viewType, sidebarWidth]
  );

  const {
    data: creatorsSearchData,
    isFetching: isSearchCreatorsFetching,
    isRefetching: isSearchCreatorsRefetching,
    isLoading: isSearchCreatorsLoading,
    isFetched: isSearchCreatorsFetched,
    isError: isSearchCreatorsError,
    fetchNextPage,
    hasNextPage,
  } = useSearchCreatorsQuery({
    sessionId,
    paging,
    searchQuery,
    sorting,
    selectedCampaignId,
    selectedBrandId,
    filterGroups,
    isRestoring: isRestoringFromPersistence,
    disabled: searchCreatorsQueryDisabled,
  });

  useEffect(() => {
    // autoload next pages if the current loaded data does not fill the table view
    // this is done to make sure that there is enough rows to be able to scroll (which activates next fetches)
    const pagesPerScreen = Math.ceil(
      (tableContainerRef.current?.clientHeight || 0) /
        (TABLE_ROW_HEIGHT * SEARCH_CREATORS_ITEMS_PER_PAGE)
    );
    if (
      hasNextPage &&
      sessionId &&
      creatorsSearchData.loadedPages < pagesPerScreen &&
      !isSearchCreatorsFetching &&
      !isSearchCreatorsLoading
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    sessionId,
    creatorsSearchData.loadedPages,
    isSearchCreatorsFetching,
    isSearchCreatorsLoading,
  ]);

  useEffect(() => {
    searchCreatorsActions.setTotalResults(
      creatorsSearchData.pagingParams.totalResults
    );
  }, [creatorsSearchData]);

  const handleFetchNextPage = useCallback(() => {
    if (isSearchCreatorsLoading || isSearchCreatorsFetching) {
      return;
    }
    fetchNextPage();
  }, [fetchNextPage, isSearchCreatorsLoading, isSearchCreatorsFetching]);

  useEffect(() => {
    if (!isSearchCreatorsFetching && creatorsSearchData.creators && sessionId) {
      const searchSessionId = creatorsSearchData?.searchSessionId || sessionId;

      const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.RECRUITMENT_SEARCH
      );

      AnalyticsService.dispatchEvent(eventNames.RECRUITMENT_RESULTS_PRESENTED, {
        flow_id: recruitmentSearchFlow.flow_id,
        screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
        recruitment_search_id: searchSessionId,
        recruitment_screen_name: getAnalyticsSearchScreenName(viewType),
        item_position: sorting?.field || '',
        item_identifier: sorting?.direction || '',
        creator_id: JSON.stringify(
          mapCreatorsForAnalytics(creatorsSearchData.creators)
        ),
        campaign_id: selectedCampaignId || '',
        result_source: '',
      });
    }
  }, [
    sessionId,
    objectHash(creatorsSearchData.creators),
    isSearchCreatorsFetching,
    sorting?.field,
  ]);

  useEffect(() => {
    if (isSearchCreatorsError) {
      raiseFlashMessageError();
    }
  }, [isSearchCreatorsError]);

  const [visibleColumns, setVisibleColumns] = useState({
    instagramFollowers: false,
  });

  useEffect(() => {
    if (isSearchCreatorsFetched && creatorsSearchData.searchSessionId) {
      searchCreatorsActions.setSessionInformation(
        creatorsSearchData.searchSessionId
      );
    }
  }, [
    isSearchCreatorsLoading,
    isSearchCreatorsFetching,
    creatorsSearchData.searchSessionId,
  ]);

  const searchDisplayCreators = useMemo(
    () => mapCreatorsForDisplay(creatorsSearchData.creators, selectedPlatform),
    [creatorsSearchData.creators, filterGroups]
  );

  const onSelectionCreatorChange = (id: string, isSelected: boolean) => {
    const selectedCreator = creatorsSearchData.creators.find(
      (creator) => creator.id === id
    );
    if (selectedCreator) {
      searchCreatorsActions.selectCreator(selectedCreator, isSelected);
      onCreatorsSelection?.([selectedCreator], isSelected);
    }
  };

  const onSocialNetworkClick = useCallback(
    (buttonName: string, creatorId: string) =>
      trackSearchButtonEvent.pressed({
        button_name: buttonName,
        screen_name: getAnalyticsSearchScreenName(viewType),
        creator_id: creatorId,
      }),
    [viewType]
  );

  const columns = useCreatorsTableColumns({
    selectedPlatform,
    selectedCreators,
    onSelectionCreatorChange,
    onSocialNetworkClick,
  });

  const onSortingChange = (newSorting: SortingState) => {
    if (newSorting?.length) {
      searchCreatorsActions.changeSorting(
        newSorting[0].id,
        newSorting[0].desc ? 'DESC' : 'ASC'
      );
      onSortingSelection?.(
        newSorting[0].id,
        newSorting[0].desc ? 'DESC' : 'ASC'
      );
    } else {
      searchCreatorsActions.changeSorting('');
      onSortingSelection?.('', DefaultSortDirection);
    }
  };

  const selectedCreatorsMap = Object.fromEntries(
    Array.from(selectedCreators, ([key]) => [key, true])
  );

  const mapSorting = () => {
    if (!isEmpty(sorting)) {
      return [
        {
          id: sorting.field,
          desc: (sorting.direction as string) === 'DESC',
        },
      ];
    }
    return [];
  };

  const onToggleColumn = () => {
    setVisibleColumns({
      ...visibleColumns,
      instagramFollowers: !visibleColumns.instagramFollowers,
    });
  };

  const onCreatorClick = (creator: SearchDisplayCreator) => {
    const redirectUrl = getCreatorProfileModalUrl(creator.id);

    onCreatorClickAnalytics(JSON.stringify(mapCreatorsForAnalytics([creator])));
    if (isCtrlPressed) {
      window.open(redirectUrl, '_blank');
    } else {
      navigate(redirectUrl);
    }
  };

  const onCreatorClickAnalytics = useCallback(
    (creatorId: string) => {
      const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.RECRUITMENT_SEARCH
      );
      AnalyticsService.dispatchEvent(
        eventNames.RECRUITMENT_PORTFOLIO_PRESENTED,
        {
          flow_id: recruitmentSearchFlow.flow_id,
          screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
          creator_portfolio_presentation_id: '',
          recruitment_search_id: sessionId,
          creator_id: creatorId,
          recruitment_screen_name: getAnalyticsSearchScreenName(viewType),
        }
      );
    },
    [viewType, sessionId]
  );

  const renderAddColumnButton = () => {
    return (
      <Button
        appearance="neutral"
        mode="tinted"
        size="medium"
        onClick={onToggleColumn}
        className={styles.addColumnsButton}
        icon={
          <Icon size="medium" appearance="neutral" name="Actions-Add-Bold" />
        }
      />
    );
  };

  const renderEmptyState = () => {
    return (
      <Stack direction="column" spacing="16px" className={styles.emptyState}>
        <img src={searchEmptyStateSrc} alt="" />
        <Headline size="sm">
          {translate(`${TRANSLATION_PREFIX}.empty-state`)}
        </Headline>
      </Stack>
    );
  };

  const noResults =
    creatorsSearchData.creators.length === 0 &&
    !isSearchCreatorsLoading &&
    !isSearchCreatorsFetching;

  const getTableContainerStyles = () => {
    const classes = [styles.tableContainer];
    if (
      !(isSearchCreatorsLoading || searchCreatorsQueryDisabled) &&
      isSearchCreatorsFetching
    ) {
      classes.push(styles.loading);
    }
    if (!selectedCampaignId) {
      classes.push(styles.lowSearchHeader);
    }
    return classes.join(' ');
  };

  const getGridContainerStyles = () => {
    const classes = [styles.gridContainer];
    if (!selectedCampaignId) {
      classes.push(styles.lowSearchHeader);
    }
    return classes.join(' ');
  };

  useEffect(() => {
    if (filterGroups === previousFilterGroups) {
      return;
    }
    if (rowVirtualizerRef.current) {
      rowVirtualizerRef.current.scrollToIndex(0);
    }
    if (gridScrollRef.current) {
      gridScrollRef.current.scrollTo(0, 0);
    }
    sessionStorage.setItem(GRID_SCROLL_TOP_KEY, '0');
    sessionStorage.setItem(TABLE_SCROLL_TOP_KEY, '0');
  }, [filterGroups, previousFilterGroups]);

  const renderCreatorCard = useCallback(
    (creator: SearchDisplayCreator) => {
      const matchedPostsImages = creator.matchedPosts.map(
        (matchedPost) => matchedPost.imageUrl
      );

      return (
        <CreatorCard
          key={creator.id}
          id={creator.id}
          fullName={creator.fullName}
          email={creator.email}
          profileImage={getProfileImageUrl(creator.profileImage)}
          categories={creator.categories}
          age={creator.age?.toString()}
          location={creator.location}
          images={
            [...matchedPostsImages, ...(creator.portfolioImages ?? [])].slice(
              0,
              3
            ) as [string, string, string]
          }
          socialNetworks={creator.socialNetworks}
          selectedSocialNetworkProvider={creator.selectedPlatform}
          isOverflowMenuEnabled={creatorCardOverflowMenuEnabled}
          creatorCardCtaProps={creatorCardCtaProps}
          creatorCardOverflowMenuItemsCallBack={
            creatorCardOverflowMenuItemsCallBack
          }
          rating={creator.rating}
          signupDate={creator.signupDate}
          creatorProfileUrl={getCreatorProfileModalUrl(creator.id)}
          onSocialNetworkClick={onSocialNetworkClick}
          onCardClick={onCreatorClickAnalytics}
        />
      );
    },
    [
      creatorCardOverflowMenuEnabled,
      creatorCardOverflowMenuItemsCallBack,
      creatorCardCtaProps,
      onCreatorClickAnalytics,
      onSocialNetworkClick,
    ]
  );

  const renderCreatorsDisplayView = () => {
    switch (viewType) {
      case VIEW_TYPE_TABLE: {
        return (
          <div
            className={styles.searchCreatorTableContainer}
            ref={tableContainerRef}
          >
            <Table
              data={searchDisplayCreators}
              columns={columns}
              isLoadingInitial={
                isSearchCreatorsLoading ||
                searchCreatorsQueryDisabled ||
                isSearchCreatorsRefetching
              }
              isLoading={isSearchCreatorsFetching}
              showProgressBars={isSearchCreatorsRefetching}
              visibleColumns={visibleColumns}
              rowSelection={selectedCreatorsMap}
              sorting={mapSorting()}
              onSorting={onSortingChange}
              onRowClick={onCreatorClick}
              classes={{
                container: getTableContainerStyles(),
                footer: selectedCreators.size > 0 ? styles.highFooter : '',
              }}
              onEndReached={handleFetchNextPage}
              enableVirtualization
              rowVirtualizerRef={rowVirtualizerRef}
              tableScrollTopKey={TABLE_SCROLL_TOP_KEY}
            />
          </div>
        );
      }
      case VIEW_TYPE_GRID: {
        return (
          <div ref={gridContainerRef}>
            <VirtualizedGrid
              {...creatorsVirtualizedGridProps}
              containerClassName={getGridContainerStyles()}
              data={searchDisplayCreators}
              onEndReached={handleFetchNextPage}
              isInitialLoading={
                isSearchCreatorsLoading || isSearchCreatorsRefetching
              }
              showLoader={isSearchCreatorsFetching}
              skeletonsAmount={creatorsVirtualizedGridProps.columns * 2}
              renderItemSkeleton={(index: number) => (
                <CreatorCardSkeleton key={index} />
              )}
              gridScrollRef={gridScrollRef}
              gridScrollTopKey={GRID_SCROLL_TOP_KEY}
              renderItem={renderCreatorCard}
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return noResults ? renderEmptyState() : renderCreatorsDisplayView();
}

export default SearchCreatorsDisplay;
