import React from 'react';
import {Modal} from '@lightricks/react-design-system';
import {BrandSafetyToleranceSettings} from '@/types/brandSafetyToleranceSettings';
import useHandleToleranceSettingsModal from '@/views/vetting/components/brand-safety-tolerance-settings-modal/useHandleToleranceSettingsModal';
import ToleranceSettings from '@/views/vetting/components/tolerance-settings';
import styles from './BrandSafetyToleranceSettingsModal.module.scss';

interface BrandSafetyToleranceSettingsModalProps {
  toleranceSettings: BrandSafetyToleranceSettings;
}

function BrandSafetyToleranceSettingsModal(
  props: BrandSafetyToleranceSettingsModalProps
) {
  const {toleranceSettings} = props;
  const {isOpen, close} = useHandleToleranceSettingsModal();

  const handleClose = () => {
    // TODO: handle dirty state => do not close if any changes were made
    close();
  };

  return (
    <Modal
      open={isOpen}
      showCloseButton
      handleClose={handleClose}
      className={styles.modal}
    >
      <ToleranceSettings
        toleranceSettings={toleranceSettings}
        close={handleClose}
      />
    </Modal>
  );
}

export default BrandSafetyToleranceSettingsModal;
