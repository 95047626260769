import React from 'react';
import {designSystemToken} from '@lightricks/react-design-system';
import MosaicGallery from '@/components/mosaic-gallery';
import Skeleton from '@/components/skeleton';
import {CreatorInfoSkeleton} from '@/components/suggested-creators/components/creator-info';
import {
  CREATOR_CARD_GALLERY_SETTINGS,
  SMALL_SCREEN_CONTAINER_WIDTH_THRESHOLD,
} from '../../utils/constants';
import getGalleryLayout from '../../utils/getGalleryLayout';
import styles from './CreatorCard.module.scss';

const dummyMediaItems = [...Array(7)].map((_, index) => ({
  id: String(index),
  src: '',
}));

// eslint-disable-next-line import/prefer-default-export
export function CreatorCardSkeleton({
  containerWidth,
}: {
  containerWidth: number;
}) {
  const isSmallScreen = containerWidth < SMALL_SCREEN_CONTAINER_WIDTH_THRESHOLD;
  return (
    <div className={styles.creatorCardContainer}>
      <div className={styles.leftSection}>
        <div className={styles.tags} />
        <MosaicGallery
          images={dummyMediaItems}
          layouts={getGalleryLayout(
            isSmallScreen
              ? Math.min(5, dummyMediaItems.length)
              : dummyMediaItems.length,
            'dummy',
            isSmallScreen
          )}
          fillMissing={false}
          {...(isSmallScreen
            ? CREATOR_CARD_GALLERY_SETTINGS.SMALL
            : CREATOR_CARD_GALLERY_SETTINGS.DEFAULT)}
          showSkeletons
        />
      </div>
      <div className={styles.rightSection}>
        <div
          className={`${styles.rightSectionContent} ${
            isSmallScreen ? styles.smallScreen : ''
          }`}
        >
          <CreatorInfoSkeleton />
          <div className={styles.skillsStatsContainer}>
            <Skeleton
              variant="rounded"
              width="100%"
              height={74}
              backgroundColor={designSystemToken(
                'semantic.bg.warning-secondary'
              )}
            />
            <div style={{display: 'flex', gap: 8}}>
              <Skeleton variant="rounded" width="100%" height={74} />
              <Skeleton variant="rounded" width="100%" height={74} />
            </div>
            <Skeleton variant="rounded" width="100%" height={74} />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
          </div>
        </div>
      </div>
    </div>
  );
}
