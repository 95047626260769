import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import getFormattedDuration from '@/utils/getFormattedDuration';
import translate from '@/utils/translate';
import {
  CombinedPostAnalysis,
  CombinedPostAnalysisFailure,
} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import useFittedElementsInContainer from '@/hooks/use-fitted-elements-in-container';
import {
  creatorVettingReportActions,
  ReportSettings,
} from '@/stores/creatorVettingReportStore';
import {FAILED_SCAN_FLAG} from '../../constants';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import getSeverityFromFlagScore from '../../utils/getSeverityFromFlagScore';
import PreviewOverlay from '../preview-overlay';
import styles from './ProfileVettingContentItemPosts.module.scss';

const MAX_MINIMIZED_DISPLAYED_POSTS_AMOUNT = 5;
const IMAGE_WIDTH = 140;
const GAP = 16;

interface ContentItemPostProps {
  flag: string;
  post: CombinedPostAnalysis | CombinedPostAnalysisFailure;
  hoveredPostId?: string | null;
  remainingPosts: number;
  showSeverityIndicator: boolean;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

function PostPreviewImage(props: {url: string}) {
  const {url} = props;
  const [showUnavailableOverlay, setShowUnavailableOverlay] = useState(false);
  return (
    <div className={styles.postPreviewImage}>
      <Conditional condition={!url || showUnavailableOverlay}>
        <PreviewOverlay
          iconName="Navigation-Photos-Missing"
          label={translate('Preview Unavailable')}
        />
      </Conditional>
      <Conditional condition={!!url && !showUnavailableOverlay}>
        <img
          src={url}
          onError={() => setShowUnavailableOverlay(true)}
          alt="post"
        />
      </Conditional>
    </div>
  );
}

function SeverityIndicator(props: {score: number}) {
  const {score} = props;
  const severity = getSeverityFromFlagScore(score);
  return (
    <div className={styles.severityIndicatorContainer}>
      <div className={`${styles.severityIndicator} ${styles[severity]}`} />
      <Label
        size="xxs"
        className="severity-indicator-text"
        color={designSystemToken('semantic.fg.secondary')}
      >
        {translate(`${severity.toLowerCase()}-risk`)}
      </Label>
    </div>
  );
}

function ContentItemPost(props: ContentItemPostProps) {
  const {
    flag,
    post,
    hoveredPostId,
    remainingPosts,
    showSeverityIndicator,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const videoDurationSeconds = useMemo(() => {
    return post.mediaMetadata.videoDurationSeconds || 0;
  }, [flag, post]);

  const getFlagScore = () => {
    if (flag === FAILED_SCAN_FLAG) {
      return 0;
    }
    const {flags} = (post as CombinedPostAnalysis).mediaAnalysisResult;
    return flags?.[flag]?.score;
  };

  const getPostUrl = (
    postAnalysis: CombinedPostAnalysis | CombinedPostAnalysisFailure
  ) => {
    const {mediaMetadata} = postAnalysis;
    const {thumbnailUrl, url, mediaType} = mediaMetadata;
    if (mediaType === 'video') {
      return thumbnailUrl || '';
    }
    return thumbnailUrl || url || '';
  };

  return (
    <div className={styles.postContainer}>
      <div
        className={`${styles.contentItemPost} ${
          hoveredPostId === post.id ? styles.hover : ''
        }`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {videoDurationSeconds > 0 ? (
          <div className={styles.duration}>
            <Label size="xs" color={designSystemToken('semantic.fg.primary')}>
              {getFormattedDuration(videoDurationSeconds)}
            </Label>
          </div>
        ) : null}
        {remainingPosts > 0 ? (
          <div className={styles.overlay}>
            <Label size="xl" color={designSystemToken('semantic.fg.white')}>
              +{remainingPosts}
            </Label>
          </div>
        ) : null}
        <PostPreviewImage url={getPostUrl(post)} />
      </div>
      {showSeverityIndicator ? (
        <SeverityIndicator score={getFlagScore()} />
      ) : null}
    </div>
  );
}

function ProfileVettingContentItemPosts(props: {
  posts: CombinedPostAnalysis[] | CombinedPostAnalysisFailure[];
  flag: string;
  report: ReportSettings;
  seeAll: boolean;
  setSeeAll: (seeAll: boolean) => void;
  onPostClick: (flag: string, postId: string) => void;
}) {
  const {posts, flag, report, seeAll, setSeeAll, onPostClick} = props;

  const postsContainerRef = React.useRef<HTMLDivElement>(null);

  const maxMinimizedDisplayedPostsAmount = useFittedElementsInContainer(
    postsContainerRef,
    IMAGE_WIDTH,
    GAP,
    MAX_MINIMIZED_DISPLAYED_POSTS_AMOUNT
  );

  const displayedPosts = seeAll
    ? posts
    : posts.slice(0, maxMinimizedDisplayedPostsAmount);
  const remainingPosts = posts.length - displayedPosts.length;

  useEffect(() => {
    if (displayedPosts.length < maxMinimizedDisplayedPostsAmount && seeAll) {
      setSeeAll(false);
    }
  }, [displayedPosts, maxMinimizedDisplayedPostsAmount, seeAll]);

  const getShowSeverityIndicator = useCallback(
    (index: number) => {
      if (flag === FAILED_SCAN_FLAG) {
        return false;
      }
      if (seeAll || remainingPosts === 0) {
        return true;
      }
      return index + 1 !== maxMinimizedDisplayedPostsAmount;
    },
    [seeAll, remainingPosts, flag]
  );

  return (
    <div className={styles.profileVettingContentItemPostsContainer}>
      <div
        className={styles.postsContainer}
        ref={postsContainerRef}
        data-testid={
          flag === FAILED_SCAN_FLAG
            ? CREATOR_VETTING_REPORT_TEST_IDS.FAILED_CONTENT__POSTS
            : CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT__POSTS
        }
      >
        {displayedPosts.map((post, index) => (
          <ContentItemPost
            key={post.id}
            flag={flag}
            post={post}
            hoveredPostId={report?.hoveredPostId}
            remainingPosts={
              !seeAll &&
              index + 1 === maxMinimizedDisplayedPostsAmount &&
              remainingPosts > 0
                ? remainingPosts
                : 0
            }
            showSeverityIndicator={getShowSeverityIndicator(index)}
            onClick={() => {
              if (index === displayedPosts.length - 1 && remainingPosts > 0) {
                setSeeAll(true);
                if (report?.id) {
                  creatorVettingReportActions.setSeeAllFlagPosts(
                    report?.id,
                    flag,
                    true
                  );
                }
              } else {
                onPostClick(flag, post.id);
              }
            }}
            onMouseEnter={() => {
              if (report?.id) {
                creatorVettingReportActions.setHoveredPostId(
                  report?.id,
                  post.id
                );
              }
            }}
            onMouseLeave={() => {
              if (report?.id) {
                creatorVettingReportActions.setHoveredPostId(report?.id, null);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ProfileVettingContentItemPosts;
