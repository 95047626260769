import React from 'react';
import {Outlet} from 'react-router-dom';
import {BrandSafetyToleranceSettings} from '@/types/brandSafetyToleranceSettings';
import BrandSafetyToleranceSettingsModal from '@/views/vetting/components/brand-safety-tolerance-settings-modal';
import Conditional from '@/components/conditional';
import useBrandSafetyToleranceSettingsQuery from '@/hooks/queries/use-brand-safety-tolerance-settings-query';
import useBrandId from '@/hooks/use-brand-id';

/**
 * Shared route for the vetting hub
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Vetting() {
  const brandId = useBrandId();

  const {toleranceSettings, isLoading, isError} =
    useBrandSafetyToleranceSettingsQuery({
      brandId,
    });

  // TODO: add checks for permissions and render accordingly
  return (
    <>
      <Outlet />
      <Conditional condition={!isLoading && !isError}>
        <BrandSafetyToleranceSettingsModal
          toleranceSettings={toleranceSettings as BrandSafetyToleranceSettings}
        />
      </Conditional>
    </>
  );
}

export default Vetting;
