import {isEqual} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {
  Body,
  Button,
  designSystemToken,
  Headline,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import getPlatformDisplayNameFromProvider from '@/utils/getPlatformDisplayNameFromProvider';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import {CombinedPostMetadata, SocialNetwork} from '@/types/creatorSafetyReport';
import {IdentityPostEmbedding} from '@/types/identityPostEmbedding';
import Conditional from '@/components/conditional';
import CreatorProfileAvatar from '@/components/creator-profile-avatar';
import InfiniteLoader from '@/components/infinite-loader';
import Link from '@/components/link';
import VideoPlayer from '@/components/video-player';
import useIdentityPostEmbeddingQuery from '@/hooks/queries/use-identity-post-embedding-query';
import PostPanelCarouselMessage from '../post-panel-carousel-message';
import styles from './PostPanelEmbeddedMedia.module.scss';

const BOTTOM_PADDING = 32;
const TOP_HEADER_HEIGHT = 82;
const TIKTOK_ASPECT_RATIO = 0.3;
const TIKTOK_MIN_WIDTH = 325;

interface PostPanelEmbeddedMediaProps {
  useInternalVideoPlayer?: boolean;
  useInternalImageViewer?: boolean;
  isVideo?: boolean;
  mediaUrl: string | null;
  thumbnailUrl: string | null;
  postMetadata: CombinedPostMetadata;
  creatorName: string | null;
  creatorAvatar: string | null;
  socialNetwork?: SocialNetwork;
  isPostAnalysis?: boolean;
  trackAction?: (actionName: string, actionValue: string) => void;
}

interface MediaOverlayProps {
  icon: React.ReactNode | null;
  title?: string;
  subtitle?: string;
  cta?: React.ReactNode;
  showOverlay?: boolean;
  media?: React.ReactNode;
  fixedWidth?: boolean;
  url?: string;
  style?: React.CSSProperties;
  trackAction?: (actionName: string, actionValue: string) => void;
}

function Footer({label}: {label: string}) {
  return (
    <div className={styles.footer}>
      <Body size="sm" color={designSystemToken('semantic.fg.secondary')}>
        {label}
      </Body>
    </div>
  );
}

function getDimensionsObject(width: number, height: number) {
  return {
    width,
    height,
    aspectRatio: height
      ? width / (height + BOTTOM_PADDING + TOP_HEADER_HEIGHT)
      : 0.4,
  };
}

const DEFAULT_THUMBNAIL_DIMENSIONS = {
  width: '100%',
  height: '100%',
  aspectRatio: 0.4,
};

function useThumbnailDimensions(
  thumbnailUrl: string | null,
  dataFromServer: IdentityPostEmbedding | null | undefined,
  isLoadingFromServer: boolean
) {
  const [thumbnailDimensions, setThumbnailDimensions] = useState<null | {
    width: number | string;
    height: number | string;
    aspectRatio: number;
  }>(null);
  useEffect(() => {
    if (dataFromServer?.forceShowNoContentOverlay) {
      setThumbnailDimensions(DEFAULT_THUMBNAIL_DIMENSIONS);
      return;
    }
    if (
      dataFromServer?.size &&
      (dataFromServer?.html || dataFromServer?.thumbnailUrl)
    ) {
      setThumbnailDimensions(
        getDimensionsObject(
          dataFromServer.size.width,
          dataFromServer.size.height
        )
      );
    } else if (thumbnailUrl && !isLoadingFromServer) {
      const img = new Image();
      img.src = thumbnailUrl;
      img.onload = () => {
        setThumbnailDimensions(getDimensionsObject(img.width, img.height));
      };
      img.onerror = () => {
        setThumbnailDimensions(DEFAULT_THUMBNAIL_DIMENSIONS);
      };
    }
  }, [thumbnailUrl, dataFromServer, isLoadingFromServer]);

  return thumbnailDimensions;
}

function MediaWithOverlay(props: MediaOverlayProps) {
  const {
    icon,
    title,
    subtitle,
    cta,
    showOverlay = true,
    media,
    fixedWidth = false,
    url,
    style,
    trackAction,
  } = props;

  const mediaElement =
    url && media ? (
      <Link
        to={url}
        onClick={() => trackAction?.('Open content media', url)}
        className={`${styles.thumbnail} ${styles.link}`}
        target="_blank"
        keepBrandIdIfPresent={false}
      >
        {media}
      </Link>
    ) : (
      media
    );

  return (
    <div
      className={`${styles.mediaWithOverlayContainer} ${
        fixedWidth ? styles.fixedWidth : ''
      } ${!title && !subtitle ? styles.noPadding : ''}`}
      style={style}
    >
      <Conditional condition={showOverlay}>
        <div
          className={`${styles.mediaOverlay} ${
            !title && !subtitle ? styles.iconOnly : ''
          }`}
        >
          <div className={styles.top}>
            {icon}
            <Conditional condition={!!title}>
              <Headline
                size="md"
                color={designSystemToken('semantic.fg.inverse')}
              >
                {title}
              </Headline>
            </Conditional>
            <Conditional condition={!!subtitle}>
              <Body
                size="md"
                className={styles.subtitle}
                color={designSystemToken('semantic.fg.tertiary')}
              >
                {subtitle}
              </Body>
            </Conditional>
          </div>
          <Conditional condition={!!cta}>
            <div className={styles.bottom}>{cta}</div>
          </Conditional>
        </div>
      </Conditional>
      <Conditional condition={!!mediaElement}>{mediaElement}</Conditional>
    </div>
  );
}

function CreatorProfileLink(props: {
  creatorName: string | null;
  creatorAvatar: string | null;
  socialNetwork?: SocialNetwork;
  trackAction?: (actionName: string, actionValue: string) => void;
}) {
  const {creatorName, creatorAvatar, socialNetwork, trackAction} = props;

  if (!socialNetwork) {
    return null;
  }

  return (
    <div className={styles.creatorProfileLink}>
      <div className={styles.details}>
        <CreatorProfileAvatar
          className={styles.avatar}
          profileImageUrl={creatorAvatar || ''}
          profileDisplayName={creatorName || ''}
          size="2xs"
        />
        <Label size="md" color={designSystemToken('semantic.fg.white')}>
          {socialNetwork.handle}
        </Label>
      </div>
      <Link
        to={socialNetwork.profilePageUrl}
        onClick={() =>
          trackAction?.(
            translate('View profile'),
            socialNetwork?.profilePageUrl
          )
        }
        keepBrandIdIfPresent={false}
        target="_blank"
      >
        <Button appearance="neutral" mode="elevated" size="small">
          {translate('View profile')}
        </Button>
      </Link>
    </div>
  );
}

function PostPanelEmbeddedMedia({
  useInternalVideoPlayer = false,
  useInternalImageViewer = false,
  isVideo = false,
  mediaUrl,
  thumbnailUrl,
  postMetadata,
  creatorName,
  creatorAvatar,
  socialNetwork,
  isPostAnalysis,
  trackAction,
}: PostPanelEmbeddedMediaProps) {
  const [fallbackToThumbnail, setFallbackToThumbnail] = useState(false);
  const [showSensitive, setShowSensitive] = useState(false);
  const [showCarouselMessage, setShowCarouselMessage] = useState(false);
  const {id, url} = postMetadata;

  const isEnabled =
    !!id && !!url && !useInternalImageViewer && !useInternalVideoPlayer;

  const {data, isLoading} = useIdentityPostEmbeddingQuery({
    provider: postMetadata.socialNetwork || '',
    url,
    enabled: isEnabled,
  });

  const dimensions = useThumbnailDimensions(thumbnailUrl, data, isEnabled);

  const aspectRatio = postMetadata.socialNetwork?.includes?.('tiktok')
    ? TIKTOK_ASPECT_RATIO
    : dimensions?.aspectRatio || 0;

  const embedHtml = data?.html || '';

  useEffect(() => {
    if (
      !isLoading &&
      isEnabled &&
      embedHtml &&
      postMetadata.mediaIndex.length &&
      !isEqual(postMetadata.mediaIndex, [0])
    ) {
      setShowCarouselMessage(true);
    }
  }, [isLoading, isEnabled, embedHtml, postMetadata]);

  const isStory = postMetadata.socialStream === 'story';
  const maxThumbnailHeight =
    isStory || (!data?.isEmbeddable && isVideo) ? '95%' : '100%';

  const style = useMemo(() => {
    const baseStyle: React.CSSProperties = {
      maxHeight: maxThumbnailHeight,
    };
    if (aspectRatio) {
      baseStyle.aspectRatio = aspectRatio;
    }
    if (postMetadata.socialNetwork?.includes?.('tiktok')) {
      baseStyle.minWidth = TIKTOK_MIN_WIDTH;
    } else if (dimensions?.width && !isStory) {
      baseStyle.width = dimensions.width;
    }
    return baseStyle;
  }, [aspectRatio, maxThumbnailHeight, postMetadata, dimensions, isStory]);

  const creatorProfileLink = (
    <CreatorProfileLink
      creatorName={creatorName}
      creatorAvatar={creatorAvatar}
      socialNetwork={socialNetwork}
    />
  );

  const embeddedHtmlElement = (
    <div
      className={styles.postPanelEmbeddedMedia}
      style={style}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{__html: embedHtml}}
    />
  );

  const videoPlayButtonIcon = (
    <Link
      to={postMetadata.url}
      onClick={() => trackAction?.('Open content media', postMetadata.url)}
      target="_blank"
      keepBrandIdIfPresent={false}
    >
      <Icon
        size="large"
        appearance="neutral"
        name="StateBar-Play"
        color={designSystemToken('semantic.fg.inverse')}
      />
    </Link>
  );

  const sensitiveThumbnail = (
    <>
      {creatorProfileLink}
      <img
        className={`${styles.thumbnail} ${
          data?.isSensitive && !showSensitive ? styles.blurry : ''
        }`}
        src={thumbnailUrl || ''}
        alt="thumbnail"
        style={style}
      />
    </>
  );

  const thumbnail = (
    <MediaWithOverlay
      icon={isVideo ? videoPlayButtonIcon : null}
      url={postMetadata.url}
      style={style}
      media={
        <>
          {creatorProfileLink}
          <img
            src={thumbnailUrl || ''}
            className={styles.thumbnail}
            style={style}
            alt="thumbnail"
          />
        </>
      }
    />
  );

  const loaderElement = (
    <div className={styles.spinnerContainer}>
      <InfiniteLoader
        isLoading={isLoading}
        containerClassName={styles.spinnerInnerContainer}
      />
    </div>
  );

  const openOriginalPostCta = (
    <Link
      to={postMetadata.url}
      onClick={() =>
        trackAction?.(
          translate('Go to post', {
            platform: getPlatformDisplayNameFromProvider(
              socialNetwork?.provider
            ),
          }),
          postMetadata.url
        )
      }
      target="_blank"
      keepBrandIdIfPresent={false}
    >
      <Button appearance="overlay" mode="filled" size="small">
        {translate('Go to post', {
          platform: getPlatformDisplayNameFromProvider(socialNetwork?.provider),
        })}
      </Button>
    </Link>
  );

  const storyUnavailableOverlay = (
    <MediaWithOverlay
      icon={
        <Icon
          size="medium"
          appearance="neutral"
          name="Navigation-Attention-Line"
          color={designSystemToken('semantic.fg.inverse')}
        />
      }
      title={translate('Story Unavailable')}
      fixedWidth
      media={creatorProfileLink}
      style={style}
    />
  );

  const contentUnavailableVideoRemovedOverlay = (
    <MediaWithOverlay
      icon={
        <Icon
          size="medium"
          appearance="neutral"
          name="Navigation-Attention-Line"
          color={designSystemToken('semantic.fg.inverse')}
        />
      }
      title={translate('Content Unavailable')}
      subtitle={
        isPostAnalysis
          ? translate(
              'This video has been removed, but you can still see why it was flagged in the summary.'
            )
          : translate('This video has been removed.')
      }
      fixedWidth
      media={creatorProfileLink}
    />
  );

  const contentUnavailableOrPrivateOverlay = (
    <MediaWithOverlay
      icon={
        <Icon
          size="medium"
          appearance="neutral"
          name="Navigation-Attention-Line"
          color={designSystemToken('semantic.fg.inverse')}
        />
      }
      title={translate('Content Unavailable')}
      subtitle={translate(
        'This was deleted or is private. If it’s private, you may be able to see it by logging in.'
      )}
      cta={openOriginalPostCta}
      fixedWidth
      media={creatorProfileLink}
    />
  );

  const contentUnavailableOrFlaggedOverlay = (
    <MediaWithOverlay
      icon={
        <Icon
          size="medium"
          appearance="neutral"
          name="Navigation-Attention-Line"
          color={designSystemToken('semantic.fg.inverse')}
        />
      }
      title={translate('Content Unavailable')}
      subtitle={translate(
        'This was deleted or restricted. If it’s restricted, you may be able to see it by logging in.'
      )}
      cta={openOriginalPostCta}
      fixedWidth
      media={creatorProfileLink}
    />
  );

  const contentUnavailableOrFlaggedOverlayWithProfileLink = (
    <div
      className={`${styles.postPanelEmbeddedMedia} ${styles.thumbnail}`}
      style={style}
    >
      {creatorProfileLink}
      {contentUnavailableOrFlaggedOverlay}
    </div>
  );

  const internalVideoPlayerWithProfileLink = (
    <div className={`${styles.postPanelEmbeddedMedia}`} style={style}>
      {creatorProfileLink}
      <Conditional condition={!!dimensions}>
        <VideoPlayer
          containerClassName={styles.videoPlayerContainer}
          url={mediaUrl || ''}
          onError={() => setFallbackToThumbnail(true)}
          onPlay={(isInitialPlay: boolean) => {
            if (isInitialPlay) {
              trackAction?.('Play video', postMetadata.url);
            }
          }}
          customLoader={loaderElement}
          playerStyle={{
            width: 'unset',
            height: '100%',
            background: 'transparent',
          }}
        />
      </Conditional>
    </div>
  );

  const imageViewerWithProfileLink = (
    <div
      className={`${styles.postPanelEmbeddedMedia} ${styles.thumbnail}`}
      style={style}
    >
      {creatorProfileLink}
      <img
        src={thumbnailUrl || ''}
        alt="thumbnail"
        onError={() =>
          queryClient.setQueryData(
            ['socialNetworkPostEmbedding', postMetadata.socialNetwork, url],
            {forceShowNoContentOverlay: true}
          )
        }
      />
    </div>
  );

  const nonEmbeddableContent = thumbnail;

  const sensitiveButtonIcon = !showSensitive ? (
    <Icon
      size="medium"
      appearance="neutral"
      name="Features-Eyes-Hide"
      color={designSystemToken('semantic.fg.inverse')}
    />
  ) : null;

  const sensitiveContentOverlay = (
    <MediaWithOverlay
      url={isVideo && data?.isSensitive ? postMetadata.url : undefined}
      icon={
        isVideo && showSensitive ? videoPlayButtonIcon : sensitiveButtonIcon
      }
      title={!showSensitive ? translate('Sensitive Content') : undefined}
      subtitle={
        !showSensitive
          ? translate('It may contain graphic or violent material.')
          : undefined
      }
      cta={
        data?.isSensitive && !showSensitive ? (
          <Button
            appearance="overlay"
            mode="filled"
            size="small"
            onClick={() => {
              setShowSensitive(true);
              trackAction?.(translate('See Anyways'), postMetadata.url);
            }}
          >
            {translate('See Anyways')}
          </Button>
        ) : null
      }
      media={sensitiveThumbnail}
      fixedWidth
    />
  );

  const getContent = () => {
    if (data?.forceShowNoContentOverlay) {
      if (isStory) {
        return storyUnavailableOverlay;
      }
      return contentUnavailableOrFlaggedOverlayWithProfileLink;
    }

    if (useInternalVideoPlayer && mediaUrl && !fallbackToThumbnail) {
      return internalVideoPlayerWithProfileLink;
    }

    if (useInternalImageViewer || fallbackToThumbnail) {
      if (thumbnailUrl) {
        return imageViewerWithProfileLink;
      }
      return contentUnavailableOrFlaggedOverlayWithProfileLink;
    }

    if (isLoading && isEnabled) {
      return loaderElement;
    }

    if (data?.isAvailable && !data?.isEmbeddable) {
      return nonEmbeddableContent;
    }

    if (data?.isAvailable && data?.isSensitive) {
      return sensitiveContentOverlay;
    }

    if (!data?.isAvailable && postMetadata.socialNetwork === 'youtube') {
      return contentUnavailableVideoRemovedOverlay;
    }

    if (
      (!data?.isAvailable || data?.isPrivate) &&
      postMetadata.socialNetwork?.includes?.('tiktok')
    ) {
      return contentUnavailableOrPrivateOverlay;
    }

    if (!data?.isAvailable || data?.isPrivate) {
      return contentUnavailableOrFlaggedOverlay;
    }

    if (!isLoading && isEnabled && embedHtml) {
      return embeddedHtmlElement;
    }

    return null;
  };

  const getFooter = () => {
    if (isStory) {
      const label =
        isVideo && !fallbackToThumbnail
          ? translate('Instagram story note exclusive')
          : translate('Instagram story note');
      return <Footer label={label} />;
    }
    if (data?.isAvailable && !data.isEmbeddable && isVideo) {
      return <Footer label={translate('Non embeddable note')} />;
    }
    return null;
  };

  const getCarouselMessage = () => {
    if (!showCarouselMessage) {
      return null;
    }
    return (
      <div
        className={styles.carouselMessageContainer}
        onClick={(e) => {
          setShowCarouselMessage(false);
        }}
      >
        <PostPanelCarouselMessage
          flaggedMediaIndex={postMetadata.mediaIndex ?? [0]}
        />
      </div>
    );
  };

  return (
    <div className={styles.postPanelEmbeddedMediaContainer}>
      {getContent()}
      {getCarouselMessage()}
      {getFooter()}
    </div>
  );
}

export default PostPanelEmbeddedMedia;
