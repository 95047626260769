import {
  CampaignProps,
  PreferredCreatorCity,
  PreferredCreatorCountry,
  PreferredCreatorState,
} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefField from '@/views/campaigns/brief/brief-field-manager/brief-field/BriefField';
import {LocationType} from '@/enums/locationType';

function mapLocations(
  locations:
    | PreferredCreatorState[]
    | PreferredCreatorCity[]
    | PreferredCreatorCountry[],
  type: LocationType
) {
  return (
    locations?.map((location) => ({
      ...location,
      type,
    })) || []
  );
}

class CampaignBriefCreatorLocations extends BriefField {
  briefFieldManager: BriefFieldManagerAttributes;

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    value: any
  ) {
    super(briefFieldManager, campaignData, key, value);
    this.briefFieldManager = briefFieldManager;
    this.value = [
      ...mapLocations(
        campaignData?.preferredCreatorCountries || [],
        LocationType.COUNTRY
      ),
      ...mapLocations(
        campaignData?.preferredCreatorStates || [],
        LocationType.STATE
      ),
      ...mapLocations(
        campaignData?.preferredCreatorCities || [],
        LocationType.CITY
      ),
    ];
  }

  set value(value: any) {
    super.value = value;
  }

  get value() {
    return super.value;
  }
}

export default CampaignBriefCreatorLocations;
