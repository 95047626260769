import React from 'react';
import {Box} from '@mui/material';
import {Body, Button, Headline, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import Link from '@/components/link';
import styles from './PostPanelHeader.module.scss';
import {Social, postTypeTitles} from './PostPanelHeaderTypes';

export type PostPanelHeaderProps = {
  type: Social;
  mediaIndex: number[];
  date: string;
  url: string;
  shouldShowNavigateButton?: boolean;
  trackAction?: (actionName: string, actionValue: string) => void;
};

function getIcon(type: Social) {
  switch (type) {
    case postTypeTitles.InstagramPost:
    case postTypeTitles.InstagramCarousel:
      return <Icon name="Social-Instagram" size="large" appearance="neutral" />;
    case postTypeTitles.InstagramStory:
      return (
        <Icon
          name="Social-InstagramStories"
          size="large"
          appearance="neutral"
        />
      );
    case postTypeTitles.InstagramReel:
      return (
        <Icon name="Social-InstagramReels" size="large" appearance="neutral" />
      );
    case postTypeTitles.TikTokVideo:
      return <Icon name="Social-TikTok" size="large" appearance="neutral" />;
    case postTypeTitles.YouTubeVideo:
      return <Icon name="Social-YouTube" size="large" appearance="neutral" />;
    case postTypeTitles.YouTubeShorts:
      return <Icon name="Social-YTShorts" size="large" appearance="neutral" />;
    case postTypeTitles.FacebookPost:
      return <Icon name="Social-Facebook" size="large" appearance="neutral" />;
    case postTypeTitles.TwitterPost:
      return <Icon name="Social-Twitter" size="large" appearance="neutral" />;
    case postTypeTitles.PinterestPost:
      return <Icon name="Social-Pinterest" size="large" appearance="neutral" />;
    case postTypeTitles.Post:
      return <Icon name="Social-Instagram" size="large" appearance="neutral" />;
    default:
      return null;
  }
}

export default function PostPanelHeader({
  type,
  mediaIndex,
  date,
  url,
  shouldShowNavigateButton = true,
  trackAction,
}: PostPanelHeaderProps) {
  const SocialIcon = getIcon(type);
  return (
    <Box className={styles.postPanelHeaderContainer}>
      <Box className={styles.iconAndTitle}>
        <Box className={styles.socialIcon}>{SocialIcon}</Box>
        <Box className={styles.titleContainer}>
          <Headline size="xxs">
            {translate(type, {
              index: mediaIndex.map((index) => index + 1).join(', '),
            })}
          </Headline>
          <Body size="sm" className={styles.subtitle}>
            {date}
          </Body>
        </Box>
      </Box>
      <Conditional condition={shouldShowNavigateButton}>
        <Link
          to={url}
          target="_blank"
          className={styles.navigateButton}
          keepBrandIdIfPresent={false}
          onClick={() => trackAction?.('Navigate to post', url)}
        >
          <Button
            appearance="neutral"
            mode="tinted"
            size="medium"
            icon
            className={styles.shareIcon}
          >
            <Icon size="medium" appearance="neutral" name="Actions-Send" />
          </Button>
        </Link>
      </Conditional>
    </Box>
  );
}
