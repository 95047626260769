import React from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import Conditional from '@/components/conditional';

export enum ArrowDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

interface ArrowProps {
  buttonClassName?: string;
  direction: ArrowDirection;
  disabled?: boolean;
  onClick: (direction: ArrowDirection) => void;
  renderIcon?: () => React.ReactNode;
}

function Arrow(props: ArrowProps) {
  const {buttonClassName, direction, disabled, onClick, renderIcon} = props;
  return (
    <Button
      className={buttonClassName}
      appearance="neutral"
      mode="plain"
      size="small"
      disabled={disabled}
      onClick={() => onClick(direction)}
      style={{
        transform:
          direction === ArrowDirection.RIGHT ? 'scaleX(-1)' : undefined,
      }}
    >
      <Conditional
        condition={!!renderIcon}
        fallback={
          <Icon
            size="large"
            appearance={disabled ? 'disabled' : 'neutral'}
            name="Actions-Arrow-Back-Small"
          />
        }
      >
        {renderIcon?.()}
      </Conditional>
    </Button>
  );
}

export default Arrow;
