import {isEqual} from 'lodash';
import {set} from 'lodash/fp';
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
  SwitchButton,
} from '@lightricks/react-design-system';
import raiseFlashMessage, {
  raiseFlashMessageError,
} from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import {BrandSafetyToleranceSettings} from '@/types/brandSafetyToleranceSettings';
import CategoryStatus from '@/types/categoryStatusEnum';
import useTrackBrandSafetyToleranceEditEvent from '@/views/vetting/components/tolerance-settings/utils/useToleranceEditAnalytics';
import {ToleranceFilter} from '@/components/creator-vetting-report/constants';
import useTrackButtonEvent from '@/hooks/analytics/use-track-button-event';
import useUpdateBrandSafetyToleranceSettings from '@/hooks/mutations/brand-safety/use-update-brand-safety-tolerance-settings';
import useBrandId from '@/hooks/use-brand-id';
import {brandSafetyToleranceSettingsActions} from '@/stores/brandSafetyToleranceSettings';
import styles from './ToleranceSettings.module.scss';
import CategoriesList from './components/categories-list';
import CategoryFlagsList from './components/category-flags-list/CategoryFlagsList';
import Footer from './components/footer';
import Header from './components/header';

interface ToleranceSettingsProps {
  toleranceSettings: BrandSafetyToleranceSettings;
  close: () => void;
}

interface ToleranceEditState {
  previous_state: string | number;
  new_state: string | number;
}

function getDefaultState(
  categories: BrandSafetyToleranceSettings['categories']
) {
  return categories.map((category) => ({
    ...category,
    flags: category.flags.map((flag) => ({
      ...flag,
      value: 0,
    })),
    status: CategoryStatus.ENABLED,
  }));
}

function ToleranceSettings(props: ToleranceSettingsProps) {
  const [resetToDefault, setResetToDefault] = useState(false);
  const {toleranceSettings, close} = props;
  const brandId = useBrandId();
  const location = useLocation();

  const trackButtonEvent = useTrackButtonEvent();
  const updateToleranceSettings = useUpdateBrandSafetyToleranceSettings({
    brandId,
  });

  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);

  const [localToleranceSettings, setLocalToleranceSettings] =
    useState(toleranceSettings);
  const {categories} = localToleranceSettings;

  const activeCategory = categories[activeCategoryIndex];

  const trackBrandSafetyToleranceEditEvent =
    useTrackBrandSafetyToleranceEditEvent();

  const trackToleranceEdit = () => {
    /**
     * Create an object with the changes made to the tolerance settings
     * example payload:
     * {
     *     "SEXUAL": { "previous_state": "ENABLED",  "new_state": "DISABLED" },
     *     "ADDICTIVE.drugs": { "previous_state": 0.66, "new_state": 0 },
     *     "ADDICTIVE.alcohol": { "previous_state": 0, "new_state": 0.66 },
     *     "VIOLENCE_AND_DANGER.harmAndInjury": { "previous_state": 0, "new_state": 0.66 },
     * }
     */
    const toleranceChanges = localToleranceSettings.categories.reduce(
      (acc: Record<string, ToleranceEditState>, category, categoryIndex) => {
        const previousCategory = toleranceSettings.categories[categoryIndex];
        if (category.status !== previousCategory.status) {
          acc[category.name] = {
            previous_state: previousCategory.status,
            new_state: category.status,
          };
        }
        category.flags.forEach((flag, flagIndex) => {
          const previousFlag = previousCategory.flags[flagIndex];
          if (flag.value !== previousFlag.value) {
            acc[`${category.name}.${flag.name}`] = {
              previous_state: previousFlag.value,
              new_state: flag.value,
            };
          }
        });
        return acc;
      },
      {}
    );

    trackBrandSafetyToleranceEditEvent.edit({
      tolerance_changes: JSON.stringify(toleranceChanges),
      source: location.pathname,
    });
  };

  const onCategoryChange = (category: string) => {
    const index = categories.findIndex((cat) => cat.name === category);
    if (index !== activeCategoryIndex) {
      setActiveCategoryIndex(index);
    }
    trackButtonEvent.pressed({
      button_name: categories[index].displayName,
      screen_name: 'brand_safety_tolerance',
    });
  };

  const handleSwitchChange = () => {
    setResetToDefault(false);
    const newStatus =
      activeCategory.status === CategoryStatus.ENABLED
        ? CategoryStatus.DISABLED
        : CategoryStatus.ENABLED;
    setLocalToleranceSettings(
      set(
        `categories.${activeCategoryIndex}.status`,
        newStatus,
        localToleranceSettings
      )
    );
  };

  const onFlagChange = (flagId: string, value: number) => {
    setResetToDefault(false);
    const flagIndex = activeCategory.flags.findIndex(
      (flag) => flag.id === flagId
    );

    setLocalToleranceSettings(
      set(
        `categories.${activeCategoryIndex}.flags.${flagIndex}.value`,
        value,
        localToleranceSettings
      )
    );
  };

  const handleReset = () => {
    setResetToDefault(true);
    const resetCategories = getDefaultState(categories);
    setLocalToleranceSettings({
      ...localToleranceSettings,
      categories: resetCategories,
    });
    trackButtonEvent.pressed({
      button_name: 'Reset to default',
      screen_name: 'brand_safety_tolerance',
    });
  };

  const handleSubmit = async () => {
    if (isEqual(toleranceSettings, localToleranceSettings)) {
      close();
      return;
    }

    try {
      await updateToleranceSettings.mutateAsync({
        brandId,
        categories,
      });
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error;
      if (errorMessage) {
        raiseFlashMessageError({title: errorMessage, subtitle: ''});
      }
      return;
    }

    brandSafetyToleranceSettingsActions.setToleranceFilter(
      resetToDefault ? ToleranceFilter.DEFAULT : ToleranceFilter.CUSTOM
    );

    raiseFlashMessage({
      status: 'success',
      message: (
        <Headline size="xs">
          {resetToDefault
            ? translate('Risk tolerance reset to default.')
            : translate('Risk tolerance updated for all creators')}
        </Headline>
      ),
      mode: 'light',
      icon: (
        <Icon
          style={{marginTop: 2}}
          size="medium"
          appearance="neutral"
          name="Actions-Patch-Accept-Line"
        />
      ),
    });
    trackToleranceEdit();
    close();
  };

  return (
    <div className={styles.toleranceSettingsContainer}>
      <Header />
      <div className={styles.contentContainer}>
        <div className={styles.leftSection}>
          <CategoriesList
            categories={localToleranceSettings.categories}
            activeCategory={activeCategory.name}
            onCategoryChange={onCategoryChange}
          />
        </div>
        <div className={styles.rightSection}>
          <div className={styles.rightSectionContainer}>
            <div className={styles.switchSection}>
              <div
                className={styles.switchContainer}
                onClick={handleSwitchChange}
              >
                <Body
                  size="md"
                  color={designSystemToken('semantic.fg.primary')}
                >
                  {translate('Show in the report')}
                </Body>
                <SwitchButton
                  checked={activeCategory.status === CategoryStatus.ENABLED}
                  className={styles.switch}
                />
              </div>
            </div>
            <CategoryFlagsList
              category={activeCategory}
              onFlagChange={onFlagChange}
            />
          </div>
        </div>
      </div>
      <Footer handleReset={handleReset} handleSubmit={handleSubmit} />
    </div>
  );
}

export default ToleranceSettings;
