import React from 'react';
import {
  Body,
  designSystemToken,
  Headline,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './Header.module.scss';

function Header() {
  return (
    <div className={styles.headerContainer}>
      <Headline size="lg" color={designSystemToken('semantic.fg.primary')}>
        {translate('Customize risk tolerance')}
      </Headline>
      <Body size="lg" color={designSystemToken('semantic.fg.secondary')}>
        {translate("Set your brand's tolerance parameters for all creators.")}
      </Body>
    </div>
  );
}

export default Header;
