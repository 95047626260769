import {set} from 'lodash/fp';
import React, {useMemo, useRef} from 'react';
import {Body, Icon, designSystemToken} from '@lightricks/react-design-system';
import {IconProps} from '@lightricks/react-design-system/dist/components/icon/IconProps';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import {YOUTUBE_DS_ICON_OVERRIDES} from '@/config/constants';
import LEGACY_COLORS from '@/config/legacyColors';
import {CampaignBriefDeliverableItem} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefFieldHelperPopper from '@/views/campaigns/brief/components/brief-field-helper-popper';
import {SaveCampaign} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import Textarea from '@/components/textarea';
import styles from './Deliverable.module.scss';

const TRANSLATION_PREFIX =
  'components.campaign-brief.sections.deliverables.deliverable';

export const DELIVERABLE_ICON_NAME = {
  story_post: 'Social-InstagramStories',
  feed_post_photo: 'Social-Instagram',
  reel_post: 'Social-InstagramReels',
  tiktok_video: 'Social-TikTok',
  content_standard_photo: 'Navigation-Photos-Light',
  content_standard_video: 'Social-InstagramReels',
  content_premium_photo: 'Navigation-Photos-Light',
  content_premium_video: 'Social-InstagramReels',
  youtube_short_form_video: 'Social-YouTube',
  youtube_long_form_video: 'Social-YouTube',
  youtube_short: 'Social-YTShorts',
} as {[key: string]: IconName};

type DeliverableProps = {
  testID?: string;
  deliverable: CampaignBriefDeliverableItem;
  editable?: boolean;
  onChange?: (value: string) => void;
  onRemove: () => void;
  minimumChildContentSubmissionCount?: number;
  isInvalid: boolean;
  handleOnFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onMouseDown?: (
    event: React.MouseEvent<HTMLDivElement | HTMLTextAreaElement>
  ) => void;
  isFocused?: boolean;
  readOnly?: boolean;
  onBlur: () => void;
};

function Deliverable({
  testID = 'deliverable-item',
  deliverable,
  editable,
  onChange,
  onRemove,
  handleOnFocus,
  onMouseDown,
  onBlur,
  minimumChildContentSubmissionCount,
  isInvalid,
  isFocused = false,
  readOnly = false,
}: DeliverableProps) {
  const youtubeOverrides = useMemo(() => {
    if (deliverable.itemType.includes('youtube')) {
      return YOUTUBE_DS_ICON_OVERRIDES;
    }
    return {};
  }, [deliverable.itemType]);

  function getHeader() {
    return (
      <div className={styles.deliverableHeader}>
        <div className={styles.left}>
          <Icon
            size="medium"
            appearance="neutral"
            color={designSystemToken('semantic.fg.primary')}
            name={DELIVERABLE_ICON_NAME[deliverable.itemType] as IconName}
            {...youtubeOverrides}
          />
          <div
            className={styles.deliverableType}
            data-testid={`${testID}--header`}
          >
            <Body size="md">
              {translate(
                `${TRANSLATION_PREFIX}.deliverable-type-${deliverable.itemType}`
              )}
            </Body>
            <Body size="md">-</Body>
            <Body size="md" className={styles.deliverableItemType}>
              {translate(`${TRANSLATION_PREFIX}.${deliverable.itemType}`, {
                count: minimumChildContentSubmissionCount,
              })}
            </Body>
          </div>
        </div>
        <div className={styles.right}>
          {editable ? (
            <div
              className={styles.removeIcon}
              onClick={onRemove}
              data-testid={`${testID}--remove`}
            >
              <Icon
                size="medium"
                appearance="neutral"
                color={designSystemToken('semantic.fg.secondary')}
                name="Actions-Remove"
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  function getDescriptionInput() {
    return (
      <Textarea
        testID={`${testID}--textarea`}
        className={`${styles.textarea} ${!editable ? styles.disabled : ''}`}
        placeholder={translate(`${TRANSLATION_PREFIX}.deliverable-placeholder`)}
        rows={3}
        value={deliverable.description}
        onChange={
          readOnly ? undefined : ({target: {value}}) => onChange?.(value)
        }
        onFocus={handleOnFocus}
        onMouseDown={onMouseDown}
        onBlur={onBlur}
        disabled={!editable}
        readOnly={readOnly}
      />
    );
  }

  return (
    <div
      key={deliverable.id}
      className={`${styles.deliverable} ${isFocused ? styles.focused : ''} ${
        isInvalid ? styles.invalid : ''
      }`}
      data-testid={testID}
    >
      {getHeader()}
      {getDescriptionInput()}
    </div>
  );
}

interface DeliverableWrapperProps extends DeliverableProps {
  index: number;
  briefFieldManager: BriefFieldManagerAttributes;
  campaignBriefDeliverableItems: CampaignBriefDeliverableItem[];
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number
  ) => void;
  saveCampaign?: SaveCampaign;
  campaignId: string;
  viewPresentationId?: string;
}

function DeliverableWrapper(props: DeliverableWrapperProps) {
  const {
    deliverable,
    index,
    editable,
    briefFieldManager,
    campaignBriefDeliverableItems,
    updateBriefForm,
    saveCampaign,
    campaignId,
    viewPresentationId,
  } = props;

  const handleChangeExistingDeliverableItemRef = useRef<any>(null);
  handleChangeExistingDeliverableItemRef.current = (description: string) =>
    updateBriefForm?.(
      `campaignBriefDeliverableItems`,
      set(
        `${index}.description`,
        description,
        briefFieldManager.fields.campaignBriefDeliverableItems?.value
      ),
      'campaignBriefDeliverableItems'
    );

  return (
    <BriefFieldHelperPopper
      fieldName={`${briefFieldManager.fields.campaignBriefDeliverableItems?.agentKey}.${deliverable.id}`}
      fieldValue={deliverable.description}
      onChange={handleChangeExistingDeliverableItemRef.current}
      saveCampaign={saveCampaign}
      disabled={!editable}
      offsetY={0}
      campaignId={campaignId}
      viewPresentationId={viewPresentationId}
    >
      {(childrenProps) => (
        <Deliverable
          {...childrenProps}
          {...props}
          onChange={handleChangeExistingDeliverableItemRef.current}
        />
      )}
    </BriefFieldHelperPopper>
  );
}

export default DeliverableWrapper;
