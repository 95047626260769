import React from 'react';
import {Body, designSystemToken, Label} from '@lightricks/react-design-system';
import elevationShadow from '@/utils/elevationShadow';
import translate from '@/utils/translate';
import {Flag} from '@/types/brandSafetyToleranceSettings';
import {
  Severity,
  SEVERITY_COLORS,
} from '@/components/creator-vetting-report/constants';
import DiscreteSlider from '@/components/discrete-slider/DiscreteSlider';
import styles from './CategoryFlag.module.scss';

const VALUE_MIN = 0;
const VALUE_MID = 0.33;
const VALUE_MAX = 0.66;

interface CategoryFlagProps {
  flag: Flag;
  onChange: (flagId: string, value: number) => void;
  disabled: boolean;
}

function CategoryFlag(props: CategoryFlagProps) {
  const {flag, onChange, disabled} = props;
  const flagValue = VALUE_MAX - flag.value;

  const getThumbColor = (value: number) => {
    if (value === VALUE_MIN) {
      return SEVERITY_COLORS[Severity.HIGH];
    }
    if (value === VALUE_MID) {
      return SEVERITY_COLORS[Severity.MEDIUM];
    }
    return SEVERITY_COLORS[Severity.LOW];
  };

  const sliderOverrideSettingsSx = {
    'marginTop': 0,
    '& .MuiSlider-track': {
      border: 'none',
      background: disabled
        ? designSystemToken('semantic.fg.disabled')
        : `linear-gradient(90deg, ${SEVERITY_COLORS[Severity.HIGH]} 0%, ${
            flagValue > VALUE_MID
              ? SEVERITY_COLORS[Severity.LOW]
              : SEVERITY_COLORS[Severity.MEDIUM]
          } 100%)`,
    },
    '& .MuiSlider-rail': {
      background: designSystemToken('semantic.bg.neutral-inverse'),
      opacity: 1,
    },
    '& .MuiSlider-mark': {
      'backgroundColor': 'white',
      'height': '8px',
      'width': '8px',
      'borderRadius': '50%',
      'marginLeft': '-4px',
      'border': '2px solid',
      '&[data-index="0"]': {
        borderColor: SEVERITY_COLORS[Severity.HIGH],
        backgroundColor: 'white',
        opacity: 1,
      },
      '&[data-index="1"]': {
        borderColor:
          flagValue >= VALUE_MID
            ? SEVERITY_COLORS[Severity.MEDIUM]
            : designSystemToken('semantic.bg.disabled'),
        backgroundColor: 'white',
        opacity: 1,
      },
      '&[data-index="2"]': {
        borderColor:
          flagValue >= VALUE_MAX
            ? SEVERITY_COLORS[Severity.LOW]
            : designSystemToken('semantic.bg.disabled'),
        backgroundColor: 'white',
        opacity: 1,
      },
    },
    '& .MuiSlider-markLabel': {
      'color': designSystemToken('semantic.fg.secondary'),
      '&[data-index="0"]': {
        transform: 'translateX(0%)',
      },
      '&[data-index="2"]': {
        transform: 'translateX(-100%)',
      },
    },
    '& .MuiSlider-thumb': {
      'color': `${getThumbColor(flagValue)}`,
      'height': '14px',
      'width': '14px',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: elevationShadow(2),
      },
      '&:before': {
        boxShadow: elevationShadow(1),
      },
    },
    '&.Mui-disabled': {
      'cursor': 'not-allowed',
      '& .MuiSlider-rail': {
        background: designSystemToken('semantic.bg.disabled'),
        opacity: 0.5,
      },
      '& .MuiSlider-thumb': {
        color: designSystemToken('semantic.fg.tertiary'),
      },
      '& .MuiSlider-mark': {
        borderColor: designSystemToken('semantic.bg.disabled'),
      },
      '& .MuiSlider-markLabel': {
        color: designSystemToken('semantic.fg.disabled'),
      },
    },
  };

  const flagInfo = () => {
    if (flag.value === VALUE_MIN) {
      return translate('Flag all risks');
    }
    if (flag.value === VALUE_MID) {
      return translate('Flag med + high level risks');
    }
    return translate('Flag only high level risks');
  };

  const labelColor = (labelValue: number) => {
    if (disabled) {
      return designSystemToken('semantic.fg.disabled');
    }
    if (labelValue === flagValue) {
      return designSystemToken('semantic.fg.neutral');
    }
    return designSystemToken('semantic.fg.inverse-secondary');
  };

  const headerTextColor = disabled
    ? designSystemToken('semantic.fg.disabled')
    : designSystemToken('semantic.fg.neutral');

  return (
    <div className={styles.categoryFlagContainer}>
      <div className={styles.header}>
        <Label size="lg" className={styles.flagName} color={headerTextColor}>
          {flag.displayName}
        </Label>
        <Body size="md" className={styles.flagInfo} color={headerTextColor}>
          {flagInfo()}
        </Body>
      </div>
      <DiscreteSlider
        min={VALUE_MIN}
        max={VALUE_MAX}
        step={VALUE_MID}
        marks={[
          {
            value: VALUE_MIN,
            label: (
              <Body size="md" color={labelColor(VALUE_MIN)}>
                {translate('Less flags')}
              </Body>
            ),
          },
          {
            value: VALUE_MID,
            label: (
              <Body size="md" color={labelColor(VALUE_MID)}>
                {translate('Moderate')}
              </Body>
            ),
          },
          {
            value: VALUE_MAX,
            label: (
              <Body size="md" color={labelColor(VALUE_MAX)}>
                {translate('More flags')}
              </Body>
            ),
          },
        ]}
        value={flagValue}
        onChange={(_, value: number | number[]) => {
          onChange(flag.id, VALUE_MAX - (value as number));
        }}
        disabled={disabled}
        sx={sliderOverrideSettingsSx}
      />
    </div>
  );
}

export default CategoryFlag;
