import {IconProps} from '@lightricks/react-design-system/dist/components/icon/IconProps';
import LEGACY_COLORS from '@/config/legacyColors';

export const CONTENT_SUBMISSION_STATUS = {
  PENDING: 'pending',
  APPROVED: 'accepted',
  ARCHIVED: 'archived',
  REJECTED: 'rejected',
  EDITS_REQUESTED: 'rejected',
};

export const CONTENT_SUBMISSIONS_PER_PAGE = 40;

export const CONTENT_SUBMISSION_STATE_MAP = {
  pending: 'pending,edits_requested',
  accepted: 'accepted',
  archived: 'archived',
};

export enum NETWORKS {
  INSTAGRAM = 'instagram',
  PINTEREST = 'pinterest',
  TIKTOK = 'tiktok',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

export const uuidRegex =
  '[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}';

export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  GONE: 410,
};

export const ENTER_KEY = {
  key: 'Enter',
  charCode: 13,
};

export const ANONYMOUS_USER_AVATAR_NAME = 'anonymous_user.jpg';

// overrides to make Social-YouTube icon have height of 20px and correct color according to YT brand guidelines
// Usage: <Icon name="Social-YouTube" appearance="neutral" {...YOUTUBE_DS_ICON_OVERRIDES} />
export const YOUTUBE_DS_ICON_OVERRIDES = {
  size: 'large',
  color: LEGACY_COLORS.ALMOST_BLACK,
  style: {transform: 'scale(1.134) translateX(-2px)'},
} as Partial<IconProps>;

export const YOUTUBE_DS_ICON_INVERSE_OVERRIDES = {
  size: 'large',
  // color: LEGACY_COLORS.ALMOST_BLACK,
  style: {transform: 'scale(1.134)'},
} as Partial<IconProps>;
