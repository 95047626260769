import {MRT_ColumnDef, MRT_Row} from 'material-react-table';
import React, {useCallback, useMemo} from 'react';
import getEngagementRateString from '@/utils/getEngagementRateString';
import getFollowersCountString from '@/utils/getFollowersCountString';
import translate from '@/utils/translate';
import {SearchDisplayCreator} from '@/types/models/search-creators/searchCreators';
import UsernameCell from '@/views/creators/components/creators-table/UsernameCell';
import getProfileImageUrl from '@/views/creators/search/utils/getProfileImageUrl';
import CreatorSocialsIcons from '@/components/creator-card/CreatorSocialsIcons';
import styles from './Column.module.scss';

const TRANSLATION_PREFIX = 'views.creators';

interface UseCreatorsTableColumnsProps {
  selectedPlatform: string;
  selectedCreators: Map<string, {id: string}>;
  onSelectionCreatorChange: (id: string, isSelected: boolean) => void;
  onSocialNetworkClick?: (networkProvider: string, creatorId: string) => void;
}

function useCreatorsTableColumns(props: UseCreatorsTableColumnsProps) {
  const {
    selectedPlatform,
    selectedCreators,
    onSelectionCreatorChange,
    onSocialNetworkClick,
  } = props;

  const renderUsernameCell = useCallback(
    ({row}: {row: MRT_Row<SearchDisplayCreator>}) => {
      const {id, fullName, profileImage} = row.original;

      return (
        <UsernameCell
          picture={getProfileImageUrl(profileImage)}
          name={fullName}
          isSelected={selectedCreators.has(id)}
          onCheckboxChange={(checked: boolean) => {
            onSelectionCreatorChange(id, checked);
          }}
        />
      );
    },
    [selectedCreators, onSelectionCreatorChange]
  );

  const renderFollowersCell = useCallback(
    ({row}: {row: MRT_Row<SearchDisplayCreator>}) => {
      const {followers} = row.original;

      return <div> {getFollowersCountString(followers)} </div>;
    },
    []
  );

  const renderEngagementRateCell = useCallback(
    ({row}: {row: MRT_Row<SearchDisplayCreator>}) => {
      const {engagementRate} = row.original;

      const engagementRateString = getEngagementRateString(engagementRate);

      return (
        <div
          className={`${styles.engagementRateCell} ${
            engagementRateString === '-' ? styles.bold : ''
          }`}
        >
          {engagementRateString}
        </div>
      );
    },
    []
  );

  const renderSocialNetworksCell = useCallback(
    ({row}: {row: MRT_Row<SearchDisplayCreator>}) => {
      const {socialNetworks} = row.original;

      return (
        <div className={styles.socialNetworksCell}>
          <CreatorSocialsIcons
            socialNetworks={socialNetworks}
            selectedPlatformProvider={selectedPlatform}
            onClickSocialNetwork={(networkProvider: string) => {
              onSocialNetworkClick?.(networkProvider, row.original.id);
            }}
          />
        </div>
      );
    },
    [selectedPlatform, onSocialNetworkClick]
  );

  const columns: MRT_ColumnDef<SearchDisplayCreator>[] = useMemo(
    () => [
      {
        id: 'name',
        header: translate(
          `${TRANSLATION_PREFIX}.components.table.columns.name`
        ),
        accessorKey: 'username',
        enableSorting: false,
        Cell: renderUsernameCell,
      },
      {
        id: 'followers',
        header:
          selectedPlatform === 'youtube'
            ? translate('Subscriber Count')
            : translate(
                `${TRANSLATION_PREFIX}.components.table.columns.followers`
              ),
        accessorKey: 'followers',
        enableSorting: false,
        Cell: renderFollowersCell,
      },
      {
        id: 'engagementRate',
        header:
          selectedPlatform === 'youtube'
            ? translate('Avg Eng')
            : translate(
                `${TRANSLATION_PREFIX}.components.table.columns.engagement-rate`
              ),
        accessorKey: 'engagementRate',
        enableSorting: false,
        Cell: renderEngagementRateCell,
      },
      {
        id: 'instagramFollowers',
        header: translate(
          `${TRANSLATION_PREFIX}.components.table.columns.instagram-followers`
        ),
        accessorKey: 'followers',
        enableSorting: false,
      },
      {
        id: 'socialNetworks',
        header: translate(
          `${TRANSLATION_PREFIX}.components.table.columns.social-networks`
        ),
        enableSorting: false,
        accessorKey: 'socialNetworks',
        Cell: renderSocialNetworksCell,
      },
    ],
    [
      renderUsernameCell,
      renderFollowersCell,
      renderEngagementRateCell,
      renderSocialNetworksCell,
    ]
  );

  return columns;
}

export default useCreatorsTableColumns;
