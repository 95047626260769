import {snakeCase} from 'lodash';
import axiosWithAuth from '@/utils/axiosWithAuth';
import {BrandSafetyToleranceSettings} from '@/types/brandSafetyToleranceSettings';

interface BrandSafetyToleranceUpdaters {
  update: {
    brandId: string;
    categories: BrandSafetyToleranceSettings['categories'];
  };
}

function mapCategoriesToDTO(
  categories: BrandSafetyToleranceSettings['categories']
) {
  return categories.map((category) => ({
    id: category.id,
    name: category.name.toLowerCase(),
    status: category.status,
    flags: category.flags.map((flag) => ({
      name: snakeCase(flag.name),
      value: flag.value,
    })),
  }));
}

const brandSafetyToleranceSettingsUpdaters = {
  update: async (payload: BrandSafetyToleranceUpdaters['update']) => {
    const {brandId, categories} = payload;
    return axiosWithAuth.post(`/brands_safety_settings/${brandId}/batch`, {
      categories: mapCategoriesToDTO(categories),
    });
  },
};

export default brandSafetyToleranceSettingsUpdaters;
