import {SortingState} from '@tanstack/table-core';
import {create} from 'zustand';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';

type VettingHubTabValue = CreatorBrandSafetyVetting['status'] | 'all';

export const TAB_VALUES: {[key: string]: VettingHubTabValue} = {
  ALL: 'all',
  READY_FOR_REVIEW: 'ready_for_review',
  APPROVED: 'approved',
  DECLINED: 'declined',
  INVITED: 'invited',
  VETTING_UNAVAILABLE: 'vetting_unavailable',
};

export type VettingHubState = {
  activeTab: VettingHubTabValue;
  sorting: SortingState;
  pageScrollPosition: number;
  searchNetwork: 'instagram' | 'tiktok';
  searchValue: string;
  hoveredRow: CreatorBrandSafetyVetting | null;
};

const DEFAULT_STATE: VettingHubState = {
  activeTab: 'all',
  sorting: [{desc: false, id: 'status'}],
  pageScrollPosition: 0,
  searchNetwork: 'instagram',
  searchValue: '',
  hoveredRow: null,
};

const vettingHubStore = create<VettingHubState>(() => DEFAULT_STATE);

const setActiveTab = (activeTab: VettingHubTabValue) => {
  vettingHubStore.setState({activeTab});
};

const setSorting = (sorting: SortingState) => {
  vettingHubStore.setState({sorting});
};

const setPageScrollPosition = (pageScrollPosition: number) => {
  vettingHubStore.setState({pageScrollPosition});
};

const setSearchNetwork = (searchNetwork: VettingHubState['searchNetwork']) => {
  vettingHubStore.setState({searchNetwork});
};

const setSearchValue = (searchValue: VettingHubState['searchValue']) => {
  vettingHubStore.setState({searchValue});
};

const setHoveredRow = (hoveredRow: CreatorBrandSafetyVetting | null) => {
  vettingHubStore.setState({hoveredRow});
};

export const vettingHubActions = {
  setActiveTab,
  setSorting,
  setPageScrollPosition,
  setSearchNetwork,
  setSearchValue,
  setHoveredRow,
};

export default vettingHubStore;
