import {create, StateCreator} from 'zustand';
import {persist} from 'zustand/middleware';
import {ToleranceFilter} from '@/components/creator-vetting-report/constants';

type BrandSafetyToleranceSettingsState = {
  toleranceFilter: ToleranceFilter;
};

const toleranceSettingsSlice: StateCreator<
  BrandSafetyToleranceSettingsState,
  [['zustand/persist', unknown]]
> = () => ({
  toleranceFilter: ToleranceFilter.DEFAULT,
});

const brandSafetyToleranceSettingsStore =
  create<BrandSafetyToleranceSettingsState>()(
    persist(toleranceSettingsSlice, {
      name: 'brandSafetyToleranceSettingsStore',
    })
  );

const setToleranceFilter = (toleranceFilter: ToleranceFilter) =>
  brandSafetyToleranceSettingsStore.setState({toleranceFilter});

export const brandSafetyToleranceSettingsActions = {
  setToleranceFilter,
};

export default brandSafetyToleranceSettingsStore;
