import React from 'react';
import {designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import IconStat from '@/components/icon-stat';
import styles from './CreatorStats.module.scss';

interface CreatorStatsProps {
  followers?: string;
  engagementRate?: string;
  averageImpressions?: string;
  network?: string | null;
}

function CreatorStats(props: CreatorStatsProps) {
  const {
    followers = '',
    engagementRate = '',
    averageImpressions = '',
    network,
  } = props;
  return (
    <div className={styles.creatorStatsContainer}>
      <div className={styles.followersAndEngagement}>
        <Conditional condition={!!followers}>
          <IconStat
            title={followers}
            subtitle={
              network === 'youtube'
                ? translate('Subscribers')
                : translate('Followers')
            }
            icon="Navigation-Profile"
            iconColor={designSystemToken('semantic.fg.secondary')}
          />
        </Conditional>
        <Conditional condition={!!engagementRate}>
          <IconStat
            title={engagementRate}
            subtitle={translate('Engagement')}
            icon="Features-Pitch-TurnOn"
            iconColor={designSystemToken('semantic.fg.secondary')}
          />
        </Conditional>
      </div>
      <div className={styles.averageImpressions}>
        <Conditional condition={!!averageImpressions}>
          <IconStat
            title={averageImpressions}
            subtitle={translate('Average Impressions')}
            icon="Features-Eye"
            iconColor={designSystemToken('semantic.fg.secondary')}
          />
        </Conditional>
      </div>
    </div>
  );
}

export default CreatorStats;
