import React, {useState} from 'react';
import {Label} from '@lightricks/react-design-system';
import Conditional from '@/components/conditional';
import styles from './ToggleableTags.module.scss';

const DEFAULT_TAGS_LIMIT = 8;

export type ToggleableTagsProps = {
  testID?: string;
  tags: string[];
  size?: 'sm' | 'md' | 'lg';
  initialShowAllTags?: boolean;
  onShowAllTagsChange?: (showAllTags: boolean) => void;
  tagsLimit?: number;
  tagsContainerClassName?: string;
  tagClassName?: string;
};

function ToggleableTags(props: ToggleableTagsProps) {
  const {
    testID = 'toggleable-tags',
    tags,
    size = 'lg',
    initialShowAllTags = false,
    onShowAllTagsChange,
    tagsLimit = DEFAULT_TAGS_LIMIT,
    tagsContainerClassName = '',
    tagClassName = '',
  } = props;

  const [showAllTags, setShowAllTags] = useState(
    !(tags.length > tagsLimit) || initialShowAllTags
  );

  const tagsForDisplay = showAllTags ? tags : tags.slice(0, tagsLimit);

  return (
    <div
      className={`${styles.toggleableTagsContainer} ${tagsContainerClassName}`}
      data-testid={testID}
    >
      {tagsForDisplay.map((tag) => (
        <Label
          size={size}
          key={tag}
          className={`${styles.tag} ${tagClassName} ${styles[size]}`}
        >
          {tag}
        </Label>
      ))}
      <Conditional condition={!showAllTags && tags.length - tagsLimit > 0}>
        <Label
          data-testid={`${testID}--toggle-button`}
          size={size}
          sx={{textDecoration: 'underline', cursor: 'pointer', fontSize: 14}}
          onClick={() => {
            setShowAllTags(true);
            onShowAllTagsChange?.(true);
          }}
        >
          +{tags.length - tagsLimit}
        </Label>
      </Conditional>
    </div>
  );
}

export default ToggleableTags;
