import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
} from '@aws-sdk/client-cognito-identity';
import {S3Client, PutObjectCommand} from '@aws-sdk/client-s3';
import Sentry from '@/services/sentry/Sentry';
import urlToFile from '@/utils/urlToFile';
import {getEnv} from '@/config/environment';
import api from '@/api';

const CONFIG_REGION = 'us-east-1';
const COGNITO_REFRESH_INTERVAL = 50 * 60 * 1000; // 50 minutes; expiration is 60 minutes
const COGNITO_URL = '/dashboard_user/cognito_identity';

type LocalFile = {
  name: string;
  size: number;
  type: string;
  blob?: Blob;
};

type Settings = {
  cognitoRefreshInterval: number;
  cognitoURL: string;
  maxRetries: number;
  poolId: string;
};

interface S3Setting extends Settings {
  buckets: {
    [key: string]: string | undefined;
  };
}

export class Aws {
  private s3Client: S3Client | null = null;

  private cognitoClient: CognitoIdentityClient;

  private refreshInterval: NodeJS.Timeout | null = null;

  private uploadController: AbortController | null = null;

  private AWS_MAX_RETRIES: number;

  private uploadProgress = {
    loaded: 0,
    total: 0,
  };

  constructor() {
    const settings = Aws.s3Settings;
    this.AWS_MAX_RETRIES = settings.maxRetries;
    this.cognitoClient = new CognitoIdentityClient({
      region: CONFIG_REGION,
      maxAttempts: settings.maxRetries,
    });
  }

  static get s3Settings(): S3Setting {
    return {
      buckets: {
        campaignBrief: getEnv().VITE_S3_BUCKET_CONTENT_SUBMISSIONS,
        contentSubmission: getEnv().VITE_S3_BUCKET_CONTENT_SUBMISSIONS,
        messageAttachments: getEnv().VITE_S3_BUCKET_MESSAGE_ATTACHMENTS,
      },
      cognitoRefreshInterval: COGNITO_REFRESH_INTERVAL,
      cognitoURL: COGNITO_URL,
      poolId: getEnv().VITE_COGNITO_POOL_ID,
      maxRetries: 8,
    };
  }

  get uploadPercent(): number {
    if (this.uploadProgress.total === 0) return 0;
    return Math.round(
      (this.uploadProgress.loaded / this.uploadProgress.total) * 100
    );
  }

  private async getCredentials() {
    try {
      // Get token from backend
      const response = await api.get(COGNITO_URL);
      const data = response.data as {id: string; token: string};

      // Get credentials from Cognito
      const command = new GetCredentialsForIdentityCommand({
        IdentityId: data.id,
        Logins: {
          'cognito-identity.amazonaws.com': data.token,
        },
      });

      const result = await this.cognitoClient.send(command);

      if (!result.Credentials) {
        throw new Error('No credentials received from Cognito');
      }

      // Return both credentials and identity ID
      return {
        credentials: result.Credentials,
        identityId: data.id,
      };
    } catch (error) {
      console.error('AWS credentials error:', error);
      Sentry.captureException(error as Error, {
        message: 'Failed to get AWS credentials',
        extra: {url: COGNITO_URL},
      });
      throw error;
    }
  }

  private async initializeS3Client() {
    try {
      const {credentials} = await this.getCredentials();

      this.s3Client = new S3Client({
        region: CONFIG_REGION,
        credentials: {
          accessKeyId: credentials.AccessKeyId || '',
          secretAccessKey: credentials.SecretKey || '',
          sessionToken: credentials.SessionToken,
        },
        maxAttempts: this.AWS_MAX_RETRIES as number,
      });

      // Setup refresh interval
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }

      this.refreshInterval = setInterval(
        () => this.initializeS3Client(),
        COGNITO_REFRESH_INTERVAL
      );
    } catch (error) {
      console.error('S3 client initialization error:', error);
      Sentry.captureException(error as Error, {
        message: 'Failed to initialize S3 client',
        extra: {error: error instanceof Error ? error.message : String(error)},
      });
      throw error;
    }
  }

  private async ensureS3Client() {
    if (!this.s3Client) {
      await this.initializeS3Client();
    }
    return this.s3Client as S3Client;
  }

  async uploadToBucket(bucketName: string, file: LocalFile): Promise<string> {
    try {
      const s3Client = await this.ensureS3Client();

      // Reset progress
      this.uploadProgress = {loaded: 0, total: 0};

      // Get identity ID for the path
      const {identityId} = await this.getCredentials();

      // Create unique file name with identity path
      const fileName = `${identityId}/${encodeURIComponent(file.name)}`;

      // Prepare file data
      const fileData = file.blob
        ? new Uint8Array(await file.blob.arrayBuffer())
        : new Uint8Array(await new Response(file as any).arrayBuffer());

      // Setup abort controller
      this.uploadController = new AbortController();

      const command = new PutObjectCommand({
        Bucket: bucketName,
        Key: fileName,
        ContentType: file.type,
        Body: fileData,
      });

      await s3Client.send(command, {
        abortSignal: this.uploadController.signal,
      });

      return `https://${bucketName}.s3.amazonaws.com/${encodeURIComponent(
        fileName
      )}`;
    } catch (error) {
      console.error('S3 upload error:', error);
      Sentry.captureException(error as Error, {
        message: 'Failed to upload to S3',
        extra: {
          bucketName,
          fileName: file.name,
          error: error instanceof Error ? error.message : String(error),
        },
      });
      throw error;
    } finally {
      this.uploadController = null;
    }
  }

  async uploadToBucketFromUrl(bucketName: string, url: string) {
    try {
      const fileName = url.split('?')[0].split('/').pop() || '';
      const file = await urlToFile(url, fileName);

      return await this.uploadToBucket(bucketName, file);
    } catch (error: any) {
      Sentry.captureException(error, {message: 'S3 upload from URL failure'});
      throw error;
    }
  }

  abortUpload() {
    if (this.uploadController) {
      this.uploadController.abort();
    }
  }

  dispose() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    this.abortUpload();
  }
}

export default Aws;
