import React, {useState, useEffect} from 'react';
import {Stack} from '@mui/material';
import {Button, Headline} from '@lightricks/react-design-system';
import raiseFlashMessage from '@/utils/raiseFlashMessage';
import queryClient from '@/utils/reactQueryClient';
import {EmailPreference} from '@/types/emailPreference';
import useUpdateEmailPreferences from '@/hooks/mutations/use-update-email-preferences';
import useEmailPreferencesQuery from '@/hooks/queries/use-email-preferences-query';
import translate from '../../../utils/translate';
import styles from './Notifications.module.scss';
import NotificationsTable from './NotificationsTable';

function Notifications() {
  const TRANSLATION_PREFIX = 'components.preferences.notifications';
  const {emailPreferences: initialEmailPreferences}: any =
    useEmailPreferencesQuery();
  const [emailPreferences, setEmailPreferences] = useState<EmailPreference[]>(
    []
  );
  const [changedPreferences, setChangedPreferences] = useState<
    Partial<EmailPreference[]>
  >([]) as any;

  useEffect(() => {
    if (initialEmailPreferences) {
      setEmailPreferences(initialEmailPreferences);
    }
  }, [initialEmailPreferences]);

  const updateEmailPreferencesMutation = useUpdateEmailPreferences();

  const handleUpdatePreferences = async () => {
    await updateEmailPreferencesMutation.mutateAsync(changedPreferences);
    queryClient.setQueryData(['emailPreferences'], changedPreferences);

    raiseFlashMessage({
      status: 'success',
      mode: 'light',
      message: translate(`${TRANSLATION_PREFIX}.save-preferences-banner-title`),
    });
  };

  const handleUnsubscribe = async () => {
    const updatedPreferences = emailPreferences.map((preference) => {
      if (preference.name === 'brand_digest') {
        return {...preference, enabled: false, frequency: null};
      }
      return {...preference, enabled: false, frequency: null};
    });
    setEmailPreferences(updatedPreferences);
    setChangedPreferences(updatedPreferences);
    await updateEmailPreferencesMutation.mutateAsync(updatedPreferences as any);
    queryClient.setQueryData(['emailPreferences'], updatedPreferences);

    raiseFlashMessage({
      status: 'success',
      mode: 'light',
      message: translate(`${TRANSLATION_PREFIX}.unsubscribe-banner-title`),
    });
  };

  if (!emailPreferences || emailPreferences.length === 0) {
    return null;
  }

  return (
    <Stack className={styles.notificationsContainer}>
      <Stack gap="40px">
        <Stack gap="9px">
          <Headline size="lg">
            {translate(`${TRANSLATION_PREFIX}.title`)}
          </Headline>
        </Stack>
        <Stack gap="8px">
          <NotificationsTable
            emailPreferences={emailPreferences}
            setEmailPreferences={setEmailPreferences}
            setChangedPreferences={setChangedPreferences}
          />
          <Stack direction="row" className={styles.buttons}>
            <Button
              appearance="neutral"
              mode="tinted"
              size="large"
              onClick={handleUnsubscribe}
              className={styles.unsubscribeButton}
            >
              {translate(`${TRANSLATION_PREFIX}.unsubscribe`)}
            </Button>
            <Button
              appearance="neutral"
              mode="filled"
              size="large"
              onClick={handleUpdatePreferences}
              className={styles.updateButton}
            >
              {translate(`${TRANSLATION_PREFIX}.save-preferences`)}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Notifications;
