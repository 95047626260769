import React from 'react';
import {
  Body,
  Button,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Link from '@/components/link';
import styles from './EmptyState.module.scss';

function EmptyState() {
  return (
    <div className={styles.emptyStateContainer}>
      <div className={styles.emptyStateContent}>
        <div className={styles.content}>
          <Headline
            size="sm"
            color={designSystemToken('semantic.fg.secondary')}
          >
            {translate('Smart Matches Unavailable')}
          </Headline>
          <Body
            size="md"
            className={styles.description}
            color={designSystemToken('semantic.fg.secondary')}
          >
            {translate(
              'We’re having trouble loading matches for this campaign. Try switching to another campaign or find creators using search.'
            )}
          </Body>
        </div>
        <div>
          <Link to="/search">
            <Button
              appearance="neutral"
              mode="tinted"
              size="small"
              startIcon={
                <Icon
                  appearance="neutral"
                  size="medium"
                  name="Actions-Search-Generic"
                />
              }
            >
              {translate('Go to Search')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EmptyState;
